var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
            _vm._v("公司名稱")
          ]),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "網站名稱" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.formData.SiteTitle,
                              expression: "formData.SiteTitle",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.SiteTitle },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "SiteTitle",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }