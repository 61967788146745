var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { "hide-footer": "", title: "下載QRCode" } },
        [
          _c("div", { staticClass: "d-block text-center" }),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
              _vm._v("下載為圖片(.zip)")
            ]),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { role: "button" },
                  on: {
                    click: function($event) {
                      return _vm.returnModal("zip")
                    }
                  }
                },
                [_vm._v("下載")]
              )
            ]),
            _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
              _vm._v("下載為文件(.pdf)")
            ]),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { role: "button" },
                  on: {
                    click: function($event) {
                      return _vm.returnModal("pdf")
                    }
                  }
                },
                [_vm._v("下載")]
              )
            ]),
            _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
              _vm._v("下載為文字檔(.txt)")
            ]),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { role: "button" },
                  on: {
                    click: function($event) {
                      return _vm.returnModal("txt")
                    }
                  }
                },
                [_vm._v("下載")]
              )
            ])
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }