<template>
  <div class="fieldsPanel">
    <v-app id="inspire" class="myGridPanel">

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
<!--        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>複製</button>-->
        <button type="button" class="btn btn-primary" @click="btnCopyLink"><i class="fa fa-save" ></i>複製播放連結</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

      <ValidationObserver ref="form">
        <div class="form-group row">
          <label for="" class="a_thinlabel col-sm-2">頻道名稱</label>
          <div class="a_thinInputDiv col-sm-10 ">
            <validation-provider rules="required" v-slot="{ errors }"  name="Name" >
              <input type="text" class="form-control " placeholder="" v-model="formData.Name" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="a_thinlabel col-sm-2">重新整理次數</label>
          <div class="a_thinInputDiv col-sm-10 ">
            <validation-provider rules="required" v-slot="{ errors }"  name="RefreshLoopCount" >
              <input type="text" class="form-control " placeholder="" v-model="formData.RefreshLoopCount" >  (輪播幾次後，重新下載頻道內容)
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="a_thinlabel col-sm-2">頻道裝置</label>
          <div class="a_thinInputDiv col-sm-10">

            旋轉角度
            <div class="btn-group btn-group-toggle">
              <label class="btn btn-primary" :class="formData.RotateAngle===0?'active':''">
                <input type="radio" value="0"  v-model.number="formData.RotateAngle" autocomplete="off"> 0
              </label>
              <label class="btn btn-primary" :class="formData.RotateAngle===90?'active':''">
                <input type="radio" value="90" v-model.number="formData.RotateAngle" autocomplete="off"> 90
              </label>
              <label class="btn btn-primary" :class="formData.RotateAngle===180?'active':''">
                <input type="radio" value="180" v-model.number="formData.RotateAngle" autocomplete="off"> 180
              </label>
              <label class="btn btn-primary" :class="formData.RotateAngle===270?'active':''">
                <input type="radio" value="270" v-model.number="formData.RotateAngle" autocomplete="off"> 270
              </label>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info" style="text-align: left;">播放列表</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <EditMediaList :media-list="formData.MediaList"></EditMediaList>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info" style="text-align: left;">跑馬燈 (上)</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <EditMarqueeList :marquee="formData.MarqueeListUp"></EditMarqueeList>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info" style="text-align: left;">跑馬燈 (下)</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <EditMarqueeList :marquee="formData.MarqueeListDown"></EditMarqueeList>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <MyCopySingleLineDlg ref="myCopySingleLineDlg" ></MyCopySingleLineDlg>
    </v-app>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import EditMarqueeList from "@/pages/ubossBk/views/utube/EditMarqueeList";
import EditMediaList from "@/pages/ubossBk/views/utube/EditMediaList";
import apiUBossUTubeBk from "@/pages/ubossBk/util/apiUBossUTubeBk";
import MyCopySingleLineDlg from "@/pages/commonComponent/MyCopySingleLineDlg";
// import ud from "@/pages/commonUBoss/ubossDeclare";
export default {
  components: { MyCopySingleLineDlg, EditMediaList, EditMarqueeList},
  data: function () {
    return {
      systemId: "",
      formData: {
      },
      formDataOld: {
        SystemId: "",
        Name: "",
        RotateAngle:0,
        RefreshLoopCount:10,
        MediaList:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
          ],
        },
        MarqueeUp:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
          ]
        },
        MarqueeDown:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
          ]
        },
      },
      formDataDemo: {
        SystemId: "",
        Name: "",
        RotateAngle:0,
        RefreshLoopCount:10,
        MediaList:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MediaType:1,
              MediaUrl:"https://via.placeholder.com/400",
              PlaySettingImagePlaySec:0,
              PlaySettingVideoStartMin:0,
              PlaySettingVideoStartSec:0,
              PlaySettingVideoEndMin:0,
              PlaySettingVideoEndSec:0,
              Memo:"",
            },
          ],
        },
        MarqueeUp:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"1010 特惠xxxxxxxxxxxxxxxxxxxx",
              MarqueeStyleCSS:"",
              Memo:""
            },
          ]
        },
        MarqueeDown:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"1010 特惠xxxxxxxxxxxxxxxxxxxx",
              MarqueeStyleCSS:"",
              Memo:""
            },
          ]
        },
      },
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossUTubeBk.api_UTubeChannel_Get(this.systemId)

        this.formData=data
      }else{
        this.formData.MediaList.SystemId=util.uuid();
        this.formData.MarqueeListUp.SystemId=util.uuid();
        this.formData.MarqueeListDown.SystemId=util.uuid();
      }
    },
    btnUpdate(){
      // let router=this.$router
      let that=this;
      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossUTubeBk.api_UTubeChannel_Save(that.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            // router.back()
          })
          //---------------------------------------
        }
      });
    },
    btnCopyLink(){

      let linkText="https://biz.uboss.cc/utube/paypaydrink/channel";

      if (this.systemId==="9894a515-fb04-4045-af1a-44e0008638e0"){
        linkText=linkText+"1";
      }else if (this.systemId==="2d6229a9-5784-4dd2-ab6c-78adc06ecb11"){
        linkText=linkText+"2";
      }else if (this.systemId==="941dc53f-0d40-49ae-bc11-13bd292d7a10"){
        linkText=linkText+"3";
      }else if (this.systemId==="96f470f2-0beb-4c84-a705-a85ec5571faf"){
        linkText=linkText+"4";
      }else if (this.systemId==="e3ece0fe-7ce1-4d9b-a894-77a3245ea6ef"){
        linkText=linkText+"5";
      }else{
        //QQ error log
      }

      this.$refs.myCopySingleLineDlg.value=linkText;
      this.$refs.myCopySingleLineDlg.showModal();
    },
    submit(){
      // for (let i = 0; i < this.exchangeRate.Details.length; i++) {
      //   let item = this.exchangeRate.Details[i];
      //
      //   if (item.Year===""){
      //     util.showErrorMessage("第"+i+1+" 行，「年」為必填欄位")
      //     return;
      //   }
      //   if (item.Month===""){
      //     util.showErrorMessage("第"+i+1+" 行，「月」為必填欄位")
      //     return;
      //   }
      //   if (item.Type===""){
      //     util.showErrorMessage("第"+i+1+" 行，「幣別」為必填欄位")
      //     return;
      //   }
      //   if (item.Rate===""){
      //     util.showErrorMessage("第"+i+1+" 行，「匯率」為必填欄位")
      //     return;
      //   }
      // }

      // apiGPK.api_Save_ExchangeRate(this.exchangeRate.SystemId,"",this.exchangeRate).then((data)=>{
      //   util.ShowMessage('儲存成功')
      // })
    },
    btnTest(){

    },

  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  width: 1500px;
}
#tblMain2{
  width: 1500px;
}
.btn{
  color: white !important;
  margin: 5px;
  /*width: px;*/
}
.short{
  width:80px;display: inline;
}
</style>
