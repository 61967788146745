var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { size: "s", "hide-footer": "", title: "" } },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("票據列印")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-4", attrs: { for: "" } }, [
              _vm._v("票據樣式:")
            ]),
            _c("div", { staticClass: "col-sm-8" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ticketDesignSystemId,
                      expression: "ticketDesignSystemId"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { width: "200px" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.ticketDesignSystemId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.printTemplates, function(item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.SystemId } },
                    [_vm._v(_vm._s(item.Name))]
                  )
                }),
                0
              )
            ])
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.toggleModal()
                }
              }
            },
            [_vm._v("列印")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }