var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "ckeditorDiv" },
      [
        _c("MyCkEditor", {
          ref: "myCkEditor",
          attrs: {
            height: "400",
            width: "100%",
            "file-upload-list-lite_is-show": true,
            "file-upload-list-lite_owner-system-id": _vm.propsWebPart.Id,
            "file-upload-list-lite_file-use-memo": this.$u
              .UBossFileUseType_OnePageWebPartHtml
          },
          model: {
            value: _vm.propsWebPart.PartHtmlContent,
            callback: function($$v) {
              _vm.$set(_vm.propsWebPart, "PartHtmlContent", $$v)
            },
            expression: "propsWebPart.PartHtmlContent"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }