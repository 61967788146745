<template>
  <div style="max-width: 1200px" >
    <COM_HeaderNav mode="udmIndex"></COM_HeaderNav>

   <MyToolbarVuetify ref="myToolbar" v-on:button-click="toolbarButton_Click"> </MyToolbarVuetify>

    <div class="bk_firstBlock"/>

    <MyFormPanel ref="form" :data-source="formData">
      <div slot="testSlot">
        <button class="btn btn-primary" >送出</button>
      </div>
    </MyFormPanel>

  </div>

</template>

<script>
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import {MyToolbar, MyToolbarGroup} from "@/pages/commonComponent/js/MyToolbarVuetify";
import MyFormPanel from "@/pages/commonComponent/MyFormPanel/MyFormPanel.vue";
import {
  MyFormPanelField,
  MyFormPanelFieldUITypeEnum, MyFormPanelForm,
  MyFormPanelPanel
} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";
import {MyToolbarButtonIdEnum} from "@/pages/commonComponent/js/MyToolbarVuetify";

export default {
  components: {MyFormPanel, MyToolbarVuetify, COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData:{
        name:"taylor",
        tel:"0915071457",
        address:"桃園市中壢區福州路130號",
      }
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossShopBk.api_Member_Get(this.systemId)

        this.formData=data
      }

    },
    initToolbar(){
      let toolbarGroup1=new MyToolbarGroup();
      toolbarGroup1
          .addButton_BackDefault()
          .addButton_Save()
      ;

      let toolbar=new MyToolbar();

      toolbar.addGroup(toolbarGroup1);

      this.$refs.myToolbar.setProp_Toolbar(toolbar);
    },
    initForm(){
      //Doc
      //form
      //  panel
      //    row
      //      field

      //---------------------------------------
      //Fields
      //---------------------------------------
      let fName=new MyFormPanelField("姓名","name");
      fName.type=MyFormPanelFieldUITypeEnum.input;
      fName.require=true;

      fName.style="";
      fName.slot="testSlot";
      fName.fieldClassName="col-sm-3";

      let fTel=new MyFormPanelField("電話","tel");
      fTel.type=MyFormPanelFieldUITypeEnum.inputNumberOnly;
      fTel.require=true;

      let fAddress=new MyFormPanelField("地址","address");
      fAddress.type=MyFormPanelFieldUITypeEnum.textarea;
      fAddress.require=true;

      let fMemo=new MyFormPanelField("備註","memo");
      fMemo.type=MyFormPanelFieldUITypeEnum.textarea;
      fMemo.require=true;

      //---------------------------------------
      //Panels
      //---------------------------------------
      let myFormPanel1=new MyFormPanelPanel("");
      myFormPanel1.addRow()
          .addFields(6,fName)
          .addFields(6,fTel)
      ;

      myFormPanel1.addRow()
          .addFields(12,fAddress)
          .addFields(12,fMemo)
      ;


      // let myFormPanel2=new MyFormPanelPanel("");
      // myFormPanel2.addRow()
      //
      // ;
      //---------------------------------------
      //Form
      //---------------------------------------
      let myForm=new MyFormPanelForm();

      myForm.addPanel(myFormPanel1);

      //---------------------------------------
      this.$refs.form.setPropFormDefine(myForm);

    },
    toolbarButton_Click(buttonId,selectIds){  // eslint-disable-line
      if (buttonId===MyToolbarButtonIdEnum.Save){
        console.log("");
      }

      // alert(buttonId);
      // alert(selectIds);
    },
  },
  mounted() {
    this.loadData();
    this.initToolbar();
    this.initForm();
  },
}
</script>

<style scoped>

</style>
