<template>
  <div>
    <h1>播放方式</h1>
    <div class="form-check">
      <input class="form-check-input" id="radio1" type="radio" value="1" v-model.number="MediaList.MediaPlayType">
      <label class="form-check-label" for="radio1" >
        依順序播放
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" id="radio2" type="radio" value="2" v-model.number="MediaList.MediaPlayType">
      <label class="form-check-label" for="radio2" >
        隨機播放
      </label>
    </div>


    <h1>播放列表</h1>

    <b-button-group>
    <div v-for="(item,index) in UTubeMediaTypeEnum_ToToDic()" :key="index">
      <a class="btn btn-primary" @click="DataItem_AddLast(item.value)"><i class="fas fa-plus-circle"></i> {{item.text}}</a>
    </div>
    </b-button-group>

    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th class="text-center">啟/停用</th>
        <th>類型</th>
        <th>內容</th>
        <th>播放設定</th>
        <th>備註</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.MediaList.Items" :key="index">
        <td class="text-center">
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td width="120px">
          <!--                <button class="btn btn-primary" >啟用</button>-->
          <MyButtonEnableDisable v-model="item.IsEnable"></MyButtonEnableDisable>
        </td>
        <td width="120px">
          {{ UTubeMediaTypeEnum_ToText(item.MediaType) }}
        </td>

        <td width="300px">
          <div v-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUpload">
            <FileUploadButton :emmit-back-param="item" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_UTube" ></FileUploadButton>
            <a class="btn btn-danger" @click="clearFileUpload(item)"><i class="fa fa-trash"></i></a>
          </div>
          <div v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUrl">
            <MyInputImage v-model="item.MediaUrl"></MyInputImage>
          </div>
          <div v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_VideoUrl">
            <MyInputVideo v-model="item.MediaUrl"></MyInputVideo>
          </div>
          <div v-else>
              XX:{{item.MediaType}}
          </div>


        </td>

        <td>
          <div v-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUpload">
            播放秒數 <input type="text" class="form-control short" placeholder="" v-model.number="item.PlaySettingImagePlaySec">
          </div>
          <div v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUrl">
            播放秒數 <input type="text" class="form-control short" placeholder="" v-model.number="item.PlaySettingImagePlaySec">
          </div>
          <div v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_VideoUrl">
<!--            播放片段(目前只支援youtube影片) <br>-->
<!--            <input type="text" class="form-control short" placeholder="" v-model="item.PlaySettingVideoStartMin">分-->
<!--            <input type="text" class="form-control short" placeholder="" v-model="item.PlaySettingVideoStartSec">秒-->
<!--            ~-->
<!--            <input type="text" class="form-control short" placeholder="" v-model="item.PlaySettingVideoEndMin">分-->
<!--            <input type="text" class="form-control short" placeholder="" v-model="item.PlaySettingVideoEndSec">秒-->
          </div>
          <div v-else>
            XX:{{item.MediaType}}
          </div>
        </td>

        <td>
          <input type="text" class="form-control" placeholder="" v-model="item.Memo">
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
/**

 **/
// import MyColorPicker from "@/pages/commonComponent/MyColorPicker";
import MyButtonEnableDisable from "@/pages/commonComponent/MyButtonEnableDisable";
import MyInputImage from "@/pages/commonComponent/MyInputImage";
import MyInputVideo from "@/pages/commonComponent/MyInputVideo";

import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";
import ud from "@/pages/commonUBoss/ubossDeclare";
import FileUploadButton from "@/pages/commonComponent/FileUploadButton";

export default {
  components: {
    FileUploadButton,
    MyButtonEnableDisable,
    MyInputImage,
    MyInputVideo
  },
  //name: "EditMediaList",
  props: ["MediaList"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      // MediaList:{
      //   SystemId:"",
      //   MediaPlayType:1,
      //   Items:[
      //   ],
      // },
      MediaListDemo:{
        SystemId:"",
        MediaPlayType:1,
        Items:[
          {
            SystemId: "",
            OrderKey:"",
            IsEnable:true,
            MediaType:1,
            MediaUrl:"https://via.placeholder.com/400",
            PlaySettingImagePlaySec:0,
            PlaySettingVideoStartMin:0,
            PlaySettingVideoStartSec:0,
            PlaySettingVideoEndMin:0,
            PlaySettingVideoEndSec:0,
            Memo:"",
          },
        ],
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.MediaList.Items,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.MediaList.Items,"SystemId","OrderKey")
    },
    DataItem_AddLast(value){
      let newItem={
        SystemId: "",
        OrderKey:"999",
        IsEnable:true,
        MediaType:value,
        MediaUrl:"",
        PlaySettingImagePlaySec:0,
        PlaySettingVideoStartMin:0,
        PlaySettingVideoStartSec:0,
        PlaySettingVideoEndMin:0,
        PlaySettingVideoEndSec:0,
        Memo:"",
      };
      newItem.SystemId=util.uuid();

      arrayUtil.orderAppendLast(newItem,this.MediaList.Items,"OrderKey")
    },
    DataItem_Remove(item){
      this.MediaList.Items= this.MediaList.Items.filter(s => s.SystemId !==item.SystemId);
    },
    UTubeMediaTypeEnum_ToToDic(){
      return ud.UTubeMediaTypeEnum_ToDict();
    },
    UTubeMediaTypeEnum_ToText(value){
      return ud.UTubeMediaTypeEnum_ToText(value);
    },
    clearFileUpload(item){
      item.MediaUrl=""
      util.ShowMessage("已刪除")
    },
    uploadSuccess(callBackParam,result){
      debugger // eslint-disable-line
      if (result && result.FileCount>0){
        callBackParam.MediaUrl=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  width: 1500px;
}
#tblMain2{
  width: 1500px;
}
.btn{
  color: white !important;
  margin: 5px;
  /*width: px;*/
}
.short{
  width:80px;display: inline;
}
</style>
