<template>
  <div>
    <button v-if="CurrentSettingId!==''" type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>

    <A_SettingBasicInfo v-if="CurrentSettingId==='A_SettingBasicInfo'" :title="CurrentSettingTitle"></A_SettingBasicInfo>
    <B_SettingShop v-if="CurrentSettingId==='B_SettingShop'" :title="CurrentSettingTitle"></B_SettingShop>
    <C_SettingOnePage v-if="CurrentSettingId==='C_SettingOnePage'" :title="CurrentSettingTitle"></C_SettingOnePage>
    <D_SettingForm v-if="CurrentSettingId==='D_SettingForm'" :title="CurrentSettingTitle"></D_SettingForm>
    <T_SettingThirdPartyService_NewebPay v-if="CurrentSettingId==='T_SettingThirdPartyService_NewebPay'" :title="CurrentSettingTitle"></T_SettingThirdPartyService_NewebPay>
    <T_SettingThirdPartyService_EcPay v-if="CurrentSettingId==='T_SettingThirdPartyService_EcPay'" :title="CurrentSettingTitle"></T_SettingThirdPartyService_EcPay>

  </div>
</template>

<script>
import A_SettingBasicInfo from "@/pages/ubossBk/views/setting/A_SettingBasicInfo";
import B_SettingShop from "@/pages/ubossBk/views/setting/B_SettingShop";
import C_SettingOnePage from "@/pages/ubossBk/views/setting/C_SettingOnePage";
import D_SettingForm from "@/pages/ubossBk/views/setting/D_SettingForm";
import T_SettingThirdPartyService_NewebPay from "@/pages/ubossBk/views/setting/T_SettingThirdPartyService_NewebPay";
import T_SettingThirdPartyService_EcPay from "@/pages/ubossBk/views/setting/T_SettingThirdPartyService_EcPay";
/**

 **/


export default {
  components: {
    D_SettingForm,
    C_SettingOnePage,
    B_SettingShop,
    A_SettingBasicInfo,
    T_SettingThirdPartyService_NewebPay,
    T_SettingThirdPartyService_EcPay
    },
  //name: "ShoppingGeneral",
  props: ["settingAll","CurrentSettingId","CurrentSettingTitle"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    btnUpdate(){

    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
