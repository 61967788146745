var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("input", {
      ref: "file",
      staticClass: "form-control",
      attrs: { type: "file", placeholder: "", hidden: "" },
      on: { change: _vm.selectFile }
    }),
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm._l(this.files, function(item, index) {
          return _c("div", { key: index, staticClass: "col-1" }, [
            _c("img", {
              attrs: { src: _vm.toDownloadLink(item) + "?width=80&height=80" },
              on: {
                click: function($event) {
                  return _vm.imageClick(item)
                }
              }
            }),
            _c(
              "a",
              {
                staticClass: "btn btn-danger btn-dangerU",
                on: {
                  click: function($event) {
                    return _vm.DataItem_Remove(item)
                  }
                }
              },
              [_c("i", { staticClass: "far fa-trash-alt" })]
            )
          ])
        }),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-primaryU",
              on: { click: _vm.fileUplaodControlClick }
            },
            [_c("i", { staticClass: "fa fa-upload" }), _vm._v("上傳圖片")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }