var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("播放方式")]),
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.MediaList.MediaPlayType,
              expression: "MediaList.MediaPlayType",
              modifiers: { number: true }
            }
          ],
          staticClass: "form-check-input",
          attrs: { id: "radio1", type: "radio", value: "1" },
          domProps: {
            checked: _vm._q(_vm.MediaList.MediaPlayType, _vm._n("1"))
          },
          on: {
            change: function($event) {
              _vm.$set(_vm.MediaList, "MediaPlayType", _vm._n("1"))
            }
          }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "radio1" } },
          [_vm._v(" 依順序播放 ")]
        )
      ]),
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.MediaList.MediaPlayType,
              expression: "MediaList.MediaPlayType",
              modifiers: { number: true }
            }
          ],
          staticClass: "form-check-input",
          attrs: { id: "radio2", type: "radio", value: "2" },
          domProps: {
            checked: _vm._q(_vm.MediaList.MediaPlayType, _vm._n("2"))
          },
          on: {
            change: function($event) {
              _vm.$set(_vm.MediaList, "MediaPlayType", _vm._n("2"))
            }
          }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "radio2" } },
          [_vm._v(" 隨機播放 ")]
        )
      ]),
      _c("h1", [_vm._v("播放列表")]),
      _c(
        "b-button-group",
        _vm._l(_vm.UTubeMediaTypeEnum_ToToDic(), function(item, index) {
          return _c("div", { key: index }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.DataItem_AddLast(item.value)
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-plus-circle" }),
                _vm._v(" " + _vm._s(item.text))
              ]
            )
          ])
        }),
        0
      ),
      _c(
        "table",
        { staticClass: "table table-hover", attrs: { id: "tblMain" } },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(this.MediaList.Items, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Remove(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveUp(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-up" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveDown(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-down" })]
                  )
                ]),
                _c(
                  "td",
                  { attrs: { width: "120px" } },
                  [
                    _c("MyButtonEnableDisable", {
                      model: {
                        value: item.IsEnable,
                        callback: function($$v) {
                          _vm.$set(item, "IsEnable", $$v)
                        },
                        expression: "item.IsEnable"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { attrs: { width: "120px" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.UTubeMediaTypeEnum_ToText(item.MediaType)) +
                      " "
                  )
                ]),
                _c("td", { attrs: { width: "300px" } }, [
                  item.MediaType === _vm.$u.UTubeMediaTypeEnum_ImageUpload
                    ? _c(
                        "div",
                        [
                          _c("FileUploadButton", {
                            attrs: {
                              "emmit-back-param": item,
                              "upload-type": _vm.$u.UBossUploadType_UTube
                            },
                            on: { uploadSuccess: _vm.uploadSuccess }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function($event) {
                                  return _vm.clearFileUpload(item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          )
                        ],
                        1
                      )
                    : item.MediaType === _vm.$u.UTubeMediaTypeEnum_ImageUrl
                    ? _c(
                        "div",
                        [
                          _c("MyInputImage", {
                            model: {
                              value: item.MediaUrl,
                              callback: function($$v) {
                                _vm.$set(item, "MediaUrl", $$v)
                              },
                              expression: "item.MediaUrl"
                            }
                          })
                        ],
                        1
                      )
                    : item.MediaType === _vm.$u.UTubeMediaTypeEnum_VideoUrl
                    ? _c(
                        "div",
                        [
                          _c("MyInputVideo", {
                            model: {
                              value: item.MediaUrl,
                              callback: function($$v) {
                                _vm.$set(item, "MediaUrl", $$v)
                              },
                              expression: "item.MediaUrl"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", [_vm._v(" XX:" + _vm._s(item.MediaType) + " ")])
                ]),
                _c("td", [
                  item.MediaType === _vm.$u.UTubeMediaTypeEnum_ImageUpload
                    ? _c("div", [
                        _vm._v(" 播放秒數 "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: item.PlaySettingImagePlaySec,
                              expression: "item.PlaySettingImagePlaySec",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control short",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: item.PlaySettingImagePlaySec },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                item,
                                "PlaySettingImagePlaySec",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ])
                    : item.MediaType === _vm.$u.UTubeMediaTypeEnum_ImageUrl
                    ? _c("div", [
                        _vm._v(" 播放秒數 "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: item.PlaySettingImagePlaySec,
                              expression: "item.PlaySettingImagePlaySec",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control short",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: item.PlaySettingImagePlaySec },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                item,
                                "PlaySettingImagePlaySec",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ])
                    : item.MediaType === _vm.$u.UTubeMediaTypeEnum_VideoUrl
                    ? _c("div")
                    : _c("div", [_vm._v(" XX:" + _vm._s(item.MediaType) + " ")])
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.Memo,
                        expression: "item.Memo"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "" },
                    domProps: { value: item.Memo },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "Memo", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("啟/停用")]),
        _c("th", [_vm._v("類型")]),
        _c("th", [_vm._v("內容")]),
        _c("th", [_vm._v("播放設定")]),
        _c("th", [_vm._v("備註")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }