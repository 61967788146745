<template>
  <div class="container" >

    <div class="row">
      <div class="col-sm-6 col-lg-3" v-if="showOnePage">
        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('OnePageIndex')">官網</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showOnePage">
        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('OnePageAdvIndex4')">官網進階</a>
      </div>
<!--      <div class="col-sm-6 col-lg-3" v-if="showOnePage">-->
<!--        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('OnePageAdvIndex')">官網進階</a>-->
<!--      </div>-->
      <div class="col-sm-6 col-lg-3" v-if="showOnePage">
        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('OnePageAdvIndex3')">官網進階2</a>
      </div>
<!--      <div class="col-sm-6 col-lg-3" v-if="showOnePage">-->
<!--        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('OnePageAdvIndexOrg')">官網進階Org</a>-->
<!--      </div>-->

      <div class="col-sm-6 col-lg-3" v-if="showShop">
        <a class="btn btn-primary bg-gradient-info btnNav abutton" @click="goto('OrderIndex')"  >購物車</a>
      </div>


      <div class="col-sm-6 col-lg-3" v-if="showUDM">
        <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('UDMIndex')">UDM</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showTicket">
          <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('TicketIndex')">票券</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showTicket">
          <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('ADMIndex')">客戶管理</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showTicket2">
          <a class="btn btn-primary bg-gradient-primary btnNav abutton" @click="goto('Ticket2TicketTypeIndex')">票券</a>
      </div>


      <div class="col-sm-6 col-lg-3" v-if="showForm">
        <a class="btn btn-primary bg-gradient-warning btnNav abutton" @click="goto('UFormIndex')"  >表單</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showUTV">
        <a class="btn btn-primary bg-gradient-success btnNav abutton" @click="goto('UTubeIndex')"  >UTube</a>
      </div>
      <div class="col-sm-6 col-lg-3" v-if="showMarketing">
        <a class="btn btn-primary bg-gradient-success btnNav abutton" @click="goto('MarketingQRIndex')"  >行銷工具</a>
      </div>

      <div class="col-sm-6 col-lg-3" v-if="showCalendar">
        <a class="btn btn-primary bg-gradient-success btnNav abutton" @click="goto('CalendarIndex')"  >日曆</a>
      </div>

      <div class="col-sm-6 col-lg-3" >
        <a class="btn btn-primary bg-gradient-dark btnNav abutton" href="/Login"  >登出</a>
      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col-sm-6 col-lg-3" v-if="showOnePage">-->
<!--        <a class="btn btn-primary bg-gradient-primary" href="/OnePageIndex" style="height: 70px;width: 100%">官網</a>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="col-sm-6 col-lg-3">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="btn btn-primary bg-gradient-info" href="/ShoppingCart" style="height: 70px;width: 100%">購物車</a>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div class="col-sm-6 col-lg-3" v-if="showForm">-->
<!--        <a class="btn btn-primary bg-gradient-warning" href="/UFormIndex" style="height: 70px;width: 100%">表單</a>-->
<!--      </div>-->

<!--    </div>-->
  </div>

<!--    <div class="row">-->
<!--      <div class="col-sm-6 col-lg-3">-->
<!--        <div class="card text-white bg-gradient-primary">-->
<!--          <div class="card-body card-body pb-0 d-flex justify-content-between align-items-start">-->
<!--            <div>-->
<!--              <div class="text-value-lg"></div>-->
<!--              <div>OnePage</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="c-chart-wrapper mt-3 mx-3" style="height:70px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--            <canvas class="chart chartjs-render-monitor" id="card-chart1" height="105" style="display: block; height: 70px; width: 502px;" width="753"></canvas>-->
<!--            <div id="card-chart1-tooltip" class="c-chartjs-tooltip top bottom" style="opacity: 0; left: 349.833px; top: 128.858px;"><div class="c-tooltip-header"><div class="c-tooltip-header-item">May</div></div><div class="c-tooltip-body"><div class="c-tooltip-body-item"><span class="c-tooltip-body-item-color" style="background-color: rgb(50, 31, 219);"></span><span class="c-tooltip-body-item-label">My First dataset</span><span class="c-tooltip-body-item-value">51</span></div></div></div></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; /.col&ndash;&gt;-->
<!--      <div class="col-sm-6 col-lg-3">-->
<!--        <div class="card text-white bg-gradient-info">-->
<!--          <div class="card-body card-body pb-0 d-flex justify-content-between align-items-start">-->
<!--            <div>-->
<!--              <div class="text-value-lg"></div>-->
<!--              <div>Shopping Cart</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="c-chart-wrapper mt-3 mx-3" style="height:70px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--            <canvas class="chart chartjs-render-monitor" id="card-chart2" height="105" style="display: block; height: 70px; width: 502px;" width="753"></canvas>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; /.col&ndash;&gt;-->
<!--      <div class="col-sm-6 col-lg-3">-->
<!--        <div class="card text-white bg-gradient-warning">-->
<!--          <div class="card-body card-body pb-0 d-flex justify-content-between align-items-start">-->
<!--            <div>-->
<!--              <div class="text-value-lg"></div>-->
<!--              <div>UForm</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="c-chart-wrapper mt-3" style="height:70px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--            <canvas class="chart chartjs-render-monitor" id="card-chart3" height="105" style="display: block; height: 70px; width: 534px;" width="801"></canvas>-->
<!--            <div id="card-chart3-tooltip" class="c-chartjs-tooltip top bottom" style="opacity: 0; left: 468.5px; top: 131px;"><div class="c-tooltip-header"><div class="c-tooltip-header-item">July</div></div><div class="c-tooltip-body"><div class="c-tooltip-body-item"><span class="c-tooltip-body-item-color" style="background-color: rgba(255, 255, 255, 0.2);"></span><span class="c-tooltip-body-item-label">My First dataset</span><span class="c-tooltip-body-item-value">40</span></div></div></div></div>-->
<!--        </div>-->
<!--      </div>-->







      <!-- /.col-->
<!--      <div class="col-sm-6 col-lg-3">-->
<!--        <div class="card text-white bg-gradient-danger">-->
<!--          <div class="card-body card-body pb-0 d-flex justify-content-between align-items-start">-->
<!--            <div>-->
<!--              <div class="text-value-lg">9.823</div>-->
<!--              <div>Members online</div>-->
<!--            </div>-->
<!--            <div class="btn-group">-->
<!--              <button class="btn btn-transparent dropdown-toggle p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                <svg class="c-icon">-->
<!--                  <use xlink:href="#cil-settings"></use>-->
<!--                </svg>-->
<!--              </button>-->
<!--              <div class="dropdown-menu dropdown-menu-right" style="margin: 0px;"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="c-chart-wrapper mt-3 mx-3" style="height:70px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--            <canvas class="chart chartjs-render-monitor" id="card-chart4" height="105" style="display: block; height: 70px; width: 502px;" width="753"></canvas>-->
<!--            <div id="card-chart4-tooltip" class="c-chartjs-tooltip top" style="opacity: 0; left: 128.563px; top: 102.3px;"><div class="c-tooltip-header"><div class="c-tooltip-header-item">February</div></div><div class="c-tooltip-body"><div class="c-tooltip-body-item"><span class="c-tooltip-body-item-color" style="background-color: rgba(255, 255, 255, 0.2);"></span><span class="c-tooltip-body-item-label">My First dataset</span><span class="c-tooltip-body-item-value">81</span></div></div></div></div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- /.col-->
<!--    </div>-->
<!--  </div>-->




<!--  <div class="container-fluid">-->
<!--    <div class="row">-->

<!--      &lt;!&ndash; Earnings (Monthly) Card Example &ndash;&gt;-->
<!--      <div class="col-xl-3 col-md-6 mb-4">-->
<!--        <div class="card border-left-primary shadow h-100 py-2">-->
<!--          <div class="card-body">-->
<!--            <div class="row no-gutters align-items-center">-->
<!--              <div class="col mr-2">-->
<!--                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1 funBtn">-->
<!--                  <a :href="onePageLink()" target="_blank">OnePage 編輯</a> </div>-->
<!--                <div class="h5 mb-0 font-weight-bold text-gray-800"></div>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <i class="fas fa-calendar fa-2x text-gray-300"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; Earnings (Monthly) Card Example &ndash;&gt;-->
<!--      <div class="col-xl-3 col-md-6 mb-4">-->
<!--        <div class="card border-left-success shadow h-100 py-2">-->
<!--          <div class="card-body">-->
<!--            <div class="row no-gutters align-items-center">-->
<!--              <div class="col mr-2">-->
<!--                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">-->
<!--                  </div>-->
<!--                <div class="h5 mb-0 font-weight-bold text-gray-800"></div>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; Earnings (Monthly) Card Example &ndash;&gt;-->
<!--      <div class="col-xl-3 col-md-6 mb-4">-->
<!--        <div class="card border-left-info shadow h-100 py-2">-->
<!--          <div class="card-body">-->
<!--            <div class="row no-gutters align-items-center">-->
<!--              <div class="col mr-2">-->
<!--                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">-->
<!--                </div>-->
<!--                <div class="row no-gutters align-items-center">-->
<!--                  <div class="col-auto">-->
<!--                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800"></div>-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <div class="progress progress-sm mr-2">-->
<!--&lt;!&ndash;                      <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; Pending Requests Card Example &ndash;&gt;-->
<!--      <div class="col-xl-3 col-md-6 mb-4">-->
<!--        <div class="card border-left-warning shadow h-100 py-2">-->
<!--          <div class="card-body">-->
<!--            <div class="row no-gutters align-items-center">-->
<!--              <div class="col mr-2">-->
<!--                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1"></div>-->
<!--                <div class="h5 mb-0 font-weight-bold text-gray-800"></div>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <i class="fas fa-comments fa-2x text-gray-300"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--  </div>-->
</template>

<script>

import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import ubossCommon from "@/pages/commonUBoss/ubossCommon";
import session from "@/pages/common/session";
import * as ubossCommonConst from "@/pages/commonUBoss/ubossCommon";

export default {
  data: function() {
    return {
      formData:{
        name:"Sophie Wang",
      },
      submitted: false,
      showOnePage:false,
      showOnePageAdv:false,
      showUDM:false,
      showForm:false,
      showShop:false,
      showUTV:false,
      showMarketing:false,
      showCalendar:false,
      showTicket:false,
      showTicket2:false,
      showAdmin:false,//QQ 要搬到adm.uboss
    };
  },
  methods: {
    async loadData(){
      let data= await apiUBossBk.api_CustomerSetting();

      this.showOnePage=data.IsEnableOnePage;
      this.showShop=data.IsEnableShop;
      // this.showUDM=data.IsEnableUDM; //QQ
      this.showForm=data.IsEnableForm;

      if (ubossCommon.IsFormMode()){
        this.showOnePage=false;
        this.showOnePageAdv=false;
        this.showShop=false;
        this.showUDM=false;
        this.showForm=false;
        this.showTicket=false;
      }

      if (ubossCommon.IsLocalhost()){
        //本地用
        this.showOnePageAdv=true;
        this.showShop=false;
        // this.showForm=true;
        // this.showUTV=true;
        // this.showMarketing=true;
        this.showCalendar=false;
        this.showUDM=true;
        this.showTicket=true;
        this.showTicket2=true;
        this.showAdmin=false;
      }

      // let loginid=session.getLoginId(ubossCommon.SessionKeyPrefix_Bk);
      //
      // //QQ
      // if (loginid==="paypaydrink"){
      //   this.showMarketing=true;
      //   this.showUTV=true;
      // }

      let loginId=session.getLoginId(ubossCommonConst.SessionKeyPrefix_Bk)

      if (loginId==="bodytalk"){
        this.showTicket2=true;
      }

      // this.showUTV=true;
      // this.showMarketing=true;
    },
  },
  mounted() {
    this.loadData()
  },
}

</script>

<style scoped>
.funBtn{
  font-size: 20px;
}
.btnNav{
  height: 70px;
  width: 100%;
  line-height: 58px;  /*  扣除pad/margin.. (用google devlTool看的) */
}
</style>
