<template>
  <div>


    <h1>bootstrap 4</h1>





    <h1>ppd product option</h1>
    <ProductOptionSelectorPpd v-model="productSelectOptions"></ProductOptionSelectorPpd>
    {{productSelectOptions}}

    <a class="btn btn-primary" href="#" @click="test1">Test1</a>



    <h1>ppd 配方</h1>
    <PpdDLGDrinkMaterial v-model="ppdRecipe"></PpdDLGDrinkMaterial>

    <h1>Tags</h1>
    <MyTagInput v-model="tags" :only-existing-tags="false"></MyTagInput>

<!--    <MyTagInput v-model="tags" :only-existing-tags="true" :existingTags="options" ></MyTagInput>-->

    <h1>Grid</h1>
    <a role="button" class="btn btn-primary" @click="btnSelectRow">選取row</a>
    <MyGridGoodTable ref="myGridGoodTable" :columns="demo.columns" :rows="demo.rows" :show-checkbox-column="false"></MyGridGoodTable>

    <h1>Grid bootstrap</h1>


    <MyGridBootstrapTable ref="myGridBootstrapTable" :columns="datas.columns" :rows="datas.rows" :show-checkbox-column="true"></MyGridBootstrapTable>
<!--      <template v-slot:name="slotProps">-->

<!--      </template>-->
<!--      <template v-slot:description="slotProps">-->

<!--      </template>-->

    <h1>Grid Vuetify</h1>
    myGridVuetify.gridMeta:{{gridMeta}}

    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig" :grid-meta="gridMeta"  >
      <template v-slot:name="{ item }">
        {{ item.name}} xx
      </template>
      <template v-slot:calories="{ item }">
        {{ item.calories}} ccc
      </template>
      <template v-slot:isEnable="{ item }">
        <MyLabelIsEnable v-model="item.isEnable"></MyLabelIsEnable>
      </template>
      <template v-slot:isVisible="{ item }">
        <MyLabelIsVisible v-model="item.isVisible"></MyLabelIsVisible>
      </template>
      <template v-slot:isEnable="{ item }">
        <MyLabelTrueFalse v-model="item.isVisible" true-text="見" false-text="不見"></MyLabelTrueFalse>
      </template>
    </MyGridVuetify>

    <h1>Select</h1>
    <MySelectCascading ref="mySelectCascading1" v-model="SelectValue1" :datas="SelectDemo_Group1" :child-select-ref="ChildSelectRef1" :is-root="true" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>
    <MySelectCascading ref="mySelectCascading2" v-model="SelectValue2" :datas="SelectDemo_Group2" :child-select-ref="ChildSelectRef2" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>
    <MySelectCascading ref="mySelectCascading3" v-model="SelectValue3" :datas="SelectDemo_Group3" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>


    <h1>Toolbar Grid Vuetify</h1>

    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta"  v-on:add-click="btnAdd_Click" :edit-click="btnEdit_Click"></MyToolbarVuetify>
    <h1>Toolbar Edit Page</h1>

    <MyToolbarEditPage></MyToolbarEditPage>

    <h1>Lite Form</h1>

    <MyLiteFormDesigntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormDesigntime>
    <MyLiteFormRuntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormRuntime>

    <h1>My Left Right</h1>
    <MyListboxLeftRight v-model="listboxValues" :datasource-all="listboxValuesAll">
      <template v-slot:left="{item}">
        {{item.Text}}
      </template>
      <template v-slot:right="{item}">
        {{item.Text}}
      </template>
    </MyListboxLeftRight>
  </div>
</template>

<script>
import MyTagInput from "@/pages/commonComponent/MyTagInput";
import MyGridGoodTable from "@/pages/commonComponent/MyGridGoodTable";
import ProductOptionSelectorPpd from "@/pages/commonUBossCustomize/Ppd/ProductOptionSelectorPpd";
import MyGridBootstrapTable from "@/pages/commonComponent/MyGridBootstrapTable";
import MySelectCascading from "@/pages/commonComponent/MySelectCascading";
import PpdDLGDrinkMaterial from "@/pages/ubossBk/views/customize/PpdDLGDrinkMaterial";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyLabelIsEnable from "@/pages/commonComponent/MyLabelIsEnable.vue";
import MyLabelIsVisible from "@/pages/commonComponent/MyLabelIsVisible.vue";
import MyLabelTrueFalse from "@/pages/commonComponent/MyLabelTrueFalse.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import MyToolbarEditPage from "@/pages/commonComponent/MyToolbarEditPage.vue";
import MyLiteFormDesigntime from "@/pages/commonComponent/MyLiteFormDesigntime.vue";
import MyLiteFormRuntime from "@/pages/commonComponent/MyLiteFormRuntime.vue";
import MyListboxLeftRight from "@/pages/commonComponent/MyListboxLeftRight.vue";

export default {
  name: "Test",
  components: {
    MyListboxLeftRight,
    MyLiteFormRuntime,
    MyLiteFormDesigntime,
    MyToolbarEditPage,
    MyToolbarVuetify,
    MyLabelTrueFalse,
    MyLabelIsVisible,
    MyLabelIsEnable,
    MyGridVuetify,
    PpdDLGDrinkMaterial,
    MySelectCascading,
    MyGridBootstrapTable,
    ProductOptionSelectorPpd,
    MyGridGoodTable, MyTagInput},
  // props: [""],
  data: function() {
    return {
      id:"",
      tags:[],
      formData:{
        name:"",
      },
      options:[
        {key: '1', value: 'Apple'},
        {key: '2', value: 'Banana'},
      ],
      productSelectOptions: {
        "temperature": {
          IsEnable:true,
          SelectValue:"cool"
        },
        "sugar": {
          IsEnable:true,
          SelectValue:"10"
        },
        "ice": {
          IsEnable:true,
          SelectValue:"3"
        },
      },
      productSelectOptions2:[
        {"temperature":""},
        {"sugar":""},
        {"ice":""},
      ],
      SelectValue1:"",
      SelectValue2:"",
      SelectValue3:"",
      ChildSelectRef1:null,
      ChildSelectRef2:null,
      SelectDemo_Group1:[
        {
          Id:"1",
          Text:"Group1",
          Value:"G1",
        },
        {
          Id:"2",
          Text:"Group2",
          Value:"G2",
        },
        {
          Id:"3",
          Text:"Group3",
          Value:"G3",
        },
      ],
      SelectDemo_Group2:[
        {
          Id:"1",
          Text:"Group21",
          Value:"G21",
          ParentId:"G1",
        },
        {
          Id:"2",
          Text:"Group22",
          Value:"G22",
          ParentId:"G1",
        },
        {
          Id:"3",
          Text:"Group23",
          Value:"G23",
          ParentId:"G2",
        },
      ],
      SelectDemo_Group3:[
        {
          Id:"1",
          Text:"Group31",
          Value:"G31",
          ParentId:"G21",
        },
        {
          Id:"2",
          Text:"Group32",
          Value:"G32",
          ParentId:"G21",
        },
        {
          Id:"3",
          Text:"Group33",
          Value:"G33",
          ParentId:"G22",
        },
      ],
      demo:{
        columns: [
          {
            label: 'Name',
            field: 'name',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: '搜尋', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              // filterDropdownItems: ["a","b","c"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: 'enter', //only trigger on enter not on keyup
            },
          },
          {
            label: 'Age',
            field: 'age',
            type: 'number',
          },
          {
            label: 'Created On',
            field: 'createdAt',
            type: 'date',
            dateInputFormat: 'yyyy-MM-dd',
            dateOutputFormat: 'MMM do yy',
          },
          {
            label: 'Percent',
            field: 'score',
            type: 'percentage',
          },
        ],
        rows: [
          { id:1, name:"John", age: 20, createdAt: '',score: 0.03343 },
          { id:2, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
          { id:3, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
          { id:4, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
          { id:5, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
        ],
      },
      datas:{
        columns: [
          {
            title:"id",
          },
          {
            title:"name",
          },
          {
            title:"age",
          },
          {
            title:"country",
            visible: true,
            filterable: true,
            editable: true,
            sortable: true,
            renderfunction: this.renderColumn_Test
          }
        ],
        rows: [
          {
            "selected":true,
            "id": 1,
            "name": "John",
            "country": "UK",
            "age": 25,
          },
          {
            "selected":true,
            "id": 2,
            "name": "Mary",
            "country": "France",
            "age": 30,
          },
          {
            "selected":true,
            "id": 3,
            "name": "Ana",
            "country": "Portugal",
            "age": 20,
          }
        ]
      },
      testjson:[


      ],
      ppdRecipe:{},
      ppdRecipeDemo:{
        冰:0,
        百香果:0,
        西柚:0,
        黑糖:0,
        葡萄:0,
        芒果:0,
        柳橙:0,
        荔枝:0,
        粉桃:0,
        草莓:0,
        蔓越莓:0,
        鳳梨荔枝:0,
        鮮奶:0,
        哈密瓜:0,
        蔗糖:0,
        醇木紅茶:0,
        青茶:0,
        聖羅勒:0,
        純淨水:0,
      },
      gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [
          {
            text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
          { text: 'isEnable', value: 'isEnable' },
          { text: 'isVisible', value: 'isVisible' },
        ],
        datas: [
          {
            SystemId:"1",
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
            isEnable:true,
            isVisible:true,
          },
          {
            SystemId:"2",
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: '1%',
            isEnable: false,
            isVisible:false,
          },
        ],
      },
      toolbarVuetifyConfig:{
        Add:{
          BtnText:"新增",
          Enable:true,
          CallBack:"",
        },
        Edit:{
          BtnText:"修改2",
          Enable:true,
          CallBack:"btnEdit_Click()",
        },
      },
      gridMeta:{},
      liteFormData:[

      ],
      liteFormConfig:{

      },
      listboxValues:[
        {
          SystemId:"1",
          Text:"A",
          OrderKey:"1",
        },
      ],
      listboxValuesAll:[
        {
          SystemId:"1",
          Text:"A",
          OrderKey:"1",
        },
        {
          SystemId:"2",
          Text:"B",
          OrderKey:"2",
        },
        {
          SystemId:"3",
          Text:"C",
          OrderKey:"3",
        }
      ]
    };
  },
  // computed: {
  //   myGridVuetify() {
  //     return myGridVuetify
  //   }
  //
  // },
  methods: {
    btnAdd_Click(){
      alert('hi btnAdd_Click')
    },
    btnEdit_Click(){
      alert('edit')
    },
    test1(){
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },

    btnSelectRow(){
      debugger // eslint-disable-line
      let rowsSelected=this.$refs.grid.selectedRows();  // eslint-disable-line
    },
    renderColumn_Test(colname, entry){
      return '<div class="btn-group" role="group" >'+
          '  <button type="button" class="btn btn-sm btn-primary"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span></button>'+
          '  <button type="button" class="btn btn-sm btn-danger"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>'+
          '</div><span>'+JSON.stringify(entry)+'</span>';
    },



  },
  mounted() {

    this.$nextTick(()=>{
      this.ChildSelectRef1=this.$refs.mySelectCascading2;
      this.ChildSelectRef2=this.$refs.mySelectCascading3;
    })

    //this.loadData()
  },
}
</script>

<style scoped>
.table {
  overflow-x:scroll;
  overflow-y:scroll;
}

#collapse1{
  /*overflow-y:scroll;*/
  overflow-x:scroll;
  /*height:200px;*/
  width:1000px;
}
</style>
