var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bk_page_rootDiv" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "TicketIndex" } }),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { role: "button" },
          on: {
            click: function($event) {
              return _vm.btnAdd_Click()
            }
          }
        },
        [_vm._v("add")]
      ),
      _c("MyToolbarVuetify", {
        attrs: { config: _vm.toolbarVuetifyConfig, "grid-meta": _vm.gridMeta },
        on: {
          "add-click": _vm.btnAdd_Click,
          "edit-click": _vm.btnEdit_Click,
          "delete-click": _vm.btnDelete_Click
        }
      }),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        staticStyle: { width: "1000px" },
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta },
        scopedSlots: _vm._u([
          {
            key: "Fun",
            fn: function(ref) {
              return undefined
            }
          },
          {
            key: "IsEnable",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("MyLabelIsEnable", {
                  model: {
                    value: item.IsEnable,
                    callback: function($$v) {
                      _vm.$set(item, "IsEnable", $$v)
                    },
                    expression: "item.IsEnable"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }