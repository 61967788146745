import {UBossDefaultImage,UBossDefaultLogo} from "@/pages/commonUBoss/ubossDeclare";
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
    toDownloadLinkOrDefaultLogo(link){
        if (!link){
            link=UBossDefaultLogo;
        }

        return apiServerUtil.toDownloadLink(link);
    },
    toDownloadLinkOrDefault(link){
        if (!link){
            link=UBossDefaultImage;
        }

        return apiServerUtil.toDownloadLink(link);
    },
}