var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vc-calendar", {
        attrs: {
          attributes: _vm.attributes,
          "available-dates": _vm.setting_availableDates
        },
        on: { dayclick: _vm.onDayClick }
      }),
      _vm.showOption
        ? _c("div", [
            _vm._v(" 上午 10:00 / 下午 14:00 "),
            _vm.timeperiod === "day"
              ? _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { role: "button" },
                      on: { click: _vm.btnBook }
                    },
                    [_vm._v("預約")]
                  )
                ])
              : _vm.timeperiod === "halfday"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }, [_vm._v(" 上午 ")]),
                    _c("div", { staticClass: "col-9" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { role: "button" },
                          on: { click: _vm.btnBook }
                        },
                        [_vm._v("預約")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }, [_vm._v(" 下午 ")]),
                    _c("div", { staticClass: "col-9" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { role: "button" },
                          on: { click: _vm.btnBook }
                        },
                        [_vm._v("預約")]
                      )
                    ])
                  ])
                ])
              : _vm.timeperiod === "hour"
              ? _c(
                  "div",
                  _vm._l(_vm.timeperiodHour, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ]),
                      _c("div", { staticClass: "col-9" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { role: "button" },
                            on: { click: _vm.btnBook }
                          },
                          [_vm._v("預約")]
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _vm.timeperiod === "halfhour"
              ? _c(
                  "div",
                  _vm._l(_vm.timeperiodHalfHour, function(item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ]),
                      _c("div", { staticClass: "col-9" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { role: "button" },
                            on: { click: _vm.btnBook }
                          },
                          [_vm._v("預約")]
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _vm.timeperiod === "specifyTimePeriod"
              ? _c(
                  "div",
                  _vm._l(_vm.timeperiodSpecifyTimePeriod, function(
                    item,
                    index
                  ) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ]),
                      _c("div", { staticClass: "col-9" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { role: "button" },
                            on: { click: _vm.btnBook }
                          },
                          [_vm._v("預約")]
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _c("div")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }