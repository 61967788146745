<template>
  <div>
    <!--    AA-->
    <COM_HeaderNav mode="uformDefine"></COM_HeaderNav>

    <!--    <SearchPanelForm ref="searchPanelForm" :fieldDefines="searchFieldDefines" :keyword-fields="searchKeywordFields"></SearchPanelForm>-->


    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <!--      <div class="btn-group mr-2" role="group" aria-label="First group">-->
              <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle"></i>新增</button>
              <button type="button" class="btn btn-danger" @click="btnDelete"><i class="fa fa-trash"></i>刪除</button>
      <!--      </div>-->
      <div class="btn-group mx-1" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          匯出
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="$refs.grid.exportExcel()">Excel</a>
          <!--          <a class="dropdown-item" href="#">PDF</a>-->
        </div>
      </div>
    </div>

<!--    info:{{getInfo()}}-->
    <span v-if="isMobile">
      <MyMobileList :datas="gridDefine.datas" :field-to-show="gridDefine.fieldToShow" :swipe-setting="gridDefine.swipeSetting">
      </MyMobileList>


      <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
      >
      <template v-slot:activator>
        <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-account-circle
          </v-icon>
        </v-btn>
      </template>
      <v-btn
          fab
          dark
          small
          color="green"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
          fab
          dark
          small
          color="indigo"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
          fab
          dark
          small
          color="red"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>
    </span>
    <span v-else>
      <MyKendoGrid ref="grid" :columns="gridDefine.columns" :datas="gridDefine.datas"></MyKendoGrid>
    </span>


  </div>
</template>

<script>

import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyKendoGrid from "@/pages/commonComponent/MyKendoGrid";
import { isMobile } from 'mobile-device-detect';
import MyMobileList from "@/pages/commonComponent/MyMobileList";
import apiUBossFormBk from "@/pages/ubossBk/util/apiUBossFormBk";

export default {
  //name: "MemberIndex",
  // props: [""],
  components: {
    MyMobileList,
    MyKendoGrid,
    COM_HeaderNav,

  },
  data: function () {
    return {
      isMobile:isMobile,
      searchDefine:{
        searchFieldDefines:[],
        searchKeywordFields:[],
      },
      gridDefine:{
        columns: [
          // { field: 'SystemId', title: 'SystemId', minWidth: 50, width: '50px'},
          { field: 'Title', title: '表單名稱' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M,cell: this.cellFunctionLink},
          { field: 'Title', title: '回傳資料' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M,cell: this.cellFunctionLinkFormData},
        ],
        datas:[],
        fieldToShow:{
          title:"表單名稱",
          memo:"說明",
          badge1:"建立日期",
        },
        swipeSetting:{
          ItemClick:this.swipeSettingItemClick
        }
      },
    };
  },
  methods: {
    async loadData() {
      this.gridDefine.datas=await apiUBossFormBk.api_Form_GridInfo_ListQuery(null)
    },
    cellFunctionLink: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        that.gotoWithSystemId('UFormEdit',props.dataItem.SystemId)
      }
      let linkText=props.dataItem.Title;//QQ Friendly

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML: "<a class='alink'><i class='fa fa-edit'></i>"+linkText +" </a>"
        }
      });
    },
    cellFunctionLinkFormData: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        that.gotoWithSystemId('UFormDataIndex',props.dataItem.SystemId)
      }
      let linkText="回傳資料";//QQ Friendly

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML: "<a class='alink'><i class='fa fa-comment-dots'></i>"+linkText +" </a>"
        }
      });
    },
    swipeSettingItemClick:function (item){ // eslint-disable-line
      this.gotoWithSystemId('UFormDataIndex',item.SystemId)
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0);
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0);
    },
    closeFirst() {
      this.$refs.list.closeActions(0);
    },
    closeAll() {
      this.$refs.list.closeActions();
    },
    remove(item) {
      this.mockSwipeList = this.mockSwipeList.filter(i => i !== item);
      // console.log(e, 'remove');
    },

    fbClick(e) {
      console.log(e, "First Button Click");
    },
    sbClick(e) {
      console.log(e, "Second Button Click");
    },
    gotoResult(){
      this.$router.push({ name: 'UFormDataIndex', query: { ID: 0 }})
    },
    getInfo(){
      let strTemplate=`isMobile:${this.isMobile} agent:${navigator.userAgent}`;

      return strTemplate;
    },
    btnCreate(){
      this.$router.push({ name: 'UFormEditFirstStep', query: { systemId: "" }})
    },
    btnDelete(){

    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>