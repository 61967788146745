<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="4"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">名稱</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="名稱" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Name">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">備註</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="備註" >
            <textarea class="form-control" rows="3" v-model="formData.Memo"></textarea>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">票據樣式</label>
        <div class="a_thinInputDiv col-sm-10">
            <FileUploadButton :emmit-back-param="formData" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_Ticket"></FileUploadButton>
          <div style="width:680px;height:200px;background-image: url('https://via.placeholder.com/680x200'); background-size: 100% 100%; background-repeat: no-repeat;position: relative;" :style="getStyle()">

            <vue-draggable-resizable :w="width" :h="height" :x="x" :y="y" @dragging="onDrag" @resizing="onResize" :parent="true" :grid=[20,20] :lock-aspect-ratio="true">
              QR Code
<!--              X: {{ x }} / Y: {{ y }} - Width: {{ width }} / Height: {{ height }}-->
            </vue-draggable-resizable>
<!--            <vue-draggable-resizable :w="width" :h="height" :x="x" :y="y" @dragging="onDrag" @resizing="onResize" :parent="true" :grid=[20,20] :lock-aspect-ratio="true">-->
<!--              1234-E482-C145-8DE4-->
<!--            </vue-draggable-resizable>-->
          </div>


        </div>
      </div>

<!--      <hr>-->
<!--      X: {{ x }} / Y: {{ y }} - Width: {{ width }} / Height: {{ height }}-->

      <a role="button" class="btn btn-primary" @click="test()" v-if="false">Test</a>

<!--      <template>-->
<!--        <div style="height: 500px; width: 500px; border: 1px solid red; position: relative;">-->
<!--          <vue-draggable-resizable :w="100" :h="100" @dragging="onDrag" @resizing="onResize" :parent="true">-->
<!--            <p>Hello! I'm a flexible component. You can drag me around and you can resize me.<br>-->
<!--              X: {{ x }} / Y: {{ y }} - Width: {{ width }} / Height: {{ height }}</p>-->
<!--          </vue-draggable-resizable>-->
<!--        </div>-->
<!--      </template>-->

<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">配送方式</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="配送方式" >-->
<!--            <MyCodeListButtonRadio v-model="formData.CLShipType" :options="$u.UCode_CLShipType" size="md"></MyCodeListButtonRadio>-->
<!--&lt;!&ndash;            <select class="form-control" v-model="formData.CLShipType">&ndash;&gt;-->
<!--&lt;!&ndash;              <option></option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="self">自取</option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="ship">宅配</option>&ndash;&gt;-->
<!--&lt;!&ndash;            </select>&ndash;&gt;-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->


    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import FileUploadButton from "@/pages/commonComponent/FileUploadButton.vue";
import apiServerUtil from "@/pages/common/apiServerUtil";
// import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  components: {FileUploadButton, COM_HeaderNav,VueDraggableResizable},
  data: function () {
    return {
      systemId: "",
      formData: {
        SystemId:"",
        BackgroundImageSrc:"",
        BlockInfo1:"",
        BlockInfo2:"",
        BlockInfo3:"",
        BlockInfo4:"",
        BlockInfo5:"",
        Memo:"",
      },
      formDataDemo: {

        "OrderId":"",
        "RelMember":"",
        "CLSourceType":"",
        "CLInvoiceType":"",
        "CLPayType":"",
        "PayTypeMemo":"",
        "PayTypeLog":"",
        "CLPayStatus":"",
        "CLOrderStatus":"",
        "CLShipType":"",
        "CLShipStatus":"",
        "ShipDt":"",
        "TotalOrgAmount":"",
        "TotalAmount":"",
      },
      width: 200,
      height: 150,
      x: 200,
      y: 100
    };
  },
  methods: {
    test(){
      this.x=this.x+10;
    },
    onResize: function (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
    },
    getStyle(){
      if (this.formData){
        if (this.formData.BackgroundImageSrc){
          return {
            "background-image": "url('" + this.toDownloadLink(false,this.formData.BackgroundImageSrc) + "')",
          }
        }
      }

      return "";
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    },
    uploadSuccess(formData,result){
      //result
      //  count
      //  size
      //  newFileNames
      //  fileUrls
      if (result && result.FileCount>0){
        let fileUrls=result.FileUrls

        formData.BackgroundImageSrc=fileUrls[0]
        // this.$forceUpdate()
      }else{
        util.ShowMessage("檔案上傳失敗")
      }

    },
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossBk.api_TicketDesign_Get(this.systemId)
        this.formData=data

        let block1=JSON.parse(this.formData.BlockInfo1);
        this.x=block1.x;
        this.y=block1.y;
        this.width=block1.width;
        this.height=block1.height;

        this.$forceUpdate()
      }
    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (this.formData.ShipDt===""){
            this.formData.ShipDt=null;
          }

          this.formData.BlockInfo1=JSON.stringify({
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height
          })

          //---------------------------------------
          apiUBossBk.api_TicketDesign_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
