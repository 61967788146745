<template>
  <div class="form-inline">
    <input type="url" class="form-control " placeholder="" v-model="value"><a class="btn btn-primary" @click="preview()" style="color:white"><i class="far fa-eye"></i> </a>
    <img style="width:100%" :src="this.propsValue" v-if="showPreview">
  </div>
</template>

<script>
/**
 <MyInputImage v-model="item.url"></MyInputImage>
 **/


export default {
  // name: "MyColorPicker",
  props: ["value"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue: this.value,
      showPreview:false,
    };
  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    preview(){
      this.showPreview=!this.showPreview;
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
