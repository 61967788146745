var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.field.type === _vm.MyFormPanelFieldUITypeEnum.input
    ? _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelNew,
              expression: "modelNew"
            }
          ],
          staticClass: "form-control",
          class: _vm.getCssClass(_vm.field),
          style: _vm.field.style,
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.modelNew },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.modelNew = $event.target.value
            }
          }
        })
      ])
    : _vm.field.type === _vm.MyFormPanelFieldUITypeEnum.inputNumberOnly
    ? _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelNew,
              expression: "modelNew"
            }
          ],
          staticClass: "form-control",
          class: _vm.getCssClass(_vm.field),
          style: _vm.field.style,
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.modelNew },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.modelNew = $event.target.value
            }
          }
        })
      ])
    : _vm.field.type === _vm.MyFormPanelFieldUITypeEnum.radio
    ? _c("div", [_vm._m(0)])
    : _vm.field.type === _vm.MyFormPanelFieldUITypeEnum.textarea
    ? _c("div", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelNew,
              expression: "modelNew"
            }
          ],
          staticClass: "form-control",
          class: _vm.getCssClass(_vm.field),
          style: _vm.field.style,
          attrs: { rows: "3" },
          domProps: { value: _vm.modelNew },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.modelNew = $event.target.value
            }
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "select",
      {
        staticClass: "form-control",
        attrs: { id: "selItems", name: "selItems" }
      },
      [_c("option"), _c("option", [_vm._v("是")]), _c("option", [_vm._v("否")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }