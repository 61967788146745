var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "noMarginForForm" }, [
    this.webPart.DescText
      ? _c("div", { staticClass: "row" }, [
          _c(
            "h3",
            { staticClass: "col-sm-3 formFieldLabel", attrs: { for: "" } },
            [_vm._v(_vm._s(this.webPart.DescText))]
          )
        ])
      : _vm._e(),
    this.webPart.Desc
      ? _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "col-sm-1" }),
          _c("span", {
            staticClass: "col-sm-11",
            domProps: { innerHTML: _vm._s(this.webPart.Desc) }
          })
        ])
      : _vm._e(),
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 formFieldInputBlock" },
          [
            _c(
              "ValidationObserver",
              { ref: "form" },
              [
                _c("validation-provider", {
                  attrs: { rules: "required", name: _vm.webPart.DescText },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _vm._l(_vm.webPart.ShopItems, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "form-check" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.SelectItem,
                                      expression: "formData.SelectItem"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "radio",
                                    id: "rdo" + item.Id + index,
                                    name: "shopItem"
                                  },
                                  domProps: {
                                    value: item.Text,
                                    checked: _vm._q(
                                      _vm.formData.SelectItem,
                                      item.Text
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.formData,
                                        "SelectItem",
                                        item.Text
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: "rdo" + item.Id + index }
                                  },
                                  [_vm._v(" " + _vm._s(item.Text) + " ")]
                                )
                              ]
                            )
                          }),
                          _c("span", { staticClass: "error_msg" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }