var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "carousel",
        {
          attrs: {
            autoplay: true,
            nav: false,
            dots: true,
            center: false,
            items: 1,
            loop: true,
            responsive: _vm.responsive,
            margin: 10,
            autoWidth: true
          }
        },
        _vm._l(_vm.propsWebPart.PartImages, function(image, index) {
          return _c(
            "a",
            {
              key: index,
              attrs: {
                href: image.LinkUrl ? image.LinkUrl : "#",
                target: image.LinkTarget
              }
            },
            [
              _c("img", {
                staticStyle: { width: "400px" },
                attrs: { src: _vm.toDownloadLink(false, image.ImageUrl) }
              })
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }