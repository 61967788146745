<template>
  <div>
    <p v-if="this.value" style="color:green">{{ trueText }}</p>

    <p v-if="!this.value" style="color:red">{{ falseText }}</p>

  </div>
</template>

<script>
/**
 <MyLabelTrueFalse v-model="item.IsEnable"></MyLabelTrueFalse>
 <MyLabelTrueFalse v-model="item.IsEnable" :trueText="是" :falseText="否" ></MyLabelTrueFalse>
 **/


export default {
  // name: "MyColorPicker",
  props: {
    value: {
      type: Boolean,
    },
    trueText: {
      type: String,
      default: "啟用中"
    },
    falseText: {
      type: String,
      default: "停用中"
    },
  },
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    disable(){
      this.value=false;
    },
    enable(){
      this.value=true;
    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
