var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(this.ProductOption.OptionSets.Items, function(item, index) {
      return _c("div", { key: index, staticClass: "form-group row" }, [
        _vm.isInSetting
          ? _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v(_vm._s(item.Text))
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-sm-10" },
          [
            _vm.isInSetting
              ? _c("div", { staticClass: "checkbox" }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.propsValue[item.SystemId].IsEnable,
                          expression: "propsValue[item.SystemId].IsEnable"
                        }
                      ],
                      attrs: { type: "checkbox", value: "" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.propsValue[item.SystemId].IsEnable
                        )
                          ? _vm._i(_vm.propsValue[item.SystemId].IsEnable, "") >
                            -1
                          : _vm.propsValue[item.SystemId].IsEnable
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.propsValue[item.SystemId].IsEnable,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.propsValue[item.SystemId],
                                  "IsEnable",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.propsValue[item.SystemId],
                                  "IsEnable",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.propsValue[item.SystemId],
                              "IsEnable",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _vm._v("啟用")
                  ])
                ])
              : _vm._e(),
            _vm.propsValue[item.SystemId].IsEnable
              ? _c("MyButtonRadio", {
                  attrs: {
                    options: _vm.convertToButtonRadionOption(item.Options),
                    size: "md"
                  },
                  model: {
                    value: _vm.propsValue[item.SystemId].SelectValue,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.propsValue[item.SystemId],
                        "SelectValue",
                        $$v
                      )
                    },
                    expression: "propsValue[item.SystemId].SelectValue"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }