<template>
  <div >
<!--    :is-vertical-resize="true" 				// 設置表格的縱向自適應-->
<!--    :columns="tableConfig.columns" 			// 列的集合-->
<!--    :title-rows="tableConfig.titleRows"		// 表格的表頭-->
<!--    :table-data="tableConfig.tableData"		// 表格的數據集-->
<!--    :show-vertical-border="false"			// 是否顯示垂直border-->
<!--    :width="1295" 							// 設置表格的寬度-->
<!--    :height="540" 							// 設置表格的高度-->
<!--    :min-height="500"						// 設置表格的最低高度-->
<!--    row-hover-color="#eee"					// 鼠標移動到相應行時變換的顏色-->
<!--    row-click-color="#edf7ff"				// 鼠標點擊時變換的顏色-->
<!--    :paging-index="(pageIndex-1)*pageSize"	// 當前表格列的數目-->
<!--    :select-all="selectAll"					// 選擇所有選項時觸發的函數-->
<!--    :select-change="selectChange"			// 選中某一選項時觸發的函數-->
<!--    :select-group-change="selectGroupChange"// 任意選中選項出發的函數-->
<!--    :is-loading="isLoading"					// 數據加載時顯示loading圖標    -->
<!--    :width="1295"-->
<!--    :height="540"-->
<!--    :min-height="500"-->

<!-- 其他有用的屬性-->
    :max-height="300"
    max-height="calc(100vh - 350px)"
    :scroll-width="1200"    //在mobile時，可以往右捲動
    :max-height="200" :fixed-header="true"
    :max-height="200" :fixed-header="false"

    column
      width: "40%",
      width: 400,
      fixed: "left",    //釘選欄

      //超過長度變成...
      ellipsis: {
        showTitle: true,
      },

      //超過長度變成...  (但顯示2行)
      ellipsis: {
        showTitle: true,
        lineClamp: 2,
      },
-->
    <div class="input-group" style="width:200px">
     <span class="input-group-append">
          <button class="btn btn-outline-secondary border-start-0 border ms-n3" type="button">
              <i class="fa fa-search"></i>
          </button>
      </span>
      <input type="text" class="form-control" placeholder="關鍵字" v-model="searchText" >
    </div>

    <div style="background-color: white;max-width: 1280px">
      <ve-table
          ref="grid"
          :style="{'word-break':wordBreak}"
          :row-style-option="rowStyleOption"
          :radio-option="radioOption"
          :checkbox-option="checkboxOption"
          :is-vertical-resize="true"
          :columns="columns"
          :title-rows="tableConfig.titleRows"
          :table-data="filterDataSource"
          :show-vertical-border="true"
          row-hover-color="#eee"
          row-click-color="#edf7ff"
          :select-all="config.selectAll"
          :select-change="config.selectChange"
          :select-group-change="config.selectGroupChange"
          :paging-index="(config.pageIndex-1)*config.pageSize"
          :is-loading="config.isLoading"
          :expand-option="expandOption"
          row-key-field-name="SystemId"
          :border-around="true"
          :border-x="true"
          :border-y="true"
          :sort-option="sortOption"
          :column-width-resize-option="columnWidthResizeOption"
          :cell-style-option="cellStyleOption"
      ></ve-table>
      <ve-pagination v-show="!showEmpty" :total="tableData.length" :page-size-option="[10,50,100]" :page-size="10" />
      <div v-show="showEmpty" class="empty-data">查無資料!</div>
    </div>


    <a role="button" class="btn btn-primary" @click="btnTest1()">hide column</a>
    <a role="button" class="btn btn-primary" @click="btnTest2()">show column</a> <br>
    <a role="button" class="btn btn-primary" @click="btnTest3()">radio select</a>
    <a role="button" class="btn btn-primary" @click="btnTest4()">radio select by id</a>
    <a role="button" class="btn btn-primary" @click="btnTest5()">radio unselect </a> <br>
    <a role="button" class="btn btn-primary" @click="btnTest6()">checkbox select</a>
    <a role="button" class="btn btn-primary" @click="btnTest7()">checkbox select all</a>
    <a role="button" class="btn btn-primary" @click="btnTest8()">checkbox unselect all</a> <br>
    <a role="button" class="btn btn-primary" @click="btnTest9()">顯示 查無資料</a>
  </div>

</template>

<!--
https://happy-coding-clans.github.io/vue-easytable/#/en/doc/start

-->
<script>


import _ from "lodash";

export default {
  components: {
  },
  data: function () {
    return {
      searchText: "",
      searchStatus: "",
      showEmpty:false,
      wordBreak: "normal",      // normal | keep-all | break-all  | break-word    //https://happy-coding-clans.github.io/vue-easytable/#/en/doc/table/column-width?anchor=long-text-destroys-layout
      expandOption: {
        render: ({ row, column, rowIndex }, h) => { // eslint-disable-line
          return (
              <p>
                My name is <span style="color:#1890ff;">{row.name}</span>
                ,I'm living in {row.address}
              </p>
          );
        },
      },
      rowStyleOption: {
        stripe: true,
        hoverHighlight: true,
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => { // eslint-disable-line
          if (column.key === "date") {
            return "table-body-cell-class1";
          }

          if (column.key === "name" && row.name === "Larsen") {
            return "table-body-cell-class2";
          }
        },
      },
      columnWidthResizeOption: {
        // default false
        enable: true,
        // column resize min width
        // minWidth: 30,
        // column size change
        // sizeChange: ({ column, differWidth, columnWidth }) => {
        //   this.columnResizeInfo.column = column;
        //   this.columnResizeInfo.differWidth = differWidth;
        //   this.columnResizeInfo.columnWidth = columnWidth;
        // },
      },
      config:{
        pageIndex: 1,
        pageSize: 10,
        selectAll: true,
        selectChange:true,
        isLoading: false,
        selectGroupChange:true,
      },
      tableConfig: {
        multipleSort: false,
        tableData: [],
        columns: [
          {field: "option", width: 75, titleAlign: "center", type: "selection"},
          {field: "seqNum", width: 125, columnAlign: "center"}
        ],
        titleRows: [
          [
            {fields: ["option"], titleAlign: "center", type:"selection"},
            {fields: ["seqNum"], title: "單號", titleAlign: "center"},
            {fields: ["name"], title: "姓名", titleAlign: "center"},
            {fields: ["stuId"], title: "學號", titleAlign: "center"},
            {fields: ["tel"], title: "手機號", titleAlign: "center"},
            {fields: ["date"], title: "日期", titleAlign: "center"},
            {fields: ["reward"], title: "賞金", titleAlign: "center"},
            {fields: ["state"], title: "狀態", titleAlign: "center"},
            {fields: ["comment"], title: "備註", titleAlign: "center"}
          ]
        ],
      },
      sortOption: {
        sortAlways: true,
        sortChange: (params) => {
          console.log("sortChange::", params);
          this.sortChange(params);
        },
      },
      radioOption: {
        defaultSelectedRowKey: "",
        disableSelectedRowKeys:[],
        selectedRowKey:"",//有宣告這個值後，就需要手動控制此值
        selectedRowChange: ({ row }) => {
          this.gridEvent_RadioSelectedRowChange(row);
        },
      },
      checkboxOption: {
        hideSelectAll: false,
        defaultSelectedRowKeys:[],
        disableSelectedRowKeys:[],
        selectedRowKeys:[],//有宣告這個值後，就需要手動控制此值
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {  // eslint-disable-line
          // console.log(row, isSelected, selectedRowKeys);
          this.gridEvent_CheckboxSelectedRowChange(row,isSelected, selectedRowKeys );
        },
        // selected all change event
        selectedAllChange: ({ isSelected, selectedRowKeys }) => { // eslint-disable-line
          // console.log(isSelected, selectedRowKeys);
          this.gridEvent_CheckboxSelectedRowAll(isSelected, selectedRowKeys );
        },
      },
      columns: [
        {
          field: "",
          key: "seq",
          title: "#",
          align: "center",
          width: 7,
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => { // eslint-disable-line
            return (
                <span class="text-bold" style="color:#1890ff;">
                                    {++rowIndex}
                                </span>
            );
          },
        },
        {
          field: "",
          key:"checkbox",
          // type=checkbox
          type: "checkbox",
          title: "",
          width: 7,
          align: "left",
          fixed: "left",
        },
        {
          field: "",
          key:"radio",
          // type=checkbox
          type: "radio",
          title: "",
          width: 7,
          align: "left",
          fixed: "left",
        },
        {
          field: "",
          key: "expand",
          // 设置需要显示展开图标的列
          type: "expand",
          title: "",
          width: 7,
          align: "left",
          fixed: "left",
        },
        {
          field: "",
          key: "action",
          // 设置需要显示展开图标的列
          title: "",
          width: 50,
          align: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => { // eslint-disable-line
            return (
                <span>
                  <button
                      class="btn btn-primary"
                      on-click={() => this.btnEdit(row)}
                  >
                      Edit
                  </button>
                  &nbsp;
                  <button
                      class="btn btn-primary"
                      on-click={() => this.btnDelete(row)}
                  >
                  Delete
                  </button>
                </span>
            );
          },
        },
        {
          field: "name",
          key: "name",
          title: "Name",
          align: "left",
          sortBy: "",

          renderBodyCell: ({ row, column, rowIndex }, h) => { // eslint-disable-line
            const text = row[column.field];
            return (
                <span>
                                    <a
                                        class="test-text"
                                        href="http://github.com"
                                        style="color:#1890ff;cursor:pointer;"
                                    >
                                        {text}
                                    </a>
                                </span>
            );
          },
        },
        {
          field: "date",
          key: "date",
          title: "Date",
          align: "left",
          sortBy: "",
        },
        {
          field: "hobby",
          key: "hobby",
          title: "Hobby",
          sortBy: "",
          align: "left",
        },
        { field: "address",
          key: "address",
          title: "Address",
          align: "left",
          sortBy: "",
        },
        { field: "status",
          key: "status",
          title: "status",
          align: "left",
          sortBy: "",
          filter: {
            filterList: [
              {
                value: "enable",
                label: "enable",
                selected: false,
              },
              {
                value: "disable",
                label: "disable",
                selected: false,
              },
            ],
            // filter confirm hook
            filterConfirm: (filterList) => {
              const labels = filterList
                  .filter((x) => x.selected)
                  .map((x) => x.label);
              this.searchStatus=labels;
              //this.searchByDateField(labels);
            },
            // filter reset hook
            filterReset: (filterList) => {  // eslint-disable-line
              this.searchStatus=[];
              // this.searchByDateField([]);
            },
          },
        },
        { field: "textbox",
          key: "textbox",
          title: "textbox",
          align: "left",
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {// eslint-disable-line
            return (
                <input type="text" class="form-control" style="border:1px" id="name" name="name" placeholder="" value={row["textbox"]} on-change={(val) => {
                  this.cellDataChange(row, column, val);
                }} />
            );
          },
         },
        { field: "select",
          key: "select",
          title: "select",
          align: "left",
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {// eslint-disable-line
            return (
                <select class="form-control" value={row["select"]} on-change={(val) => {
                  this.cellDataChange(row, column, val);
                }}>
                  <option></option>
                  <option>1</option>
                  <option>select</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
            );
          },
        },
      ],
      tableData: [
        {
          SystemId: "1",
          name: "Aohn",
          date: "1900-05-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Shanghai",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "2",
          name: "Dickerson",
          date: "1910-06-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Beijing",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "3",
          name: "Larsen",
          date: "2000-07-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Chongqing",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "4",
          name: "Geneva",
          date: "2010-08-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Xiamen",
          status: "disable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "5",
          name: "Jami",
          date: "2020-09-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Shenzhen",
          status: "disable",
          textbox:"text",
          select:"select",
        },
      ],
    };
  },
  computed: {
    filterDataSource(){
      //filter all properties in array
      let dataToShow =this.tableData;

      if (this.searchText){
        dataToShow = dataToShow.filter((item) => {
          return Object.keys(item).some((key) => {
            return String(item[key]).toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
          });
        });
      }
      if (this.searchStatus.length>0){
        dataToShow = dataToShow.filter(
              (x) => this.searchStatus.length === 0 || this.searchStatus.includes(x.status),
          );
      }


      return dataToShow;
    }
  },
  methods: {
    btnTest1(){
      this.$refs["grid"].hideColumnsByKeys(["address"]);
    },
    btnTest2(){
      this.$refs["grid"].showColumnsByKeys(["address"]);
    },
    btnTest3(){
      this.radioOption.selectedRowKey="3";
    },
    btnTest4(){
      this.gridUtil_RadioSelectById("1")
    },
    btnTest5(){
      this.gridUtil_RadioUnSelect();
    },
    btnTest6(){
      // this.checkboxOption.defaultSelectedRowKeys=["5"];
      this.checkboxOption.selectedRowKeys=["3","4"];
    },
    btnTest7(){
      // this.checkboxOption.defaultSelectedRowKeys=["5"];
      this.gridUtil_CheckboxSelectAll();
    },
    btnTest8(){
      // this.checkboxOption.defaultSelectedRowKeys=["5"];
      this.gridUtil_UnCheckboxSelectAll();
    },
    btnTest9(){
      // this.checkboxOption.defaultSelectedRowKeys=["5"];
      this.tableData=[];
      this.showEmpty=true;
    },
    gridEvent_RadioSelectedRowChange(row){
      this.radioOption.selectedRowKey = row.SystemId;
    },
    gridUtil_RadioSelectById(key) {
      let selectedRowKey = this.radioOption.selectedRowKey;

      if (selectedRowKey === key) {
        this.radioOption.selectedRowKey = "";
      } else {
        this.radioOption.selectedRowKey = key;
      }
    },
    gridUtil_RadioUnSelect() {
      this.radioOption.selectedRowKey = "";
    },

    gridEvent_CheckboxSelectedRowChange(row,isSelected, selectedRowKeys ){  // eslint-disable-line
      // console.log(row.SystemId,isSelected,selectedRowKeys);
      this.checkboxOption.selectedRowKeys = selectedRowKeys;
    },
    gridEvent_CheckboxSelectedRowAll(isSelected, selectedRowKeys){// eslint-disable-line
      // console.log(isSelected,selectedRowKeys);
    },
    gridUtil_CheckboxSelectAll() {
      this.checkboxOption.selectedRowKeys = this.tableData.map((x) => x.SystemId);
    },
    gridUtil_UnCheckboxSelectAll() {
      this.checkboxOption.selectedRowKeys = [];
    },

    sortChange(params) {// eslint-disable-line
      //foreach params property
      Object.entries(params).forEach(([key, value]) => {
        if (value){
          this.tableData=_.orderBy(this.tableData,[key],[value])
        }
      });
    },
    // searchByDateField(labels) {
    //   this.tableData = this.tableData.filter(
    //       (x) => labels.length === 0 || labels.includes(x.date),
    //   );
    // },
    btnEdit(row){
      //row.SystemId
      console.info(row);
    },
    btnDelete(row){
      //row.SystemId
      console.info(row);
    },
    // cell data change (不能運作)
    cellDataChange(row, column, cellValue) {
      const { submitData } = this;

      let currentCell = submitData.find(
          (x) => x.rowKey === row["rowKey"] && x.field === column.field,
      );

      if (currentCell) {
        currentCell.value = cellValue;
      } else {
        let newCell = {
          rowKey: row["rowKey"],
          field: column.field,
          value: cellValue,
        };
        this.submitData.push(newCell);
      }
    },

  },
 mounted() {

    // debugger // eslint-disable-line
    if (this.$mq === 'sm'){
      this.$refs["grid"].showColumnsByKeys(["expand"]);
      this.$refs["grid"].hideColumnsByKeys(["seq"]);
      this.$refs["grid"].hideColumnsByKeys(["address"]);
    }else{
      this.$refs["grid"].hideColumnsByKeys(["expand"]);
    }

  },
}
</script>

<style scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>

<style>
.btn{
  margin: 5px;
}
.table-body-cell-class1 {
  background: #91d5ff !important;
  color: #fff !important;
}

.table-body-cell-class2 {
  background: orange !important;
  color: #fff !important;
}
</style>
