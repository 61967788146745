<template>
  <div class="c-sidebar c-sidebar-lg c-sidebar-light c-sidebar-right c-sidebar-overlaid" id="aside">
    <button class="c-sidebar-close c-class-toggler" type="button" data-target="_parent" data-class="c-sidebar-show">
      <svg class="c-icon">
        <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-x"></use>
      </svg>
    </button>
  </div>
</template>

<script>
import session from "../../../common/session";

export default {
  data: function() {
    return {
      showMenu: true,
      userId:"",
      showFunReport:false,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    logout(){
      session.logout(this.$router,this)
    }
  },
  mounted() {


    //this.loadData()

    this.userId=session.getAccUid()

    if (this.userId==="ADMIN"){
      this.showFunReport=true;
    }
  },
}
</script>

<style scoped>
.navbar-nav{
  width: 200px !important;
}
</style>
