<template>
    <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="ADMIndex"></COM_HeaderNav>

    <MyToolbarBase>
        <div class="btn-group mr-2" role="group" aria-label="First group">
            <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
            <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-search" ></i>票券使用明細</button>
            <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
        </div>
    </MyToolbarBase>

  <div class="fieldsPanel">
    <ValidationObserver ref="form">

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">類型</label>
        <div class="a_thinInputDiv col-sm-10">
          <select class="form-control" v-model="formData.type">
            <option value="1">一般</option>
            <option value="2">VIP</option>
          </select>
        </div>
      </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">會員名稱</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" placeholder="" v-model="formData.name">
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">會員編號</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" placeholder="" v-model="formData.no">
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">會員名稱</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" placeholder="" v-model="formData.name">
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">電話</label>
            <div class="col-sm-10">
                <input type="tel" class="form-control" placeholder="" v-model="formData.tel">
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">地址</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" placeholder="" v-model="formData.address">
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">狀態</label>
            <div class="col-sm-10">
                <select class="form-control" v-model="formData.status">
                    <option value="1">生效中</option>
                    <option value="2">停用</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="" class="col-sm-2">備註</label>
            <div class="col-sm-10">
                <textarea class="form-control" id="txtMemo" name="txtMemo" rows="3"  v-model="formData.memo"></textarea>
            </div>
        </div>





    </ValidationObserver>
  </div>
    </div>
</template>

<script>
// import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import MyToolbarBase from "@/pages/commonComponent/MyToolbarBase.vue";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";

export default {
    components: {COM_HeaderNav, MyToolbarBase},
  data: function () {
    return {
      systemId: "",
      formData: {
          Fun:"",
          SystemId:"1",
          type:"",
          no:"001",
          name:"taylor",
          tel:"0915071457",
          address:"桃園市中壢區福州路130號",
          status:"",
          memo:"",
          createDt:['2023-01-01'],
          modifyDt:['2023-06-30'],
      },
    };
  },
  methods: {
    GenPassCode(){
      this.formData.passCode = util.uuid().substring(0,8);
    },
    async loadData() {
      // this.systemId=this.$route.query.systemId;
      //
      // if (this.systemId){
      //   let data=await apiUBossShopBk.api_Member_Get(this.systemId)
      //
      //   this.formData=data
      // }

    },
    btnUpdate(){
      // let router=this.$router
      //
      // this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
      //   if (!isValid) {
      //     let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
      //     util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
      //     return;
      //   } else {
      //     //---------------------------------------
      //     apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
      //       util.ShowMessage("儲存成功","訊息")
      //       router.back()
      //     })
      //     //---------------------------------------
      //   }
      // });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
