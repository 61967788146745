var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("link", {
        attrs: {
          rel: "stylesheet",
          href:
            "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css"
        }
      }),
      _c("tags-input", {
        attrs: {
          "element-id": "xxxxxxxxyyyyyyy",
          "existing-tags": _vm.existingTags,
          placeholder: "標籤",
          typeahead: true,
          "typeahead-style": "dropdown",
          "typeahead-max-results": 20,
          "typeahead-activation-threshold": 1,
          "typeahead-show-on-focus": true,
          "allow-duplicates": false,
          "only-existing-tags": _vm.onlyExistingTags,
          "delete-on-backspace": true,
          "id-field": _vm.idField,
          "text-field": _vm.textField,
          "discard-search-text": "請選擇標籤"
        },
        model: {
          value: _vm.propsValue,
          callback: function($$v) {
            _vm.propsValue = $$v
          },
          expression: "propsValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }