<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingProduct"></COM_HeaderNav>


    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>下一步</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>

<!--        //QQ-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存後繼續新增</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>複製並編輯</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-eye"></i>前台預覽</a>-->
      </div>
    </div>

    <ValidationObserver ref="form">

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">商品名稱</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="商品名稱" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Name">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";


export default {
  components: {COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {
        "ProductCode":"",
        "Name":"",
        "SName":"",
        "PriceSuggest":0,
        "Price":0,
        "PriceCost":0,
        "CLProductStatus":"",
        "Desc":"",
        "OnMarketDt":"",
        "DiscontinueDt":"",
        "Memo":"",
      },
      formDataDemo: {
        "ProductCode":"",
        "Name":"",
        "SName":"",
        "PriceSuggest":"",
        "Price":"",
        "PriceCost":"",
        "CLProductStatus":"",
        "Summary":"",
        "Desc":"",
        "OnMarketDt":"",
        "DiscontinueDt":"",
        "Memo":"",
      },
      options: {    //QQ
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: '//localhost:3000/upload',
        testChunks: false
      },
      attrs: {
        accept: 'image/*'
      }
    };
  },
  methods: {
    async loadData() {


    },
    btnUpdate(){
      let that=this             // eslint-disable-line
      let router=this.$router   // eslint-disable-line

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (that.formData.OnMarketDt===""){
            that.formData.OnMarketDt=null;
          }
          if (that.formData.DiscontinueDt===""){
            that.formData.DiscontinueDt=null;
          }
          //---------------------------------------
          debugger // eslint-disable-line
          apiUBossShopBk.api_Product_Save(that.formData).then((data)=>{
            this.$router.push({ name: 'ProductEdit', query: { systemId: data.SystemId }})
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.uploader-example {
  width: 880px;
  padding: 15px;
  /*margin: 40px auto 0;*/
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
