export const MyToolbarButtonIdEnum = Object.freeze({"Add":1,"Edit":2, "Delete":3,"DeleteAlone":4, "Copy":5,"Export":6,"Sort":7,"Setting":8,"Query":9,"Save":10,"Home":11,"Custom1":901,"Custom2":902,"Custom3":903,"Back":904,"BackDefault":905})
export const MyToolbarButtonSelectTypeEnum = Object.freeze({"AlwayOn":1, "NoSelect":2,"SingleSelect":3, "MustSelect_Delete":4})

class MyToolbar {
    constructor() {
        this._Groups = [];
    }

    get Groups() {
        return this._Groups;
    }

    addGroup(group) {
        // let item =new MyToolbarGroup();
        this._Groups.push(group);
        return this;
    }
}


class MyToolbarGroup {
    constructor() {
        this._Buttons = [];
    }

    get Buttons() {
        return this._Buttons;
    }
    /**
     * @param {MyToolbarButtonIdEnum} id
     * @param {MyToolbarButtonSelectTypeEnum} selectType
     */
    addButtons(text,icon,id,selectType) {
        let button =new MyToolbarButton(text,icon,id,selectType);
        this._Buttons.push(button);
        return this;
    }
    /**
     * @param {MyToolbarButtonIdEnum} id
     * @param {MyToolbarButtonSelectTypeEnum} selectType
     */
    addButtonsWithClassName(text,icon,id,selectType,className) {
        let button =new MyToolbarButton(text,icon,id,selectType);
        button.className=className;
        this._Buttons.push(button);
        return this;
    }
    //---------------------------------------
    addButton_Add(){
        this.addButtons("新增","fa fa-plus-circle",MyToolbarButtonIdEnum.Add,MyToolbarButtonSelectTypeEnum.NoSelect)
        return this;
    }
    addButton_Edit(){
        this.addButtons("修改","fa fa-pen",MyToolbarButtonIdEnum.Edit,MyToolbarButtonSelectTypeEnum.SingleSelect)
        return this;
    }
    addButton_Delete(){
        this.addButtons("刪除","fa fa-trash",MyToolbarButtonIdEnum.Delete,MyToolbarButtonSelectTypeEnum.MustSelect_Delete)
        return this;
    }
    addButton_DeleteAlone(){
        this.addButtonsWithClassName("刪除","fa fa-trash",MyToolbarButtonIdEnum.Delete,MyToolbarButtonSelectTypeEnum.AlwayOn,"btn-danger")
        return this;
    }
    addButton_Copy(){
        this.addButtons("複製","fa fa-clone",MyToolbarButtonIdEnum.Copy,MyToolbarButtonSelectTypeEnum.SingleSelect)
        return this;
    }
    addButton_Export(){
        this.addButtons("匯出","fa fa-file-export",MyToolbarButtonIdEnum.Export,MyToolbarButtonSelectTypeEnum.NoSelect)
        return this;
    }
    addButton_Sort(){
        this.addButtons("排序","fa fa-sort",MyToolbarButtonIdEnum.Sort,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }
    addButton_Setting(){
        this.addButtons("設定","fa fa-cog",MyToolbarButtonIdEnum.Setting,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }

    addButton_Query(){
        this.addButtons("查詢","fa fa-search",MyToolbarButtonIdEnum.Query,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }
    addButton_Save(){
        this.addButtons("儲存","fa fa-save",MyToolbarButtonIdEnum.Save,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }

    addButton_Back(){
        this.addButtons("回上一頁","fa fa-arrow-left",MyToolbarButtonIdEnum.Back,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }
    addButton_BackDefault(){
        this.addButtons("回上一頁","fa fa-arrow-left",MyToolbarButtonIdEnum.BackDefault,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }
    addButton_Home(){
        this.addButtons("首頁","fa fa-home",MyToolbarButtonIdEnum.Home,MyToolbarButtonSelectTypeEnum.AlwayOn)
        return this;
    }

}

class MyToolbarButton {
    /**
     * @param {MyToolbarButtonSelectTypeEnum} selectType
     */
    constructor(text,icon,buttonId,selectType) {
        this._text=text;
        this._icon=icon;
        this._buttonId=buttonId;
        this._selectType=selectType;
    }
    get text() {
        return this._text;
    }
    get icon() {
        return this._icon;
    }
    get buttonId() {
        return this._buttonId;
    }

    get selectType() {
        return this._selectType;
    }

    get className() {
        return this._className;
    }

    set className(value) {
        this._className = value;
    }

}

export {MyToolbar,MyToolbarGroup}
