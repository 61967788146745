<template>
    <MyToolbarBase>
        <div class="btn-group mr-2" role="group" aria-label="First group" v-for="(group,indexGroup) in toolbar.Groups" :key="indexGroup">
<!--          <button class="btn btn-primary d-md-none">-->
<!--            <i class="fa fa-search"></i>-->
<!--            <span class="invisible">Search</span>-->
<!--          </button>-->
            <button v-for="(button,indexButton) in group.Buttons" :key="indexButton" type="button" class="btn " :class="button.className || getClass(button)" @click="button_Click(button)"><i :class="button.icon"></i> {{getButtonText(button)}}</button>
        </div>

<!--        <div class="btn-group mr-2" role="group" aria-label="First group">-->
<!--            <button type="button" class="btn " :class="getClass_NoSelect" v-if="configDefault.Add.Enable" @click="button_Click(configDefault.Add.CallBack)"><i class="fa fa-plus-circle"></i> {{configDefault.Add.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_SingleSelect" v-if="configDefault.Edit.Enable" @click="button_Click(configDefault.Edit.CallBack)"><i class="fa fa-pen"></i> {{configDefault.Edit.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_MustSelect_Delete" v-if="configDefault.Delete.Enable" @click="button_Click(configDefault.Delete.CallBack)"><i class="fa fa-trash"></i> {{configDefault.Delete.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_SingleSelect" v-if="configDefault.Copy.Enable"><i class="fa fa-clone"></i> {{configDefault.Copy.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_SingleSelect" v-if="configDefault.CustomButton1.Enable"><i class="fa fa-clone"></i> {{configDefault.CustomButton1.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_SingleSelect" v-if="configDefault.CustomButton2.Enable"><i class="fa fa-clone"></i> {{configDefault.CustomButton2.BtnText}}</button>-->
<!--            <button type="button" class="btn " :class="getClass_SingleSelect" v-if="configDefault.CustomButton3.Enable"><i class="fa fa-clone"></i> {{configDefault.CustomButton3.BtnText}}</button>-->
<!--        </div>-->


<!--        <div class="btn-group " role="group" v-if="configDefault.Export.Enable">-->
<!--            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                {{configDefault.Export.BtnText}}-->
<!--            </button>-->
<!--            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">-->
<!--                <a class="dropdown-item" href="#">Excel</a>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="btn-group mr-2" role="group" aria-label="Second group">-->
<!--            <button type="button" class="btn btn-primary" v-if="configDefault.Sort.Enable"><i class="fa fa-sort"></i>{{configDefault.Sort.BtnText}}</button>-->
<!--        </div>-->


<!--        <div class="btn-group" role="group" aria-label="Third group">-->
<!--            <button type="button" class="btn btn-primary" v-if="configDefault.Setting.Enable"><i class="fa fa-cog"></i> {{configDefault.Setting.BtnText}}</button>-->
<!--        </div>-->
    </MyToolbarBase>
</template>

<script>/**
 有grid
 <MyToolbarVuetify ref="myToolbar" :grid-select-ids="gridSelectIds"  v-on:button-click="toolbarButton_Click"> </MyToolbarVuetify>

 無grid
 <MyToolbarVuetify ref="myToolbar" v-on:button-click="toolbarButton_Click"> </MyToolbarVuetify>

 **/
import MyToolbarBase from "@/pages/commonComponent/MyToolbarBase.vue";
import {MyToolbarButtonIdEnum, MyToolbarButtonSelectTypeEnum} from "@/pages/commonComponent/js/MyToolbarVuetify";

export default {
  components: {MyToolbarBase},
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    gridSelectIds:{
      type: Array,
      default() {
        return null
      }
    },

  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      toolbar:{
        type: Object,
      },
      flag:{
        SingleSelectFunctionEnable:false,
        NoSelectFunctionEnable:true,
        MustSelectFunctionEnable:false,
      },
      // configDefault:{
      //   Add:{
      //     BtnText:"新增",
      //     Enable:false,
      //     CallBack:"",
      //   },
      //   Edit:{
      //     BtnText:"修改",
      //     Enable:false,
      //     CallBack:"",
      //   },
      //   Delete:{
      //     BtnText:"刪除",
      //     Enable:false,
      //     CallBack:"",
      //   },
      //   Copy:{
      //     BtnText:"複製",
      //     Enable:false,//預設不出現
      //     CallBack:"",
      //   },
      //   Export:{
      //     BtnText:"匯出",
      //     Enable:false,  //QQ 待完成
      //     CallBack:"",
      //   },
      //   Sort:{
      //     BtnText:"排序",
      //     Enable:false, //預設不出現
      //     CallBack:"",
      //   },
      //   Setting:{
      //     BtnText:"設定",
      //     Enable:false, //預設不出現
      //     CallBack:"",
      //   },
      //   CustomButton1:{
      //     BtnText:"",
      //     Enable:false, //預設不出現
      //     CallBack:"",
      //   },
      //   CustomButton2:{
      //     BtnText:"",
      //     Enable:false, //預設不出現
      //     CallBack:"",
      //   },
      //   CustomButton3:{
      //     BtnText:"",
      //     Enable:false, //預設不出現
      //     CallBack:"",
      //   },
      // },

    };
  },
  watch: {
    // propsValue(newVal) {
    //   //this.('input', newVal);
    //   this.$emit('input', newVal);
    // },
    // value(newVal) {
    //   this.propsValue = newVal
    // },
    'gridSelectIds':function (newValue){
      // console.log("gridSelectIds",newValue.length);
      // if (newValue) {
        if (newValue.length===0){
          this.flag.SingleSelectFunctionEnable=false;
          this.flag.NoSelectFunctionEnable=true;
          this.flag.MustSelectFunctionEnable=false;
        }else if (newValue.length===1){
          this.flag.SingleSelectFunctionEnable=true;
          this.flag.NoSelectFunctionEnable=false;
          this.flag.MustSelectFunctionEnable=true;
        }else if (newValue.length>1){
          this.flag.SingleSelectFunctionEnable=false;
          this.flag.NoSelectFunctionEnable=false;
          this.flag.MustSelectFunctionEnable=true;
        }
      // }
    }
  },
  computed: {
    isMobile() {
      return this.$mq === 'sm';
    },
    getClass_AlwaysOn() {
      return {
        'btn-primary':true
      }
    },
    getClass_SingleSelect() {
      return {
        'btn-primary': this.flag.SingleSelectFunctionEnable,
        'btn-secondary': !this.flag.SingleSelectFunctionEnable
      }
    },
    getClass_NoSelect() {
      return {
        'btn-primary': this.flag.NoSelectFunctionEnable,
        'btn-secondary': !this.flag.NoSelectFunctionEnable
      }
    },
    getClass_MustSelect_Delete() {
      return {
        'btn-danger': this.flag.MustSelectFunctionEnable,
        'btn-secondary': !this.flag.MustSelectFunctionEnable
      }
    },
  },
  methods: {
    /**
     * @param {MyToolbarButton} button
     */
    getClass(button){
      // let sClassStr="";
      if (button.selectType===MyToolbarButtonSelectTypeEnum.AlwayOn) {
        return this.getClass_AlwaysOn;  //回傳 computed
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.NoSelect){
        return this.getClass_NoSelect;  //回傳 computed
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.SingleSelect){
        return this.getClass_SingleSelect;  //回傳 computed
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.MustSelect_Delete){
        return this.getClass_MustSelect_Delete; //回傳 computed
      }

      // return sClassStr;
    },
    setProp_Toolbar(toolbar){
      this.toolbar=toolbar;
    },
    getButtonText(button){
      if (this.isMobile){
        return "";
      }else{
        return button.text;
      }
    },
    //給予value屬性預設值
    // initValue() {
    //   const isMyObjectEmpty = !Object.keys(this.value).length;
    //
    //   if (isMyObjectEmpty) {
    //     let template = {...this.datasDemo};
    //     this.value = template;
    //   }
    // },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    /**
     * @param {MyToolbarButton} button
     */
    button_Click(button){
      // debugger // eslint-disable-line
      // console.log(component);
      if (button.buttonId===MyToolbarButtonIdEnum.BackDefault){
        this.$router.back();
        return;
      }

      if (this.isButtonEnable(button,this.gridSelectIds)){
        if (this.gridSelectIds){
          this.$emit('button-click',button.buttonId,this.gridSelectIds)
        }else{
          this.$emit('button-click',button.buttonId,null)
        }
      }else{
        // eslint-disable-line
      }
    },
    isButtonEnable(button,selectedRecord){
      if (button.selectType===MyToolbarButtonSelectTypeEnum.AlwayOn) {
        return true;
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.NoSelect){
        if (selectedRecord){
          if (selectedRecord.length===0){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.SingleSelect){
        if (selectedRecord){
          if (selectedRecord.length===1){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
      }else if (button.selectType===MyToolbarButtonSelectTypeEnum.MustSelect_Delete){
        if (selectedRecord){
          if (selectedRecord.length!==0){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
      }

    }
  },
  mounted() {
    // this.initValue()
    //this.loadData()
    // util.ObjectOverride(this.configDefault,this.config);
  },
}
</script>
