var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "ADMIndex" } }),
      _c("MyToolbarVuetify", {
        attrs: { config: _vm.toolbarVuetifyConfig, "grid-meta": _vm.gridMeta },
        on: {
          "add-click": _vm.btnAdd_Click,
          "edit-click": _vm.btnEdit_Click,
          "delete-click": _vm.btnDelete_Click
        }
      }),
      _vm._v(" Status 示意 "),
      _c("span", { staticClass: "badge bg-primary" }, [_vm._v("Bz")]),
      _c("span", { staticClass: "badge bg-primary" }, [_vm._v("Fm")]),
      _c("span", { staticClass: "badge bg-danger" }, [_vm._v("Dm")]),
      _c("span", { staticClass: "badge bg-danger" }, [_vm._v("Tk")]),
      _c("span", { staticClass: "badge bg-warning" }, [_vm._v("Sms")]),
      _c("span", { staticClass: "badge bg-warning" }, [_vm._v("Em")]),
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.showDialog } },
        [_vm._v("Detail")]
      ),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function(ref) {
              var item = ref.item
              return [
                item.type === "1" ? _c("p", [_vm._v("常態使用")]) : _vm._e(),
                item.type === "2" ? _c("p", [_vm._v("客戶使用")]) : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("DLGDetail", { ref: "dlgEditor" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }