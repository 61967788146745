var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "udmIndex" } }),
      _c("MyToolbarVuetify", {
        ref: "myToolbar",
        attrs: { "grid-meta": _vm.gridMeta },
        on: { "button-click": _vm.toolbarButton_Click }
      }),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        staticClass: "bk_firstBlock",
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta },
        scopedSlots: _vm._u([
          {
            key: "Fun",
            fn: function(ref) {
              return undefined
            }
          },
          {
            key: "IsEnable",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("MyLabelIsEnable", {
                  model: {
                    value: item.IsEnable,
                    callback: function($$v) {
                      _vm.$set(item, "IsEnable", $$v)
                    },
                    expression: "item.IsEnable"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" form panel "),
      _c("MyFormPanelTester"),
      _vm._v(" table easy "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { role: "button" },
          on: { click: _vm.btnTest1 }
        },
        [_vm._v("Test")]
      ),
      _vm._v(" " + _vm._s(_vm.info) + " "),
      _c("MyVueEasytable", { ref: "grid" }),
      _vm._v(" table easy demo "),
      _c("MyVueEasytableDemo"),
      _vm._v(" table "),
      _vm._v(" wh " + _vm._s(_vm.width) + " " + _vm._s(_vm.height) + " "),
      _vm.$mq !== "sm" ? _c("span", [_vm._m(0)]) : _vm._e(),
      _vm.$mq === "sm" ? _c("span", [_vm._m(1)]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "nav",
      {
        staticClass: "navbar navbar-light bg-light",
        staticStyle: { "max-width": "1200px" }
      },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary justify-content-center" },
          [_vm._v("Next")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "nav",
      { staticClass: "navbar fixed-bottom navbar-light bg-light" },
      [
        _c("button", { staticClass: "btn btn-primary ml-auto" }, [
          _vm._v("Next")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }