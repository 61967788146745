<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingProduct"></COM_HeaderNav>

<!--    SearchPanel-->
    <SearchPanelForm ref="searchPanelForm" :fieldDefines="searchDefine.searchFieldDefines" :keyword-fields="searchDefine.searchKeywordFields"></SearchPanelForm>

<!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle" ></i>新增</button>
        <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle" ></i>新增 預約商品</button>
        <button type="button" class="btn btn-danger" @click="btnDelete"><i class="fa fa-trash" ></i>刪除</button>
      </div>
      <div class="btn-group mx-1" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          匯出
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="$refs.grid.exportExcel()">Excel</a>
        </div>
      </div>
    </div>

<!--    Grid-->
    <span v-if="isMobile">
      <MyMobileList :datas="gridDefine.datas" :field-to-show="gridDefine.fieldToShow" :swipe-setting="gridDefine.swipeSetting">
      </MyMobileList>
    </span>
    <span v-else>
      <MyKendoGrid ref="grid" :columns="gridDefine.columns" :datas="gridDefine.datas"></MyKendoGrid>
    </span>
  </div>
</template>

<script>

import SearchPanelForm from "@/pages/ubossBk/views/components/SearchPanelForm";
import MyKendoGrid from "@/pages/commonComponent/MyKendoGrid";
import { isMobile } from 'mobile-device-detect';
import MyMobileList from "@/pages/commonComponent/MyMobileList";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import apiServerUBossUtil from "@/pages/commonUBoss/apiServerUBossUtil";


export default {
  components: {
    COM_HeaderNav,
    MyMobileList,
    MyKendoGrid,
    SearchPanelForm,
  },
  data: function () {
    return {
      isMobile:isMobile,
      searchDefine:{
        searchFieldDefines:[],
        searchKeywordFields:[],
      },
      gridDefine:{
        columns: [
          // { field: 'SystemId', title: 'SystemId', minWidth: 50, width: '50px'},
          { field: 'Name', title: '商品' ,width:this.$dec.GridColumnWidth_Image, minWidth: this.$dec.GridColumnWidth_Image_M,cell: this.cellFunctionLink},
          { field: 'Name', title: '商品名稱' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'SName', title: '簡稱' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'Price', title: '售價' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'Memo', title: '備註' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
        ],
        datas:[],
        fieldToShow:{
          title:"Name",
          memo:"Price",
          badge1:"SName",
        },
        swipeSetting:{
          ItemClick:this.swipeSettingItemClick
        }
      },
    };
  },
  methods: {
    async loadData() {
      this.gridDefine.datas=await apiUBossShopBk.api_Product_ListQuery(null)
    },
    cellFunctionLink: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        that.gotoWithSystemId('ProductEdit',props.dataItem.SystemId)
      }
      // let linkText=props.dataItem.Name;
      // let linkText="";

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML:  "<a class='alink'><i class='fa fa-edit'></i>"+ "<img src="+this.toDownloadLink(props.dataItem.ImageMainUrl)+" style='width:70px;height:70px'>"  +" </a>"
        }
      });

    },
    swipeSettingItemClick:function (item){ // eslint-disable-line
      this.gotoWithSystemId('ProductEditFirstStep',item.SystemId)
    },
    btnCreate(){
      this.$router.push({ name: 'ProductEditFirstStep', query: { systemId: "" }})
    },
    btnDelete(){

    },
    toDownloadLink(link){
      return apiServerUBossUtil.toDownloadLinkOrDefault(link);
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
