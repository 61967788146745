var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("bootstrap 4")]),
      _c("h1", [_vm._v("ppd product option")]),
      _c("ProductOptionSelectorPpd", {
        model: {
          value: _vm.productSelectOptions,
          callback: function($$v) {
            _vm.productSelectOptions = $$v
          },
          expression: "productSelectOptions"
        }
      }),
      _vm._v(" " + _vm._s(_vm.productSelectOptions) + " "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "#" },
          on: { click: _vm.test1 }
        },
        [_vm._v("Test1")]
      ),
      _c("h1", [_vm._v("ppd 配方")]),
      _c("PpdDLGDrinkMaterial", {
        model: {
          value: _vm.ppdRecipe,
          callback: function($$v) {
            _vm.ppdRecipe = $$v
          },
          expression: "ppdRecipe"
        }
      }),
      _c("h1", [_vm._v("Tags")]),
      _c("MyTagInput", {
        attrs: { "only-existing-tags": false },
        model: {
          value: _vm.tags,
          callback: function($$v) {
            _vm.tags = $$v
          },
          expression: "tags"
        }
      }),
      _c("h1", [_vm._v("Grid")]),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { role: "button" },
          on: { click: _vm.btnSelectRow }
        },
        [_vm._v("選取row")]
      ),
      _c("MyGridGoodTable", {
        ref: "myGridGoodTable",
        attrs: {
          columns: _vm.demo.columns,
          rows: _vm.demo.rows,
          "show-checkbox-column": false
        }
      }),
      _c("h1", [_vm._v("Grid bootstrap")]),
      _c("MyGridBootstrapTable", {
        ref: "myGridBootstrapTable",
        attrs: {
          columns: _vm.datas.columns,
          rows: _vm.datas.rows,
          "show-checkbox-column": true
        }
      }),
      _c("h1", [_vm._v("Grid Vuetify")]),
      _vm._v(" myGridVuetify.gridMeta:" + _vm._s(_vm.gridMeta) + " "),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.name) + " xx ")]
            }
          },
          {
            key: "calories",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.calories) + " ccc ")]
            }
          },
          {
            key: "isEnable",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("MyLabelTrueFalse", {
                  attrs: { "true-text": "見", "false-text": "不見" },
                  model: {
                    value: item.isVisible,
                    callback: function($$v) {
                      _vm.$set(item, "isVisible", $$v)
                    },
                    expression: "item.isVisible"
                  }
                })
              ]
            }
          },
          {
            key: "isVisible",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("MyLabelIsVisible", {
                  model: {
                    value: item.isVisible,
                    callback: function($$v) {
                      _vm.$set(item, "isVisible", $$v)
                    },
                    expression: "item.isVisible"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("h1", [_vm._v("Select")]),
      _c("MySelectCascading", {
        ref: "mySelectCascading1",
        attrs: {
          datas: _vm.SelectDemo_Group1,
          "child-select-ref": _vm.ChildSelectRef1,
          "is-root": true,
          "field-name-text": "Text",
          "field-name-value": "Value",
          "field-name-parent-id": "ParentId"
        },
        model: {
          value: _vm.SelectValue1,
          callback: function($$v) {
            _vm.SelectValue1 = $$v
          },
          expression: "SelectValue1"
        }
      }),
      _c("MySelectCascading", {
        ref: "mySelectCascading2",
        attrs: {
          datas: _vm.SelectDemo_Group2,
          "child-select-ref": _vm.ChildSelectRef2,
          "field-name-text": "Text",
          "field-name-value": "Value",
          "field-name-parent-id": "ParentId"
        },
        model: {
          value: _vm.SelectValue2,
          callback: function($$v) {
            _vm.SelectValue2 = $$v
          },
          expression: "SelectValue2"
        }
      }),
      _c("MySelectCascading", {
        ref: "mySelectCascading3",
        attrs: {
          datas: _vm.SelectDemo_Group3,
          "field-name-text": "Text",
          "field-name-value": "Value",
          "field-name-parent-id": "ParentId"
        },
        model: {
          value: _vm.SelectValue3,
          callback: function($$v) {
            _vm.SelectValue3 = $$v
          },
          expression: "SelectValue3"
        }
      }),
      _c("h1", [_vm._v("Toolbar Grid Vuetify")]),
      _c("MyToolbarVuetify", {
        attrs: {
          config: _vm.toolbarVuetifyConfig,
          "grid-meta": _vm.gridMeta,
          "edit-click": _vm.btnEdit_Click
        },
        on: { "add-click": _vm.btnAdd_Click }
      }),
      _c("h1", [_vm._v("Toolbar Edit Page")]),
      _c("MyToolbarEditPage"),
      _c("h1", [_vm._v("Lite Form")]),
      _c("MyLiteFormDesigntime", {
        attrs: { config: _vm.liteFormConfig },
        model: {
          value: _vm.liteFormData,
          callback: function($$v) {
            _vm.liteFormData = $$v
          },
          expression: "liteFormData"
        }
      }),
      _c("MyLiteFormRuntime", {
        attrs: { config: _vm.liteFormConfig },
        model: {
          value: _vm.liteFormData,
          callback: function($$v) {
            _vm.liteFormData = $$v
          },
          expression: "liteFormData"
        }
      }),
      _c("h1", [_vm._v("My Left Right")]),
      _c("MyListboxLeftRight", {
        attrs: { "datasource-all": _vm.listboxValuesAll },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.Text) + " ")]
            }
          },
          {
            key: "right",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.Text) + " ")]
            }
          }
        ]),
        model: {
          value: _vm.listboxValues,
          callback: function($$v) {
            _vm.listboxValues = $$v
          },
          expression: "listboxValues"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }