var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "k-pop",
        {
          ref: "kpop",
          attrs: { boundary: "viewport", "with-arrow": "", theme: "clean" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var hide = ref.hide
                return [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.OpenDialog(_vm.field)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa fa-cog" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Remove(_vm.field)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveUp(_vm.field)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-up" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveDown(_vm.field)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-down" })]
                  ),
                  _c(
                    "small",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        right: "0"
                      },
                      on: { click: hide }
                    },
                    [_c("span", { staticClass: "fa fa-times-circle" })]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { slot: "trigger" },
              slot: "trigger"
            },
            [_c("i", { staticClass: "fa fa-ellipsis-v" })]
          )
        ]
      ),
      _c("FormFieldDesignerDialog", {
        ref: "formDeisngerDialog",
        on: {
          returnOk: _vm.formDeisngerDialogReturnOk,
          closeModel: _vm.formDeisngerDialogCloseModel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }