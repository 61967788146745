var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "onePageAdv" } }),
      _c("div", { staticClass: "bk_toolbar_fixed-top" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("回上一頁")]
        ),
        _vm._m(0)
      ]),
      _vm.ready
        ? _c("OnePageMain", {
            attrs: {
              "design-time": true,
              "one-page-id": _vm.SystemId,
              "layout-type": "1"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "btn btn-primary", attrs: { href: "#" } }, [
      _c("i", { staticClass: "fa fa-clone" }),
      _vm._v(" 複製")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }