var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { size: "s", "hide-footer": "", title: "" } },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("票據狀態")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-4", attrs: { for: "" } }, [
              _vm._v("票據狀態:")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-8" },
              [
                _c("MyCodeListButtonRadio", {
                  attrs: {
                    options: _vm.Ticket2Declare.Ticket_CLStatus2,
                    size: "md"
                  },
                  model: {
                    value: _vm.CLStatus,
                    callback: function($$v) {
                      _vm.CLStatus = $$v
                    },
                    expression: "CLStatus"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.toggleModal()
                }
              }
            },
            [_vm._v("儲存")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }