<template>

  <header class="c-header c-header-light c-header-fixed" v-if="ShowHeader">
    <div style="width: 50px">

    </div>
    <div class="c-header-nav">
      {{Title}} 目前登入者:{{headerBarInfo}}
    </div>
<!--    <ul class="c-header-nav mfs-auto">-->
<!--      <li class="c-header-nav-item px-3 c-d-legacy-none">-->

<!--        <button class="c-class-toggler c-header-nav-btn" type="button" id="header-tooltip" data-target="body" data-class="c-dark-theme" data-toggle="c-tooltip" data-placement="bottom" title="Toggle Light/Dark Mode">-->
<!--          <svg class="c-icon c-d-dark-none">-->
<!--            <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-moon"></use>-->
<!--          </svg>-->
<!--          <svg class="c-icon c-d-default-none">-->
<!--            <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-sun"></use>-->
<!--          </svg>-->
<!--        </button>-->
<!--      </li>-->
<!--    </ul>-->
<!--    <ul class="c-header-nav">-->
<!--      <li class="c-header-nav-item dropdown"><a class="c-header-nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">-->
<!--        <div class="c-avatar">-->
<!--          <img class="c-avatar-img" src="theme/coreUI3/extra/noun_user avatar_423426.png" alt="">-->
<!--        </div>-->
<!--      </a>-->
<!--        <div class="dropdown-menu dropdown-menu-right pt-0"><a class="dropdown-item" href="/Login">-->
<!--&lt;!&ndash;          <svg class="c-icon mfe-2">&ndash;&gt;-->
<!--&lt;!&ndash;            <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-bell"></use>&ndash;&gt;-->
<!--&lt;!&ndash;          </svg> 訊息<span class="badge badge-info mfs-auto">42</span></a><a class="dropdown-item" href="#">&ndash;&gt;-->
<!--          <svg class="c-icon mfe-2">-->
<!--            <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>-->
<!--          </svg> 登出</a></div>-->
<!--      </li>-->
<!--      顯示出 MenuRight-->
<!--      <button class="c-header-toggler c-class-toggler mfe-md-3" type="button" data-target="#aside" data-class="c-sidebar-show">-->
<!--        <svg class="c-icon c-icon-lg">-->
<!--          <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-applications-settings"></use>-->
<!--        </svg>-->
<!--      </button>-->
<!--    </ul>-->


<!--    Breadcrumb -->
<!--    <div class="c-subheader justify-content-between px-3">-->
<!--      &lt;!&ndash; Breadcrumb&ndash;&gt;-->
<!--      <ol class="breadcrumb border-0 m-0 px-0 px-md-3">-->
<!--        <li class="breadcrumb-item">Home</li>-->
<!--        <li class="breadcrumb-item"><a href="#">Admin</a></li>-->
<!--        <li class="breadcrumb-item active">Dashboard</li>-->
<!--        &lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
<!--      </ol>-->
<!--    </div>-->
  </header>

</template>

<script>
import session from "../../../common/session";
import * as ubossCommonConst from "@/pages/commonUBoss/ubossCommon";
import ubossCommon from "@/pages/commonUBoss/ubossCommon";
// import $ from "jquery"
// import "bootstrap"
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'COM_Header',
  components: {},
  data: function() {
    return {
      showDialogLogin: false,
      headerBarInfo:"",
      Title:ubossCommon.BizOrFormTitle(),
        ShowHeader:true,
    };
  },
  watch:{
    '$route'(to,from){// eslint-disable-line
      this.setShowHeader();
    },
  },
  methods: {
    test:function(){
    },
    showLogin: function () {
      this.showDialogLogin = true;
    },
    isLogined: function() {
      return session.isLogined();
    },
    login_info_accUid: function() {
      return session.login_info_accUid();
    },
    setShowHeader(){
      if (this.$router.currentRoute.name==="Index"){
        this.ShowHeader=true;
      }else{
        this.ShowHeader=false;
      }
    }
    // dlgLogin:function(){
    //   this.$bvModel.show('modal_user_login')
    // }
  },
  created() {
    this.headerBarInfo=session.getLoginId(ubossCommonConst.SessionKeyPrefix_Bk)
    // if (process.env.VUE_APP_runMode==="udm"){
    //     this.ShowHeader=false;
    // }else{
    //
    // }
    this.setShowHeader();

  }

  // components: {
  //   HelloWorld
  // }
}
</script>
