<template>
  <div  class="center">
    <div style="">
      stepName:{{stepName}}
    <div v-if="stepName==='Test'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>
          <a class="navbar-brand link">標誌</a>
        </template>
        <template v-slot:Breadcrumb>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="link">首頁</a></li>
            <li class="breadcrumb-item"><a class="link">分類</a></li>
            <li class="breadcrumb-item active" aria-current="page">目前頁面</li>
          </ol>
        </template>
        <template v-slot:HeaderBar>

        </template>
        <template v-slot:Body>
          <h1>歡迎訪問我們的手機網頁</h1>
          <p>這是一個基於Bootstrap 4的示例，包括頂部導覽、麵包屑、資訊部分和底部工具列。</p>
        </template>
        <template v-slot:BodyBar>

        </template>
        <template v-slot:BottomBar>
          <a class="link">首頁</a>
          <a class="link">關於</a>
          <a class="link">聯絡我們</a>
        </template>
      </MobileUILayout>

    </div>

    <div v-if="stepName==='SiteList'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>
          <a class="navbar-brand link">標誌</a>
        </template>
        <template v-slot:Breadcrumb>


          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a role="button" class="btn btn-primary" href="#"><i class="fa fa-home"></i></a>
            </li>
            <li class="breadcrumb-item"><a class="link" @click="goto('home')">{{title('H','首頁')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('onepageadv')">{{title('進階','官網進階')}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{title('網站列表','網站列表')}}</li>
          </ol>
        </template>
        <template v-slot:HeaderBar>

        </template>
        <template v-slot:Body>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(site,index) in sites" :key="index">
              <td ><i class="fa fa-bars"></i></td>
              <td class="text-left">

                <a class="link" @click="gotoStep('PageList')">{{site.name}}</a>
              </td>
              <td class="text-right">
                <button class="btn btn-primary" @click="showModal_dialogWebPage()"><i class="fa fa-ellipsis-v"></i></button>
              </td>
            </tr>
<!--            <tr>-->
<!--              <td ></td>-->
<!--              <td class="text-left">-->

<!--                <a class="link" @click="gotoStep('PageList')">www</a>-->
<!--              </td>-->
<!--              <td class="text-right">-->
<!--                <button class="btn btn-primary" @click="showModal_dialogWebPage()">...</button>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td ></td>-->
<!--              <td class="text-left">-->
<!--                <a class="link" @click="gotoStep('PageList')">vip</a>-->
<!--              </td>-->
<!--              <td class="text-right">-->
<!--                <button class="btn btn-primary" @click="showModal_dialogWebPage()">...</button>-->
<!--              </td>-->
<!--            </tr>-->

            </tbody>
          </table>
        </template>
        <template v-slot:BodyBar>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" @click="nextStep()" >新增一個網站<i class="fa fa-arrow-right"></i></button>
          </div>
        </template>
        <template v-slot:BottomBar>
          <a class="link">首頁</a>
          <a class="link">關於</a>
          <a class="link">聯絡我們</a>
        </template>
      </MobileUILayout>

    </div>
    <div v-if="stepName==='SiteEdit'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>

        </template>
        <template v-slot:Breadcrumb>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="link" @click="goto('home')">{{title('H','首頁')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('onepageadv')">{{title('進階','官網進階')}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{title('網站編輯','網站編輯')}}</li>
          </ol>
        </template>
        <template v-slot:HeaderBar>

        </template>
        <template v-slot:Body>
          <div class="text-center">
            請輸入網站名稱
          </div>

          <input type="text" class="form-control"  placeholder="" v-model="data_siteName">

        </template>
        <template v-slot:BodyBar>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" @click="previousStep()" ><i class="fa fa-arrow-left"></i>上一步</button>
            <button class="btn btn-primary btn-lg" @click="nextStep()" >下一步<i class="fa fa-arrow-right"></i></button>
          </div>
        </template>
        <template v-slot:BottomBar>

        </template>
      </MobileUILayout>

    </div>
    <div v-if="stepName==='SiteEditReadOnly'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>

        </template>
        <template v-slot:Breadcrumb>

        </template>
        <template v-slot:HeaderBar>
          <div class="text-left">
            網站名稱:{{data_siteName}}
          </div>
          <div class="text-right">
<!--            <button class="btn btn-primary" @click="previousStep()" >上一步</button>-->
          </div>


        </template>
        <template v-slot:Body>

        </template>
        <template v-slot:BodyBar>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" @click="previousStep()" ><i class="fa fa-arrow-left"></i>上一步</button>
            <button class="btn btn-primary btn-lg" @click="nextStep()" >新增網頁<i class="fa fa-arrow-right"></i></button>
          </div>
        </template>
        <template v-slot:BottomBar>

        </template>
      </MobileUILayout>
    </div>
    <div v-if="stepName==='PageList'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>

        </template>
        <template v-slot:Breadcrumb>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="link" @click="goto('home')">{{title('H','首頁')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('onepageadv')">{{title('進階','官網進階')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('sitelist')">{{title('網站(www)' ,'網站(www)')}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{title('網頁列表','網頁列表')}}</li>
          </ol>
        </template>
        <template v-slot:HeaderBar>
          <div class="text-left">
            網站名稱:{{data_siteName}}
          </div>
          <div class="text-right">

          </div>


        </template>
        <template v-slot:Body>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(page,index) in pages" :key="index">
              <td ><i class="fa fa-bars"></i></td>
              <td class="text-left">
                <a class="link" @click="gotoStep('PageDesigner')">{{ page.name }}</a>
              </td>
              <td class="text-right">
                <button class="btn btn-primary" @click="showModal_dialogWebPage()"><i class="fa fa-ellipsis-v"></i></button>
              </td>
            </tr>
<!--            <tr>-->
<!--              <td ></td>-->
<!--              <td class="text-left">-->
<!--                <a class="link" @click="gotoStep('PageDesigner')">產品一 (首頁)</a>-->
<!--              </td>-->
<!--              <td class="text-right">-->
<!--                <button class="btn btn-primary" @click="showModal_dialogWebPage()">...</button>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td ></td>-->
<!--              <td class="text-left">-->
<!--                <a class="link" @click="gotoStep('PageDesigner')">產品二</a>-->
<!--              </td>-->
<!--              <td class="text-right">-->
<!--                <button class="btn btn-primary" @click="showModal_dialogWebPage()">...</button>-->
<!--              </td>-->
<!--            </tr>-->

            </tbody>
          </table>
        </template>
        <template v-slot:BodyBar>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" @click="previousStep()" ><i class="fa fa-arrow-left"></i>上一步</button>
            <button class="btn btn-primary btn-lg" @click="nextStep()" >新增網頁<i class="fa fa-arrow-right"></i></button>
          </div>
        </template>
        <template v-slot:BottomBar>

        </template>
      </MobileUILayout>
    </div>


    <div v-if="stepName==='PageEdit1'">
      <MobileUILayout :show-logo="false" :show-bottom-bar="false">
        <template v-slot:Logo>

        </template>
        <template v-slot:Breadcrumb>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="link" @click="goto('home')">{{title('H','首頁')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('onepageadv')">{{title('進階','官網進階')}}</a></li>
            <li class="breadcrumb-item"><a class="link" @click="goto('sitelist')">{{title('網站(www)' ,'網站(www)')}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{title('網頁編輯','網頁編輯')}}</li>
          </ol>
        </template>
        <template v-slot:HeaderBar>
          <div class="text-left">
            網站名稱:{{data_siteName}}
          </div>
          <div class="text-right">

          </div>


        </template>
        <template v-slot:Body>

          <div class="text-center">
            請輸入網頁名稱
          </div>

          <input type="text" class="form-control"  placeholder="" v-model="data_pageName">
        </template>
        <template v-slot:BodyBar>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" @click="previousStep()" ><i class="fa fa-arrow-right"></i>上一步</button>
            <button class="btn btn-primary btn-lg" @click="nextStep()" >下一步<i class="fa fa-arrow-right"></i></button>
          </div>
        </template>
        <template v-slot:BottomBar>

        </template>
      </MobileUILayout>



    </div>
    <div v-if="stepName==='PageDesigner'">
      onepage design page


      <div class="text-center">
        <button class="btn btn-primary btn-lg" @click="previousStep()" ><i class="fa fa-arrow-left"></i>上一步</button>
        <button class="btn btn-primary btn-lg" @click="nextStep()" >下一步<i class="fa fa-arrow-right"></i></button>
      </div>
    </div>

    <div v-if="stepName===6">

    </div>



    <b-modal ref="dialogWebPage" size="s" hide-footer title="">
      <div class="d-block text-center">
        <h3>網頁設定</h3>
      </div>

      <table class="table table-hover">
        <tbody>
        <tr>
          <td >
            <button class="btn btn-primary" ><i class="fa fa-home"></i>設為首頁</button>
          </td>
        </tr>
        <tr>
          <td>
            <button class="btn btn-primary" ><i class="fa fa-eye"></i>是否顯示</button>
          </td>
        </tr>
        <tr>
          <td>
            <button class="btn btn-danger" ><i class="fa fa-trash"></i>刪除</button>
          </td>
        </tr>

        </tbody>
      </table>

<!--      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal_dialogWebPage">關閉</b-button>-->
    </b-modal>


  </div>
  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import MobileUILayout from "@/pages/commonComponent/Layout/MobileUILayout.vue";
// import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
// import MyButtonIsVisible from "@/pages/commonComponent/MyButtonIsVisible.vue";
// import MyButtonBoolean from "@/pages/commonComponent/MyButtonBoolean.vue";

let configEditPage="OnePageAdvEdit";

export default {
  components: {MobileUILayout},//MyButtonBoolean, MyButtonIsVisible, COM_HeaderNav
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      datas:null,
      currentCName:null,
      ready:false,


      data_siteName:"",
      data_pageName:"",

      sites:[],
      pages:[],


      stepName:"SiteList",
      stepDefine:[
        {id:1,name:"SiteList"},
        {id:2,name:"SiteEdit"},
        {id:3,name:"PageList"},
        {id:4,name:"PageEdit1"},
        {id:5,name:"PageEdit2"},
      ],
      isMobile:true,
    };
  },
  computed:{
    // ModeText(){
    //   return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    // }
  },
  methods: {
    addSite(){
      if (!this.data_siteName){
        return ;
      }
      this.sites.push({
        name:this.data_siteName
      })
      // this.data_siteName="";
    },
    addPage(){
      if (!this.data_pageName){
        return ;
      }

      this.pages.push({
        name:this.data_pageName
      })
      this.data_pageName="";
    },
    nextStep(){
      if (this.stepName==='SiteList'){
        this.stepName="SiteEdit";
      }else if (this.stepName==='SiteEdit'){
        this.addSite();
        this.stepName="PageList";
      }else if (this.stepName==='PageList'){
        this.stepName="PageEdit1";
      }else if (this.stepName==='PageEdit1'){
        this.addPage();
        this.stepName="PageDesigner";
      }else if (this.stepName==='PageDesigner'){
        this.stepName="PageList";
      }else{
        alert('case else:' + this.stepName)
      }
    },
    previousStep(){
      if (this.stepName==='SiteList'){
        this.stepName="SiteList";
      }else if (this.stepName==='SiteEdit'){
        this.stepName="SiteList";
      }else if (this.stepName==='PageList'){
        this.stepName="SiteEdit";
      }else if (this.stepName==='PageEdit1'){
        this.stepName="PageEdit1";
      }else if (this.stepName==='PageDesigner'){
        this.stepName="PageEdit1";
      }else{
        alert('case else:' + this.stepName)
      }
    },
    gotoStep(stepName){
      //get step id
      this.stepName=stepName;
    },
    goto(where){
      if (where==='home'){
        this.$router.push({ name: '/'})
      }else if (where==='onepageadv'){
        this.gotoStep('SiteList')
      }else if (where==='sitelist'){
        this.gotoStep('PageList')
      }else{
        alert('case else:' + where)
      }
    },
    title(mobile,desktop){
      if (this.isMobile){
        return mobile;
      }else{
        return desktop;
      }
    },
    showModal_dialogWebPage() {
      this.$refs['dialogWebPage'].show()
    },
    hideModal_dialogWebPage() {
      this.$refs['dialogWebPage'].hide()
    },
    async loadData() {
      //QQADV
      let data=await apiUBossOnePageBk.api_Get_OnePageAdv()
      //
      // this.datas=data
      if (data && data.CNames.length>0){
        this.datas=data;
      }else{
        this.datas={
          SystemId:util.uuid(),
          CNames:[],
        };

        this.datas.CNames.push({
          SystemId:util.uuid(),
          CName:"www",
          Memo:"",
          Pages:[],
        })
      }

      this.ready=true;
    },
    async submit(){
      for (let i = 0; i < this.datas.CNames.length; i++) {
        let item = this.datas.CNames[i];

        if (item.CName===""){
          util.showErrorMessage("第"+(i+1)+" 行，「CName名稱」為必填欄位")
          return;
        }

        //pages
        let hasHomePage=item.Pages.filter(s=>s.IsHomePage).length>0;
        let hasVisible=item.Pages.filter(s=>s.IsHomePage).length>0;

        if (!hasHomePage){
          util.showErrorMessage("CName名稱「" + item.CName +"」，「頁面」必須要有一個首頁")
          return;
        }

        if (!hasVisible){
          util.showErrorMessage("CName名稱「" + item.CName +"」，「頁面」必須要有一個顯示的頁面")
          return;
        }

      }

      let systemId=await apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas);
      this.datas.SystemId=systemId;
      util.ShowMessage('儲存成功');

    },
    PreviewCName(item){
      let routeData = this.$router.resolve({name: 'OnePagePreview'});
      //QQADV
      window.open("http://127.0.0.1:9007"+routeData.href + "?systemId=" +'' +"&layoutType="+ '1' +"&onePageAdvId="+ this.datas.SystemId +"&cnameSystemId="+ item.SystemId , '_blank');
    },
    DataItem_CName_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        CName:"",
        Memo:"",
        Pages:[],
      }

      arrayUtil.orderAppendLast(newItem,this.datas.CNames,"OrderKey")
      this.currentCName=null;
    },
    DataItem_CName_Edit(item){
      // this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
      this.currentCName=item;
    },
    // DataItem_CName_MoveUp(item){
    //   arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    // },
    // DataItem_CName_MoveDown(item){
    //   arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    // },
    DataItem_CName_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.CNames= this.datas.CNames.filter(s => s.SystemId !==item.SystemId);
      this.currentCName=null;
    },

    DataItem_Page_AddLast(){
      let hasHomePage=this.currentCName.Pages.filter(s=>s.IsHomePage).length>0;

      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsHomePage:!hasHomePage,
        IsVisible:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.currentCName.Pages,"OrderKey")
    },
    DataItem_Page_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_Page_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.currentCName.Pages,"SystemId","OrderKey")
    },
    DataItem_Page_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.currentCName.Pages,"SystemId","OrderKey")
    },
    DataItem_Page_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.currentCName.Pages= this.currentCName.Pages.filter(s => s.SystemId !==item.SystemId);
    },
    setHomePage(item){
      for (let i = 0; i < this.currentCName.Pages.length; i++) {
        let item2 = this.currentCName.Pages[i];
        item2.IsHomePage=false;
      }

      item.IsHomePage=true;
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.center{
  max-width:1024px;
  margin:0 auto;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
</style>
