<template>
  <div style="max-width: 1200px" >
<!--    <COM_HeaderNav mode="OnePageAdv2EditStep1"></COM_HeaderNav>-->

   <MyToolbarVuetify class="bk_toolbar_fixed-top" ref="myToolbar" v-on:button-click="toolbarButton_Click"> </MyToolbarVuetify>

    <div class="bk_firstBlock"/>

    <MyFormPanel ref="form" :data-source="formData">
<!--      <div slot="testSlot">  //QQ-->
<!--        <button class="btn btn-primary" >送出</button>-->
<!--      </div>-->
    </MyFormPanel>

<!--    //QQ-->
    <span v-if="$mq !== 'sm'">
      <nav class="navbar navbar-light bg-light" style="max-width: 1200px">
          <button class="btn btn-primary justify-content-center" @click="editOnePage()">編輯頁面</button>
      </nav>
    </span>
    <span v-if="$mq === 'sm'">
      <nav class="navbar fixed-bottom navbar-light bg-light">
        <button class="btn btn-primary ml-auto" @click="editOnePage()">編輯頁面</button>
      </nav>
    </span>


  </div>

</template>

<script>
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
// import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import {MyToolbar, MyToolbarGroup} from "@/pages/commonComponent/js/MyToolbarVuetify";
import MyFormPanel from "@/pages/commonComponent/MyFormPanel/MyFormPanel.vue";
import {
  MyFormPanelField,
  MyFormPanelFieldUITypeEnum, MyFormPanelForm,
  MyFormPanelPanel
} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";
import {MyToolbarButtonIdEnum} from "@/pages/commonComponent/js/MyToolbarVuetify";

export default {
  components: {MyFormPanel, MyToolbarVuetify},
  data: function () {
    return {
      systemId: "",
      formData:  {
        SystemId: "1",
        name: "page1",
        isHomePage:true,
        isVisible: true,
        memo: "",
        createDt:"2023-07-01",
        modifyDt:"2023-07-01",
      },
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossShopBk.api_Member_Get(this.systemId)

        this.formData=data
      }

    },
    initToolbar(){
      let toolbarGroup1=new MyToolbarGroup();
      toolbarGroup1
          .addButton_Home()
          .addButton_BackDefault()
          .addButton_Save()
      ;

      let toolbar=new MyToolbar();

      toolbar.addGroup(toolbarGroup1);

      this.$refs.myToolbar.setProp_Toolbar(toolbar);
    },
    initForm(){
      //Doc
      //form
      //  panel
      //    row
      //      field

      //---------------------------------------
      //Fields
      //---------------------------------------
      let fName=new MyFormPanelField("名稱","name");
      fName.type=MyFormPanelFieldUITypeEnum.input;
      fName.require=true;

      // fName.style="";//QQ
      // fName.slot="testSlot";
      // fName.fieldClassName="col-sm-3";

      let fisHomePage=new MyFormPanelField("設為首頁","isHomePage");
      fisHomePage.type=MyFormPanelFieldUITypeEnum.radio;
      fisHomePage.require=true;

      let fisVisible=new MyFormPanelField("是否顯示","isVisible");
      fisVisible.type=MyFormPanelFieldUITypeEnum.radio;
      fisVisible.require=true;

      let fMemo=new MyFormPanelField("備註","memo");
      fMemo.type=MyFormPanelFieldUITypeEnum.textarea;
      fMemo.require=true;

      //---------------------------------------
      //Panels
      //---------------------------------------
      let myFormPanel1=new MyFormPanelPanel("");
      myFormPanel1.addRow()
          .addFields(12,fName)
          .addFields(12,fisHomePage)
          .addFields(12,fisVisible)
      ;

      myFormPanel1.addRow()
          .addFields(12,fMemo)
      ;


      // let myFormPanel2=new MyFormPanelPanel("");
      // myFormPanel2.addRow()
      //
      // ;
      //---------------------------------------
      //Form
      //---------------------------------------
      let myForm=new MyFormPanelForm();

      myForm.addPanel(myFormPanel1);

      //---------------------------------------
      this.$refs.form.setPropFormDefine(myForm);

    },
    toolbarButton_Click(buttonId,selectIds){  // eslint-disable-line
      if (buttonId===MyToolbarButtonIdEnum.Save){
        console.log("");
      }

      // alert(buttonId);
      // alert(selectIds);
    },
    editOnePage(){
      let routeData = this.$router.resolve({name: 'OnePagePreview'});
      //QQADV
      let CNameSystemId="a2c62967-1d62-455f-b50a-b2067068478c";//QQ
      let PageSystemId="f835c1c3-d777-9b2b-7363-1d4dd79cbb2e";//QQ
      window.open("http://127.0.0.1:9007"+routeData.href + "?systemId=" +'' +"&layoutType="+ '1' +"&onePageAdvId="+ CNameSystemId +"&cnameSystemId="+ PageSystemId , '_blank');
    }
  },
  mounted() {
    this.loadData();
    this.initToolbar();
    this.initForm();
  },
}
</script>

<style scoped>

</style>
