var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        ref: "grid",
        style: { height: "520px" },
        attrs: {
          "data-items": _vm.datasCurrentPage,
          "selected-field": _vm.selectedField,
          columns: _vm.columnsSetWidthed,
          pageable: _vm.pageable,
          "page-size": _vm.pageSize,
          skip: _vm.skip,
          take: _vm.take,
          total: _vm.total,
          sortable: _vm.sortable,
          sort: _vm.sort,
          resizable: true
        },
        on: {
          rowclick: _vm.onRowClick,
          selectionchange: _vm.onSelectionChange,
          pagechange: _vm.pageChangeHandler,
          headerselectionchange: _vm.onHeaderSelectionChange,
          sortchange: _vm.sortChangeHandler
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }