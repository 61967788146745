var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isDesignTime
      ? _c(
          "span",
          [
            _c(
              "draggable-container",
              { staticClass: "dragContainer" },
              [
                _c(
                  "draggable-child",
                  {
                    key: _vm.dragItemDefault.Id,
                    class: _vm.getClass(_vm.dragItemDefault),
                    attrs: {
                      id: _vm.dragItemDefault.Id,
                      width: _vm.dragItemDefault.Width,
                      height: _vm.dragItemDefault.Height,
                      "default-position": _vm.dragItemDefault.Position
                    },
                    on: {
                      stop: _vm.handleStop,
                      start: _vm.handleStart,
                      drag: _vm.handleDrag,
                      resize: _vm.handleResize
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "dragItem",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "move",
                        boxSizing: "border-box",
                        background: _vm.dragItemDefault.Background,
                        opacity: _vm.getOpacity(_vm.dragItemDefault)
                      }
                    })
                  ]
                ),
                _vm._l(_vm.image.TextBlocks, function(item) {
                  return _c(
                    "draggable-child",
                    {
                      key: item.Id,
                      ref: "child",
                      refInFor: true,
                      class: _vm.getClass(item),
                      attrs: {
                        id: item.Id,
                        width: item.Width,
                        height: item.Height,
                        dataId: item.Id,
                        "default-position": item.Position
                      },
                      on: {
                        stop: _vm.handleStop,
                        start: _vm.handleStart,
                        drag: _vm.handleDrag,
                        resize: _vm.handleResize,
                        click: function($event) {
                          return _vm.handleClick(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dragItem",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "move",
                            boxSizing: "border-box",
                            background: item.Background,
                            opacity: _vm.getOpacity(item)
                          }
                        },
                        [
                          item.Id !== 999
                            ? _c(
                                "div",
                                {
                                  staticStyle: { width: "100%", height: "100%" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn-group blockToolbarFixRight",
                                      attrs: { role: "group", "aria-label": "" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-primary btn-sm ",
                                          on: {
                                            click: function($event) {
                                              return _vm.showDialog(item)
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-pen" })]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-danger btn-sm ",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeChild(item.Id)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.HtmlContent)
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _c("MyCkEditorDialog", {
              ref: "editorDialog",
              on: { returnOk: _vm.eventEditorDialogOK },
              model: {
                value: _vm.currentHtml,
                callback: function($$v) {
                  _vm.currentHtml = $$v
                },
                expression: "currentHtml"
              }
            })
          ],
          1
        )
      : _c(
          "span",
          _vm._l(_vm.image.TextBlocks, function(item) {
            return _c(
              "div",
              {
                key: item.Id,
                ref: "child",
                refInFor: true,
                staticClass: "designerContainer_Runtime",
                style: {
                  width: item.Width,
                  height: item.Height,
                  top: _vm.toPx(item.Position.x),
                  left: _vm.toPx(item.Position.y)
                },
                attrs: { id: item.Id }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dragItem",
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "move",
                      boxSizing: "border-box",
                      background: item.Background,
                      opacity: _vm.getOpacity(item)
                    }
                  },
                  [
                    _c("div", [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(item.HtmlContent) }
                      })
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }