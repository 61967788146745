var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "max-width": "1500px" }, attrs: { id: "app" } },
      [
        _c(
          "v-app",
          { attrs: { id: "inspire" } },
          [
            _c("v-data-table", {
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.config.headers,
                items: _vm.config.datas,
                "items-per-page": _vm.config.items_per_page,
                "footer-props": _vm.gridFooterProps,
                "item-key": _vm.config.itemKey,
                "show-select": "",
                "single-select": false,
                "mobile-breakpoint": "0"
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(Object.keys(_vm.config.datas[0]), function(slot) {
                    return {
                      key: "item." + slot,
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._t(
                            slot,
                            [_vm._v(" " + _vm._s(item[slot]) + " ")],
                            { item: item }
                          )
                        ]
                      }
                    }
                  })
                ],
                null,
                true
              ),
              model: {
                value: _vm.gridMeta.selectedRecord,
                callback: function($$v) {
                  _vm.$set(_vm.gridMeta, "selectedRecord", $$v)
                },
                expression: "gridMeta.selectedRecord"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }