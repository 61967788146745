<template>
  <div>
    <div class="card">
      <div class="card-header">{{title}}</div>
      <div class="card-body">

<!--        <div class="form-group row">-->
<!--          <label for="" class="col-sm-2">支付平台</label>-->
<!--          <div class="a_thinInputDiv col-sm-10">-->
<!--            <div class="">-->
<!--              <div class="form-group form-check form-check-inline">-->
<!--                &lt;!&ndash;                      //QQ&ndash;&gt;-->
<!--                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>-->
<!--                <label class="form-check-label" for="inlineRadio1">藍新</label>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->
<!--        </div>-->


        <div class="form-group row">
          <label for="" class="col-sm-2">HashKey</label>
          <div class="a_thinInputDiv col-sm-10">
            <validation-provider rules="required" v-slot="{ errors }"  name="HashKey" >
              <input type="text" class="form-control " placeholder="" v-model="formData.Hashkey" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
            <a href="#"><i class="fa fa-question"></i> 如何取得? </a>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">HashIV</label>
          <div class="a_thinInputDiv col-sm-10">
            <validation-provider rules="required" v-slot="{ errors }"  name="HashKey" >
              <input type="text" class="form-control " placeholder="" v-model="formData.HashIV" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">商店代碼</label>
          <div class="a_thinInputDiv col-sm-10">
            <validation-provider rules="required" v-slot="{ errors }"  name="商店代碼" >
              <input type="text" class="form-control " placeholder="" v-model="formData.MerchantID" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">信用卡一次付清</label>
          <div class="a_thinInputDiv col-sm-10">
            <div class="checkbox">
              <validation-provider rules="" v-slot="{ errors }"  name="信用卡一次付清" >
                <label><input type="checkbox" v-model="formData.Credit">啟用</label>
                <span class="error_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
        </div>
        <!--              //QQ-->
        <!--              <div class="form-group row">-->
        <!--                <label for="" class="col-sm-2">信用卡分期</label>-->
        <!--                <div class="a_thinInputDiv col-sm-10">-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">3期</label>-->
        <!--                  </div>-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">6期</label>-->
        <!--                  </div>-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">12期</label>-->
        <!--                  </div>-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">18期</label>-->
        <!--                  </div>-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">24期</label>-->
        <!--                  </div>-->
        <!--                  <div class="checkbox">-->
        <!--                    <label><input type="checkbox" value="">30期</label>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <a href="#"><i class="fa fa-question"></i> 注意事項? </a>-->
        <!--              </div>-->
        <div class="form-group row">
          <label for="" class="col-sm-2">WebATM</label>
          <div class="a_thinInputDiv col-sm-10">
            <div class="checkbox">
              <validation-provider rules="" v-slot="{ errors }"  name="WebATM" >
                <label><input type="checkbox" v-model="formData.WebAtm">啟用</label>
                <span class="error_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="col-sm-2">ATM轉帳</label>
          <div class="a_thinInputDiv col-sm-10">
            <div class="checkbox">
              <validation-provider rules="" v-slot="{ errors }"  name="ATM轉帳" >
                <label><input type="checkbox" v-model="formData.Atm">啟用</label>
                <span class="error_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/**

 **/


export default {
  //name: "ShoppingGeneral",
  props: ["title"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    btnUpdate(){

    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
