var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: this.$onePageUtil.DebugOnePage
        ? "border-color: red;border-style: solid"
        : ""
    },
    [
      this.$onePageUtil.DebugOnePage
        ? _c("div", [
            _vm._v(" Id:" + _vm._s(_vm.webPart.Id)),
            _c("br"),
            _vm._v(" Title:" + _vm._s(_vm.webPart.Title)),
            _c("br"),
            _vm._v(" WebPartType:" + _vm._s(_vm.webPart.WebPartType)),
            _c("br")
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 labelClass",
            staticStyle: { color: "#2c9faf" }
          },
          [_vm._v("標題")]
        ),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.webPart.Title,
                expression: "webPart.Title"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: "name", placeholder: "" },
            domProps: { value: _vm.webPart.Title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.webPart, "Title", $event.target.value)
              }
            }
          })
        ])
      ]),
      this.webPart.WebPartType === this.$onePageUtil.WebPartType_Html
        ? _c(
            "div",
            [_c("WebPartHtmlDesigner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Image
        ? _c(
            "div",
            [
              _c("WebPartImageDesigner", { attrs: { "web-part": _vm.webPart } })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_SliderImage
        ? _c(
            "div",
            [
              _c("WebPartSlickerImageDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_SliderImage2
        ? _c(
            "div",
            [
              _c("WebPartSlickerImage2Designer", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_SliderImage3
        ? _c(
            "div",
            [
              _c("WebPartSlickerImage3Designer", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Video
        ? _c(
            "div",
            [
              _c("WebPartVideo", {
                attrs: {
                  "design-time": _vm.designTime,
                  "web-part": _vm.webPart
                }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormLink
        ? _c(
            "div",
            [
              _c("WebPartFormLinkDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Form
        ? _c(
            "div",
            [_c("WebPartFormDesigner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormSubmit
        ? _c(
            "div",
            [
              _c("WebPartFormSubmitDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormShop
        ? _c(
            "div",
            [
              _c("WebPartFormShopDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_ListData
        ? _c(
            "div",
            [
              _c("WebPartListDataDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_FormShopWithProduct
        ? _c(
            "div",
            [
              _c("WebPartFormShopWithProductDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_FormShopWithProductCalendar
        ? _c(
            "div",
            [
              _c("WebPartFormShopWithProductCalendarDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_HR
        ? _c(
            "div",
            [_c("WebPartHRDesigner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_PricePlan
        ? _c(
            "div",
            [
              _c("WebPartPricePlanDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_HotProducts
        ? _c(
            "div",
            [
              _c("WebPartHotProductsDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_CallToAction
        ? _c(
            "div",
            [
              _c("WebPartCallToActionDesigner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Card
        ? _c(
            "div",
            [_c("WebPartCardDesigner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : _c("div")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }