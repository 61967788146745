<template>
  <div class="">

    <div class="bk_toolbar btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <slot>

      </slot>
    </div>

  </div>
</template>

<script>


export default {
  computed: {

  },
  methods: {

  },
  mounted() {
    // this.initValue()
    //this.loadData()
    // util.ObjectOverride(this.configDefault,this.config);
  },
}
</script>

<style scoped>
button{
 /*margin-left: 10px;*/
    margin-top: 10px;
    /*padding-top: 10px;*/
    color:white;
}
</style>
