var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ubossHtmlContent" },
    [
      _c("div", { staticClass: "text-center" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { role: "button", href: "#" },
            on: {
              click: function($event) {
                return _vm.showDialog()
              }
            }
          },
          [_vm._v(_vm._s(_vm.propsWebPart.Title))]
        )
      ]),
      _c("DLGContactMe", {
        ref: "dlgEditor",
        on: { returnOk: _vm.dlgEditorReturnOK }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }