

<template>
  <div>
    <div v-if="ModelDefined.SearchInputType==='text'">
      <div class="form-group row">
        <label for="" class="col-sm-12 "><span v-html="getTitle(ModelDefined)"/></label>
        <div class="col-sm-12 ">
            <input type="text" class="form-control" placeholder="" v-model="ModelField" :class="getCssClass(ModelDefined)" >
        </div>
      </div>
    </div>
    <div v-else-if="ModelDefined.SearchInputType==='select'">
      <div class="form-group row">
        <label for="" class="col-sm-12 ">{{getTitle(ModelDefined)}}</label>
        <div class="col-sm-12 ">
            <select class="form-control" v-model="ModelField" :class="getCssClass(ModelDefined)">
              <option v-for="(option,index) in ModelDefined.Values" v-bind:key="index"  v-bind:value="option.Value">{{option.Text}}</option>
            </select>
        </div>
      </div>
    </div>
    <div v-else-if="ModelDefined.SearchInputType==='selectWithInput'">  <!-- same select -->
      <div class="form-group row">
        <label for="" class="col-sm-12 ">{{getTitle(ModelDefined)}}</label>
        <div class="col-sm-12 ">
            <vue-select v-model="ModelField"  taggable :options="convertValuesToVueSelectOptions(ModelDefined.Values)" :class="getCssClass(ModelDefined)"
            />
        </div>
      </div>
    </div>
    <div v-else-if="ModelDefined.SearchInputType==='dateRange'">
      <div class="form-group row">
        <label for="" class="col-sm-12 "><span v-html="getTitle(ModelDefined)"/></label>
        <div class="col-sm-12 ">
            <input type="date" class="form-control myInput_Date" placeholder="" v-model="ModelField_S" :class="getCssClass(ModelDefined)" style="width: 160px;display: inline">~
            <input type="date" class="form-control myInput_Date" placeholder="" v-model="ModelField_E" :class="getCssClass(ModelDefined)" style="width: 160px;display: inline">
        </div>
      </div>
    </div>
    <div v-else-if="ModelDefined.SearchInputType==='numberRange'">
      <div class="form-group row">
        <label for="" class="col-sm-12 "><span v-html="getTitle(ModelDefined)"/></label>
        <div class="col-sm-12 ">
            <vue-numeric class="form-control" v-bind:precision="0" v-model="ModelField_S" :class="getCssClass(ModelDefined)" style="width: 100px;display: inline"></vue-numeric>~
            <vue-numeric class="form-control" v-bind:precision="0" v-model="ModelField_E" :class="getCssClass(ModelDefined)" style="width: 100px;display: inline"></vue-numeric>
        </div>
      </div>
    </div>
    <div v-else-if="ModelDefined.SearchInputType==='label'">
      <div class="form-group row">
        <label for="" class="col-sm-12 "><span v-html="getTitle(ModelDefined)"/></label>
        <div class="col-sm-12 ">
          <label for="" >{{ModelField}} </label>
        </div>
      </div>
    </div>
    <div v-else>
      other {{ModelDefined.SearchInputType}}
      <div class="form-group row">
        <label for="" class="col-sm-12 "><span v-html="getTitle(ModelDefined)"/></label>
        <div class="col-sm-12 ">
            <input type="text" class="form-control" placeholder="" v-model="ModelField" :class="getCssClass(ModelDefined)">
        </div>
      </div>
    </div>


  </div>

</template>

<script>

import 'vue-select/dist/vue-select.css';
// import apiGPK from "../../../common/apiGPK";
/**


 */
export default {
  name: "SearchFormField",
  data: function() {
    return {
      formData:{
        keyword:"",
      },
      ModelDefinedDemo:{
        Group:"",
        Title:"",
        SearchInputType:"",
        Values:[],
        Visible:"",
      },
      selectOtherText_ModelFieldPart1:"",
      selectOtherText_ModelFieldPart2:"",
      selectOtherText_showText:false,
      Manuf_Factory:[],
    };
  },
  props: ['ModelField','ModelField_S','ModelField_E','ModelDefined','role'],
  methods: {
    convertValuesToVueSelectOptions(values){
      let newArray = values.map(obj => ({
        label: obj.Text,
        code: obj.Value
      }));

      return newArray;
    },
    getTitle:function (ModelDefined){
      return ModelDefined.Title;
      // return mForm.getFormatedTitle(ModelDefined.Title,ModelDefined.Formater)
      // return ModelDefined.Title + "Seq:"+ ModelDefined.Seq +" SearchInputType:" + ModelDefined.SearchInputType
    },
    splitOptions(values){
      return values.split('\n');
    },
    splitOptionsFactory(values){
      let defaultOptions= values.split('\n');
      // return defaultOptions
      let returnOptions=defaultOptions

      this.Manuf_Factory.forEach(
          s=>{
            if (!returnOptions.includes(s)){
              returnOptions.push(s)
            }
          }
      );

      return returnOptions

    },
    getCssClass(field){  // eslint-disable-line
      return ""
      // if (field.MustInput){
      //   return "requireField";
      // }
    },
    // onSearch(search, loading) {
    //   if(search.length) {
    //     loading(true);
    //     this.search(loading, search, this);
    //   }
    // },
    // search(loading, search,vm){
    //   vm.options=["a1","a22",search]
    //   loading(false);
    // },
    // search: _.debounce((loading, search, vm) => {
    //   fetch(
    //       `https://api.github.com/search/repositories?q=${escape(search)}`
    //   ).then(res => {
    //     res.json().then(json => (vm.options = json.items));
    //     loading(false);
    //   });
    // }, 350)
  },
  watch:{
    'ModelField'(newValue){
      // this.$emit("update:ModelField",newValue)

      if (this.ModelDefined.SearchInputType==='selectOtherText'){
        let options = this.ModelDefined.Values.map(obj => obj.Value);
        let index = options.indexOf(this.ModelField);

        if (index > -1){
          this.selectOtherText_ModelFieldPart1=this.ModelField
          this.selectOtherText_ModelFieldPart2=""
          this.selectOtherText_showText=false
        }else{
          this.selectOtherText_ModelFieldPart1="其他"
          this.selectOtherText_ModelFieldPart2=this.ModelField
          this.selectOtherText_showText=true
        }
      }

      console.log(this.ModelDefined.Name  +":" +newValue);
      this.$emit("update:model-field", newValue)  //通知(同步)上一層物件 model-field 值改變了
    },
    'ModelField_S'(newValue){
      console.log(this.ModelDefined.Name  +":" +newValue);
      this.$emit("update:model-field_-s", newValue)
    },
    'ModelField_E'(newValue){
      console.log(this.ModelDefined.Name  +":" +newValue);
      this.$emit("update:model-field_-e", newValue)
    },
    'selectOtherText_ModelFieldPart1'(newValue){
      if (newValue==="其他"){
        this.selectOtherText_showText=true
      }else{
        this.selectOtherText_showText=false
        this.ModelField=newValue
      }
    },
    'selectOtherText_ModelFieldPart2'(newValue){
      this.ModelField=newValue
    }
  },
  mounted() {

    // if (this.ModelDefined.SearchInputType==='selectInputFactory'){
    //   apiGPK.api_Query_Purchase_Purchase_Manuf_Factory().then((data)=>{
    //     this.Manuf_Factory=data
    //   })
    // }



    // console.log("data:"+this.ModelDefined.InputValue);
    // console.log("ModelField"+this.ModelField);
  },
}
</script>

<style scoped>
.requireField{
  background-color: #f8ccc8;
}
/* SearchPanelForm/SearchFormField 均有此class*/
.col-sm-12{
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

</style>
