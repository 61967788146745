var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "noMarginForForm" },
    [
      this.webPart.DescText
        ? _c("div", { staticClass: "row" }, [
            _c(
              "h3",
              { staticClass: "col-sm-3 formFieldLabel", attrs: { for: "" } },
              [_vm._v(_vm._s(this.webPart.DescText))]
            )
          ])
        : _vm._e(),
      this.webPart.Desc
        ? _c("div", { staticClass: "row" }, [
            _c("span", { staticClass: "col-sm-1" }),
            _c("span", {
              staticClass: "col-sm-11",
              domProps: { innerHTML: _vm._s(this.webPart.Desc) }
            })
          ])
        : _vm._e(),
      _c(
        "ValidationObserver",
        { ref: "form" },
        _vm._l(this.webPart.Fields, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("FormFieldRunner", {
                attrs: { field: item, "field-value": _vm.formData[item.Id] },
                on: {
                  "update:fieldValue": function($event) {
                    return _vm.$set(_vm.formData, item.Id, $event)
                  },
                  "update:field-value": function($event) {
                    return _vm.$set(_vm.formData, item.Id, $event)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }