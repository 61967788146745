<template>
  <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="TicketIndex"></COM_HeaderNav>

<!--    <div class="form-group row">-->
<!--      <div class="col-sm-10">-->
<!--        <a class="btn btn-primary mybutton" href="#" @click.prevent="submit" style="width: 100px">儲存</a>-->
<!--        <a class="btn btn-primary" href="#"><i class="fa fa-clone"></i> 複製</a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>-->

    <a role="button" class="btn btn-primary" @click="btnAdd_Click()">add</a>
    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta"  v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"> </MyToolbarVuetify>

<!--      //QQ width 1000-->
    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig" :grid-meta="gridMeta" style="width:1000px"  >
      <template v-slot:Fun="{}">
<!--        <a class="btn btn-primary" ><i class="fa fa-align-justify handle"></i>拖拉順序</a>-->
      </template>
      <template v-slot:IsEnable="{ item }">
        <MyLabelIsEnable v-model="item.IsEnable"></MyLabelIsEnable>
      </template>
    </MyGridVuetify>

  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyLabelIsEnable from "@/pages/commonComponent/MyLabelIsEnable.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";

let configEditPage="TicketEdit";

export default {
  components: {MyToolbarVuetify, MyLabelIsEnable, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:10,
        itemKey:"SystemId",
        headers: [
          {
            text: '功能',
            align: 'start',
            sortable: false,
            value: 'Fun',
          },
            { text: '類型', value: 'Type' },
            { text: '專案名稱', value: 'Name' },
            { text: '是否啟用', value: 'IsEnable' },
            { text: '生效日期', value: 'StartDt' },
            { text: '失效日期', value: 'ExpireDt' },
            { text: '備註', value: 'Memo' },
            { text: '建立日期', value: 'CreateDt' },
            { text: '修改日期', value: 'ModifyDt' },
        ],
        datas: [
          {
              Fun:"",
              SystemId:"1",
              Type:"推薦制",
              Name:"老客推薦新客",
              MemberData:"",//**
              IsEnable:true,
              StartDt:"",
              ExpireDt:"",
              Memo:"",
              CreateDt:"2020-01-01",
              ModifyDt:"2020-01-01",
          },
          {
              Fun:"",
              SystemId:"2",
              Type:"發行制",
              Name:"2023五月促銷",
              MemberData:"",//**
              IsEnable:true,
              StartDt:"2023-05-01",
              ExpireDt:"2023-06-01",
              Memo:"",
              CreateDt:"2020-01-01",
              ModifyDt:"2020-01-01",
          },
        ],
      },
      datas:{
        Details:[
          {
            Name:"產品1",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Name:"產品2",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Name:"產品3",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
        ]

      },
      toolbarVuetifyConfig:{
        Add:{
          Enable:true,
          CallBack:"",
        },
        Edit:{
          Enable:true,
          CallBack:"",
        },
        Delete:{
          Enable:true,
          CallBack:"",
        },
        Copy:{
          Enable:true,
          CallBack:"",
        },
        Sort:{
          Enable:false,
        },
      },
      gridMeta:{},
      ready:false,
    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()

      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },

    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },
    btnAdd_Click(){
      this.$router.push({ name: configEditPage, query: { SystemId: "" }})
    },
    btnEdit_Click(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    btnDelete_Click(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>
