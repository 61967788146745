var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("播放方式")]),
    _c("div", { staticClass: "form-check" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.Marquee.MediaPlayType,
            expression: "Marquee.MediaPlayType",
            modifiers: { number: true }
          }
        ],
        staticClass: "form-check-input",
        attrs: {
          name: "rg1" + this._uid,
          type: "radio",
          id: "r1" + this._uid,
          value: "1"
        },
        domProps: { checked: _vm._q(_vm.Marquee.MediaPlayType, _vm._n("1")) },
        on: {
          change: function($event) {
            _vm.$set(_vm.Marquee, "MediaPlayType", _vm._n("1"))
          }
        }
      }),
      _c(
        "label",
        { staticClass: "form-check-label", attrs: { for: "r1" + this._uid } },
        [_vm._v(" 依順序播放 ")]
      )
    ]),
    _c("div", { staticClass: "form-check" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.Marquee.MediaPlayType,
            expression: "Marquee.MediaPlayType",
            modifiers: { number: true }
          }
        ],
        staticClass: "form-check-input",
        attrs: {
          name: "rg1" + this._uid,
          type: "radio",
          id: "r2" + this._uid,
          value: "2"
        },
        domProps: { checked: _vm._q(_vm.Marquee.MediaPlayType, _vm._n("2")) },
        on: {
          change: function($event) {
            _vm.$set(_vm.Marquee, "MediaPlayType", _vm._n("2"))
          }
        }
      }),
      _c(
        "label",
        { staticClass: "form-check-label", attrs: { for: "r2" + this._uid } },
        [_vm._v(" 隨機播放 ")]
      )
    ]),
    _c("h1", [_vm._v("轉速")]),
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.Marquee.PlaySpeed,
            expression: "Marquee.PlaySpeed",
            modifiers: { number: true }
          }
        ],
        staticClass: "form-control short",
        attrs: { type: "number", id: "name", name: "name", placeholder: "" },
        domProps: { value: _vm.Marquee.PlaySpeed },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.Marquee, "PlaySpeed", _vm._n($event.target.value))
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _vm._v(" (預設 100) ")
    ]),
    _c(
      "a",
      { staticClass: "btn btn-primary", on: { click: _vm.DataItem_AddLast } },
      [_c("i", { staticClass: "fas fa-plus-circle" })]
    ),
    _c(
      "table",
      { staticClass: "table table-hover", attrs: { id: "tblMain2" } },
      [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(this.Marquee.Items, function(item, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_Remove(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "far fa-trash-alt" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_MoveUp(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-up" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_MoveDown(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-down" })]
                )
              ]),
              _vm._m(1, true),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.MarqueeText,
                      expression: "item.MarqueeText"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: item.MarqueeText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "MarqueeText", $event.target.value)
                    }
                  }
                })
              ]),
              _c(
                "td",
                [
                  _vm._v(" 字型格式 "),
                  _c("MyCSSPicker", {
                    attrs: { setting: _vm.cssPickerSetting },
                    model: {
                      value: item.MarqueeStyleCSS,
                      callback: function($$v) {
                        _vm.$set(item, "MarqueeStyleCSS", $$v)
                      },
                      expression: "item.MarqueeStyleCSS"
                    }
                  })
                ],
                1
              ),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.Memo,
                      expression: "item.Memo"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: item.Memo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "Memo", $event.target.value)
                    }
                  }
                })
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("啟/停用")]),
        _c("th", [_vm._v("播放內容")]),
        _c("th", [_vm._v("播放設定")]),
        _c("th", [_vm._v("備註")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { width: "120px" } }, [
      _c("button", { staticClass: "btn btn-primary" }, [_vm._v("啟用")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }