<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="2"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate" v-if="!isPublished"><i class="fa fa-save" ></i>發行</button>
        <button type="button" class="btn btn-primary" @click="btnDownloadQR" v-if="isPublished"><i class="fa fa-save" ></i>下載QRCode</button>
        <button type="button" class="btn btn-primary" @click="btnDownloadTicket" v-if="isPublished"><i class="fa fa-save" ></i>列印票券</button>
        <button type="button" class="btn btn-primary" @click="btnListTicket" v-if="isPublished"><i class="fa fa-save" ></i>票券清單</button>
        <button type="button" class="btn btn-primary" @click="btnModifyTicket" v-if="isPublished"><i class="fa fa-save" ></i>整批修改</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">票種</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="票種" >
            <select class="form-control" v-model="formData.RelSP_TicketType" style="width:200px" :disabled="saveThenLock">
              <option v-for="(item, index) in ticketTypes" v-bind:key="index" :value="item.SystemId">{{ item.Name }}</option>
            </select>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">有效日期</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="有效日期" >
            <input type="date" class="form-control " placeholder="" v-model="formData.EffectDt" :disabled="saveThenLock" style="width:200px">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">發行張數</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="發行張數" >
            <input type="number" class="form-control " placeholder="" v-model="formData.QuantityTotal" :disabled="saveThenLock" style="width:200px">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">備註</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="備註" >
            <textarea class="form-control" rows="3" v-model="formData.Memo" :disabled="saveThenLock"></textarea>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">配送方式</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="配送方式" >-->
<!--            <MyCodeListButtonRadio v-model="formData.CLShipType" :options="$u.UCode_CLShipType" size="md"></MyCodeListButtonRadio>-->
<!--&lt;!&ndash;            <select class="form-control" v-model="formData.CLShipType">&ndash;&gt;-->
<!--&lt;!&ndash;              <option></option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="self">自取</option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="ship">宅配</option>&ndash;&gt;-->
<!--&lt;!&ndash;            </select>&ndash;&gt;-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->


    </ValidationObserver>

    <DLGPrint ref="dlgEditor" v-on:returnOk="dlgEditorReturnOK" />
    <DLGTicketStatus ref="dlgTicketStatus" v-on:returnOk="dlgTicketStatusReturnOK" />
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import DLGPrint from "@/pages/ubossBk/views/ticket2/ticketPublish/DLGPrint.vue";
import DLGTicketStatus from "@/pages/ubossBk/views/ticket2/ticket/DLGTicketStatus.vue";
// import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";

export default {
  components: {DLGTicketStatus, DLGPrint, COM_HeaderNav},
  watch: {
    '$route': 'loadData'
  },
  data: function () {
    return {
      systemId: "",
      formData: {

      },
      formDataDemo: {

        "OrderId":"",
        "RelMember":"",
        "CLSourceType":"",
        "CLInvoiceType":"",
        "CLPayType":"",
        "PayTypeMemo":"",
        "PayTypeLog":"",
        "CLPayStatus":"",
        "CLOrderStatus":"",
        "CLShipType":"",
        "CLShipStatus":"",
        "ShipDt":"",
        "TotalOrgAmount":"",
        "TotalAmount":"",
      },
      saveThenLock:false,
      isPublished:false,
      ticketTypes:[],
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossBk.api_TicketPublish_Get(this.systemId)
        this.formData=data
        this.saveThenLock=true;
        this.isPublished=true;
      }

      this.ticketTypes=await apiUBossBk.api_TicketType_ListQuery(null);
    },
    btnDownloadQR(){
      apiUBossBk.api_TicketPublish_DownloadTicketQRCode(this.formData.SystemId).then(()=>{
        util.ShowMessage("QRCode產生 成功","訊息")
      })
    },
    btnDownloadTicket(){
      this.$refs.dlgEditor.showModal();
    },
    btnListTicket(){
      this.gotoWithQuery('Ticket2TicketIndex',{  ticketPublishSystemId: this.formData.SystemId })
    },
    btnModifyTicket(){
      this.$refs.dlgTicketStatus.showModal();
    },
    btnUpdate(){
      // let router=this.$router
      let that=this

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (this.formData.ShipDt===""){
            this.formData.ShipDt=null;
          }
          //---------------------------------------
          apiUBossBk.api_TicketPublish_SaveTicketPublish(this.formData).then((data)=>{
            util.ShowMessage("發行成功","訊息")
            that.gotoWithSystemId('Ticket2TicketPublishEdit',data.SystemId)
          })
          //---------------------------------------
        }
      });
    },
    dlgEditorReturnOK(ticketDesignSystemId){
      apiUBossBk.api_TicketPublish_DownloadTicketPDF(this.systemId,ticketDesignSystemId).then(()=>{
        util.ShowMessage("票券產生 成功","訊息")
      })
    },
    dlgTicketStatusReturnOK(clStatus){// eslint-disable-line
      debugger // eslint-disable-line
      apiUBossBk.api_Ticket_UpdateStatusByPublishId(this.formData.SystemId,clStatus).then(()=>{
        util.ShowMessage("修改成功","訊息")
      })
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
