var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
        _vm._v(" 日曆名稱:"),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.calendar.Name,
              expression: "calendar.Name"
            }
          ],
          staticClass: "form-control myInputWidth",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.calendar.Name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.calendar, "Name", $event.target.value)
            }
          }
        })
      ]),
      _c(
        "div",
        { staticClass: "shadow p-3 mb-5 bg-white rounded" },
        [
          _vm._v(" 預約說明 "),
          _c("MyCkEditor", {
            ref: "myCkEditor",
            attrs: {
              height: "200",
              width: "100%",
              "file-upload-list-lite_is-show": false,
              template_feature_isEnable: false,
              "upload-image_feature_is-enable": false
            },
            model: {
              value: _vm.calendar.Memo,
              callback: function($$v) {
                _vm.$set(_vm.calendar, "Memo", $$v)
              },
              expression: "calendar.Memo"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
        _vm._v(" 預約時段單位 "),
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.calendar.Timeperiod,
                expression: "calendar.Timeperiod"
              }
            ],
            attrs: { type: "radio", value: "day", name: "" },
            domProps: { checked: _vm._q(_vm.calendar.Timeperiod, "day") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.calendar, "Timeperiod", "day")
              }
            }
          }),
          _vm._v("日")
        ]),
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.calendar.Timeperiod,
                expression: "calendar.Timeperiod"
              }
            ],
            attrs: { type: "radio", value: "halfday", name: "" },
            domProps: { checked: _vm._q(_vm.calendar.Timeperiod, "halfday") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.calendar, "Timeperiod", "halfday")
              }
            }
          }),
          _vm._v("上午/下午")
        ]),
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.calendar.Timeperiod,
                expression: "calendar.Timeperiod"
              }
            ],
            attrs: { type: "radio", value: "hour", name: "" },
            domProps: { checked: _vm._q(_vm.calendar.Timeperiod, "hour") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.calendar, "Timeperiod", "hour")
              }
            }
          }),
          _vm._v("小時")
        ]),
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.calendar.Timeperiod,
                expression: "calendar.Timeperiod"
              }
            ],
            attrs: { type: "radio", value: "halfhour", name: "" },
            domProps: { checked: _vm._q(_vm.calendar.Timeperiod, "halfhour") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.calendar, "Timeperiod", "halfhour")
              }
            }
          }),
          _vm._v("半小時")
        ]),
        _c("br"),
        _c(
          "div",
          [
            _vm._v(" 上班時間 "),
            _c("b-form-timepicker", {
              staticClass: "myInputWidth",
              attrs: { locale: "en" },
              model: {
                value: _vm.calendar.StartWorkTime,
                callback: function($$v) {
                  _vm.$set(_vm.calendar, "StartWorkTime", $$v)
                },
                expression: "calendar.StartWorkTime"
              }
            }),
            _vm._v(" 午休時間 "),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between",
                staticStyle: { "max-width": "400px" }
              },
              [
                _c("b-form-timepicker", {
                  staticClass: "myInputWidth",
                  attrs: { locale: "en" },
                  model: {
                    value: _vm.calendar.RestStartTime,
                    callback: function($$v) {
                      _vm.$set(_vm.calendar, "RestStartTime", $$v)
                    },
                    expression: "calendar.RestStartTime"
                  }
                }),
                _vm._v(" ~ "),
                _c("b-form-timepicker", {
                  staticClass: "myInputWidth",
                  attrs: { locale: "en" },
                  model: {
                    value: _vm.calendar.RestEndTime,
                    callback: function($$v) {
                      _vm.$set(_vm.calendar, "RestEndTime", $$v)
                    },
                    expression: "calendar.RestEndTime"
                  }
                })
              ],
              1
            ),
            _vm._v(" 下班時間 "),
            _c("b-form-timepicker", {
              staticClass: "myInputWidth",
              attrs: { locale: "en" },
              model: {
                value: _vm.calendar.EndWorkTime,
                callback: function($$v) {
                  _vm.$set(_vm.calendar, "EndWorkTime", $$v)
                },
                expression: "calendar.EndWorkTime"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
        _vm._v(" 快速選取 "),
        _c(
          "div",
          [
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_All_Check()
                  }
                }
              },
              [_vm._v("全部可以")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_All_UnCheck()
                  }
                }
              },
              [_vm._v("全部不可以")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_Spec([1, 2, 3, 4, 5], [6, 0])
                  }
                }
              },
              [_vm._v("周休二日")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_Spec([1, 2, 3, 4, 5, 6], [0])
                  }
                }
              },
              [_vm._v("周日休")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_Spec([1, 2, 3, 4, 5, 0], [6])
                  }
                }
              },
              [_vm._v("周六休")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { role: "button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectAction_Spec([2, 3, 4, 5, 6, 0], [1])
                  }
                }
              },
              [_vm._v("周一休")]
            ),
            _c("br", {}),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day0, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day0 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(0, _vm.status_day0)
                  }
                }
              },
              [_vm._v("星期日")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day1, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day1 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(1, _vm.status_day1)
                  }
                }
              },
              [_vm._v("星期一")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day2, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day2 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(2, _vm.status_day2)
                  }
                }
              },
              [_vm._v("星期二")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day3, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day3 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(3, _vm.status_day3)
                  }
                }
              },
              [_vm._v("星期三")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day4, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day4 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(4, _vm.status_day4)
                  }
                }
              },
              [_vm._v("星期四")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day5, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day5 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(5, _vm.status_day5)
                  }
                }
              },
              [_vm._v("星期五")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-1 mt-3",
                attrs: { pressed: _vm.status_day6, variant: "secondary" },
                on: {
                  "update:pressed": function($event) {
                    _vm.status_day6 = $event
                  },
                  click: function($event) {
                    return _vm.selectAction_ByDate(6, _vm.status_day6)
                  }
                }
              },
              [_vm._v("星期六")]
            )
          ],
          1
        )
      ]),
      _c("MyCalendarAvailableSetting", {
        ref: "myCalendarAvailableSetting",
        attrs: { "calendar-setting": _vm.calendar }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }