<template>
  <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="TicketMemberIndex"></COM_HeaderNav>


    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta" v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click" ></MyToolbarVuetify>

    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig"  :grid-meta="gridMeta">
<!--      <template v-slot:Fun="{ item }">-->
<!--        <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>-->
<!--        <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="fa fa-trash"></i></a>-->
<!--        <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>-->
<!--        <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>-->
<!--      </template>-->

      <template v-slot:type="{item}">
        <p v-if="item.type==='1'" >常態使用</p>
        <p v-if="item.type==='2'" >客戶使用</p>
      </template>
    </MyGridVuetify>


  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";

let configEditPage="TicketMemberEdit";

export default {
  components: {MyToolbarVuetify, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [

          { text: '類型', value: 'type' },
          { text: '會員編號', value: 'no' },
          { text: '會員名稱', value: 'name' },
          { text: '電話', value: 'tel' },
          { text: '狀態', value: 'status' },
          { text: '備註', value: 'memo' },
          { text: '建立日期', value: 'createDt' },
          { text: '修改日期', value: 'modifyDt' },
        ],
        datas: [
          {
            Fun:"",
            SystemId:"1",
            type:"",
            no:"0001",
            name:"taylor",
            tel:"0915071457",
            address:"桃園市中壢區福州路130號",
            status:"",
            memo:"",
            createDt:'2023-01-01',
            modifyDt:'2023-06-30',
          },
          {
              Fun:"",
              SystemId:"2",
              type:"",
              no:"0002",
              name:"daniel",
              tel:"0915071457",
              address:"桃園市中壢區福州路130號",
              status:"",
              memo:"",
              createDt:'2023-01-01',
              modifyDt:'2023-06-30',
          },
          {
              Fun:"",
              SystemId:"3",
              type:"",
              no:"0003",
              name:"tom",
              tel:"0915071457",
              address:"桃園市中壢區福州路130號",
              status:"",
              memo:"",
              createDt:'2023-01-01',
              modifyDt:'2023-06-30',
          },
        ],
      },
      ready:false,
      toolbarVuetifyConfig:{
        Add:{
          Enable:true,
          CallBack:"",
        },
        Edit:{
          Enable:true,
          CallBack:"",
        },
        Delete:{
          Enable:true,
          CallBack:"",
        },
        Copy:{
          Enable:true,
          CallBack:"",
        },
        Sort:{
          Enable:false,
        },
        CustomButton1:{
            BtnText:"匯出",
            Enable:true,
            CallBack:"",
        },
        CustomButton2:{
            BtnText:"匯入",
            Enable:true,
            CallBack:"",
        },
      },

      gridMeta:{},
    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()

      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },

    DataItem_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    },
    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },

    btnAdd_Click(){
      this.$router.push({ name: configEditPage, query: { SystemId: "" }})
    },
    btnEdit_Click(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    btnDelete_Click(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>
