<template>
  <div>
<!--    selectedID:  {{selectedID}} <br>-->
<!--    selectedIDs:  {{selectedIDs}}<br>-->
<!--    selectedItem:  {{selectedItem}}<br>-->
<!--    selectedItems:  {{selectedItems}}<br>-->
<!--    selectedItemsGrid:  {{selectedItemsGrid}}  這個最準<br>-->
<!--    selectedItemsSystemIds:  {{selectedItemsSystemIds()}} <br>-->
    <Grid
        ref="grid"
        :style="{height: '520px'}"
        :data-items="datasCurrentPage"
        :selected-field="selectedField"
        :columns="columnsSetWidthed"
        :pageable="pageable"
        :page-size="pageSize"
        :skip= "skip"
        :take="take"
        :total="total"
        :sortable="sortable"
        :sort= "sort"
        :resizable="true"

        @rowclick="onRowClick"
        @selectionchange="onSelectionChange"
        @pagechange="pageChangeHandler"
        @headerselectionchange="onHeaderSelectionChange"
        @sortchange="sortChangeHandler"
    >

    </Grid>
  </div>
</template>

<!--cell-render="myTemplate"-->
<!--<template v-slot:myTemplate="{props}">-->
<!--  <span @click="alert('hi')">{{props.field}}</span>-->
<!--</template>-->

<script>
import { Grid } from '@progress/kendo-vue-grid';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { orderBy } from '@progress/kendo-data-query';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
let minGridWidth = 0;

export default {
  props:{
    columns: {
      type: Array,
      required: true,
    },
    datas: {
      type: Array,
      required: true,
    },

  },
  components:{
    'Grid': Grid,
  },
  data: function () {
    return {
      staticColumns:[],
      selectedField: 'selected',
      selectedID: 0,
      selectedIDs: [],
      pageSize: 20,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'input',
        pageSizes: true,
        previousNext: true
      },
      skip: 0,
      take: 10,
      allowUnsort: true,
      multiple: false,
      sort:[],
      columnsSetWidthed:[],
      setMinWidth: false,
      gridCurrent: 0,
    };
  },
  computed: {

    // columns () {
    //   return [
    //     { field: 'selected', width: '50px',minWidth:'50px', headerSelectionValue: this.areAllSelected },
    //     ...this.staticColumns
    //   ]
    // },
    // datasCurrentPage() {
    //     let datasSorted=orderBy(this.datas, this.sort)
    //     let datasToShow= datasSorted.slice(this.skip, this.take + this.skip);
    //
    //     datasToShow= datasToShow.map((item) => ({ ...item, selected: item.SystemId === this.selectedID }));
    //
    //     return datasToShow;
    // },
    datasCurrentPage: {
      get: function() {
        let datasSorted=orderBy(this.datas, this.sort)
        let datasToShow= datasSorted.slice(this.skip, this.take + this.skip);

        datasToShow= datasToShow.map((item) => ({ ...item, selected: this.selectedIDs.includes(item.SystemId) }));

        return datasToShow;
      },
    },
    // items () {
    //   let items= this.datasCurrentPage.map((item) => ({ ...item, selected: item.SystemId === this.selectedID }));
    //
    //   return items;
    // },
    selectedItem () {
      let items =this.datasCurrentPage.find((item) => item.SystemId === this.selectedID);

      return items;
    },
    selectedItems () {
      let items =this.datasCurrentPage.find((item) => this.selectedIDs.includes(item.SystemId));

      return items;
    },

    total () {
      return this.datas ? this.datas.length : 0;
    },
    sortable() {
      return {
        allowUnsort: this.allowUnsort,
        mode: this.multiple ? 'multiple' : 'single'
      };
    },
    areAllSelected () {
      let checked= this.datasCurrentPage.findIndex(item => item.selected === false) !== -1;
      return checked;
    },
  },
  methods: {

    // onRowClick (event) {  //multi select
    //   this.selectedID = event.dataItem.SystemId;
    //   event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    // },
    selectedItemsObjects () {
      let items =this.datasCurrentPage.filter((item) => item.selected);

      return items;
    },
    selectedItemsSystemIds () {
      let items =this.datasCurrentPage.filter((item) => item.selected);

      return items.map(item => item.SystemId);
    },
    selectedItemsGrid () {
      let items =this.datasCurrentPage.filter((item) => item.selected);

      return items;
    },
    onRowClick (event) {  //singe select
      // this.selectedID = event.dataItem.SystemId;
      this.selectedIDs=[]
      this.selectedIDs.push(event.dataItem.SystemId)
    },
    onHeaderSelectionChange (event) { //點左上全選 //eslint-disable-line
      let checked = event.event.target.checked;
      // this.datas.map((item) => { return {...item, selected: checked} });
      // this.datasCurrentPage.map((item) => { return {...item, selected: checked} });

      if (checked){
        this.selectedIDs=[]
        this.datasCurrentPage.map((item) => {  this.selectedIDs.push(item.SystemId) });
      }else{
        this.selectedIDs=[]
      }
    },
    onSelectionChange (event) { //eslint-disable-line
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },

    pageChangeHandler: function(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    exportExcel () {
      saveExcel({
        data: this.datas,
        fileName: "export",
        columns:this.columnsSetWidthed
        // columns: [
        //   { field: 'ID'},
        //   { field: 'Name', title: 'Name' }
        // ]
      });
    },
    sortChangeHandler: function(e) {
      this.sort = e.sort;
    },
    defineColumns(){
      this.columnsSetWidthed = this.columns.map((column, index) => {  //eslint-disable-line
        return {
          field: column.field,
          title: column.title,
          width: this.setWidth(column.minWidth),
          cell:column.cell,
          type:column.type,
          format:column.format,
        };
      });

      //---------------------------------------
      let selectColumn={ field: 'selected', width: '50px',minWidth:'50px', headerSelectionValue: this.areAllSelected }
      this.columnsSetWidthed.unshift (selectColumn)

      //---------------------------------------

    },
    handleResize() {
      if (this.grid.offsetWidth < minGridWidth && !this.setMinWidth) {
        this.setMinWidth = true;
      } else if (this.grid.offsetWidth > minGridWidth) {
        this.gridCurrent = this.grid.offsetWidth;
        this.setMinWidth = false;
      }
      this.defineColumns();
    },
    setWidth(minWidth) {
      let width = this.setMinWidth
          ? minWidth
          : minWidth + (this.gridCurrent - minGridWidth) / this.columns.length;
      width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
      return width;
    },
    loadGrid(){
      //---------------------------------------
      this.grid = document.querySelector(".k-grid");
      window.addEventListener("resize", this.handleResize);
      this.columns.map(item => {
        return (minGridWidth += item.minWidth);
      });

      this.gridCurrent = this.grid.offsetWidth;
      this.setMinWidth = this.grid.offsetWidth < minGridWidth;

      this.defineColumns();
    }
  },
  created() {
    // let selectColumn={ field: 'selected', width: '30px',minWidth:'30px', headerSelectionValue: this.areAllSelected }
    // this.columns.unshift (selectColumn)

    // this.columnsSetWidthed=this.columns
    //---------------------------------------

  },
  mounted() {
    this.loadGrid()

  },
}
</script>

<style scoped>

</style>
