<template>
  <div>
    <button class="btn btn-primary" @click="disable()" v-if="this.value">啟用中</button>

    <button class="btn btn-danger" @click="enable()" v-if="!this.value">停用中</button>

  </div>
</template>

<script>
/**
 <MyButtonEnableDisable v-model="item.IsEnable"></MyButtonEnableDisable>
 **/


export default {
  // name: "MyColorPicker",
  props: ["value"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    disable(){
      this.value=false;
    },
    enable(){
      this.value=true;
    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
