<template>
  <div>
    <COM_HeaderNav mode="onePageAdv"></COM_HeaderNav>
      <div class="bk_toolbar_fixed-top">
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>

        <a class="btn btn-primary" href="#"><i class="fa fa-clone"></i> 複製</a>

<!--        <a class="btn btn-primary" href="#"><i class="fa fa-share-alt"></i>分享 </a>-->
      </div>

    <OnePageMain v-if="ready" :design-time="true" :one-page-id="SystemId" layout-type="1"></OnePageMain>
  </div>
</template>

<script>
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
// import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
// import ubossCommon from "@/pages/commonUBoss/ubossCommon";
import OnePageMain from "@/pages/featureOnePage/OnePageMain.vue";
// import Index2 from "@/pages/ubossHome/views/Index2.vue";

export default {
  components: {OnePageMain, COM_HeaderNav},
  //name: "Index",
  // props: [""],
  data: function () {
    return {
      SystemId:"",
      id: "",
      formData: {
        name: "",
      },
      OnePageLink:"",
      ready:false,
    };
  },
  watch:{
    '$route'(to,from){// eslint-disable-line
      this.SystemId=this.$route.query.systemId
      this.type=this.$route.query.type

      this.loadData()
      this.$forceUpdate();
    },
  },
  methods: {
    async loadData() {
      let dataEnsure= await apiUBossOnePageBk.api_Ensure_OnePagePage(this.SystemId);  // eslint-disable-line
      this.ready=true;
      // let data= await apiUBossBk.api_CustomerSetting();  // eslint-disable-line
      //
      // if (ubossCommon.IsLocalhost()){
      //   //本地用
      //   this.OnePageLink="http://127.0.0.1:9007/Index2?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09"+"&id="+this.SystemId;
      // }else{
      //   this.OnePageLink=data.OnePageSetting.SettingUrl + "&id=" + this.SystemId;
      // }

      // console.log("link");
      // console.log(this.OnePageLink);
      // document.getElementById('myIframe').src=this.OnePageLink;
    },
  },
  mounted() {
    this.SystemId=this.$route.query.systemId
    this.loadData()
  },
}


</script>

<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 85vw;
}
</style>
