var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.CurrentSettingId !== ""
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.btnUpdate }
            },
            [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
          )
        : _vm._e(),
      _vm.CurrentSettingId === "A_SettingBasicInfo"
        ? _c("A_SettingBasicInfo", {
            attrs: { title: _vm.CurrentSettingTitle }
          })
        : _vm._e(),
      _vm.CurrentSettingId === "B_SettingShop"
        ? _c("B_SettingShop", { attrs: { title: _vm.CurrentSettingTitle } })
        : _vm._e(),
      _vm.CurrentSettingId === "C_SettingOnePage"
        ? _c("C_SettingOnePage", { attrs: { title: _vm.CurrentSettingTitle } })
        : _vm._e(),
      _vm.CurrentSettingId === "D_SettingForm"
        ? _c("D_SettingForm", { attrs: { title: _vm.CurrentSettingTitle } })
        : _vm._e(),
      _vm.CurrentSettingId === "T_SettingThirdPartyService_NewebPay"
        ? _c("T_SettingThirdPartyService_NewebPay", {
            attrs: { title: _vm.CurrentSettingTitle }
          })
        : _vm._e(),
      _vm.CurrentSettingId === "T_SettingThirdPartyService_EcPay"
        ? _c("T_SettingThirdPartyService_EcPay", {
            attrs: { title: _vm.CurrentSettingTitle }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }