var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossOnePageBkApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------------------------------
    async api_Get_OnePageAdv() {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Get_OnePageAdv",sendData);
    },

    async api_Save_OnePageAdv(systemId,formData) {
        var method="Save_OnePageAdv";

        return this.getApiManager_General().api_CallMethod_SendObj(method,formData);
    },
    async api_Ensure_OnePagePage(onePageSystemId) {
        var sendData={
            onePageSystemId:onePageSystemId
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Ensure_OnePagePage?onePageSystemId=" +onePageSystemId,sendData);
    },

}
