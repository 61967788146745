var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "ticket2", "button-index": "3" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("票號")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "票號" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _vm._v(" " + _vm._s(_vm.TicketFullCode) + " "),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("狀態")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "狀態" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.Ticket2Declare.Ticket_CLStatus2,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLStatus", $$v)
                            },
                            expression: "formData.CLStatus"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("到期日")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "到期日" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("fmtDate")(_vm.formData.ExpireDt)) +
                            " "
                        ),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("售出日")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "售出日" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("fmtDate")(_vm.formData.SellDt)) +
                            " "
                        ),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("使用時間")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "使用時間" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("fmtDate")(_vm.formData.UseDt)) +
                            " "
                        ),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("銷售者")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "銷售者" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Buyer,
                              expression: "formData.Buyer"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Buyer },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Buyer",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("服務人員")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "服務人員" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.UseOptUserId,
                              expression: "formData.UseOptUserId"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.UseOptUserId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "UseOptUserId",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }