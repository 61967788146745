<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="3"></COM_HeaderNav>
<!--    SearchPanel-->
    <SearchPanelForm ref="searchPanelForm" :fieldDefines="searchDefine.searchFieldDefines" :keyword-label="searchDefine.searchKeywordLabel" :keyword-fields="searchDefine.searchKeywordFields" v-on:search="searchByQueryObject" ></SearchPanelForm>

<!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle" ></i>新增</button>
        <button type="button" class="btn btn-danger" @click="btnDelete"><i class="fa fa-trash" ></i>刪除</button>

        <button type="button" class="btn btn-primary" @click="btnModifyBatch"><i class="fa fa-plus-circle" ></i>整批修改</button>
      </div>
      <div class="btn-group mx-1" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          匯出
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="$refs.grid.exportExcel()">Excel</a>
        </div>
      </div>
    </div>

<!--    Grid-->
    <span v-if="isMobile">
      <MyMobileList :datas="gridDefine.datas" :field-to-show="gridDefine.fieldToShow" :swipe-setting="gridDefine.swipeSetting">
      </MyMobileList>
    </span>
    <span v-else>
      <MyKendoGrid ref="grid" :columns="gridDefine.columns" :datas="gridDefine.datas"></MyKendoGrid>
    </span>
  <DLGTicketStatus ref="dlgTicketStatus" v-on:returnOk="dlgTicketStatusReturnOK" />
  </div>


</template>

<script>


import SearchPanelForm from "@/pages/ubossBk/views/components/SearchPanelForm.vue";
import MyKendoGrid from "@/pages/commonComponent/MyKendoGrid.vue";
import { isMobile } from 'mobile-device-detect';
import MyMobileList from "@/pages/commonComponent/MyMobileList.vue";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import ubossUIHelper from "@/pages/commonUBoss/ubossUIHelper";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import * as Ticket2Declare from "@/pages/ubossBk/views/ticket2/ticket2Declare";
import util from "@/pages/common/util";
import DLGTicketStatus from "@/pages/ubossBk/views/ticket2/ticket/DLGTicketStatus.vue";


export default {
  components: {
    DLGTicketStatus,
    COM_HeaderNav,
    MyMobileList,
    MyKendoGrid,
    SearchPanelForm,
  },
  data: function () {
    return {
      isMobile:isMobile,
      searchDefine:{
        searchKeywordLabel:"",
        searchKeywordFields:[],
        searchFieldDefines:[],
      },
      gridDefine:{
        columns: [
          // { field: 'SystemId', title: 'SystemId', minWidth: 50, width: '50px'},
          { field: 'TicketFullCode', title: '票號' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M,cell: this.cellFunctionLink},
          { field: 'CLStatus_Text', title: '狀態' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M},
          { field: 'ExpireDt', title: '到期日' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M,cell: this.cellDate_ExpireDt},
          { field: 'SellDt', title: '售出日' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M,cell: this.cellDate_SellDt},
          { field: 'UseDt', title: '使用時間' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M,cell: this.cellDate_UseDt},
          { field: 'Buyer', title: '銷售者' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'UseOptUserId', title: '服務人員' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
        ],
        datas:[],
        fieldToShow:{
          title:"OrderId",   //QQ token,multi
          memo:"CLOrderStatus",
          badge1:"TotalAmount",
        },
        swipeSetting:{
          ItemClick:this.swipeSettingItemClick
        }
      },
      publishSystemId:"",
      TypeSystemId:"",

    };
  },
  methods: {
    async loadData() {
      this.publishSystemId=this.$route.query.ticketPublishSystemId;
      let queryObject=this.$refs.searchPanelForm.getQueryObject();

      queryObject.Tag01=this.publishSystemId;

      this.gridDefine.datas=await apiUBossBk.api_Ticket_ListQuery(queryObject);
    },
    cellFunctionLink: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        // that.gotoWithSystemId('Ticket2TicketEdit',props.dataItem.SystemId)
        that.gotoWithQuery('Ticket2TicketEdit',{ systemId: props.dataItem.SystemId, TicketFullCode: props.dataItem.TicketFullCode })
      }
      let linkText=props.dataItem.TicketFullCode;

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML: "<a class='alink'><i class='fa fa-edit'></i>"+linkText +" </a>"
        }
      });
    },
    cellDate_ExpireDt: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      return this.cellDate(h, tdElement , props, clickHandler ,"ExpireDt");
    },
    cellDate_SellDt: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      return this.cellDate(h, tdElement , props, clickHandler ,"SellDt");
    },
    cellDate_UseDt: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      return this.cellDate(h, tdElement , props, clickHandler ,"UseDt");
    },
    //CC
    cellDate: function (h, tdElement , props, clickHandler ,fieldName) {  // eslint-disable-line
      let cellValue=props.dataItem[fieldName];

      let linkText ="";

      if (cellValue==="0001-01-01T00:00:00"){
        linkText = "";
      }else{
        let date = new Date(cellValue);
        // linkText = date.toLocaleString('zh-TW', { hour12: false });
        linkText = date.toISOString().slice(0,10);
      }

      //---------------------------------------
      return h('td', {
        domProps: {
          innerHTML: linkText
        }
      });
    },
    swipeSettingItemClick:function (item){ // eslint-disable-line
      this.gotoWithSystemId('Ticket2TicketEdit',item.SystemId)
    },
    btnCreate(){
      this.$router.push({ name: 'Ticket2TicketEdit', query: { systemId: "" }})
    },
    async btnDelete(){
      let that=this;
      const execRemoveApi = async function(systemIds) {
        // alert(systemIds)
        await apiUBossBk.api_TicketType_RemoveMulti(systemIds)
        await that.loadData();
      }
      ubossUIHelper.crud_DeleteItem(this.$refs.grid,execRemoveApi);
    },
    btnModifyBatch(){
      this.$refs.dlgTicketStatus.showModal();
    },
    SearchFieldIgnore(title){
      let defaultField=this.getSearchFieldDefault();
      defaultField.Title=title;
      defaultField.SearchType="IGNORE";
      defaultField.SearchInputType="text";

      return defaultField;
    },
    SearchFieldText(title,fieldName){
      let defaultField=this.getSearchFieldDefault();
      defaultField.Title=title;
      defaultField.FieldName=fieldName;
      defaultField.SearchInputType="text";

      return defaultField;
    },
    SearchFieldSelect(title,fieldName,values){
      let defaultField=this.getSearchFieldDefault();
      defaultField.Title=title;
      defaultField.FieldName=fieldName;
      defaultField.Values=values;
      defaultField.SearchType="EQ";
      defaultField.SearchInputType="select";

      return defaultField;
    },
    SearchFieldSelectWithInput(title,fieldName,values){
      let defaultField=this.SearchFieldSelect(title,fieldName,values);
      defaultField.SearchInputType="selectWithInput";

      return defaultField;
    },
    SearchFieldDate(title,fieldName){
      let defaultField=this.getSearchFieldDefault();
      defaultField.Title=title;
      defaultField.FieldName=fieldName;
      defaultField.SearchType="date";
      defaultField.SearchInputType="dateRange";

      return defaultField;
    },
    getSearchFieldDefault(){
      return {
        Title:"",
        FieldName:"",
        InputType:"",
        Values:[],
        MustInput:"",
        Visible:"Y",
        List:"",
        Search:"",
        InputValue:"",
        InputValue_S:"",
        InputValue_E:"",
        Permission:"",
        SearchType:"LIKE",
        SearchInputType:"",
      }
    },
    searchByQueryObject(){
      this.loadData();
    },
    setSearchConfig(){
      this.searchDefine.searchKeywordLabel="票號,銷售者";  //票號於API中特別處理
      this.searchDefine.searchKeywordFields=["Buyer"];
      this.searchDefine.searchFieldDefines.push ( this.SearchFieldIgnore("票號") );//無法處理的欄位，由API處理
      this.searchDefine.searchFieldDefines.push ( this.SearchFieldText("銷售者","Buyer") )
      this.searchDefine.searchFieldDefines.push ( this.SearchFieldSelect("狀態","CLStatus",util.addEmptyOption(Ticket2Declare.Ticket_CLStatus) ));
      // this.searchDefine.searchFieldDefines.push ( this.SearchFieldSelectWithInput("狀態","CLStatus",Ticket2Declare.Ticket_CLStatus ));
      this.searchDefine.searchFieldDefines.push ( this.SearchFieldDate("到期日","ExpireDt") )
    },
    dlgTicketStatusReturnOK(clStatus){// eslint-disable-line
      debugger // eslint-disable-line
      let systemIds=this.$refs.grid.selectedIDs;
      apiUBossBk.api_Ticket_UpdateStatus(systemIds,clStatus).then(()=>{
        util.ShowMessage("修改成功","訊息")
      })
    },
  },
  mounted() {
    this.setSearchConfig();
    this.loadData()
  },
}
</script>

<style scoped>

</style>
