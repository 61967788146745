var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-inline" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "form-control ",
        attrs: { type: "url", placeholder: "" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          }
        }
      }),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          staticStyle: { color: "white" },
          on: {
            click: function($event) {
              return _vm.preview()
            }
          }
        },
        [_c("i", { staticClass: "far fa-eye" })]
      ),
      _vm.showPreview
        ? _c("youtube", {
            attrs: { "video-id": _vm.videoId, "player-width": "100%" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }