<template>
  <div class="fieldsPanel">
    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-primary" @click="btnDownloadQR"><i class="fa fa-save" ></i>下載QRCode</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">名稱</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="Name" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Name">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">代號</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="Name" >
          <input type="text" class="form-control" placeholder="" v-model="formData.CodePrefix">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">編號起</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="Name" >
          <input type="number" class="form-control" placeholder="" v-model.number="formData.StartNo">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">編號訖</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="Name" >
          <input type="number" class="form-control" placeholder="" v-model.number="formData.EndNo">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">進階設定</label>
        <div class="a_thinInputDiv col-sm-10">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"  v-model="formData.EnableEffectDateRage">
            <label class="form-check-label" for="defaultCheck1">
              有效期間
            </label>
          </div>
          <div class="form-group custom-control-inline">
            <input type="date" class="form-control" id="dtStart" name="dtStart" placeholder=""  v-model="formData.EffectDateRageStartDt">
            ~
            <input type="date" class="form-control" id="dtEnd" name="dtEnd" placeholder=""  v-model="formData.EffectDateRageEndDt">
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck2"  v-model="formData.EnableLimitCount">
            <label class="form-check-label" for="defaultCheck2">
              每個代碼，可使用次數
            </label>
          </div>
          <input type="number" class="form-control" id="name" name="name" placeholder=""  v-model.number="formData.LimitCount">

        </div>
      </div>

<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">內容設定</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <PpdDLGDrinkMaterial ref="otherSetting"></PpdDLGDrinkMaterial>-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">行銷內容 (格式為Json資料)</label>
        <div class="a_thinInputDiv col-sm-10">
          <textarea class="form-control" rows="3" v-model="formData.ContentJson"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">說明</label>
        <div class="a_thinInputDiv col-sm-10">
          <textarea class="form-control" rows="3" v-model="formData.Memo"></textarea>
        </div>
      </div>
    </ValidationObserver>

    <DLGQRImageDownload ref="dlgQRImageDownload" v-on:returnOk="dlgQRImageDownloadReturnOk"/>
  </div>
</template>

<script>

import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
// import PpdDLGDrinkMaterial from "@/pages/ubossBk/views/customize/PpdDLGDrinkMaterial";
import DLGQRImageDownload from "@/pages/ubossBk/views/components/DLGQRImageDownload";

export default {
  components: {DLGQRImageDownload},
  data: function () {
    return {
      systemId: "",
      formData: {
        CodePrefix:"",
        StartNo:0,
        EndNo:0,
        EnableEffectDateRage:false,
        EffectDateRageStartDt:null,
        EffectDateRageEndDt:null,

        EnableLimitCount:false,
        LimitCount:0,
        ContentJson:"",
        Memo:"",
      },
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossBk.api_MarketingQRTicketType_Get(this.systemId)

        this.formData=data
        this.formData.EffectDateRageStartDt=util.FmtTrimTimeString(this.formData.EffectDateRageStartDt);
        this.formData.EffectDateRageEndDt=util.FmtTrimTimeString(this.formData.EffectDateRageEndDt);
      }

    },
    btnDownloadQR(){
      this.$refs.dlgQRImageDownload.showModal();
    },
    btnUpdate(){
      let router=this.$router

      debugger // eslint-disable-line
      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossBk.api_MarketingQRTicketType_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
    otherSettingReturnOk(){

    },
    dlgQRImageDownloadReturnOk(value){
      if (value==="pdf"){
        apiUBossBk.api_DownloadQR_Pdf(this.systemId);
      }else if (value==="zip"){
        apiUBossBk.api_DownloadQR_Zip(this.systemId);
      }else if (value==="txt"){
        apiUBossBk.DownloadQR_Txt(this.systemId);
      }else{
        alert('case else' + value)
      }
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
input{
  max-width:200px;
}
textarea{
  max-width:400px;
}
</style>
