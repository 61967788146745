<template>
  <div>

    <table class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th class="text-center">{{ options.TextLeft }}</th>
        <th class="text-center">{{ options.TextRight }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style="width:50%">

          <table class="table table-hover" id="tblSecond">
            <tbody>
            <tr v-for="(item,index) in this.value" :key="index">
              <td class="" style="width: 30%">
                <a class="btn btn-info" @click="LeftDataItem_Remove(item)"><i class="fa fa-trash"></i></a>
                <a class="btn btn-primary" @click="LeftDataItem_MoveUp(item)" v-if="options.MoveUpDownLeft"><i class="fa fa-angle-up"></i> </a>
                <a class="btn btn-primary" @click="LeftDataItem_MoveDown(item)" v-if="options.MoveUpDownLeft"><i class="fa fa-angle-down"></i> </a>
              </td>
              <td >
                <slot name="left" :item="item" ></slot>
              </td>
            </tr>

            </tbody>
          </table>
        </td>
        <td style="width:50%">

          <table class="table table-hover" id="tblSecond">
            <tbody>
            <tr v-for="(item,index) in this.datasourceRight" :key="index">
              <td class="" style="width: 30%">
                <a class="btn btn-info" @click="RightDataItem_MoveToLeft(item)"><i class="fa fa-plus-circle"></i></a>
                <a class="btn btn-primary" @click="RightDataItem_MoveUp(item)" v-if="options.MoveUpDownRight"><i class="fa fa-angle-up"></i> </a>
                <a class="btn btn-primary" @click="RightDataItem_MoveDown(item)" v-if="options.MoveUpDownRight"><i class="fa fa-angle-down"></i> </a>
              </td>
              <td >
                <slot name="right" :item="item"></slot>
              </td>
            </tr>

            </tbody>
          </table>
        </td>
      </tr>

      </tbody>
    </table>



  </div>
</template>

<script>
/**

 **/
import _ from "lodash";
import arrayUtil from "@/pages/common/arrayUtil";
// import util from "@/pages/common/util";

// import apiUBossShop from "@/pages/ubossShop/util/apiUBossShop";

export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    datasourceAll: {
      type: Array,
      default() {
        return {
          // rightOptions:[],
        }
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      // datasourceLeft:[]
      // datasourceRight:[],
      options:{
        TextLeft:"選取",
        TextRight:"全部",
        MoveUpDownLeft:true,
        MoveUpDownRight:false,
      },
    };
  },
  computed:{
    datasourceRight(){
      let arrExistIds=this.value.map((item)=>item.SystemId);

      let arrAll= _.clone(this.datasourceAll)
      return _.remove(arrAll, function(n) {
        return !arrExistIds.includes(n.SystemId);
      });
    }

  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
      // this.('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      // const isMyObjectEmpty = !Object.keys(this.value).length;
      //
      // if (isMyObjectEmpty) {
      //   let template = {...this.datasDemo};
      //   this.value = template;
      // }
    },
    async loadData() {
      this.refreshLeftItems();
    },
    refreshLeftItems(){
        // let arrExistIds=this.value.map((item)=>item.SystemId);
        //
        // let arrAll= _.clone(this.datasourceAll)
        // this.datasourceRight= _.remove(arrAll, function(n) {
        //   return !arrExistIds.includes(n.SystemId);
        // });

    },
    LeftDataItem_Remove(item){
      this.value= this.value.filter(s => s.SystemId !==item.SystemId);
      this.refreshLeftItems();
    },
    LeftDataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.value,"SystemId","OrderKey")
    },
    LeftDataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.value,"SystemId","OrderKey")
    },
    RightDataItem_MoveToLeft(item){
      this.value= this.value.push(item);
      this.refreshLeftItems();
    },

    RightDataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datasourceRight,"SystemId","OrderKey")
    },
    RightDataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datasourceRight,"SystemId","OrderKey")
    },
  },
  mounted() {
    // this.initValue()
    this.loadData()
  },
}
</script>

<style scoped>

</style>
