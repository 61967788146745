<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingProduct"></COM_HeaderNav>



    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>

<!--        //QQ-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存後繼續新增</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>複製並編輯</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-eye"></i>前台預覽</a>-->
      </div>
    </div>

    <ValidationObserver ref="form">


      <b-card no-body>
        <b-tabs card>
          <b-tab title="基本資料" active>
            <b-card-text>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">商品貨號</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="" v-slot="{ errors }"  name="商品貨號" >
                    <input type="text" class="form-control" placeholder="" v-model="formData.ProductCode">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">商品名稱</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="required" v-slot="{ errors }"  name="商品名稱" >
                    <input type="text" class="form-control " placeholder="" v-model="formData.Name">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">簡稱</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="" v-slot="{ errors }"  name="簡稱" >
                    <input type="text" class="form-control " placeholder="" v-model="formData.SName">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">訂價</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="required" v-slot="{ errors }"  name="訂價" >
                    <input type="number" class="form-control" placeholder="" v-model.number="formData.PriceSuggest">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">售價</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="required" v-slot="{ errors }"  name="售價" >
                    <input type="number" class="form-control" placeholder="" v-model.number="formData.Price">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">成本價</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="required" v-slot="{ errors }"  name="成本價" >
                    <input type="number" class="form-control" placeholder="" v-model.number="formData.PriceCost">
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">狀態</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="required" v-slot="{ errors }"  name="狀態" >
                    <!--            //QQ-->
                    <MyCodeListButtonRadio v-model="formData.CLProductStatus" :options="$u.UCode_CLProductStatus" size="md" ></MyCodeListButtonRadio>
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
<!--              <div class="form-group row">-->
<!--                <label for="" class="a_thinlabel col-sm-2">上架時間</label>-->
<!--                <div class="a_thinInputDiv col-sm-10">-->
<!--                  <validation-provider rules="" v-slot="{ errors }"  name="上架時間" >-->
<!--                    <input type="datetime-local" class="form-control " placeholder="" v-model="formData.OnMarketDt">-->
<!--                    <span class="error_msg">{{ errors[0] }}</span>-->
<!--                  </validation-provider>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="form-group row">-->
<!--                <label for="" class="a_thinlabel col-sm-2">下架時間</label>-->
<!--                <div class="a_thinInputDiv col-sm-10">-->
<!--                  <validation-provider rules="" v-slot="{ errors }"  name="下架時間" >-->
<!--                    <input type="datetime-local" class="form-control" placeholder="" v-model="formData.DiscontinueDt">-->
<!--                    <span class="error_msg">{{ errors[0] }}</span>-->
<!--                  </validation-provider>-->
<!--                </div>-->
<!--              </div>-->
              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">標籤</label>
                <div class="a_thinInputDiv col-sm-10">

<!--                  -->
                  <MyTagInput v-model="formData.Tags" :only-existing-tags="false"></MyTagInput>

                </div>
              </div>
              <div class="form-group row" v-if="$c.getCustomizeConfig().ProductOptions_Enable">
                <label for="" class="a_thinlabel col-sm-2">選項</label>
                <div class="a_thinInputDiv col-sm-10">

                  <ProductOptionSetting ref="productOptionSetting" style="max-width:750px " ></ProductOptionSetting>
                </div>
              </div>

              <div class="form-group row" v-if="$c.getCustomizeConfig().ProductOptions_IsCustomize">
                <label for="" class="a_thinlabel col-sm-2">選項</label>
                <div class="a_thinInputDiv col-sm-10">

                  <ProductOptionSelectorPpd ref="productOptionSelectorPpd" style="max-width:750px " v-model="formData.CustValue.ProductOption" :is-in-setting="true"></ProductOptionSelectorPpd>
                </div>
              </div>


              <span v-if="$c.getCustomizeConfig().ProductExtValue_Enable">
                <div class="form-group row">
                  <label for="" class="a_thinlabel col-sm-2">{{ $c.getCustomizeConfig().ProductExtValue_LabelText }}</label>
                  <div class="a_thinInputDiv col-sm-10">
                    <PpdDLGDrinkMaterial v-model="formData.CustValue.DrinkRecipe"></PpdDLGDrinkMaterial>
                  </div>
                </div>
              </span>


              <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">備註</label>
                <div class="a_thinInputDiv col-sm-10">
                  <validation-provider rules="" v-slot="{ errors }"  name="備註" >
                    <textarea class="form-control" rows="3" v-model="formData.Memo"></textarea>
                    <span class="error_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>


            </b-card-text>
          </b-tab>
          <b-tab title="圖片">
            <b-card-text>
              <FileUploadList emmit-back-param="undefined" :owner-system-id="this.systemId" file-use-memo="productImage"></FileUploadList>
            </b-card-text>
          </b-tab>
          <b-tab title="商品簡述">
            <b-card-text>
              <MyCkEditor ref="myCkEditor1" v-model="formData.Summary"  height="200" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="systemId" :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_ProductSummary" ></MyCkEditor>
            </b-card-text>
          </b-tab>
          <b-tab title="商品詳細說明">
            <b-card-text>
              <MyCkEditor ref="myCkEditor2" v-model="formData.Desc"  height="200" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="systemId" :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_ProductDesc"></MyCkEditor>
            </b-card-text>
          </b-tab>

          <b-tab title="預約設定">
            <b-card-text>
              選擇日曆

              <select class="form-control" id="selItems" name="selItems">
                <option></option>
                <option>taylor日曆</option>
              </select>

            </b-card-text>
          </b-tab>

        </b-tabs>
      </b-card>



    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import FileUploadList from "@/pages/commonComponent/FileUploadList";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyCkEditor from "@/pages/commonComponent/MyCkEditor";
import ProductOptionSetting from "@/pages/ubossBk/views/components/shop/ProductOptionSetting";
import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio";
import MyTagInput from "@/pages/commonComponent/MyTagInput";
import ProductOptionSelectorPpd from "@/pages/commonUBossCustomize/Ppd/ProductOptionSelectorPpd";
import PpdDLGDrinkMaterial from "@/pages/ubossBk/views/customize/PpdDLGDrinkMaterial";


export default {
  components: {
    PpdDLGDrinkMaterial,
    ProductOptionSelectorPpd,
    MyTagInput, MyCodeListButtonRadio, ProductOptionSetting, COM_HeaderNav, FileUploadList,MyCkEditor},
  data: function () {
    return {
      systemId: "",
      tags:[],
      formData: {
        "ProductCode":"",
        "Name":"",
        "SName":"",
        "PriceSuggest":0,
        "Price":0,
        "PriceCost":0,
        "CLProductStatus":"",
        "Desc":"",
        "OnMarketDt":"",
        "DiscontinueDt":"",
        "Memo":"",
      },
      formDataDemo: {
        "ProductCode":"",
        "Name":"",
        "SName":"",
        "PriceSuggest":"",
        "Price":"",
        "PriceCost":"",
        "CLProductStatus":"",
        "Summary":"",
        "Desc":"",
        "OnMarketDt":"",
        "DiscontinueDt":"",
        "Memo":"",
      },
    };
  },
  methods: {
    async loadData() {
      if (this.systemId){
        let data=await apiUBossShopBk.api_Product_Get(this.systemId)

        this.formData=data;
      }

    },
    btnUpdate(){
      let that=this             // eslint-disable-line
      let router=this.$router   // eslint-disable-line

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (that.formData.OnMarketDt===""){
            that.formData.OnMarketDt=null;
          }
          if (that.formData.DiscontinueDt===""){
            that.formData.DiscontinueDt=null;
          }


          //---------------------------------------
          apiUBossShopBk.api_Product_Save(that.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            this.$router.push({ name: 'ProductIndex'})
          })
          //---------------------------------------
        }
      });
    },
  },
  created() {
    this.systemId=this.$route.query.systemId;
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.uploader-example {
  width: 880px;
  padding: 15px;
  /*margin: 40px auto 0;*/
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
