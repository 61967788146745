var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("WebPartPricePlanSetting", { ref: "setting" }),
      _vm._m(0),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function($event) {
              return _vm.setting()
            }
          }
        },
        [_c("i", { staticClass: "fa fa-cog" })]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("div", { staticClass: "card-deck mb-3 text-center" }, [
        _c("div", { staticClass: "card mb-4 box-shadow" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "my-0 font-weight-normal" }, [
              _vm._v("方案1")
            ])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("h1", { staticClass: "card-title pricing-card-title" }, [
              _vm._v("1,000")
            ]),
            _c("ul", { staticClass: "list-unstyled mt-3 mb-4" }, [
              _c("li", [_vm._v("10 users included")]),
              _c("li", [_vm._v("2 GB of storage")]),
              _c("li", [_vm._v("Email support")]),
              _c("li", [_vm._v("Help center access")])
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-lg btn-block btn-outline-primary",
                attrs: { type: "button" }
              },
              [_vm._v("購買連結")]
            )
          ])
        ]),
        _c("div", { staticClass: "card mb-4 box-shadow" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "my-0 font-weight-normal" }, [
              _vm._v("方案1")
            ])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("h1", { staticClass: "card-title pricing-card-title" }, [
              _vm._v("1,000")
            ]),
            _c("ul", { staticClass: "list-unstyled mt-3 mb-4" }, [
              _c("li", [_vm._v("10 users included")]),
              _c("li", [_vm._v("2 GB of storage")]),
              _c("li", [_vm._v("Email support")]),
              _c("li", [_vm._v("Help center access")])
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-lg btn-block btn-outline-primary",
                attrs: { type: "button" }
              },
              [_vm._v("購買連結")]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }