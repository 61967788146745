var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.value
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.disable()
              }
            }
          },
          [_vm._v(_vm._s(_vm.trueText))]
        )
      : _vm._e(),
    !this.value
      ? _c(
          "button",
          {
            staticClass: "btn btn-danger",
            on: {
              click: function($event) {
                return _vm.enable()
              }
            }
          },
          [_vm._v(_vm._s(_vm.falseText))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }