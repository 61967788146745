import Vue from 'vue'
import VueRouter from 'vue-router'
import ubossCommon from "@/pages/commonUBoss/ubossCommon";

//-----------使用Feature OnePage需要設定的Route----------------------------
import routeOnePage from "@/pages/featureOnePage/router/routeOnePage";
//---------------------------------------


// import Index from '../views/Index.vue'
import Index from '../views/Index.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import Test from '../views/Test.vue'
import Test2 from '../views/Test2.vue'
import Login from '../views/Login.vue'
import MemberIndex from '../views/member/Index.vue'
import MemberEdit from '../views/member/Edit.vue'
import SettingPanel from '../views/setting/SettingPanel.vue'
import ProductIndex from '../views/product/Index.vue'
import ProductEdit from '../views/product/Edit.vue'
import ProductEditFirstStep from '../views/product/EditFirstStep.vue'
import OrderIndex from '../views/order/Index.vue'
import OrderEdit from '../views/order/Edit.vue'

import OnePageIndex from '../views/onePage/Index.vue'


import OnePageAdvIndex from '../views/onePageAdv2/Index.vue'
import OnePageAdvIndexCName from '../views/onePageAdv2/IndexCName.vue'
import OnePageAdvEditStep1 from '../views/onePageAdv2/EditStep1.vue'

import OnePageAdvIndexOrg from '../views/onePageAdv/Index.vue'
import OnePageAdvEditOrg from '../views/onePageAdv/Edit.vue'

import OnePageAdvIndexOld from '../views/onePageAdv/Index.vue'
import OnePageAdvEditOld from '../views/onePageAdv/Edit.vue'

import OnePageAdvIndex3 from '../views/onePageAdv3/Index.vue'


import OnePageAdvIndex4 from '../views/onePageAdv4/Index.vue'
import OnePageAdvEdit4 from '../views/onePageAdv4/Edit.vue'

import UDMIndex from '../views/udm/Index.vue'
import UDMEdit from '../views/udm/Edit.vue'
import UDMPassCodeIndex from '../views/udm/PassCodeIndex.vue'
import UDMPassCodeEdit from '../views/udm/PassCodeEdit.vue'
import UDMLogIndex from '../views/udm/LogIndex.vue'
import UDMLogin from '../views/udm/Login.vue'
import TicketIndex from '../views/ticket/Index.vue'
import TicketEdit from '../views/ticket/Edit.vue'
import TicketMemberIndex from '../views/ticket/MemberIndex.vue'
import TicketMemberEdit from '../views/ticket/MemberEdit.vue'
import TicketLogIndex from '../views/ticket/LogIndex.vue'


import Ticket2TicketTypeIndex from '../views/ticket2/ticketType/Index.vue'
import Ticket2TicketTypeEdit from '../views/ticket2/ticketType/Edit.vue'

import Ticket2TicketPublishIndex from '../views/ticket2/ticketPublish/Index.vue'
import Ticket2TicketPublishEdit from '../views/ticket2/ticketPublish/Edit.vue'

import Ticket2TicketIndex from '../views/ticket2/ticket/Index.vue'
import Ticket2TicketEdit from '../views/ticket2/ticket/Edit.vue'

import Ticket2TicketDesignIndex from '../views/ticket2/ticketDesign/Index.vue'
import Ticket2TicketDesignEdit from '../views/ticket2/ticketDesign/Edit.vue'


import ADMIndex from '../views/adm/Index.vue'
import ADMEdit from '../views/adm/Edit.vue'
// import UDMLogEdit from '../views/udm/LogEdit.vue'
import UFormIndex from '../views/uform/Index.vue'
import UFormEdit from '../views/uform/Edit.vue'
import UFormEditFirstStep from '../views/uform/EditFirstStep.vue'
import UFormDataIndex from '../views/uform/DataIndex.vue'

import TemplateIndex from '../views/_template2/Index.vue'
import TemplateEdit from '../views/_template2/Edit.vue'

import UTubeIndex from '../views/utube/Index.vue'
import UTubeEdit from '../views/utube/Edit.vue'


import MarketingQRIndex from '../views/marketingQR/Index.vue'
import MarketingQREdit from '../views/marketingQR/Edit.vue'

import CalendarIndex from '../views/calendar/Index.vue'
import CalendarEdit from '../views/calendar/Edit.vue'

Vue.use(VueRouter)

  const routes = [
  ...routeOnePage,
  {
    path: '/',
    name: 'home',
    component: Index,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    }
  },
  {
    path: '/ShoppingCart',
    name: 'ShoppingCart',
    component: ShoppingCart,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/MemberIndex',
    name: 'MemberIndex',
    component: MemberIndex,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: MemberEdit,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  // {
  //   path: '/SettingShopping',
  //   name: 'SettingShopping',
  //   component: SettingShopping,
  //   meta: {
  //     requiresAuth: true,
  //     roles: ['USER']
  //   }
  // },
  {
    path: '/SettingPanel',
    name: 'SettingPanel',
    component: SettingPanel,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },

    {
      path: '/ProductIndex',
      name: 'ProductIndex',
      component: ProductIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/ProductEdit',
      name: 'ProductEdit',
      component: ProductEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/ProductEditFirstStep',
      name: 'ProductEditFirstStep',
      component: ProductEditFirstStep,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },

    {
      path: '/OrderIndex',
      name: 'OrderIndex',
      component: OrderIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OrderEdit',
      name: 'OrderEdit',
      component: OrderEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageIndex',
      name: 'OnePageIndex',
      component: OnePageIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },

    {
      path: '/OnePageAdvIndex3',
      name: 'OnePageAdvIndex3',
      component: OnePageAdvIndex3,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvIndex',
      name: 'OnePageAdvIndex',
      component: OnePageAdvIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvIndexCName',
      name: 'OnePageAdvIndexCName',
      component: OnePageAdvIndexCName,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvEditStep1',
      name: 'OnePageAdvEditStep1',
      component: OnePageAdvEditStep1,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvIndexOld',
      name: 'OnePageAdvIndexOld',
      component: OnePageAdvIndexOld,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvEditOld',
      name: 'OnePageAdvEditOld',
      component: OnePageAdvEditOld,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvIndexOrg',
      name: 'OnePageAdvIndexOrg',
      component: OnePageAdvIndexOrg,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvEditOrg',
      name: 'OnePageAdvEditOrg',
      component: OnePageAdvEditOrg,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvIndex4',
      name: 'OnePageAdvIndex4',
      component: OnePageAdvIndex4,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/OnePageAdvEdit4',
      name: 'OnePageAdvEdit4',
      component: OnePageAdvEdit4,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UDMIndex',
      name: 'UDMIndex',
      component: UDMIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UDMEdit',
      name: 'UDMEdit',
      component: UDMEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UDMPassCodeIndex',
      name: 'UDMPassCodeIndex',
      component: UDMPassCodeIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UDMPassCodeEdit',
      name: 'UDMPassCodeEdit',
      component: UDMPassCodeEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UDMLogIndex',
      name: 'UDMLogIndex',
      component: UDMLogIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    //QQUDM
    {
      path: '/Bodytalk',
      name: 'Bodytalk',
      component: UDMLogin,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Aesa',
      name: 'Aesa',
      component: UDMLogin,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },

    {
      path: '/TicketIndex',
      name: 'TicketIndex',
      component: TicketIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/TicketEdit',
      name: 'TicketEdit',
      component: TicketEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/TicketMemberIndex',
      name: 'TicketMemberIndex',
      component: TicketMemberIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/TicketMemberEdit',
      name: 'TicketMemberEdit',
      component: TicketMemberEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/TicketLogIndex',
      name: 'TicketLogIndex',
      component: TicketLogIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketTypeIndex',
      name: 'Ticket2TicketTypeIndex',
      component: Ticket2TicketTypeIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketTypeEdit',
      name: 'Ticket2TicketTypeEdit',
      component: Ticket2TicketTypeEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketPublishIndex',
      name: 'Ticket2TicketPublishIndex',
      component: Ticket2TicketPublishIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketPublishEdit',
      name: 'Ticket2TicketPublishEdit',
      component: Ticket2TicketPublishEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketIndex',
      name: 'Ticket2TicketIndex',
      component: Ticket2TicketIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketEdit',
      name: 'Ticket2TicketEdit',
      component: Ticket2TicketEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketDesignIndex',
      name: 'Ticket2TicketDesignIndex',
      component: Ticket2TicketDesignIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/Ticket2TicketDesignEdit',
      name: 'Ticket2TicketDesignEdit',
      component: Ticket2TicketDesignEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },

    {
      path: '/ADMIndex',
      name: 'ADMIndex',
      component: ADMIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/ADMEdit',
      name: 'ADMEdit',
      component: ADMEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UFormIndex',
      name: 'UFormIndex',
      component: UFormIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UFormEditFirstStep',
      name: 'UFormEditFirstStep',
      component: UFormEditFirstStep,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UFormEdit',
      name: 'UFormEdit',
      component: UFormEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },

    {
      path: '/UFormDataIndex',
      name: 'UFormDataIndex',
      component: UFormDataIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
  {
    path: '/Test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Test2',
    name: 'Test2',
    component: Test2,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/TemplateIndex',
    name: 'TemplateIndex',
    component: TemplateIndex,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/TemplateEdit',
    name: 'TemplateEdit',
    component: TemplateEdit,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
    {
      path: '/UTubeIndex',
      name: 'UTubeIndex',
      component: UTubeIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/UTubeEdit',
      name: 'UTubeEdit',
      component: UTubeEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/MarketingQRIndex',
      name: 'MarketingQRIndex',
      component: MarketingQRIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/MarketingQREdit',
      name: 'MarketingQREdit',
      component: MarketingQREdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/CalendarIndex',
      name: 'CalendarIndex',
      component: CalendarIndex,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
    {
      path: '/CalendarEdit',
      name: 'CalendarEdit',
      component: CalendarEdit,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //QQUDM
    if (to.name=="Bodytalk" || to.name=="Aesa"){
      next()
    }else{
      if (!ubossCommon.IsLoginBk()) {
        next({ name: 'Login' })
      } else {
        next() // go to wherever I'm going
      }
    }

    next() // go to wherever I'm going
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (session.isLogined()) {
//       // if (!to.meta.roles) {
//       //   return next()
//       // }
//       // if (to.meta.roles.includes(store.state.user.rol)) {
//       //   switch (store.state.user.rol) {
//       //     case 'USER':
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //       break;
//       //     case 'ADMIN':
//       //       next({
//       //         name: 'AccountsHome'
//       //       })
//       //       break;
//       //     default:
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //   }
//       // }
//     } else {
//       next()
//     }
//
//     // this route requires auth, check if logged in
//     // if true, redirect to main page.
//     // if (store.getters.isUserLoggedIn) {
//     //   if (!to.meta.roles) {
//     //     return next()
//     //   }
//     //   if (to.meta.roles.includes(store.state.user.rol)) {
//     //     switch (store.state.user.rol) {
//     //       case 'USER':
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //         break;
//     //       case 'ADMIN':
//     //         next({
//     //           name: 'AccountsHome'
//     //         })
//     //         break;
//     //       default:
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //     }
//     //   }
//     // } else {
//     //   next()
//     // }
//   }
// })
