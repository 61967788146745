<template>
  <div>
    <vc-calendar :attributes="attributes" @dayclick="onDayClick" :available-dates='setting_availableDates' />



    <div v-if="showOption">
      上午 10:00 / 下午 14:00
      <div v-if="timeperiod==='day'">
        <a role="button" class="btn btn-primary" @click="btnBook" >預約</a>
      </div>
      <div v-else-if="timeperiod==='halfday'">
        <div class="row">
          <div class="col-3">
            上午
          </div>
          <div class="col-9">
            <a role="button" class="btn btn-primary" @click="btnBook">預約</a>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            下午
          </div>
          <div class="col-9">
            <a role="button" class="btn btn-primary" @click="btnBook">預約</a>
          </div>
        </div>
      </div>
      <div v-else-if="timeperiod==='hour'">
        <div class="row" v-for="(item,index) in timeperiodHour" :key="index">
          <div class="col-3">
            {{item}}
          </div>
          <div class="col-9">
            <a role="button" class="btn btn-primary" @click="btnBook">預約</a>
          </div>
        </div>
      </div>
      <div v-else-if="timeperiod==='halfhour'">
        <div class="row" v-for="(item,index) in timeperiodHalfHour" :key="index">
          <div class="col-3">
            {{item}}
          </div>
          <div class="col-9">
            <a role="button" class="btn btn-primary" @click="btnBook">預約</a>
          </div>
        </div>
      </div>
      <div v-else-if="timeperiod==='specifyTimePeriod'">
        <div class="row" v-for="(item,index) in timeperiodSpecifyTimePeriod" :key="index">
          <div class="col-3">
            {{item}}
          </div>
          <div class="col-9">
            <a role="button" class="btn btn-primary" @click="btnBook">預約</a>
          </div>
        </div>
      </div>
      <div v-else>

      </div>
    </div>
  </div>
</template>

<script>
/**

 **/


export default {
  props: ["timeperiod"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      showOption:false,
      timeperiodHour:[
        '8:00~9:00',
        '9:00~10:00',
        '10:00~11:00',
        '11:00~12:00',
        '12:00~13:00',
        '13:00~14:00',
        '14:00~15:00',
        '15:00~16:00',
        '16:00~17:00',
        '17:00~18:00',
        '18:00~19:00',
        '19:00~20:00',
      ],
      timeperiodHalfHour:[
        '8:30~9:30',
        '9:30~10:00',
        '10:30~11:00',
        '11:00~11:30',
        '11:30~12:00',
        '12:30~13:30',
        '13:30~14:00',
        '14:30~15:30',
        '15:30~16:00',
        '16:30~17:30',
        '17:30~18:00',
        '18:30~19:30',
        '19:30~20:00',
      ],
      timeperiodSpecifyTimePeriod:[
        '芳療',
        '作臉',
      ],
      days: [],
      setting_availableDates:[],
    };
  },
  computed:{
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: false,
        dates: date,
      }));
    },
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    onDayClick(day) {
      // debugger // eslint-disable-line
      this.showOption=true;

      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    btnBook(event){
      console.log(event.target)
      event.target.classList.add("btn-success")
      event.target.classList.remove("btn-danger")
    }
  },
  mounted() {
    //this.loadData()
    this.setting_availableDates.push(  {start: new Date(2022,10,23),end: new Date(2022,10,23)})
    this.setting_availableDates.push(  {start: new Date(2022,10,25),end: new Date(2022,10,25)})
  },
}
</script>

<style scoped>
.btn{
  color: white !important;
}

</style>
