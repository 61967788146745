var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "shoppingSiteSetting" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("div", { staticStyle: { "max-width": "1024px" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeSetting(_vm.Setting.A_SettingBasicInfo)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.Setting.A_SettingBasicInfo.Text))]
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeSetting(_vm.Setting.B_SettingShop)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.Setting.B_SettingShop.Text))]
            )
          ]),
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeSetting(_vm.Setting.B_SettingShop2)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.Setting.B_SettingShop2.Text))]
            )
          ]),
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: { click: _vm.gotoShopDesignTime }
              },
              [_vm._v("畫面設定")]
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeSetting(_vm.Setting.C_SettingOnePage)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.Setting.C_SettingOnePage.Text))]
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary aButton",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeSetting(_vm.Setting.D_SettingForm)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.Setting.D_SettingForm.Text))]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("SettingBlockBase", {
              staticStyle: { "max-width": "1024px" },
              attrs: {
                "setting-all": _vm.Setting,
                "current-setting-id": _vm.CurrentSettingId,
                "current-setting-title": _vm.CurrentSettingTitle
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }