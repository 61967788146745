var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { "hide-footer": "", title: "下載QRCode" } },
        [
          _c("div", { staticClass: "d-block text-center" }),
          _vm.isShow("UI_fontSizeTextbox")
            ? _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
                  _vm._v("字型大小")
                ]),
                _c("div", { staticClass: "col-sm-7 form-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.styleInfo["font-size"],
                        expression: "styleInfo['font-size']"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "100px" },
                    attrs: { type: "number", placeholder: "" },
                    domProps: { value: _vm.styleInfo["font-size"] },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.styleInfo,
                          "font-size",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("點(pt)")])
                ])
              ])
            : _vm._e(),
          _vm.isShow("UI_fontSizeSelect")
            ? _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
                  _vm._v("字型大小")
                ]),
                _c("div", { staticClass: "col-sm-7 form-inline" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.styleInfo["font-size"],
                          expression: "styleInfo['font-size']"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.styleInfo,
                            "font-size",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option"),
                      _c("option", { attrs: { value: "50" } }, [_vm._v("大")]),
                      _c("option", { attrs: { value: "40" } }, [_vm._v("中")]),
                      _c("option", { attrs: { value: "30" } }, [_vm._v("小")])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm.isShow("UI_colorPicker")
            ? _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
                  _vm._v("字型顏色")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-7" },
                  [
                    _c("MyColorPicker", {
                      model: {
                        value: _vm.styleInfo["color"],
                        callback: function($$v) {
                          _vm.$set(_vm.styleInfo, "color", $$v)
                        },
                        expression: "styleInfo['color']"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isShow("UI_backgroundColorPicker")
            ? _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
                  _vm._v("背景顏色")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-7" },
                  [
                    _c("MyColorPicker", {
                      model: {
                        value: _vm.styleInfo["background-color"],
                        callback: function($$v) {
                          _vm.$set(_vm.styleInfo, "background-color", $$v)
                        },
                        expression: "styleInfo['background-color']"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isShow("UI_previewPanel")
            ? _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-5", attrs: { for: "" } }, [
                  _vm._v("預覽")
                ]),
                _c("div", { staticClass: "col-sm-7" }, [
                  _c("div", { style: this.convertToCSSString() }, [
                    _vm._v(" 預覽文字 ")
                  ])
                ])
              ])
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.returnModal()
                }
              }
            },
            [_vm._v("儲存")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }