var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MyToolbarVuetify", {
        ref: "myToolbar",
        staticClass: "bk_toolbar_fixed-top",
        attrs: { "grid-select-ids": _vm.gridSelectIds },
        on: { "button-click": _vm.toolbarButton_Click }
      }),
      _c("div", { staticClass: "bk_firstBlock" }),
      _c("MyVueEasytable", {
        ref: "grid",
        on: { "grid-selected": _vm.grid_Selected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }