var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "table table-hover", attrs: { id: "tblMain" } },
      [
        _c("thead", { staticClass: "thead-light" }, [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.configDefault.Column1Text))]),
            _c("th", [_vm._v(_vm._s(_vm.configDefault.Column2Text))])
          ])
        ]),
        _c(
          "tbody",
          _vm._l(this.datas, function(item, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(" " + _vm._s(item.FieldText) + " ")]),
              _c("td", [
                item.ControlType === "text"
                  ? _c("div", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "" }
                      })
                    ])
                  : item.ControlType === "number"
                  ? _c("div", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "number", placeholder: "" }
                      })
                    ])
                  : item.ControlType === "date"
                  ? _c("div", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "date", placeholder: "" }
                      })
                    ])
                  : item.ControlType === "tel"
                  ? _c("div", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "tel", placeholder: "" }
                      })
                    ])
                  : _c("div")
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }