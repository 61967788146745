var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "shoppingOrder" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("訂單編號/來源")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
            _c(
              "label",
              { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
              [
                _vm._v(
                  _vm._s(_vm.formData.OrderId) +
                    "/" +
                    _vm._s(_vm.formData.CLSourceType)
                )
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("會員")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" })
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("發票類型")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "發票類型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLInvoiceType,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLInvoiceType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLInvoiceType", $$v)
                            },
                            expression: "formData.CLInvoiceType"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("付款類型")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "付款類型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLPayType,
                            size: "md",
                            "add-empty-select-item": true,
                            "empty-item-text": "未知",
                            "empty-item-value": ""
                          },
                          model: {
                            value: _vm.formData.CLPayType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLPayType", $$v)
                            },
                            expression: "formData.CLPayType"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("付款狀態")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "付款狀態" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLPayStatus,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLPayStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLPayStatus", $$v)
                            },
                            expression: "formData.CLPayStatus"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("訂單狀態")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "訂單狀態" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLOrderStatus,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLOrderStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLOrderStatus", $$v)
                            },
                            expression: "formData.CLOrderStatus"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("收件人姓名")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "收件人姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.ReceiverName,
                              expression: "formData.ReceiverName"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.ReceiverName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "ReceiverName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("收件人聯絡電話")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "收件人聯絡電話" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.ReceiverTel,
                              expression: "formData.ReceiverTel"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.ReceiverTel },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "ReceiverTel",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("配送方式")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "配送方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLShipType,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLShipType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLShipType", $$v)
                            },
                            expression: "formData.CLShipType"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("送貨地址")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10 form-inline" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.AddressCode1,
                  expression: "formData.AddressCode1"
                }
              ],
              staticClass: "form-control ",
              staticStyle: { width: "100px" },
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.formData.AddressCode1 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "AddressCode1", $event.target.value)
                }
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.AddressCode2,
                  expression: "formData.AddressCode2"
                }
              ],
              staticClass: "form-control ",
              staticStyle: { width: "100px" },
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.formData.AddressCode2 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "AddressCode2", $event.target.value)
                }
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.AddressRoad,
                  expression: "formData.AddressRoad"
                }
              ],
              staticClass: "form-control ",
              staticStyle: { width: "100%" },
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.formData.AddressRoad },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "AddressRoad", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("配送狀態")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "配送狀態" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("MyCodeListButtonRadio", {
                          attrs: {
                            options: _vm.$u.UCode_CLShipStatus,
                            size: "md"
                          },
                          model: {
                            value: _vm.formData.CLShipStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CLShipStatus", $$v)
                            },
                            expression: "formData.CLShipStatus"
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("出貨日")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "出貨日" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.ShipDt,
                              expression: "formData.ShipDt"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "date", placeholder: "" },
                          domProps: { value: _vm.formData.ShipDt },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "ShipDt",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("訂單金額")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "訂單金額" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.formData.TotalAmount,
                              expression: "formData.TotalAmount",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "number", placeholder: "" },
                          domProps: { value: _vm.formData.TotalAmount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "TotalAmount",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("備註")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.Memo,
                  expression: "formData.Memo"
                }
              ],
              staticClass: "form-control",
              attrs: { rows: "3" },
              domProps: { value: _vm.formData.Memo },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "Memo", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("h2", [_vm._v("訂購內容")]),
        _c("table", { staticClass: "table table-hover" }, [
          _c("thead", { staticClass: "thead-light" }, [
            _c("tr", [
              _c("th", { staticClass: "text-center" }, [_vm._v("項目")]),
              _c("th", [_vm._v("單價")]),
              _c("th", [_vm._v("數量")])
            ])
          ]),
          _c(
            "tbody",
            _vm._l(_vm.formData.Details, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "text-left" }, [
                  _vm._v(" " + _vm._s(item.ProductText) + " ")
                ]),
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(item.UnitPrice) + " ")
                ]),
                _c("td", { staticClass: "text-left" }, [
                  _vm._v(" " + _vm._s(item.Quantity) + " ")
                ])
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }