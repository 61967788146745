var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._l(_vm.myWebPart.PartImages, function(image, index) {
        return _c("span", { key: index }, [
          _c("div", { staticClass: "designerContainer hover12" }, [
            _c("figure", [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.toDownloadLink(false, image.ImageUrl) }
              })
            ]),
            _c(
              "div",
              { staticClass: "toolbar" },
              [
                _c("FileUploadButton", {
                  attrs: {
                    "emmit-back-param": image,
                    "upload-type": _vm.$u.UBossUploadType_OnePage
                  },
                  on: { uploadSuccess: _vm.uploadSuccess }
                }),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.remove(image.ImageUrl)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.moveUp(image.ImageUrl)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-up" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.moveDown(image.ImageUrl)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-down" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.setting(image)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-cog" })]
                )
              ],
              1
            )
          ])
        ])
      }),
      _c("span", [
        _c("div", { staticClass: "designerContainer hover12" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "toolbar" },
            [
              _c("FileUploadButton", {
                attrs: {
                  "emmit-back-param": undefined,
                  "upload-type": _vm.$u.UBossUploadType_OnePage
                },
                on: { uploadSuccess: _vm.uploadSuccess }
              })
            ],
            1
          )
        ])
      ]),
      _c("WebPartImageSetting", { ref: "setting" })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: "/ubossOnePage/_imageOnePage/1024x700.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }