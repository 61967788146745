var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.timeType === this.$onePageUtil.OnePage_TimeType_Design
        ? _c("div", [
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.footerSetting }
              },
              [_c("i", { staticClass: "fa fa-cog" })]
            )
          ])
        : _vm._e(),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.Footer.HtmlContent) } }),
      _vm.Footer
        ? _c("COM_Footer_Setting", {
            ref: "footerSetting",
            attrs: { footer: _vm.Footer, "page-id": _vm.pageId }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }