var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { size: "s", "hide-footer": "", title: "" } },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("詳細")])
          ]),
          _vm._v(" 欄位精簡排列 服務以一行顯示 "),
          _c("hr"),
          _c("table", { staticClass: "table table-hover" }, [
            _c("thead", { staticClass: "thead-light" }, [
              _c("tr", [_c("th", [_vm._v("欄位")]), _c("th", [_vm._v("值")])])
            ]),
            _c("tbody", [
              _c("tr", [_c("td"), _c("td", { staticClass: "text-left" })])
            ])
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }