<template>
  <div>

    <h1>播放方式</h1>
    <div class="form-check">
      <input class="form-check-input" :name="'rg1'+this._uid" type="radio" :id="'r1'+this._uid" value="1" v-model.number="Marquee.MediaPlayType">
      <label class="form-check-label" :for="'r1'+this._uid">
        依順序播放
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" :name="'rg1'+this._uid" type="radio" :id="'r2'+this._uid" value="2" v-model.number="Marquee.MediaPlayType">
      <label class="form-check-label" :for="'r2'+this._uid">
        隨機播放
      </label>
    </div>

<!--    <h1>方向</h1>-->
<!--    <div class="form-check">-->
<!--      <input class="form-check-input" :name="'rg2'+this._uid" type="radio" :id="'r3'+this._uid" value="1" v-model.number="Marquee.MediaPlayDir">-->
<!--      <label class="form-check-label" :for="'r3'+this._uid">-->
<!--        左->右-->
<!--      </label>-->
<!--    </div>-->
<!--    <div class="form-check">-->
<!--      <input class="form-check-input" :name="'rg2'+this._uid" type="radio" :id="'r4'+this._uid" value="2" v-model.number="Marquee.MediaPlayDir">-->
<!--      <label class="form-check-label" :for="'r4'+this._uid">-->
<!--        右->左-->
<!--      </label>-->
<!--    </div>-->


    <h1>轉速</h1>
    <div >
      <input type="number" class="form-control short" id="name" name="name" placeholder="" v-model.number="Marquee.PlaySpeed"> (預設 100)
    </div>

    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
    <table class="table table-hover" id="tblMain2">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th class="text-center">啟/停用</th>
        <th>播放內容</th>
        <th>播放設定</th>
        <th>備註</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.Marquee.Items" :key="index">
        <td class="text-center">
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td width="120px">
          <button class="btn btn-primary" >啟用</button>
        </td>
        <td>
          <input type="text" class="form-control" placeholder="" v-model="item.MarqueeText">
        </td>
        <td>
          字型格式
          <MyCSSPicker v-model="item.MarqueeStyleCSS" :setting="cssPickerSetting"></MyCSSPicker>
        </td>
        <td>
          <input type="text" class="form-control" placeholder="" v-model="item.Memo">
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";
import MyCSSPicker from "@/pages/commonComponent/MyCSSPicker";


export default {
  components: {MyCSSPicker},
  //name: "EditMarquee",
  props: ["Marquee"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      // Marquee:{
      //   SystemId:"",
      //   MediaPlayType:1,
      //   MediaPlayDir:1,
      //   PlaySpeed:10,
      //   Items:[
      //   ]
      // },
      MarqueeDemo:{
        SystemId:"",
        MediaPlayType:1,
        MediaPlayDir:1,
        PlaySpeed:10,
        Items:[
          {
            SystemId: "",
            OrderKey:"",
            IsEnable:true,
            MarqueeText:"1010 特惠xxxxxxxxxxxxxxxxxxxx",
            MarqueeStyleCSS:"",
            Memo:""
          },
        ]
      },
      cssPickerSetting:{
        UI_fontSizeTextbox:false,
        UI_fontSizeSelect:true,
      }
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.Marquee.Items,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.Marquee.Items,"SystemId","OrderKey")
    },
    DataItem_AddLast(){
      let newItem={
            SystemId: "",
            OrderKey:"999",
            IsEnable:true,
            MarqueeText:"",
            MarqueeStyleCSS:"",
            Memo:""
          };
      newItem.SystemId=util.uuid();

      arrayUtil.orderAppendLast(newItem,this.Marquee.Items,"OrderKey")
    },
    DataItem_Remove(item){
      this.Marquee.Items= this.Marquee.Items.filter(s => s.SystemId !==item.SystemId);
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>
<style scoped>
#tblMain{
  width: 1500px;
}
#tblMain2{
  width: 1500px;
}
.btn{
  color: white !important;
  margin: 5px;
  /*width: px;*/
}
.short{
  width:80px;display: inline;
}
</style>
