<template>
  <div>
    <COM_HeaderNav mode="onePageAdv"></COM_HeaderNav>

    <div class="form-group row" style="margin-top: 50px">
      <div class="col-sm-10">
        <a class="btn btn-primary mybutton" href="#" @click.prevent="submit" style="width: 100px"><i class="fa fa-save"></i>  儲存</a>
        <a class="btn btn-primary mybutton" href="#" @click.prevent="PreviewCName_Current" style="width: 100px"><i class="fa fa-eye"></i>預覽</a>
      </div>
    </div>

    <a class="btn btn-primary" @click="DataItem_Page_AddLast"><i class="fas fa-plus-circle"></i>新增頁面</a>

    <table class="table table-hover" id="tblSecond">
      <thead class="thead-light">
      <tr>
        <th>功能</th>
        <th>名稱</th>
        <th>設為首頁</th>
        <th>是否顯示</th>
<!--        <th>說明</th>-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.currentCName.Pages" :key="index">
        <td class="" >
          <a class="btn btn-primary" @click="DataItem_Page_Edit(item)"><i class="fa fa-pen"></i>編輯頁面</a>
          <a class="btn btn-danger" @click="DataItem_Page_Remove(item)"><i class="fa fa-trash"></i></a>
          <a class="btn btn-primary" @click="DataItem_Page_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_Page_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td >
          <input type="text" class="form-control" placeholder="" v-model="item.Name">
        </td>
        <td >
          <span @click="setHomePage(item)">
          <MyButtonBoolean v-model="item.IsHomePage" true-text="首頁" false-text="子頁面" ></MyButtonBoolean>
          </span>
        </td>
        <td >
          <MyButtonIsVisible v-model="item.IsVisible"></MyButtonIsVisible>
        </td>
<!--        <td >-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.Memo">-->
<!--        </td>-->
      </tr>

      </tbody>
    </table>



  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyButtonIsVisible from "@/pages/commonComponent/MyButtonIsVisible.vue";
import MyButtonBoolean from "@/pages/commonComponent/MyButtonBoolean.vue";

let configEditPage="OnePageAdvEdit4";

export default {
  components: {MyButtonBoolean, MyButtonIsVisible, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      datas:null,
      currentCName:null,
      ready:false,
    };
  },
  computed:{
    // ModeText(){
    //   return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    // }
  },
  methods: {
    async loadData() {
      //QQADV
      let data=await apiUBossOnePageBk.api_Get_OnePageAdv()
      //
      // this.datas=data
      if (data && data.CNames.length>0){
        this.datas=data;
      }else{
        this.datas={
          SystemId:util.uuid(),
          CNames:[],
        };

        this.datas.CNames.push({
          SystemId:util.uuid(),
          CName:"www",
          Memo:"",
          Pages:[],
        })
      }

      this.currentCName=this.datas.CNames[0];//☆

      this.ready=true;
    },
    async submit(){
      for (let i = 0; i < this.datas.CNames.length; i++) {
        let item = this.datas.CNames[i];

        if (item.CName===""){
          util.showErrorMessage("第"+(i+1)+" 行，「CName名稱」為必填欄位")
          return;
        }

        //pages
        let hasHomePage=item.Pages.filter(s=>s.IsHomePage).length>0;
        let hasVisible=item.Pages.filter(s=>s.IsHomePage).length>0;

        if (!hasHomePage){
          util.showErrorMessage("CName名稱「" + item.CName +"」，「頁面」必須要有一個首頁")
          return;
        }

        if (!hasVisible){
          util.showErrorMessage("CName名稱「" + item.CName +"」，「頁面」必須要有一個顯示的頁面")
          return;
        }

      }

      let systemId=await apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas);
      this.datas.SystemId=systemId;
      util.ShowMessage('儲存成功');

    },
    PreviewCName(item){
      let routeData = this.$router.resolve({name: 'OnePagePreview'});
      //QQADV
      window.open("http://127.0.0.1:9007"+routeData.href + "?systemId=" +'' +"&layoutType="+ '1' +"&onePageAdvId="+ this.datas.SystemId +"&cnameSystemId="+ item.SystemId , '_blank');
    },
    PreviewCName_Current(){
      let routeData = this.$router.resolve({name: 'OnePagePreview'});
      let cnameSystemId=this.currentCName.SystemId;
      window.open("https://biz.uboss.cc"+routeData.href + "?systemId=" +'' +"&layoutType="+ '1' +"&onePageAdvId="+ this.datas.SystemId +"&cnameSystemId="+ cnameSystemId , '_blank');
      // window.open("http://127.0.0.1:9007"+routeData.href + "?systemId=" +'' +"&layoutType="+ '1' +"&onePageAdvId="+ this.datas.SystemId +"&cnameSystemId="+ cnameSystemId , '_blank');
    },
    DataItem_Page_AddLast(){
      let hasHomePage=this.currentCName.Pages.filter(s=>s.IsHomePage).length>0;

      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsHomePage:!hasHomePage,
        IsVisible:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.currentCName.Pages,"OrderKey")
    },
    DataItem_Page_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_Page_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.currentCName.Pages,"SystemId","OrderKey")
    },
    DataItem_Page_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.currentCName.Pages,"SystemId","OrderKey")
    },
    DataItem_Page_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.currentCName.Pages= this.currentCName.Pages.filter(s => s.SystemId !==item.SystemId);
    },
    setHomePage(item){
      for (let i = 0; i < this.currentCName.Pages.length; i++) {
        let item2 = this.currentCName.Pages[i];
        item2.IsHomePage=false;
      }

      item.IsHomePage=true;
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
  .active{
    background-color: #1abc9c;
  }
</style>
