var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "center" }, [
    _c(
      "div",
      {},
      [
        _vm._v(" stepName:" + _vm._s(_vm.stepName) + " "),
        _vm.stepName === "Test"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return [
                            _c("a", { staticClass: "navbar-brand link" }, [
                              _vm._v("標誌")
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return [
                            _c("ol", { staticClass: "breadcrumb" }, [
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c("a", { staticClass: "link" }, [
                                  _vm._v("首頁")
                                ])
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c("a", { staticClass: "link" }, [
                                  _vm._v("分類")
                                ])
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "breadcrumb-item active",
                                  attrs: { "aria-current": "page" }
                                },
                                [_vm._v("目前頁面")]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return [
                            _c("h1", [_vm._v("歡迎訪問我們的手機網頁")]),
                            _c("p", [
                              _vm._v(
                                "這是一個基於Bootstrap 4的示例，包括頂部導覽、麵包屑、資訊部分和底部工具列。"
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return [
                            _c("a", { staticClass: "link" }, [_vm._v("首頁")]),
                            _c("a", { staticClass: "link" }, [_vm._v("關於")]),
                            _c("a", { staticClass: "link" }, [
                              _vm._v("聯絡我們")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    17325845
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "SiteList"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return [
                            _c("a", { staticClass: "navbar-brand link" }, [
                              _vm._v("標誌")
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return [
                            _c("ol", { staticClass: "breadcrumb" }, [
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { role: "button", href: "#" }
                                  },
                                  [_c("i", { staticClass: "fa fa-home" })]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("home")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.title("H", "首頁")))]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("onepageadv")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.title("進階", "官網進階"))
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "breadcrumb-item active",
                                  attrs: { "aria-current": "page" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.title("網站列表", "網站列表"))
                                  )
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return [
                            _c("table", { staticClass: "table table-hover" }, [
                              _c(
                                "tbody",
                                _vm._l(_vm.sites, function(site, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [
                                      _c("i", { staticClass: "fa fa-bars" })
                                    ]),
                                    _c("td", { staticClass: "text-left" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          on: {
                                            click: function($event) {
                                              return _vm.gotoStep("PageList")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(site.name))]
                                      )
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function($event) {
                                              return _vm.showModal_dialogWebPage()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-ellipsis-v"
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v("新增一個網站"),
                                  _c("i", { staticClass: "fa fa-arrow-right" })
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return [
                            _c("a", { staticClass: "link" }, [_vm._v("首頁")]),
                            _c("a", { staticClass: "link" }, [_vm._v("關於")]),
                            _c("a", { staticClass: "link" }, [
                              _vm._v("聯絡我們")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3165267629
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "SiteEdit"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return [
                            _c("ol", { staticClass: "breadcrumb" }, [
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("home")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.title("H", "首頁")))]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("onepageadv")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.title("進階", "官網進階"))
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "breadcrumb-item active",
                                  attrs: { "aria-current": "page" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.title("網站編輯", "網站編輯"))
                                  )
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(" 請輸入網站名稱 ")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data_siteName,
                                  expression: "data_siteName"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: _vm.data_siteName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.data_siteName = $event.target.value
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.previousStep()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v("上一步")
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v("下一步"),
                                  _c("i", { staticClass: "fa fa-arrow-right" })
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3206128237
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "SiteEditReadOnly"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-left" }, [
                              _vm._v(
                                " 網站名稱:" + _vm._s(_vm.data_siteName) + " "
                              )
                            ]),
                            _c("div", { staticClass: "text-right" })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.previousStep()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v("上一步")
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v("新增網頁"),
                                  _c("i", { staticClass: "fa fa-arrow-right" })
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    4200712268
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "PageList"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return [
                            _c("ol", { staticClass: "breadcrumb" }, [
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("home")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.title("H", "首頁")))]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("onepageadv")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.title("進階", "官網進階"))
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("sitelist")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.title("網站(www)", "網站(www)")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "breadcrumb-item active",
                                  attrs: { "aria-current": "page" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.title("網頁列表", "網頁列表"))
                                  )
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-left" }, [
                              _vm._v(
                                " 網站名稱:" + _vm._s(_vm.data_siteName) + " "
                              )
                            ]),
                            _c("div", { staticClass: "text-right" })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return [
                            _c("table", { staticClass: "table table-hover" }, [
                              _c(
                                "tbody",
                                _vm._l(_vm.pages, function(page, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [
                                      _c("i", { staticClass: "fa fa-bars" })
                                    ]),
                                    _c("td", { staticClass: "text-left" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          on: {
                                            click: function($event) {
                                              return _vm.gotoStep(
                                                "PageDesigner"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(page.name))]
                                      )
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function($event) {
                                              return _vm.showModal_dialogWebPage()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-ellipsis-v"
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.previousStep()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v("上一步")
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v("新增網頁"),
                                  _c("i", { staticClass: "fa fa-arrow-right" })
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1518035570
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "PageEdit1"
          ? _c(
              "div",
              [
                _c("MobileUILayout", {
                  attrs: { "show-logo": false, "show-bottom-bar": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Logo",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      },
                      {
                        key: "Breadcrumb",
                        fn: function() {
                          return [
                            _c("ol", { staticClass: "breadcrumb" }, [
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("home")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.title("H", "首頁")))]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("onepageadv")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.title("進階", "官網進階"))
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "breadcrumb-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("sitelist")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.title("網站(www)", "網站(www)")
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "li",
                                {
                                  staticClass: "breadcrumb-item active",
                                  attrs: { "aria-current": "page" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.title("網頁編輯", "網頁編輯"))
                                  )
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "HeaderBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-left" }, [
                              _vm._v(
                                " 網站名稱:" + _vm._s(_vm.data_siteName) + " "
                              )
                            ]),
                            _c("div", { staticClass: "text-right" })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "Body",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(" 請輸入網頁名稱 ")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data_pageName,
                                  expression: "data_pageName"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: _vm.data_pageName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.data_pageName = $event.target.value
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BodyBar",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.previousStep()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-right" }),
                                  _vm._v("上一步")
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-lg",
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [
                                  _vm._v("下一步"),
                                  _c("i", { staticClass: "fa fa-arrow-right" })
                                ]
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "BottomBar",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2279835390
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.stepName === "PageDesigner"
          ? _c("div", [
              _vm._v(" onepage design page "),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    on: {
                      click: function($event) {
                        return _vm.previousStep()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v("上一步")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    on: {
                      click: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  },
                  [
                    _vm._v("下一步"),
                    _c("i", { staticClass: "fa fa-arrow-right" })
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm.stepName === 6 ? _c("div") : _vm._e(),
        _c(
          "b-modal",
          {
            ref: "dialogWebPage",
            attrs: { size: "s", "hide-footer": "", title: "" }
          },
          [
            _c("div", { staticClass: "d-block text-center" }, [
              _c("h3", [_vm._v("網頁設定")])
            ]),
            _c("table", { staticClass: "table table-hover" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _c("i", { staticClass: "fa fa-home" }),
                      _vm._v("設為首頁")
                    ])
                  ])
                ]),
                _c("tr", [
                  _c("td", [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _c("i", { staticClass: "fa fa-eye" }),
                      _vm._v("是否顯示")
                    ])
                  ])
                ]),
                _c("tr", [
                  _c("td", [
                    _c("button", { staticClass: "btn btn-danger" }, [
                      _c("i", { staticClass: "fa fa-trash" }),
                      _vm._v("刪除")
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }