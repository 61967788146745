import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import mainUBoss from './mainUBoss'
import mainUBoss_My from './mainUBoss_My'
import mainUBoss_OnePage from './mainUBoss_OnePage'

mainUBoss.run(Vue)
mainUBoss_My.run(Vue)
mainUBoss_OnePage.run(Vue)

import vuetify from '../../plugins/vuetify';

new Vue({
  data: function() {
    return {
      data:"aa"
    };
  },
  computed: {
    bodyClass() {
      return this.$route.name;
    }
  },
  router,
  vuetify,
  store,
  render: h => h(App),
  created: function() {
  },
  mounted () {
    if (this.$route.name==="Index"){
      document.body.classList.add('path-front')
    }else{
      document.body.classList.add('path-content')
    }

  },
}).$mount('#wrapper')
