
<template>
  <div style="max-width: 1200px">
    <MyFormPanel ref="form" :data-source="formData">
      <div slot="testSlot">
        <button class="btn btn-primary" >送出</button>
      </div>
    </MyFormPanel>
  </div>
</template>

<script>
import {MyFormPanelForm,MyFormPanelPanel,MyFormPanelField,MyFormPanelFieldUITypeEnum} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";
import MyFormPanel from "@/pages/commonComponent/MyFormPanel/MyFormPanel.vue";

export default {
  components: {MyFormPanel},
  props: [],
  data: function () {
    return {
      formData:{
        name:"taylor",
        tel:"0915071457",
        address:"桃園市中壢區福州路130號",
      }
    };
  },
  mounted() {
    //Doc
    //form
    //  panel
    //    row
    //      field

    //---------------------------------------
    //Fields
    //---------------------------------------
    let fName=new MyFormPanelField("姓名","name");
    fName.type=MyFormPanelFieldUITypeEnum.input;
    fName.require=true;

    fName.style="";
    fName.slot="testSlot";
    fName.fieldClassName="col-sm-3";

    let fTel=new MyFormPanelField("電話","tel");
    fTel.type=MyFormPanelFieldUITypeEnum.inputNumberOnly;
    fTel.require=true;

    let fAddress=new MyFormPanelField("地址","address");
    fAddress.type=MyFormPanelFieldUITypeEnum.textarea;
    fAddress.require=true;

    let fMemo=new MyFormPanelField("備註","memo");
    fMemo.type=MyFormPanelFieldUITypeEnum.textarea;
    fMemo.require=true;

    //---------------------------------------
    //Panels
    //---------------------------------------
    let myFormPanel1=new MyFormPanelPanel("");
    myFormPanel1.addRow()
        .addFields(6,fName)
        .addFields(6,fTel)
    ;

    myFormPanel1.addRow()
        .addFields(12,fAddress)
        .addFields(12,fMemo)
    ;


    let myFormPanel2=new MyFormPanelPanel("");
    myFormPanel2.addRow()

    ;


    //---------------------------------------
    //Form
    //---------------------------------------
    let myForm=new MyFormPanelForm();

    myForm.addPanel(myFormPanel1).addPanel(myFormPanel2);

    //---------------------------------------

    this.$refs.form.setPropFormDefine(myForm);

  }

}
</script>



<style scoped>

</style>
