<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="下載QRCode">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

      <div class="form-group row" v-if="isShow('UI_fontSizeTextbox')">
        <label for="" class="col-sm-5">字型大小</label>
        <div class="col-sm-7 form-inline">
          <input type="number" class="form-control" v-model="styleInfo['font-size']" placeholder="" style="width:100px"> <label>點(pt)</label>
        </div>
      </div>
      <div class="form-group row" v-if="isShow('UI_fontSizeSelect')">
        <label for="" class="col-sm-5">字型大小</label>
        <div class="col-sm-7 form-inline">
          <select class="form-control" v-model="styleInfo['font-size']">
            <option></option>
            <option value="50">大</option>
            <option value="40">中</option>
            <option value="30">小</option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-if="isShow('UI_colorPicker')">
        <label for="" class="col-sm-5">字型顏色</label>
        <div class="col-sm-7">
          <MyColorPicker v-model="styleInfo['color']"></MyColorPicker>
        </div>
      </div>

      <div class="form-group row" v-if="isShow('UI_backgroundColorPicker')">
        <label for="" class="col-sm-5">背景顏色</label>
        <div class="col-sm-7">
          <MyColorPicker v-model="styleInfo['background-color']"></MyColorPicker>
        </div>
      </div>

      <div class="form-group row" v-if="isShow('UI_previewPanel')">
        <label for="" class="col-sm-5">預覽</label>
        <div class="col-sm-7">
          <div :style="this.convertToCSSString()">
            預覽文字
          </div>

        </div>

      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="returnModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>

<!--    //<v-input-colorpicker  v-model="propsValue" style="border: black;border-style:solid;width:100px"/>-->
  </div>
</template>

<script>

//use <MyCSSPickerDlg ref="myCSSPickerDlg" v-model="propsValue" v-on:returnOk="dialogReturnOK"></MyCSSPickerDlg>
//use <MyCSSPickerDlg ref="myCSSPickerDlg" v-model="propsValue" v-on:returnOk="dialogReturnOK"></MyCSSPickerDlg>
//use this.$refs.myCSSPickerDlg.showModal();
//setting
//{
//  "UI_fontSizeTextbox": true,
//  "UI_fontSizeSelect": true,
//}

import MyColorPicker from "@/pages/commonComponent/MyColorPicker";
export default {
  components: {MyColorPicker},
  // name: "MyColorPicker",
  props: ["value","setting"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
      showThis:false,
      styleInfo:[],
      settingRuntime:{    //預設值
        UI_fontSizeTextbox:true,
        UI_fontSizeSelect:false,
        UI_colorPicker:true,
        UI_backgroundColorPicker:true,
        UI_previewPanel:true,
      },
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    returnModal(value) {
      // this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk',value);
      this.$refs['my-modal'].hide();
    },
    convertToCSSString(){
      let css="";
      Object.entries(this.styleInfo).forEach(([key, value]) => {
        if (value){
          if (key==='font-size'){
            css+=key+":"+value+"pt;";
          }else{
            css+=key+":"+value+";";
          }
        }
      });

      this.propsValue=css;
      // this.value=css;
      return css;
    },
    convertCSSStringToValue(){
      let that=this;
      this.styleInfo=[];
      let cssStr=this.value;
      let arrStyle = cssStr.split(";");


      arrStyle.forEach((styleItem)=>{
        let aCSSItem=styleItem.split(":");

        if (aCSSItem[0]==="font-size"){
          that.styleInfo[aCSSItem[0]]=aCSSItem[1].replace("pt","");
        }else{
          that.styleInfo[aCSSItem[0]]=aCSSItem[1];
        }
      })
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    isShow(settingKey){

      if (this.settingRuntime[settingKey]===undefined){
        return true;//預設顯示
      }else{
        //使用設定值
        if (this.settingRuntime[settingKey]){
          return true;
        }else{
          return false;
        }
      }
    }
  },
  mounted() {
    //this.loadData()
    Object.entries(this.setting).forEach(([key, value]) => {
      this.settingRuntime[key]=value;
    });

    this.convertCSSStringToValue();
  },

}
</script>

<style scoped>

</style>
