<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingMember"></COM_HeaderNav>
    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">

      <div class="thin-form-group form-group row">
        <label for="" class="a_thinlabel col-sm-2">登入帳號</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="登入帳號" >
            <input type="text" class="form-control " placeholder="" v-model="formData.LoginId">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="thin-form-group form-group row">
        <label for="" class="a_thinlabel col-sm-2">姓名</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="姓名" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Name">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">Email</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="Email" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Email">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">電話</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="電話" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Tel">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";

export default {
  components: {COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {

      },
      formDataDemo: {
        "LoginId":"",
        "Name":"",
        "Email":"",
        "Tel":"",
      },
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossShopBk.api_Member_Get(this.systemId)
        this.formData=data
      }
    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>