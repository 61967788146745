var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-bootstrap-table", {
        attrs: {
          columns: _vm.columns,
          values: _vm.rows,
          "show-filter": true,
          sortable: true,
          paginated: true,
          "show-column-picker": false,
          selectable: _vm.showCheckboxColumn,
          "multi-column-sortable": true,
          "filter-case-sensitive": false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }