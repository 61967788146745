var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "shoppingProduct" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c(
        "ValidationObserver",
        { ref: "form" },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "基本資料", active: "" } },
                    [
                      _c("b-card-text", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("商品貨號")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "", name: "商品貨號" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.formData.ProductCode,
                                              expression: "formData.ProductCode"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.ProductCode
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "ProductCode",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("商品名稱")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "required", name: "商品名稱" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.formData.Name,
                                              expression: "formData.Name"
                                            }
                                          ],
                                          staticClass: "form-control ",
                                          attrs: {
                                            type: "text",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.Name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "Name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("簡稱")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "", name: "簡稱" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.formData.SName,
                                              expression: "formData.SName"
                                            }
                                          ],
                                          staticClass: "form-control ",
                                          attrs: {
                                            type: "text",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.SName
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "SName",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("訂價")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "required", name: "訂價" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: _vm.formData.PriceSuggest,
                                              expression:
                                                "formData.PriceSuggest",
                                              modifiers: { number: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.PriceSuggest
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "PriceSuggest",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("售價")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "required", name: "售價" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: _vm.formData.Price,
                                              expression: "formData.Price",
                                              modifiers: { number: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.Price
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "Price",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("成本價")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "required", name: "成本價" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: _vm.formData.PriceCost,
                                              expression: "formData.PriceCost",
                                              modifiers: { number: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            placeholder: ""
                                          },
                                          domProps: {
                                            value: _vm.formData.PriceCost
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "PriceCost",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("狀態")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "required", name: "狀態" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("MyCodeListButtonRadio", {
                                          attrs: {
                                            options:
                                              _vm.$u.UCode_CLProductStatus,
                                            size: "md"
                                          },
                                          model: {
                                            value: _vm.formData.CLProductStatus,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "CLProductStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.CLProductStatus"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("標籤")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("MyTagInput", {
                                attrs: { "only-existing-tags": false },
                                model: {
                                  value: _vm.formData.Tags,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "Tags", $$v)
                                  },
                                  expression: "formData.Tags"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm.$c.getCustomizeConfig().ProductOptions_Enable
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "a_thinlabel col-sm-2",
                                  attrs: { for: "" }
                                },
                                [_vm._v("選項")]
                              ),
                              _c(
                                "div",
                                { staticClass: "a_thinInputDiv col-sm-10" },
                                [
                                  _c("ProductOptionSetting", {
                                    ref: "productOptionSetting",
                                    staticStyle: { "max-width": "750px" }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.$c.getCustomizeConfig().ProductOptions_IsCustomize
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "a_thinlabel col-sm-2",
                                  attrs: { for: "" }
                                },
                                [_vm._v("選項")]
                              ),
                              _c(
                                "div",
                                { staticClass: "a_thinInputDiv col-sm-10" },
                                [
                                  _c("ProductOptionSelectorPpd", {
                                    ref: "productOptionSelectorPpd",
                                    staticStyle: { "max-width": "750px" },
                                    attrs: { "is-in-setting": true },
                                    model: {
                                      value:
                                        _vm.formData.CustValue.ProductOption,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.CustValue,
                                          "ProductOption",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.CustValue.ProductOption"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.$c.getCustomizeConfig().ProductExtValue_Enable
                          ? _c("span", [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "a_thinlabel col-sm-2",
                                    attrs: { for: "" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$c.getCustomizeConfig()
                                          .ProductExtValue_LabelText
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "a_thinInputDiv col-sm-10" },
                                  [
                                    _c("PpdDLGDrinkMaterial", {
                                      model: {
                                        value:
                                          _vm.formData.CustValue.DrinkRecipe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.CustValue,
                                            "DrinkRecipe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.CustValue.DrinkRecipe"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "a_thinlabel col-sm-2",
                              attrs: { for: "" }
                            },
                            [_vm._v("備註")]
                          ),
                          _c(
                            "div",
                            { staticClass: "a_thinInputDiv col-sm-10" },
                            [
                              _c("validation-provider", {
                                attrs: { rules: "", name: "備註" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.formData.Memo,
                                              expression: "formData.Memo"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { rows: "3" },
                                          domProps: {
                                            value: _vm.formData.Memo
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.formData,
                                                "Memo",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "error_msg" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "圖片" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("FileUploadList", {
                            attrs: {
                              "emmit-back-param": "undefined",
                              "owner-system-id": this.systemId,
                              "file-use-memo": "productImage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "商品簡述" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("MyCkEditor", {
                            ref: "myCkEditor1",
                            attrs: {
                              height: "200",
                              width: "100%",
                              "file-upload-list-lite_is-show": true,
                              "file-upload-list-lite_owner-system-id":
                                _vm.systemId,
                              "file-upload-list-lite_file-use-memo": this.$u
                                .UBossFileUseType_ProductSummary
                            },
                            model: {
                              value: _vm.formData.Summary,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Summary", $$v)
                              },
                              expression: "formData.Summary"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "商品詳細說明" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("MyCkEditor", {
                            ref: "myCkEditor2",
                            attrs: {
                              height: "200",
                              width: "100%",
                              "file-upload-list-lite_is-show": true,
                              "file-upload-list-lite_owner-system-id":
                                _vm.systemId,
                              "file-upload-list-lite_file-use-memo": this.$u
                                .UBossFileUseType_ProductDesc
                            },
                            model: {
                              value: _vm.formData.Desc,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Desc", $$v)
                              },
                              expression: "formData.Desc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "預約設定" } },
                    [
                      _c("b-card-text", [
                        _vm._v(" 選擇日曆 "),
                        _c(
                          "select",
                          {
                            staticClass: "form-control",
                            attrs: { id: "selItems", name: "selItems" }
                          },
                          [_c("option"), _c("option", [_vm._v("taylor日曆")])]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }