var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "btn-toolbar pull-right" }, [
      _c(
        "a",
        { staticClass: "btn btn-primary", on: { click: _vm.DataItem_AddLast } },
        [_c("i", { staticClass: "fas fa-plus-circle" })]
      )
    ]),
    _c(
      "table",
      { staticClass: "table table-hover", attrs: { id: "tblMain" } },
      [
        _c("thead", { staticClass: "thead-light" }, [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
            _c("th", [_vm._v(_vm._s(_vm.configDefault.Column1Text))]),
            _c("th", [_vm._v(_vm._s(_vm.configDefault.Column2Text))]),
            _c("th", [_vm._v(_vm._s(_vm.configDefault.Column3Text))])
          ])
        ]),
        _c(
          "tbody",
          _vm._l(this.datas, function(item, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_Insert(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-plus-circle" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_Remove(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "far fa-trash-alt" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_MoveUp(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-up" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.DataItem_MoveDown(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-angle-down" })]
                )
              ]),
              _c("td", { attrs: { width: "120px" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.FieldText,
                      expression: "item.FieldText"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: item.FieldText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "FieldText", $event.target.value)
                    }
                  }
                })
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.ControlType,
                        expression: "item.ControlType"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          item,
                          "ControlType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option"),
                    _c("option", { attrs: { value: "text" } }, [
                      _vm._v("文字")
                    ]),
                    _c("option", { attrs: { value: "number" } }, [
                      _vm._v("數字")
                    ]),
                    _c("option", { attrs: { value: "date" } }, [
                      _vm._v("日期")
                    ]),
                    _c("option", { attrs: { value: "tel" } }, [_vm._v("電話")])
                  ]
                )
              ]),
              _c("td", [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "custom-control custom-checkbox" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.MustInput,
                            expression: "item.MustInput"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: { type: "checkbox", checked: "" },
                        domProps: {
                          checked: Array.isArray(item.MustInput)
                            ? _vm._i(item.MustInput, null) > -1
                            : item.MustInput
                        },
                        on: {
                          change: function($event) {
                            var $$a = item.MustInput,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(item, "MustInput", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    item,
                                    "MustInput",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(item, "MustInput", $$c)
                            }
                          }
                        }
                      }),
                      _c("div", { staticClass: "custom-control-label" }, [
                        _vm._v("必填 ")
                      ])
                    ]
                  )
                ])
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }