<template>
  <div>
<!--    <button @click="showSingle">Show single picture.</button>-->
<!--    <button @click="showMultiple">Show a group of pictures.</button>-->

    <CoolLightBox
        :items="items"
        :index="index"
        @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>

// <MyLightBox ref="mylightBox"></MyLightBox>
//
// @click="showImage('Data/SiteFiles/7d6e7f88-a509-40fa-a726-5349d1f462d4/defaultSizeS.png')"
//
// toDownloadLink(link){
//   return apiServerUtil.toDownloadLink(link);
// },
//
// showImage(imgUrl){
//   let url=this.toDownloadLink(imgUrl);
//
//   this.$refs.mylightBox.showSingle(url)
// }


import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    CoolLightBox
  },
  data: function () {
    return {
      items: [],
      index: null
    }
  },
  methods: {
    showSingle(imgUrl) {
      this.items=[]
      this.items.push(imgUrl);
      this.index=0
      // or
      // this.imgs = {
      //   title: 'this is a placeholder',
      //   src: 'http://via.placeholder.com/350x150'
      // }
      // this.show()
    },
    showMultiple(imgUrls) {
      this.items=imgUrls;
      this.index=0
      // or
      // this.imgs = [
      //   { title: 'test img', src: 'http://via.placeholder.com/350x150' },
      //   'http://via.placeholder.com/350x150'
      // ]
      // allow mixing
      //
      // this.index = 1 // index of imgList
      // this.show()
    },
    show() {
      // this.visible = true
    },
    handleHide() {
      // this.visible = false
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>