var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-input-colorpicker", {
        staticStyle: {
          border: "black",
          "border-style": "solid",
          width: "100px"
        },
        model: {
          value: _vm.propsValue,
          callback: function($$v) {
            _vm.propsValue = $$v
          },
          expression: "propsValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }