var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bk_header_fixed-top c-subheader justify-content-between px-3"
    },
    [
      _c("div", { staticClass: "scrollmenu" }, [
        _vm.mode === "onePage"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              )
            ])
          : _vm._e(),
        _vm.mode === "onePageAdv"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              )
            ])
          : _vm.mode === "uformDefine"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active ",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UFormIndex")
                    }
                  }
                },
                [_vm._v("表單")]
              )
            ])
          : _vm.mode === "uformData"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav ",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UFormIndex")
                    }
                  }
                },
                [_vm._v("表單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active ",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UFormDataIndex")
                    }
                  }
                },
                [_vm._v("表單回傳")]
              )
            ])
          : _vm.mode === "uformData"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UFormIndex")
                    }
                  }
                },
                [_vm._v("表單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UFormDataIndex")
                    }
                  }
                },
                [_vm._v("回填資料")]
              )
            ])
          : _vm.mode === "shoppingOrder"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OrderIndex")
                    }
                  }
                },
                [_vm._v("訂單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("ProductIndex")
                    }
                  }
                },
                [_vm._v("商品")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("MemberIndex")
                    }
                  }
                },
                [_vm._v("會員")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("SettingPanel")
                    }
                  }
                },
                [_vm._v("設定")]
              )
            ])
          : _vm.mode === "shoppingProduct"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OrderIndex")
                    }
                  }
                },
                [_vm._v("訂單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("ProductIndex")
                    }
                  }
                },
                [_vm._v("商品")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("MemberIndex")
                    }
                  }
                },
                [_vm._v("會員")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("SettingPanel")
                    }
                  }
                },
                [_vm._v("設定")]
              )
            ])
          : _vm.mode === "shoppingMember"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OrderIndex")
                    }
                  }
                },
                [_vm._v("訂單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("ProductIndex")
                    }
                  }
                },
                [_vm._v("商品")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("MemberIndex")
                    }
                  }
                },
                [_vm._v("會員")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("SettingPanel")
                    }
                  }
                },
                [_vm._v("設定")]
              )
            ])
          : _vm.mode === "shoppingSiteSetting"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OrderIndex")
                    }
                  }
                },
                [_vm._v("訂單")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("ProductIndex")
                    }
                  }
                },
                [_vm._v("商品")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("MemberIndex")
                    }
                  }
                },
                [_vm._v("會員")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("SettingPanel")
                    }
                  }
                },
                [_vm._v("設定")]
              )
            ])
          : _vm.mode === "udmIndex"
          ? _c("span", [
              !_vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: { click: _vm.gotoHome }
                    },
                    [_vm._v("首頁")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMIndex")
                    }
                  }
                },
                [_vm._v("UDM")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMPassCodeIndex")
                    }
                  }
                },
                [_vm._v("PASS Code")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              ),
              _vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goto("Login")
                        }
                      }
                    },
                    [_vm._v("登出")]
                  )
                : _vm._e()
            ])
          : _vm.mode === "UDMPassCodeIndex"
          ? _c("span", [
              !_vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: { click: _vm.gotoHome }
                    },
                    [_vm._v("首頁")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMIndex")
                    }
                  }
                },
                [_vm._v("UDM")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMPassCodeIndex")
                    }
                  }
                },
                [_vm._v("PASS Code")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              ),
              _vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goto("Login")
                        }
                      }
                    },
                    [_vm._v("登出")]
                  )
                : _vm._e()
            ])
          : _vm.mode === "UDMLogIndex"
          ? _c("span", [
              !_vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: { click: _vm.gotoHome }
                    },
                    [_vm._v("首頁")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMIndex")
                    }
                  }
                },
                [_vm._v("UDM")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMPassCodeIndex")
                    }
                  }
                },
                [_vm._v("PASS Code")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("UDMLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              ),
              _vm.isUDMMode()
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btnNav",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goto("Login")
                        }
                      }
                    },
                    [_vm._v("登出")]
                  )
                : _vm._e()
            ])
          : _vm.mode === "TicketIndex"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketIndex")
                    }
                  }
                },
                [_vm._v("票券編輯")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("兌換員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("會員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              )
            ])
          : _vm.mode === "TicketMemberIndex"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketIndex")
                    }
                  }
                },
                [_vm._v("票券編輯")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("兌換員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("會員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              )
            ])
          : _vm.mode === "TicketLogIndex"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketIndex")
                    }
                  }
                },
                [_vm._v("票券編輯")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("兌換員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketMemberIndex")
                    }
                  }
                },
                [_vm._v("會員資料")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav active",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("TicketLogIndex")
                    }
                  }
                },
                [_vm._v("使用記錄")]
              )
            ])
          : _vm.mode === "ADMIndex"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("ADMIndex")
                    }
                  }
                },
                [_vm._v("客戶資料")]
              )
            ])
          : _vm.mode === "OnePageAdv2"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _vm._m(0)
            ])
          : _vm.mode === "OnePageAdv2CName"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OnePageAdvIndex")
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v(" 官網")]
              ),
              _vm._m(1)
            ])
          : _vm.mode === "OnePageAdv2EditStep1"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("OnePageAdvIndex")
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("官網")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.gotoBack()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("CName")]
              ),
              _vm._m(2)
            ])
          : _vm.mode === "ticket2"
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  attrs: { type: "button" },
                  on: { click: _vm.gotoHome }
                },
                [_vm._v("首頁")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  class: _vm.buttonIndex === "1" ? "active" : "",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("Ticket2TicketTypeIndex")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-angle-right" }),
                  _vm._v("票種管理")
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  class: _vm.buttonIndex === "2" ? "active" : "",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("Ticket2TicketPublishIndex")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-angle-right" }),
                  _vm._v("票券發行")
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  class: _vm.buttonIndex === "3" ? "active" : "",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("Ticket2TicketIndex")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-angle-right" }),
                  _vm._v("票券管理")
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btnNav",
                  class: _vm.buttonIndex === "4" ? "active" : "",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goto("Ticket2TicketDesignIndex")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-angle-right" }),
                  _vm._v("票券列印")
                ]
              )
            ])
          : _c("span")
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-primary btnNav active",
        attrs: { type: "button" }
      },
      [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("官網")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-primary btnNav active",
        attrs: { type: "button" }
      },
      [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("CName")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-primary btnNav active",
        attrs: { type: "button" }
      },
      [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("編輯頁面")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }