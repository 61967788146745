var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bk_page_rootDiv" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "TicketLogIndex" } }),
      _vm._v(" - Detail 頁面 - 匯出 (全部欄位，而不僅是grid的欄位) "),
      _c("MyToolbarVuetify", {
        attrs: { config: _vm.toolbarVuetifyConfig, "grid-meta": _vm.gridMeta },
        on: {
          "add-click": _vm.btnAdd_Click,
          "edit-click": _vm.btnEdit_Click,
          "delete-click": _vm.btnDelete_Click
        }
      }),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }