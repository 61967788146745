<template>
  <div >
    <COM_HeaderNav mode="udmIndex"></COM_HeaderNav>

      <MyToolbarBase>
          <div class="btn-group mr-2" role="group" aria-label="First group">
              <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
              <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
          </div>
      </MyToolbarBase>

    <div class="fieldsPanel">
        <ValidationObserver ref="form">

            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">類型</label>
                <div class="a_thinInputDiv col-sm-10">
                    <select class="form-control" v-model="formData.type">
                        <option value="1">常態使用</option>
                        <option value="2">客戶使用</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">描述</label>
                <div class="a_thinInputDiv col-sm-10">
                    <input type="text" class="form-control" placeholder="" v-model="formData.userName">
                </div>
            </div>

            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">PassCode</label>
                <div class="a_thinInputDiv col-sm-10">
                    <input type="text" class="form-control" placeholder=""  v-model="formData.passCode">
                    <a role="button" class="btn btn-primary" @click="GenPassCode()">產生</a>
                </div>
            </div>


            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">有效日期</label>
                <div class="a_thinInputDiv col-sm-10">
                    <div class="d-flex justify-content-between"  style="max-width: 400px">
                        <input type="date" class="form-control" id="name" name="name" placeholder=""  v-model="formData.startDt">
                        ~
                        <input type="date" class="form-control" id="name" name="name" placeholder=""  v-model="formData.expireDt">
                    </div>

                </div>
            </div>
            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">可以使用的UDM</label>
                <div class="a_thinInputDiv col-sm-10">
                    <MyListboxLeftRightKendo v-model="selectValues" :datasource-left="fields"></MyListboxLeftRightKendo>
                </div>
            </div>



        </ValidationObserver>
    </div>

  </div>
</template>

<script>
// import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import MyListboxLeftRightKendo from "@/pages/commonComponent/MyListboxLeftRightKendo.vue";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarBase from "@/pages/commonComponent/MyToolbarBase.vue";
// import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";

export default {
  components: {MyToolbarBase, COM_HeaderNav, MyListboxLeftRightKendo},
  data: function () {
    return {
      systemId: "",
      formData: {
        Fun:"",
        SystemId:"1",
        type:"1",
        userName:"taylor",
        passCode:"zade12aa",
        startDt:"2023-01-01",
        expireDt:"2023-06-30",
        resourceUDM:['全部'],
      },
      selectValues:[],
      fields:["產品一","產品二","產品三"]
    };
  },
  methods: {
    GenPassCode(){
      this.formData.passCode = util.uuid().substring(0,8);
    },
    async loadData() {
      // this.systemId=this.$route.query.systemId;
      //
      // if (this.systemId){
      //   let data=await apiUBossShopBk.api_Member_Get(this.systemId)
      //
      //   this.formData=data
      // }

    },
    btnUpdate(){
      // let router=this.$router
      //
      // this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
      //   if (!isValid) {
      //     let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
      //     util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
      //     return;
      //   } else {
      //     //---------------------------------------
      //     apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
      //       util.ShowMessage("儲存成功","訊息")
      //       router.back()
      //     })
      //     //---------------------------------------
      //   }
      // });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
