export const DB_WebPartDataDemo = []

export const DebugOnePage = false

export default {
    crud_DeleteItem(kendGrid, removeApi) {
        let selectedItems=kendGrid.selectedItemsSystemIds();

        if (selectedItems.length===0){
            alert("請選擇要刪除的資料");
            return;
        }else{
            if (confirm("確定要刪除嗎?")){
                removeApi(selectedItems);
            }else{
                return;
            }
        }
    }
}
