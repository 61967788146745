<template>
  <div>
    <div  class="form-group row" v-for="(item,index) in this.ProductOption.OptionSets.Items" :key="index">
      <label for="" class="col-sm-2">{{item.Text}}</label>
      <div class="col-sm-10">
        <MyButtonRadio v-model="propsValue[item.SystemId]" :options="convertToButtonRadionOption(item.Options)" size="md"  ></MyButtonRadio>
      </div>
    </div>
  </div>
</template>

<script>
import MyButtonRadio from "@/pages/commonComponent/MyButtonRadio";
/**
 <ProductOptionSelector v-model="productSelectOptions"></ProductOptionSelector>

 ProductOptionDefault:{ "temperature": "cool", "sugar": "10", "ice": "3" },

 **/


export default {
  components: {MyButtonRadio},
  props: {
    value: {
      type: Object,
      default: function () {
        return this.ProductOptionDefault
      }
    },
  },
  data: function () {
    return {
      id: "",
      propsValue: this.value,
      ProductOptionDefault:{ "temperature": "cool", "sugar": "10", "ice": "3" },
      ProductOption:{
        IsEnableOptionTable: true,
        OptionSets: {
          Items: [
            {
              SystemId: "temperature",
              OrderKey: "1",
              Text: "溫度",
              Options: [
                {
                  SystemId: "cool",
                  OrderKey: "1",
                  Text: "冰飲",
                },
                {
                  SystemId: "hot",
                  OrderKey: "2",
                  Text: "熱飲",
                },
              ],
            },
            {
              SystemId: "sugar",
              OrderKey: "1",
              Text: "甜度",
              Options: [
                {
                  SystemId: "0",
                  OrderKey: "1",
                  Text: "原味甜",
                },
                {
                  SystemId: "3",
                  OrderKey: "2",
                  Text: "三分",
                },
                {
                  SystemId: "5",
                  OrderKey: "2",
                  Text: "五分",
                },
                {
                  SystemId: "7",
                  OrderKey: "2",
                  Text: "七分",
                },
                {
                  SystemId: "10",
                  OrderKey: "2",
                  Text: "正常",
                },
              ],
            },
            {
              SystemId: "ice",
              OrderKey: "2",
              Text: "冰塊",
              Options: [
                {
                  SystemId: "no",
                  OrderKey: "1",
                  Text: "去冰",
                },
                {
                  SystemId: "1",
                  OrderKey: "1",
                  Text: "微冰",
                },
                {
                  SystemId: "2",
                  OrderKey: "1",
                  Text: "少冰",
                },
                {
                  SystemId: "3",
                  OrderKey: "1",
                  Text: "正常",
                },
              ],
            },
          ]
        },
        OptionTable: {
          Items: [
          ]
        }
      },
    };
  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      debugger // eslint-disable-line
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    convertToButtonRadionOption(systemOptions){
      let options=[];

      systemOptions.forEach((item)=>{

        let option={
          text:item.Text,
          value:item.SystemId,
        }

        options.push(option);
      })

      return options;
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
.btn{
  margin: 10px;
  color: white;
}
</style>
