<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="下載QRCode">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-5">下載為圖片(.zip)</label>
        <div class="col-sm-7">
          <a role="button" class="btn btn-primary" @click="returnModal('zip')">下載</a>
        </div>

        <label for="" class="col-sm-5">下載為文件(.pdf)</label>
        <div class="col-sm-7">
          <a role="button" class="btn btn-primary" @click="returnModal('pdf')">下載</a>
        </div>

        <label for="" class="col-sm-5">下載為文字檔(.txt)</label>
        <div class="col-sm-7">
          <a role="button" class="btn btn-primary" @click="returnModal('txt')">下載</a>
        </div>
      </div>
<!--      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>-->
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//use <DLGQRImageDownload ref="dlgQRImageDownload" :webPart="" v-on:returnOk="blockWebPartSelectorReturnOk">
//use this.$refs.dlgQRImageDownload.showModal();
//import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";

export default {
  props:["areaId","blockId"],
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    returnModal(value) {
      // this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk',value);
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
