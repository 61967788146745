<template>
  <div>
    <COM_HeaderNav mode="uformData"></COM_HeaderNav>
    <!--    SearchPanel-->
<!--    <SearchPanelForm ref="searchPanelForm" :fieldDefines="searchDefine.searchFieldDefines" :keyword-fields="searchDefine.searchKeywordFields"></SearchPanelForm>-->

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
<!--        <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle" ></i>新增</button>-->
<!--        <button type="button" class="btn btn-danger" @click="btnDelete"><i class="fa fa-trash" ></i>刪除</button>-->
      </div>
      <div class="btn-group mx-1" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          匯出
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="$refs.grid.exportExcel()">Excel</a>
        </div>
      </div>
    </div>

    <!--    Grid-->
    <span v-if="isMobile">
      <MyMobileList :datas="gridDefine.datas" :field-to-show="gridDefine.fieldToShow" :swipe-setting="gridDefine.swipeSetting">
      </MyMobileList>
    </span>
    <span v-else>
      <MyKendoGrid ref="grid" :columns="gridDefine.columns" :datas="gridDefine.datas"></MyKendoGrid>
    </span>
  </div>
</template>

<script>

// import SearchPanelForm from "@/pages/ubossBk/views/components/SearchPanelForm";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyKendoGrid from "@/pages/commonComponent/MyKendoGrid";
import { isMobile } from 'mobile-device-detect';
import MyMobileList from "@/pages/commonComponent/MyMobileList";
import apiUBossFormBk from "@/pages/ubossBk/util/apiUBossFormBk";

export default {
  components: {
    MyMobileList,
    MyKendoGrid,
    COM_HeaderNav,
    // SearchPanelForm,
  },
  data: function () {
    return {
      systemId:"",
      isMobile:isMobile,
      searchDefine:{
        searchFieldDefines:[],
        searchKeywordFields:[],
      },
      gridDefine:{
        columns: [
          // { field: 'SystemId', title: 'SystemId', minWidth: 50, width: '50px'},
          // { field: 'Field01', title: '表單名稱' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M,cell: this.cellFunctionLink},
          // { field: 'Field02', title: '說明' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          // { field: 'Field03', title: '建立日期' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
        ],
        datas:[],
        fieldToShow:{
          title:"Field01",
          memo:"Field02",
          badge1:"Field03",
        },
        swipeSetting:{
          ItemClick:this.swipeSettingItemClick
        }

      },
    };
  },
  methods: {
    async loadData() {
      let systemId=this.$route.query.systemId;
      let formData=await apiUBossFormBk.api_FormDataFromForm(systemId);

      for (const column of formData.Columns) {
        let columnDef={
          field: column.PropertyName,
          title: column.ColumnText ,
          width:this.$dec.GridColumnWidth_Money,
          minWidth: this.$dec.GridColumnWidth_Money_M
        }

        this.gridDefine.columns.push(columnDef)
      }

      this.gridDefine.datas=formData.Details

      this.$refs.grid.loadGrid()
    },
    cellFunctionLink: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        that.gotoWithSystemId('MemberEdit',props.dataItem.SystemId)
      }
      let linkText=props.dataItem.LoginId;//QQ Friendly

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML: "<a class='alink'><i class='fa fa-edit'></i>"+linkText +" </a>"
        }
      });
    },
    swipeSettingItemClick:function (item){ // eslint-disable-line
      this.gotoWithSystemId('MemberEdit',item.SystemId)
    },
    btnCreate(){
      this.$router.push({ name: 'MemberEdit', query: { systemId: "" }})
    },
    btnDelete(){

    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>