<template>
  <div>
<!--    https://jbaysolutions.github.io/vue2-bootstrap-table/-->
    <vue-bootstrap-table
        :columns="columns"
        :values="rows"
        :show-filter="true"
        :sortable="true"
        :paginated="true"
        :show-column-picker="false"
        :selectable="showCheckboxColumn"
        :multi-column-sortable=true
        :filter-case-sensitive=false

    >
<!--      <template v-slot:name="slotProps">-->

<!--      </template>-->
<!--      <template v-slot:description="slotProps">-->

<!--      </template>-->
    </vue-bootstrap-table>
  </div>
</template>

<script>
/**
 <MyGridBootstrapTable ref="myGridBootstrapTable" :columns="boostrapTable.columns" :rows="boostrapTable.rows" :show-checkbox-column="true"></MyGridBootstrapTable>


 # 簡易資料
```js
 datas:{
        columns: [
          {
            title:"id",
          },
          {
            title:"name",
          },
          {
            title:"age",
          },
          {
            title:"country",
         }
        ],
        rows: [
          {
            "selected":true,  //由這裡取得選取的資料
            "id": 1,
            "name": "John",
            "country": "UK",
            "age": 25,
          },
          {
            "selected":true,
            "id": 2,
            "name": "Mary",
            "country": "France",
            "age": 30,
          },
          {
            "selected":true,
            "id": 3,
            "name": "Ana",
            "country": "Portugal",
            "age": 20,
          }
        ]
      },
 ```

 # 複雜資料  (有個別指定column選項，及column的Render function)
 ```js
 datas:{
        columns: [
          {
            title:"id",
          },
          {
            title:"name",
          },
          {
            title:"age",
          },
          {
            title:"country",
            visible: true,
            filterable: true,
            editable: true,
            sortable: true,
            renderfunction: this.renderColumn_Test
          }
        ],
        rows: [
          {
            "selected":true,
            "id": 1,
            "name": "John",
            "country": "UK",
            "age": 25,
          },
          {
            "selected":true,
            "id": 2,
            "name": "Mary",
            "country": "France",
            "age": 30,
          },
          {
            "selected":true,
            "id": 3,
            "name": "Ana",
            "country": "Portugal",
            "age": 20,
          }
        ]
      },


 renderColumn_Test(colname, entry){
      return '<div class="btn-group" role="group" >'+
          '  <button type="button" class="btn btn-sm btn-primary"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span></button>'+
          '  <button type="button" class="btn btn-sm btn-danger"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>'+
          '</div><span>'+JSON.stringify(entry)+'</span>';
    }
 ```


 */
import VueBootstrapTable from "vue2-bootstrap-table2";

export default {
  components: {VueBootstrapTable},
  // props: [""],
  props: {
    showCheckboxColumn: {
      type: Boolean,
      required: false
    },
    columns: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return []
      }
    },
    rows: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      boostrapTable:{
        columns: [
          {
            title:"id",
          },
          {
            title:"name",
            visible: true,
            editable: true,
            filterable: false
          },
          {
            title:"age",
            visible: true,
            editable: true,
          },
          {
            title:"country",
            visible: true,
            editable: true,
            sortable: false
          }
        ],
        rows: [
          {
            "id": 1,
            "name": "John",
            "country": "UK",
            "age": 25,
          },
          {
            "id": 2,
            "name": "Mary",
            "country": "France",
            "age": 30,
          },
          {
            "id": 3,
            "name": "Ana",
            "country": "Portugal",
            "age": 20,
          }
        ]
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    grid_SearchFunction(row, col, cellValue, searchTerm){// eslint-disable-line
      return cellValue === 'my value';
    },
    selectedRows(){
      return this.$refs.grid.selectedRows;
    },
    rowStyleClassFn(row) { //eslint-disable-line
      return row.vgtSelected ? 'VGT-row _dblclick' : 'VGT-row';
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>
.VGT-row:hover {
  background-color: yellow;
}

tr:hover td {
  background-color: yellow;
}
</style>
