var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
        _vm._v("表單連結")
      ]),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formLink,
              expression: "formLink"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.formLink },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.formLink = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }