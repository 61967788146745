var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(this.ProductOption.OptionSets.Items, function(item, index) {
      return _c("div", { key: index, staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
          _vm._v(_vm._s(item.Text))
        ]),
        _c(
          "div",
          { staticClass: "col-sm-10" },
          [
            _c("MyButtonRadio", {
              attrs: {
                options: _vm.convertToButtonRadionOption(item.Options),
                size: "md"
              },
              model: {
                value: _vm.propsValue[item.SystemId],
                callback: function($$v) {
                  _vm.$set(_vm.propsValue, item.SystemId, $$v)
                },
                expression: "propsValue[item.SystemId]"
              }
            })
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }