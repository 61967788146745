var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "popover-1-" + _vm.field.Id } }, [
    _vm.field.InputType === this.$formUtil.InputType_Text
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: " formFieldLabel",
                class: _vm.labelClass,
                attrs: { for: "" }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.field.Title) }
                }),
                _vm._v(" "),
                _vm.isMustInput(_vm.field)
                  ? _c("span", { staticClass: "require" }, [_vm._v("*")])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "formFieldInputBlock", class: _vm.inputClass },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: _vm.ruleString(_vm.field),
                    name: _vm.field.Title
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.fieldValue,
                                  expression: "fieldValue"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.getCssClass(_vm.field),
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: _vm.fieldValue },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.fieldValue = $event.target.value
                                }
                              }
                            }),
                            _c("span", { staticClass: "error_msg" }, [
                              _vm._v(_vm._s(errors[0]))
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2674717638
                  )
                })
              ],
              1
            )
          ])
        ])
      : _vm.field.InputType === this.$formUtil.InputType_TextArea
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: " formFieldLabel",
                class: _vm.labelClass,
                attrs: { for: "" }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.field.Title) }
                }),
                _vm._v(" "),
                _vm.isMustInput(_vm.field)
                  ? _c("span", { staticClass: "require" }, [_vm._v("*")])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "formFieldInputBlock", class: _vm.labelClass },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: _vm.ruleString(_vm.field),
                    name: _vm.field.Title
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.fieldValue,
                                expression: "fieldValue"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.getCssClass(_vm.field),
                            attrs: { placeholder: "", rows: "5" },
                            domProps: { value: _vm.fieldValue },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.fieldValue = $event.target.value
                              }
                            }
                          }),
                          _c("span", { staticClass: "error_msg" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ])
      : _vm.field.InputType === this.$formUtil.InputType_Select
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: " formFieldLabel",
                class: _vm.labelClass,
                attrs: { for: "" }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.field.Title) }
                }),
                _vm._v(" "),
                _vm.isMustInput(_vm.field)
                  ? _c("span", { staticClass: "require" }, [_vm._v("*")])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "formFieldInputBlock", class: _vm.labelClass },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: _vm.ruleString(_vm.field),
                    name: _vm.field.Title
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.fieldValue,
                                  expression: "fieldValue"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.getCssClass(_vm.field),
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.fieldValue = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.splitOptions(_vm.field.Values), function(
                              option,
                              index
                            ) {
                              return _c(
                                "option",
                                {
                                  key: option,
                                  domProps: { selected: index === 0 }
                                },
                                [_vm._v(_vm._s(option))]
                              )
                            }),
                            0
                          ),
                          _c("span", { staticClass: "error_msg" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ])
      : _vm.field.InputType === this.$formUtil.InputType_CheckBox
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: " formFieldLabel",
                class: _vm.labelClass,
                attrs: { for: "" }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.field.Title) }
                }),
                _vm._v(" "),
                _vm.isMustInput(_vm.field)
                  ? _c("span", { staticClass: "require" }, [_vm._v("*")])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "formFieldInputBlock", class: _vm.labelClass },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: _vm.ruleString(_vm.field),
                    name: _vm.field.Title
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _vm._l(_vm.splitOptions(_vm.field.Values), function(
                            option
                          ) {
                            return _c(
                              "li",
                              {
                                key: option,
                                staticStyle: { "list-style-type": "none" }
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "checkbox-inline" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.fieldValue,
                                          expression: "fieldValue"
                                        }
                                      ],
                                      staticClass: "myCheckbox",
                                      attrs: {
                                        type: "checkbox",
                                        name: _vm.field.Id
                                      },
                                      domProps: {
                                        value: option,
                                        checked: Array.isArray(_vm.fieldValue)
                                          ? _vm._i(_vm.fieldValue, option) > -1
                                          : _vm.fieldValue
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.fieldValue,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = option,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.fieldValue = $$a.concat([
                                                  $$v
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.fieldValue = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.fieldValue = $$c
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(_vm._s(option))
                                  ]
                                )
                              ]
                            )
                          }),
                          _c("span", { staticClass: "error_msg" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ])
      : _vm.field.InputType === this.$formUtil.InputType_Radio
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: " formFieldLabel",
                class: _vm.labelClass,
                attrs: { for: "" }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.field.Title) }
                }),
                _vm._v(" "),
                _vm.isMustInput(_vm.field)
                  ? _c("span", { staticClass: "require" }, [_vm._v("*")])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "formFieldInputBlock", class: _vm.labelClass },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: _vm.ruleString(_vm.field),
                    name: _vm.field.Title
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _vm._l(_vm.splitOptions(_vm.field.Values), function(
                            option,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: option, staticClass: "form-check" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.fieldValue,
                                      expression: "fieldValue"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "radio",
                                    id: "rdo" + _vm.field.Id + index,
                                    name: _vm.field.Id
                                  },
                                  domProps: {
                                    value: option,
                                    checked: _vm._q(_vm.fieldValue, option)
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.fieldValue = option
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: "rdo" + _vm.field.Id + index }
                                  },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              ]
                            )
                          }),
                          _c("span", { staticClass: "error_msg" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ])
      : _vm.field.InputType === this.$formUtil.InputType_Shop
      ? _c("div", [_vm._m(0)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "table",
        {
          staticClass: "table table-hover",
          staticStyle: { "margin-left": "40px", "margin-top": "40px" },
          attrs: { id: "tblMain" }
        },
        [
          _c("thead", { staticClass: "thead-light" }, [
            _c("tr", [
              _c("th", { staticClass: "text-center" }, [_vm._v("說明")]),
              _c("th"),
              _c("th", { staticClass: "text-center" }, [_vm._v("數量")])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" 方案一 大人 $500 ")
              ]),
              _c("td", { attrs: { width: "200px" } }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  }
                })
              ])
            ]),
            _c("tr", [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" 方案一 小孩 $200 ")
              ]),
              _c("td", [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  }
                })
              ])
            ]),
            _c("tr", [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" 方案二 大人 $500 ")
              ]),
              _c("td", [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  }
                })
              ])
            ]),
            _c("tr", [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" 方案二 小孩 $200 ")
              ]),
              _c("td", [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  }
                })
              ])
            ]),
            _c("tr", [
              _c("td", { staticClass: "text-center" }, [_vm._v(" 合計 ")]),
              _c("td", [_vm._v(" $1,000 ")])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }