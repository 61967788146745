<template>
  <div class="bk_header_fixed-top c-subheader justify-content-between px-3">

    <div class="scrollmenu">
      <span v-if="mode==='onePage'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>

<!--        //TTTT-->
<!--        <a type="button" class="btn btn-primary btnNav active" >官網編輯</a>-->
<!--        <MyBarButton v-on:clickButton="clickButton" ></MyBarButton>-->
<!--        <MyBarButton v-on:clickButton="clickButton" v-show="false"></MyBarButton>-->


<!--        <span>-->
<!--        編輯頁面-->
<!--        <select class="form-control" v-on:change="clickButton2" v-model="pageId" style="display:inline">-->
<!--            <option value="1">首頁</option>-->
<!--            <option value="2">產品</option>-->
<!--            <option value="3">關於我們</option>-->
<!--        </select>-->
<!--        </span>-->

        <!-- Breadcrumb Menu-->
      </span>
      <span v-if="mode==='onePageAdv'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>

<!--        //TTTT-->
<!--        <a type="button" class="btn btn-primary btnNav active" >官網編輯</a>-->
<!--        <MyBarButton v-on:clickButton="clickButton" ></MyBarButton>-->
<!--        <MyBarButton v-on:clickButton="clickButton" v-show="false"></MyBarButton>-->


        <!-- Breadcrumb Menu-->
      </span>
        <span v-else-if="mode==='uformDefine'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav active " @click="goto('UFormIndex')">表單</a>
        <!-- Breadcrumb Menu-->
      </span>
        <span v-else-if="mode==='uformData'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav " @click="goto('UFormIndex')">表單</a>
        <a type="button" class="btn btn-primary btnNav active " @click="goto('UFormDataIndex')">表單回傳</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='uformData'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UFormIndex')">表單</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('UFormDataIndex')">回填資料</a>
        <!-- Breadcrumb Menu-->
      </span>

      <span v-else-if="mode==='shoppingOrder'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('OrderIndex')">訂單</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('ProductIndex')">商品</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('MemberIndex')">會員</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('SettingPanel')">設定</a>
        <!-- Breadcrumb Menu-->
      </span>

      <span v-else-if="mode==='shoppingProduct'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('OrderIndex')">訂單</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('ProductIndex')">商品</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('MemberIndex')">會員</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('SettingPanel')">設定</a>
        <!-- Breadcrumb Menu-->
      </span>

      <span v-else-if="mode==='shoppingMember'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('OrderIndex')">訂單</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('ProductIndex')">商品</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('MemberIndex')">會員</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('SettingPanel')">設定</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='shoppingSiteSetting'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('OrderIndex')">訂單</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('ProductIndex')">商品</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('MemberIndex')">會員</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('SettingPanel')">設定</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='udmIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome" v-if="!isUDMMode()">首頁</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('UDMIndex')">UDM</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMPassCodeIndex')">PASS Code</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMLogIndex')">使用記錄</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('Login')" v-if="isUDMMode()">登出</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='UDMPassCodeIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome" v-if="!isUDMMode()">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMIndex')">UDM</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('UDMPassCodeIndex')">PASS Code</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMLogIndex')">使用記錄</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('Login')" v-if="isUDMMode()">登出</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='UDMLogIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome" v-if="!isUDMMode()">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMIndex')">UDM</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('UDMPassCodeIndex')">PASS Code</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('UDMLogIndex')">使用記錄</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('Login')" v-if="isUDMMode()">登出</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='TicketIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('TicketIndex')">票券編輯</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketMemberIndex')">兌換員資料</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketMemberIndex')">會員資料</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketLogIndex')">使用記錄</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='TicketMemberIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketIndex')">票券編輯</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketMemberIndex')">兌換員資料</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('TicketMemberIndex')">會員資料</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketLogIndex')">使用記錄</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='TicketLogIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketIndex')">票券編輯</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketMemberIndex')">兌換員資料</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('TicketMemberIndex')">會員資料</a>
        <a type="button" class="btn btn-primary btnNav active" @click="goto('TicketLogIndex')">使用記錄</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='ADMIndex'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('ADMIndex')">客戶資料</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='OnePageAdv2'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav active" ><i class="fa fa-angle-right"></i>官網</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='OnePageAdv2CName'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('OnePageAdvIndex')"> <i class="fa fa-angle-right"></i> 官網</a>
        <a type="button" class="btn btn-primary btnNav active" ><i class="fa fa-angle-right"></i>CName</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='OnePageAdv2EditStep1'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" @click="goto('OnePageAdvIndex')"><i class="fa fa-angle-right"></i>官網</a>
        <a type="button" class="btn btn-primary btnNav" @click="gotoBack()"><i class="fa fa-angle-right"></i>CName</a>
        <a type="button" class="btn btn-primary btnNav active" ><i class="fa fa-angle-right"></i>編輯頁面</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else-if="mode==='ticket2'">
        <!-- Breadcrumb-->
        <a type="button" class="btn btn-primary btnNav" @click="gotoHome">首頁</a>
        <a type="button" class="btn btn-primary btnNav" :class="buttonIndex==='1' ? 'active' :''"  @click="goto('Ticket2TicketTypeIndex')"><i class="fa fa-angle-right"></i>票種管理</a>
        <a type="button" class="btn btn-primary btnNav" :class="buttonIndex==='2' ? 'active' :''"  @click="goto('Ticket2TicketPublishIndex')"><i class="fa fa-angle-right"></i>票券發行</a>
        <a type="button" class="btn btn-primary btnNav" :class="buttonIndex==='3' ? 'active' :''"  @click="goto('Ticket2TicketIndex')"><i class="fa fa-angle-right"></i>票券管理</a>
        <a type="button" class="btn btn-primary btnNav" :class="buttonIndex==='4' ? 'active' :''"  @click="goto('Ticket2TicketDesignIndex')"><i class="fa fa-angle-right"></i>票券列印</a>
        <!-- Breadcrumb Menu-->
      </span>
      <span v-else>

      </span>
    </div>

<!--    <span v-if="mode==='onePage'">-->
<!--      &lt;!&ndash; Breadcrumb&ndash;&gt;-->
<!--      <ul class="breadcrumb border-0 m-0 px-0 px-md-3">-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary" href="/">首頁</a></li>-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary active" >官網編輯</a></li>-->
<!--        &lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
<!--      </ul>-->
<!--    </span>-->
<!--    <span v-else-if="mode==='uformDefine'">-->
<!--      &lt;!&ndash; Breadcrumb&ndash;&gt;-->
<!--      <ul class="breadcrumb border-0 m-0 px-0 px-md-3">-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary" href="/">首頁</a></li>-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary active" href="/UFormIndex">表單</a></li>-->
<!--&lt;!&ndash;        <li class="breadcrumb-item"><a type="button" class="btn btn-primary" href="UFormDataIndex">表單回傳資料</a></li>&ndash;&gt;-->
<!--        &lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
<!--      </ul>-->
<!--    </span>-->
<!--    <span v-else-if="mode==='uformData'">-->
<!--      &lt;!&ndash; Breadcrumb&ndash;&gt;-->
<!--      <ul class="breadcrumb border-0 m-0 px-0 px-md-3">-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary" href="/">首頁</a></li>-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary" href="/UFormIndex">表單定義</a></li>-->
<!--        <li class="breadcrumb-item"><a type="button" class="btn btn-primary active" href="UFormDataIndex">表單回傳資料</a></li>-->
<!--        &lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
<!--      </ul>-->
<!--    </span>-->
<!--    <span v-else>-->

<!--    </span>-->


  </div>
</template>

<script>

// import MyBarButton from "@/pages/ubossBk/views/components/new/MyBarButton";
export default {
  // components: {MyBarButton},
  //name: "COM_HeaderNav",
  props: ["mode","buttonIndex"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      pageId:"",
    };
  },
  computed:{
    isMobile() {
      return this.$mq === 'sm';
    },
  },
  methods: {

    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    clickButton(systemId){

      this.$router.push({ name: 'OnePageIndex', query: { SystemId: systemId }})
    },
    isUDMMode(){
      if (process.env.VUE_APP_runMode==="udm"){
        return true;
      }else{
        return false;
      }
    },
    getButtonText(text){
      if (this.isMobile){
        return "";
      }else{
        return text;
      }
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>
.btnNav{
  width: 80px;
  margin-right: 5px;
}

@media (min-width: 768px){
  .btnNav{
    width: 150px;
    margin-right: 5px;
  }

}

/*.breadcrumb-item + .breadcrumb-item::before {*/
/*  content: "";*/
/*}*/

.scrollmenu {
  /*background-color: #333;*/
  overflow: auto;
  white-space: nowrap;
  //scrollbar-width: 10p;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scrollmenu::-webkit-scrollbar {
  display: none;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  /*padding: 14px;*/
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

.c-subheader{
    padding:5px;
}

.active{
  color: #efd292 !important;
}
</style>
