<template>
  <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="udmIndex"></COM_HeaderNav>

    <MyToolbarVuetify ref="myToolbar" :grid-select-ids="gridSelectIds"  v-on:button-click="toolbarButton_Click" > </MyToolbarVuetify>

    <div class="bk_firstBlock"/>

    <MyVueEasytable ref="grid" v-on:grid-selected="grid_Selected"></MyVueEasytable>

  </div>
</template>

<script>

import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import MyVueEasytable from "@/pages/commonComponent/MyVueEasytable/MyVueEasytable.vue";
import {
  MyVueEasyGrid,
  MyVueEasyGridActionBar,
  MyVueEasyGridExpandForm, MyVueEasyGridFilters, MyVueEasyGridUtility
} from "@/pages/commonComponent/MyVueEasytable/js/MyVueEasytable";
import {MyToolbar, MyToolbarGroup} from "@/pages/commonComponent/js/MyToolbarVuetify";
import {MyToolbarButtonIdEnum} from "@/pages/commonComponent/js/MyToolbarVuetify";

export default {
  components: {
    MyVueEasytable,
    MyToolbarVuetify, COM_HeaderNav
  },
  data: function () {
    return {
      datas:[
        {
          SystemId: "1",
          name: "Aohn",
          date: "1900-05-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Shanghai",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "2",
          name: "Dickerson",
          date: "1910-06-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Beijing",
          status: "enable",
          textbox:"text",
          select:"select",
        },
      ],
      gridSelectIds:{},
    };
  },
  methods: {
    async loadData() {
      this.datas=await apiUBossShopBk.api_Member_ListQuery(null)
    },
    initGrid(){
      //Grid Row Button
      let gridActionBar = new MyVueEasyGridActionBar();

      gridActionBar.addButton("btn btn-primary", "Edit", this.btnRowButtonEdit)
      gridActionBar.addButton("btn btn-danger", "Delete", this.btnRowButtonDelete)

      //Expand顯示的資訊
      let gridExpandForm = new MyVueEasyGridExpandForm();

      gridExpandForm.addItem("name", "Name");
      gridExpandForm.addItem("address", "Address");

      //Render Method
      let gridUtility = new MyVueEasyGridUtility();

      let funRenderHyperLink = gridUtility.GetFun_RenderHyperLink("test-text", (row) => row.name, (row) => "http://github.com/" + row.name);
      let funRenderActionBar=gridUtility.GetFun_RenderActionButton(gridActionBar.Buttons);
      let funRenderExpandForm=gridUtility.GetFun_RenderExpandForm(gridExpandForm.Items);

      //Filter
      let gridFilters=new MyVueEasyGridFilters();
      gridFilters
          .addFilter("enable","enable")
          .addFilter("disabled","disabled")

      //欄位
      let grid = new MyVueEasyGrid(this.$refs.grid)
          .addColumnSeq()
          .addColumnExpand()
          .addColumnCheckBox()
          .addColumnRadio()
          .addColumnRender("Action", "action", funRenderActionBar)
          .addColumnRender("Name", "name", funRenderHyperLink)
          .addColumnRender("Date", "date", funRenderHyperLink)
          .addColumn("Hobby", "hobby",true)
          .addColumn("Address", "address",true)
          .addColumnFilter("Status","status",gridFilters,this.SearchStats_Confirm,this.SearchStats_Reset)
      ;

      //格式化儲存格
      let formatCellFun=({ row, column, rowIndex }) => { // eslint-disable-line
        //整欄
        if (column.key === "date") {
          return "table-body-cell-class1";
        }

        //某欄的 某個儲存格
        if (column.key === "name" && row.name === "Larsen") {
          return "table-body-cell-class2";
        }
      };

      //設定Grid
      this.$refs.grid.set_expandOption(funRenderExpandForm);
      this.$refs.grid.set_columns(grid.Columns);
      this.$refs.grid.set_tableData(this.datas);
      this.$refs.grid.set_cellStyleOption(formatCellFun);
      this.$refs.grid.set_mobileUI();

      //Grid Doc
      // //Radio
      // this.$refs.grid.gridUtil_RadioSelectById["1"]
      // this.$refs.grid.gridUtil_RadioUnSelect(["1"])
      //
      // //Checkbox
      // this.$refs.grid.gridUtil_CheckboxSelectAll();
      // this.$refs.grid.gridUtil_UnCheckboxSelectAll();
      //
      // //Column
      // this.$refs.grid.gridUtil_ShowColumnsByKeys(["name"])
      // this.$refs.grid.gridUtil_HideColumnsByKeys(["name"])
      //
      // //Other
      // this.$refs.grid.gridUtil_ShowEmpty();
      //
      // //Get Info
      // this.info="";
      // this.info+="All:"+this.$refs.grid.gridUtil_SelectedIds();
      // this.info+="R:"+this.$refs.grid.gridUtil_RadioSelectedId();
      // this.info+="C:"+this.$refs.grid.gridUtil_CheckBoxSelectedIds();
    },
    initToolbar(){
      let toolbarGroup1=new MyToolbarGroup();
      toolbarGroup1
          .addButton_Add()
          .addButton_Edit()
          .addButton_Delete()
          .addButton_Copy()//
          .addButton_Setting()
          .addButton_Export()
          .addButton_Sort()
          .addButton_Query()
          .addButton_DeleteAlone()
      ;
      let toolbarGroup2=new MyToolbarGroup();
      // toolbarGroup2
      //     .addButton_Setting()
      //     .addButton_Export()
      //     .addButton_Sort()
      //     .addButton_Query()
      //     .addButton_DeleteAlone()
      // ;

      let toolbar=new MyToolbar();

      toolbar.addGroup(toolbarGroup1).addGroup(toolbarGroup2);

      this.$refs.myToolbar.setProp_Toolbar(toolbar);
    },
    toolbarButton_Click(buttonId,selectIds){  // eslint-disable-line
      if (buttonId===MyToolbarButtonIdEnum.Add){
        console.log("");
      }else if (buttonId===MyToolbarButtonIdEnum.Edit){
        this.$router.push({ name: 'TemplateEdit', query: { SystemId: 0 }})
      }

      // alert(buttonId);
      // alert(selectIds);
    },
    grid_Selected(selectedIds){
      this.gridSelectIds=selectedIds;
    }
  },
  created() {
    console.log("page create start");

    console.log("page create end");
  },
  mounted() {
    console.log("page mount start");
    this.loadData();
    this.initGrid();
    this.initToolbar();
    console.log("page mount end");
  },
}
</script>

<style scoped>

</style>
