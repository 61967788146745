var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "" } },
        [
          _c("b-form-radio-group", {
            attrs: {
              id: _vm.getContrlName(),
              options: _vm.optionsConvered(),
              "button-variant": _vm.buttonVariant,
              size: _vm.size,
              name: _vm.getContrlName(),
              buttons: ""
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }