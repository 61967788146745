<template>
  <div>
    <COM_HeaderNav mode="onePage"></COM_HeaderNav>

      <iframe id="myIframe" >

    </iframe>
  </div>
</template>

<script>
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default {
  components: {COM_HeaderNav},
  //name: "Index",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      OnePageLink:"",
    };
  },
  watch:{
    '$route'(to,from){// eslint-disable-line
      this.SystemId=this.$route.query.SystemId
      this.type=this.$route.query.type

      this.loadData()
      this.$forceUpdate();
    },
  },
  methods: {
    async loadData() {
      let data= await apiUBossBk.api_CustomerSetting();  // eslint-disable-line

      if (ubossCommon.IsLocalhost()){
        //本地用
        this.OnePageLink="http://127.0.0.1:9007/Index?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09";

        let systemId=this.$route.query.SystemId
        this.OnePageLink="http://127.0.0.1:9007/Index?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09&id="+systemId;
      }else{
        this.OnePageLink=data.OnePageSetting.SettingUrl;
      }



console.log("link");
  console.log(this.OnePageLink);
      document.getElementById('myIframe').src=this.OnePageLink;
    },
  },
  mounted() {
    this.loadData()
  },
}


</script>

<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
  margin: 50px;
}
</style>
