var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._v(" 選擇商品 "),
      _vm._m(0),
      _c("WebPartFormShopWithProductSetting", { ref: "setting" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "select",
      {
        staticClass: "form-control",
        attrs: { id: "selItems", name: "selItems" }
      },
      [
        _c("option"),
        _c("option", [_vm._v("1")]),
        _c("option", [_vm._v("2")]),
        _c("option", [_vm._v("3")]),
        _c("option", [_vm._v("4")]),
        _c("option", [_vm._v("5")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }