<template>
  <div class="" >
    <b-modal ref="my-modal" size="s" hide-footer title="">
      <div class="d-block text-center">
        <h3>詳細</h3>
      </div>

        欄位精簡排列
        服務以一行顯示

      <hr>
        <table class="table table-hover">
            <thead class="thead-light">
            <tr>
                <th>欄位</th>
                <th>值</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td ></td>
                <td class="text-left">
                </td>
            </tr>

            </tbody>
        </table>

      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>


//<DLGEditor ref="dlgEditor" v-on:returnOk="dlgEditorReturnOK" />
//
//
// editHtmlContent(item){
//   this.$refs.dlgEditor.callbackItem=item;
//   this.$refs.dlgEditor.htmlContent=item.HelpContent.Content;
//   this.$refs.dlgEditor.showModal();
// },
// dlgEditorReturnOK(item){
//   item.HelpContent.Content=this.$refs.dlgEditor.htmlContent;
// }

export default {
  props:["htmlContent","callbackItem"],
  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk',this.callbackItem);
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
