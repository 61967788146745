<template>
  <div>

    <select class="form-control" v-model="propsValue" >
      <option value=""></option>
      <option v-for="(item, index) in datasFiltered" v-bind:key="index" :value="item[fieldNameValue]">{{ item[fieldNameText] }}</option>
    </select>


  </div>
</template>

<script>
/**
 <MySelectCascading ref="mySelectCascading1" v-model="SelectValue1" :datas="SelectDemo_Group1" :child-select-ref="ChildSelectRef1" :is-root="true" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>
 <MySelectCascading ref="mySelectCascading2" v-model="SelectValue2" :datas="SelectDemo_Group2" :child-select-ref="ChildSelectRef2" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>
 <MySelectCascading ref="mySelectCascading3" v-model="SelectValue3" :datas="SelectDemo_Group3" field-name-text="Text" field-name-value="Value" field-name-parent-id="ParentId"></MySelectCascading>


 SelectValue1:"",
 SelectValue2:"",
 SelectValue3:"",
 ChildSelectRef1:null,
 ChildSelectRef2:null,
 SelectDemo_Group1:[
 {
          Id:"1",
          Text:"Group1",
          Value:"G1",
        },
 {
          Id:"2",
          Text:"Group2",
          Value:"G2",
        },
 {
          Id:"3",
          Text:"Group3",
          Value:"G3",
        },
 ],
 SelectDemo_Group2:[
 {
          Id:"1",
          Text:"Group21",
          Value:"G21",
          ParentId:"G1",
        },
 {
          Id:"2",
          Text:"Group22",
          Value:"G22",
          ParentId:"G1",
        },
 {
          Id:"3",
          Text:"Group23",
          Value:"G23",
          ParentId:"G2",
        },
 ],
 SelectDemo_Group3:[
 {
          Id:"1",
          Text:"Group31",
          Value:"G31",
          ParentId:"G21",
        },
 {
          Id:"2",
          Text:"Group32",
          Value:"G32",
          ParentId:"G21",
        },
 {
          Id:"3",
          Text:"Group33",
          Value:"G33",
          ParentId:"G22",
        },
 ],
 **/


export default {
  props: {
    value: {
      type: String,
      default() {
        return ""
      }
    },
    datas: {
      type: Array,
      default() {
        return []
      }
    },
    childSelectRef: {
      type: Object,
      default() {
        return undefined
      }
    },
    isRoot:{
      type: Boolean,
      default() {
        return false
      }
    },
    fieldNameText: {
      type: String,
      default() {
        return "Text"
      }
    },
    fieldNameValue: {
      type: String,
      default() {
        return "Value"
      }
    },
    fieldNameParentId: {
      type: String,
      default() {
        return "ParentId"
      }
    },
    // ParentId: {
    //   type: String,
    //   default() {
    //     return ""
    //   }
    // }
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      datasFiltered:[]
    };
  },
  watch: {
    propsValue(newVal) {
      if (this.childSelectRef){
        this.childSelectRef.setParentId(newVal);
      }
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    setParentId(value){
      this.datasFiltered= this.datas.filter(item=>item[this.fieldNameParentId]===value)
    }
  },
  mounted() {
    if (this.isRoot){
      this.datasFiltered=this.datas;
    }
  },
}
</script>

<style scoped>

</style>
