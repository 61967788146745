var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "slideshow" } },
    [
      _c(
        "splide",
        { attrs: { options: _vm.options } },
        _vm._l(_vm.propsWebPart.PartImages, function(image, index) {
          return _c("splide-slide", { key: index }, [
            _c(
              "a",
              { attrs: { href: image.LinkUrl, target: image.LinkTarget } },
              [
                _c("img", {
                  attrs: { src: _vm.toDownloadLink(false, image.ImageUrl) }
                })
              ]
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }