var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm.timeType === this.$onePageUtil.OnePage_TimeType_Design
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "container MyNavSecondBar",
                staticStyle: { "background-color": "#8fd19e" }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "a",
                    { staticClass: "btn btn-primary", on: { click: _vm.save } },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.preview }
                    },
                    [_c("i", { staticClass: "fa fa-eye" }), _vm._v("預覽")]
                  )
                ])
              ]
            ),
            _c("ShareForm", {
              ref: "shareForm",
              attrs: { "page-id": this.pageId }
            }),
            _c("section", { staticClass: "section-intro" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 anchorAdjust" }, [
                    _c("div", { staticClass: "btn-toolbar pull-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.addImage("")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus-circle" }),
                          _vm._v("圖片")
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.addHtml("")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus-circle" }),
                          _vm._v("文字")
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn-group", attrs: { role: "group" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary dropdown-toggle",
                              attrs: {
                                id: "btnGroupDrop1",
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              }
                            },
                            [_vm._v(" 廣告區 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { "aria-labelledby": "btnGroupDrop1" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.addSliderImage("")
                                    }
                                  }
                                },
                                [_vm._v("大圖輪播")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.addSliderImage2("")
                                    }
                                  }
                                },
                                [_vm._v("小圖並列")]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.addVideo("")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus-circle" }),
                          _vm._v("影片")
                        ]
                      ),
                      !_vm.IsProd()
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.addUForm("")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus-circle" }),
                              _vm._v("表單")
                            ]
                          )
                        : _vm._e(),
                      !_vm.IsProd()
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.addCallToAction("")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus-circle" }),
                              _vm._v("與我連繫")
                            ]
                          )
                        : _vm._e(),
                      !_vm.IsProd()
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.addHR("")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus-circle" }),
                              _vm._v("分隔")
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            ]),
            _c("section", { staticClass: "section-intro" }, [
              _c(
                "div",
                { staticClass: "container" },
                _vm._l(_vm.WebParts, function(webPart, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "row", class: _vm.sectionClass },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 anchorAdjust" },
                        [
                          _c("span", { attrs: { id: webPart.Id } }),
                          _c("h3", [_vm._v(_vm._s(_vm.blockTitle(webPart)))]),
                          _c("BlockDesigner", {
                            attrs: {
                              "design-time": _vm.designTime,
                              "web-part": webPart
                            }
                          }),
                          _c("div", {}, [
                            _c(
                              "div",
                              { staticClass: "btn-toolbar pull-right" },
                              [
                                _vm.canRemove(webPart)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function($event) {
                                            return _vm.remove(webPart.Id)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.moveUp(webPart.Id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-angle-up" })]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.moveDown(webPart.Id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-angle-down" })]
                                )
                              ]
                            )
                          ]),
                          _c("hr")
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      : _vm.timeType === this.$onePageUtil.OnePage_TimeType_Run
      ? _c("div", [
          _c("section", { staticClass: "section-intro" }, [
            _c(
              "div",
              { staticClass: "container noMarginNoPadding" },
              _vm._l(_vm.WebParts, function(webPart, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "row noMargin",
                    class: _vm.sectionClass
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 noMargin anchorAdjust" },
                      [
                        _c("span", { attrs: { id: webPart.Id } }),
                        _c("BlockRunner", {
                          attrs: {
                            "design-time": _vm.designTime,
                            "web-part": webPart,
                            "page-id": _vm.pageId
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }