export const DB_WebPartDataDemo = []

export const DebugOnePage = false

export const Ticket_CLStatus = [
    { Text: '庫存中', Value: '1' },
    { Text: '已發行', Value: '4' },
    { Text: '已售出', Value: '2' },
    { Text: '已兌換', Value: '3' },
    { Text: '已付款', Value: '5' }
];

export const Ticket_CLStatus2 =
    {
        庫存中:"1",
        已發行:"4",
        已售出:"2",
        已兌換:"3",
        已付款:"5",
    }
;


export default {


}
