<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="uformDefine"></COM_HeaderNav>


    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>

<!--        //QQ-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>儲存後繼續新增</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-save"></i>複製並編輯</a>-->
<!--        <a type="button" class="btn btn-primary mx-1"><i class="fa fa-eye"></i>前台預覽</a>-->
      </div>
    </div>

    <ValidationObserver ref="form">

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">表單名稱</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="表單名稱" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Title">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import apiUBossFormBk from "@/pages/ubossBk/util/apiUBossFormBk";


export default {
  components: {COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {
        "Title":"",
      },
      formDataDemo: {
        "Title":"",
      },
    };
  },
  methods: {
    async loadData() {


    },
    btnUpdate(){
      let that=this             // eslint-disable-line
      let router=this.$router   // eslint-disable-line

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossFormBk.api_Form_SaveInit(that.formData.Title).then((data)=>{
            this.$router.push({ name: 'UFormEdit', query: { systemId: data.SystemId }})
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>