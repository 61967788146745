var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ShowHeader
    ? _c("header", { staticClass: "c-header c-header-light c-header-fixed" }, [
        _c("div", { staticStyle: { width: "50px" } }),
        _c("div", { staticClass: "c-header-nav" }, [
          _vm._v(
            " " +
              _vm._s(_vm.Title) +
              " 目前登入者:" +
              _vm._s(_vm.headerBarInfo) +
              " "
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }