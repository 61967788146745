
<template>
  <div v-if="field.type===MyFormPanelFieldUITypeEnum.input">
      <input type="text" class="form-control" placeholder="" v-model="modelNew" :class="getCssClass(field)" :style="field.style" >
  </div>
  <div v-else-if="field.type===MyFormPanelFieldUITypeEnum.inputNumberOnly">
      <input type="number" class="form-control" placeholder="" v-model="modelNew" :class="getCssClass(field)" :style="field.style">
  </div>
  <div v-else-if="field.type===MyFormPanelFieldUITypeEnum.radio">
<!--    //QQ-->
    <select class="form-control" id="selItems" name="selItems">
      <option></option>
      <option>是</option>
      <option>否</option>
    </select>
  </div>
  <div v-else-if="field.type===MyFormPanelFieldUITypeEnum.textarea">
    <textarea class="form-control"  rows="3" v-model="modelNew" :class="getCssClass(field)" :style="field.style"></textarea>
  </div>
</template>

<script>

import {MyFormPanelFieldUITypeEnum} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";
// import {MyFormPanelFieldUITypeEnum,MyFormPanelValidatorEnum} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";

export default {
  props: ["field","model"],
  computed: {
    MyFormPanelFieldUITypeEnum() {
      return MyFormPanelFieldUITypeEnum
    },
    modelNew: {
      get() {
        return this.model
      },
      set(val) {
        this.$emit("update:model", val)
      }
    }
  },
  methods:{
    getCssClass(field){
      let classStr="";
      if (field.require){
        classStr= "requireField ";
      }
      if (field.className){
        classStr = classStr+ field.className + " ";
      }

      return classStr;
    },
  },
  data: function () {
    return {
      formDefine:{
      }
    };
  },
  mounted() {

  }

}
</script>



<style scoped>

</style>
