<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="3"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">票號</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="票號" >
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.TicketFullCode">-->
            {{TicketFullCode}}
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">狀態</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="狀態" >
            <MyCodeListButtonRadio v-model="formData.CLStatus" :options="Ticket2Declare.Ticket_CLStatus2" size="md"></MyCodeListButtonRadio>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">到期日</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="到期日" >
<!--            <input type="date" class="form-control " placeholder="" v-model="formData.ExpireDt">-->
            {{formData.ExpireDt | fmtDate}}
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">售出日</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="售出日" >
<!--            <input type="time" class="form-control " placeholder="" v-model="formData.SellDt">-->
            {{formData.SellDt | fmtDate}}
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">使用時間</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="使用時間" >
<!--            <input type="time" class="form-control " placeholder="" v-model="formData.UseDt">-->
            {{formData.UseDt | fmtDate}}
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">銷售者</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="銷售者" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Buyer">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">服務人員</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="服務人員" >
            <input type="text" class="form-control " placeholder="" v-model="formData.UseOptUserId">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">配送方式</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="配送方式" >-->
<!--            <MyCodeListButtonRadio v-model="formData.CLShipType" :options="$u.UCode_CLShipType" size="md"></MyCodeListButtonRadio>-->
<!--&lt;!&ndash;            <select class="form-control" v-model="formData.CLShipType">&ndash;&gt;-->
<!--&lt;!&ndash;              <option></option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="self">自取</option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option value="ship">宅配</option>&ndash;&gt;-->
<!--&lt;!&ndash;            </select>&ndash;&gt;-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->


    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";
import * as Ticket2Declare from "@/pages/ubossBk/views/ticket2/ticket2Declare";
// import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";

export default {
  computed: {
    Ticket2Declare() {
      return Ticket2Declare
    }
  },
  components: {MyCodeListButtonRadio, COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {

      },
      formDataDemo: {

        "OrderId":"",
        "RelMember":"",
        "CLSourceType":"",
        "CLInvoiceType":"",
        "CLPayType":"",
        "PayTypeMemo":"",
        "PayTypeLog":"",
        "CLPayStatus":"",
        "CLOrderStatus":"",
        "CLShipType":"",
        "CLShipStatus":"",
        "ShipDt":"",
        "TotalOrgAmount":"",
        "TotalAmount":"",
      },
      TicketFullCode:"",
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;
      this.TicketFullCode=this.$route.query.TicketFullCode;

      if (this.systemId){
        let data=await apiUBossBk.api_Ticket_Get(this.systemId)
        this.formData=data
      }
    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (this.formData.ShipDt===""){
            this.formData.ShipDt=null;
          }
          //---------------------------------------
          apiUBossBk.api_Ticket_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
