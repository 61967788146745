var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("input", {
      ref: "file",
      staticClass: "form-control",
      attrs: { type: "file", placeholder: "", hidden: "" },
      on: { change: _vm.selectFile }
    }),
    _c(
      "a",
      {
        staticClass: "btn btn-primary",
        on: { click: _vm.fileUplaodControlClick }
      },
      [_c("i", { staticClass: "fa fa-upload" })]
    ),
    _vm.allowRemoveFile
      ? _c("span", [
          _c(
            "a",
            { staticClass: "btn btn-danger", on: { click: _vm.fileRemove } },
            [_c("i", { staticClass: "fa fa-trash" })]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }