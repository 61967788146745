
var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossShopBkApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------

    //---------------------------------------
    getApiManager_Member() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"Member", this.getAxiosManager(),null)
    },

    async api_Member_ListQuery ( queryObject)
    {
        return this.getApiManager_Member().api_ListQuery(queryObject);
    },

    async api_Member_Get ( systemId)
    {
        return this.getApiManager_Member().api_Get(systemId);
    },

    async api_Member_Save ( record)
    {
        return this.getApiManager_Member().api_Save(record);
    },

    async api_Member_Remove ( systemId)
    {
        return this.getApiManager_Member().api_Remove(systemId);
    },
    //---------------------------------------
    getApiManager_Order() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"Order", this.getAxiosManager(),null)
    },

    async api_Order_ListQuery ( queryObject)
    {
        return this.getApiManager_Order().api_ListQuery(queryObject);
    },

    async api_Order_Get ( systemId)
    {
        return this.getApiManager_Order().api_Get(systemId);
    },

    async api_Order_Save ( record)
    {
        return this.getApiManager_Order().api_Save(record);
    },

    async api_Order_Remove ( systemId)
    {
        return this.getApiManager_Order().api_Remove(systemId);
    },
    //---------------------------------------
    getApiManager_Product() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"Product", this.getAxiosManager(),null)
    },

    async api_Product_ListQuery ( queryObject)
    {
        return this.getApiManager_Product().api_ListQuery(queryObject);
    },

    async api_Product_Get ( systemId)
    {
        return this.getApiManager_Product().api_Get(systemId);
    },

    async api_Product_Save ( record)
    {
        return this.getApiManager_Product().api_Save(record);
    },

    async api_Product_Remove ( systemId)
    {
        return this.getApiManager_Product().api_Remove(systemId);
    },
    //---------------------------------------
    getApiManager_SiteSetting() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"SiteSetting", this.getAxiosManager(),null)
    },
    //SystemId為空白則由api自動判斷
    async api_SiteSetting_Get ( systemId)
    {
        return this.getApiManager_SiteSetting().api_Get(systemId);
    },

    async api_SiteSetting_Save ( record)
    {
        return this.getApiManager_SiteSetting().api_Save(record);
    },

    //---------------------------------------



}
