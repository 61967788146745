var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "ticket2", "button-index": "2" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              !_vm.isPublished
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnUpdate }
                    },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("發行")]
                  )
                : _vm._e(),
              _vm.isPublished
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnDownloadQR }
                    },
                    [
                      _c("i", { staticClass: "fa fa-save" }),
                      _vm._v("下載QRCode")
                    ]
                  )
                : _vm._e(),
              _vm.isPublished
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnDownloadTicket }
                    },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("列印票券")]
                  )
                : _vm._e(),
              _vm.isPublished
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnListTicket }
                    },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("票券清單")]
                  )
                : _vm._e(),
              _vm.isPublished
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnModifyTicket }
                    },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("整批修改")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "a_thinlabel col-sm-2 label-required",
              attrs: { for: "" }
            },
            [_vm._v("票種")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "票種" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.RelSP_TicketType,
                                expression: "formData.RelSP_TicketType"
                              }
                            ],
                            staticClass: "form-control",
                            staticStyle: { width: "200px" },
                            attrs: { disabled: _vm.saveThenLock },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "RelSP_TicketType",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.ticketTypes, function(item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.SystemId }
                              },
                              [_vm._v(_vm._s(item.Name))]
                            )
                          }),
                          0
                        ),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "a_thinlabel col-sm-2 label-required",
              attrs: { for: "" }
            },
            [_vm._v("有效日期")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "有效日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.EffectDt,
                              expression: "formData.EffectDt"
                            }
                          ],
                          staticClass: "form-control ",
                          staticStyle: { width: "200px" },
                          attrs: {
                            type: "date",
                            placeholder: "",
                            disabled: _vm.saveThenLock
                          },
                          domProps: { value: _vm.formData.EffectDt },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "EffectDt",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "a_thinlabel col-sm-2 label-required",
              attrs: { for: "" }
            },
            [_vm._v("發行張數")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "發行張數" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.QuantityTotal,
                              expression: "formData.QuantityTotal"
                            }
                          ],
                          staticClass: "form-control ",
                          staticStyle: { width: "200px" },
                          attrs: {
                            type: "number",
                            placeholder: "",
                            disabled: _vm.saveThenLock
                          },
                          domProps: { value: _vm.formData.QuantityTotal },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "QuantityTotal",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("備註")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "備註" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Memo,
                              expression: "formData.Memo"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "3", disabled: _vm.saveThenLock },
                          domProps: { value: _vm.formData.Memo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Memo",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("DLGPrint", {
        ref: "dlgEditor",
        on: { returnOk: _vm.dlgEditorReturnOK }
      }),
      _c("DLGTicketStatus", {
        ref: "dlgTicketStatus",
        on: { returnOk: _vm.dlgTicketStatusReturnOK }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }