var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        staticClass: "btn-group",
        attrs: {
          role: "group",
          "aria-label": "Button group with nested dropdown"
        }
      },
      [
        _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "dropdown-menu",
              attrs: { "aria-labelledby": "btnGroupDrop1" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(_vm.$formUtil.InputType_Text)
                    }
                  }
                },
                [_vm._v("文字框")]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(
                        _vm.$formUtil.InputType_TextArea
                      )
                    }
                  }
                },
                [_vm._v("文字框(多行)")]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(
                        _vm.$formUtil.InputType_Select
                      )
                    }
                  }
                },
                [_vm._v("下拉選擇")]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(
                        _vm.$formUtil.InputType_CheckBox
                      )
                    }
                  }
                },
                [_vm._v("多選")]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(_vm.$formUtil.InputType_Radio)
                    }
                  }
                },
                [_vm._v("單選")]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.DataItem_AddLast(_vm.$formUtil.InputType_Shop)
                    }
                  }
                },
                [_vm._v("購買")]
              )
            ]
          )
        ])
      ]
    ),
    _c(
      "table",
      { attrs: { id: "tblMain" } },
      [
        _vm._l(this.webPart.Fields, function(item, index) {
          return _c("tr", { key: index, staticClass: "tblMainTR" }, [
            _c("td", [_c("FormFieldRunner", { attrs: { field: item } })], 1),
            _c(
              "td",
              { staticStyle: { width: "100px" } },
              [
                _c("WebPartFormDesignerToolbar", {
                  ref: "toolbar" + item.Id,
                  refInFor: true,
                  attrs: { "web-part": _vm.webPart, field: item }
                })
              ],
              1
            )
          ])
        }),
        _vm._m(1)
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary dropdown-toggle",
        staticStyle: { width: "200px" },
        attrs: {
          id: "btnGroupDrop1",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fas fa-plus-circle" }), _vm._v("新增 ")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "tblMainTR" }, [
      _c("td", [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { role: "button", href: "#" }
          },
          [_vm._v("送出")]
        )
      ]),
      _c("td", { staticStyle: { width: "100px" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }