var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "udmIndex" } }),
      _c("MyToolbarBase", [
        _c(
          "div",
          {
            staticClass: "btn-group mr-2",
            attrs: { role: "group", "aria-label": "First group" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.btnUpdate }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("回上一頁")]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "fieldsPanel" },
        [
          _c("ValidationObserver", { ref: "form" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("類型")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.type,
                        expression: "formData.type"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("常態使用")
                    ]),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("客戶使用")
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("描述")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.userName,
                      expression: "formData.userName"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.userName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "userName", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("PassCode")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.passCode,
                      expression: "formData.passCode"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.passCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "passCode", $event.target.value)
                    }
                  }
                }),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        return _vm.GenPassCode()
                      }
                    }
                  },
                  [_vm._v("產生")]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("有效日期")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-between",
                    staticStyle: { "max-width": "400px" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.startDt,
                          expression: "formData.startDt"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "date",
                        id: "name",
                        name: "name",
                        placeholder: ""
                      },
                      domProps: { value: _vm.formData.startDt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "startDt", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" ~ "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.expireDt,
                          expression: "formData.expireDt"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "date",
                        id: "name",
                        name: "name",
                        placeholder: ""
                      },
                      domProps: { value: _vm.formData.expireDt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "expireDt",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("可以使用的UDM")]
              ),
              _c(
                "div",
                { staticClass: "a_thinInputDiv col-sm-10" },
                [
                  _c("MyListboxLeftRightKendo", {
                    attrs: { "datasource-left": _vm.fields },
                    model: {
                      value: _vm.selectValues,
                      callback: function($$v) {
                        _vm.selectValues = $$v
                      },
                      expression: "selectValues"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }