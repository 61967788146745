import md5 from 'js-md5';
import jQuery from 'jquery'

// import 'vuetify/dist/vuetify.min.css'

import DatetimePicker from 'vuetify-datetime-picker'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import $ from 'jquery' //main.js導入(全域)
import SimpleVueValidation from 'simple-vue-validator';
import CKEditor from 'ckeditor4-vue';
// import Vuetify from 'vuetify'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import ubossCustomize from "@/pages/commonUBossCustomize/ubossCustomize";

import { ValidationObserver,ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import zh_TW from 'vee-validate/dist/locale/zh_TW.json';


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import vSelect from 'vue-select'

import VueNumeric from 'vue-numeric'

import VueMeta from "vue-meta";

import uploader from 'vue-simple-uploader'

import InputColorPicker from "vue-native-color-picker";

import VueYoutube from 'vue-youtube'

import VueClipboard from 'vue-clipboard2'

import VoerroTagsInput from '@voerro/vue-tagsinput';

import VueGoodTablePlugin from 'vue-good-table';

import 'vue-good-table/dist/vue-good-table.css'

// import iframeResize from 'iframe-resizer/js/iframeResizer';


// import '@progress/kendo-ui'
// import '@progress/kendo-theme-default/dist/all.css'

// import { Scheduler } from '@progress/kendo-scheduler-vue-wrapper'   // eslint-disable-line
// import { SchedulerResource } from '@progress/kendo-scheduler-vue-wrapper' // eslint-disable-line
// import { SchedulerView } from '@progress/kendo-scheduler-vue-wrapper' // eslint-disable-line
// import { SchedulerInstaller } from '@progress/kendo-scheduler-vue-wrapper'

import VCalendar from 'v-calendar';


import vueKeepScroll from 'vue-keep-scroll'
import VueLazyload from 'vue-lazyload'
import KPop from "@ckienle/k-pop"
import "@ckienle/k-pop/themes/clean.css"

//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
import VueFab from 'vue-float-action-button'

//vue easy table
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import { VeLocale } from "vue-easytable";
// 引入英文语言包
import zhTW from "vue-easytable/libs/locale/lang/zh-TW.js";


import VueMq from 'vue-mq'

import VueDraggableResizable from 'vue-draggable-resizable'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'


export default {
  run: function (Vue) {



    window.jQuery = jQuery
    window.$ = jQuery



    Vue.config.productionTip = false

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
    Vue.prototype.$md5 = md5;

    Vue.use(SimpleVueValidation);

    Vue.use(CKEditor);
    Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
    Vue.use(DatetimePicker)

//vue-loading-overlay
// Vue.use(VueLoading); //Use default options
    Vue.use(VueLoading, {
      canCancel: true,
      color: '#000000',
      loader: 'dots', //spinner/dots/bars
      width: 50,
      height: 50,
      backgroundColor: '#ffffff',
      isFullPage: true,
      opacity: 0.8
    });

//---------------------------------------

    Vue.prototype.$c=new ubossCustomize();

//---------------------------------------


    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    });

    localize('zh_TW',zh_TW);

    localize({
      zh_TW: {
        messages: {
          required: '必填',
          email:'須為有效的電子信箱',
          min: 'this field must have no less than {length} characters',
          max: (_, { length }) => `this field must have no more than ${length} characters`
        }
      }
    });

//---------------------------------------

// Make BootstrapVue available throughout your project
    Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
    Vue.use(IconsPlugin)

//---------------------------------------



    Vue.component('vue-select', vSelect)



//---------------------------------------
// import Cleave from 'vue-cleave-component';
// Vue.use(Cleave)
// Vue.component('Cleave', Cleave)
//---------------------------------------

    Vue.use(VueNumeric)
//---------------------------------------
    Vue.use(VueMeta)
//---------------------------------------

    Vue.use(uploader)
//---------------------------------------
    Vue.use(InputColorPicker);
//---------------------------------------
// import VueYouTubeEmbed from 'vue-youtube-embed'
// Vue.use(VueYouTubeEmbed)
// Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube2" })


    Vue.use(VueYoutube)
//---------------------------------------
    VueClipboard.config.autoSetContainer = true // add this line
    Vue.use(VueClipboard)
//---------------------------------------

    Vue.component('tags-input', VoerroTagsInput);
//---------------------------------------

// import the styles

    Vue.use(VueGoodTablePlugin);
//-----------onepage----------------------------
    Vue.use(vueKeepScroll)

    Vue.use(VueLazyload)
// // 或者新增VueLazyload 選項
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })


    Vue.use(KPop);


    //---------------------------------------



    Vue.use(VueFroala)


    Vue.use(VueFab )

    Vue.use(VCalendar, {
      componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
    });
//---------------------------------------

    Vue.use(VueEasytable);


    VeLocale.use(zhTW);
    //---------------------------------------
    //可以設定breakpoint的值，也可以取得目前的螢幕解析度
    // https://github.com/AlexandreBonaventure/vue-mq

    Vue.use(VueMq, {
      breakpoints: { // default breakpoints - customize this
        sm: 450,
        md: 1250,
        lg: Infinity,
      },
      defaultBreakpoint: 'md' // customize this for SSR
    })

    //---------------------------------------
    Vue.component('vue-draggable-resizable', VueDraggableResizable)
  },
}
