<template>
  <div>
    <h1>先選日期，再選商品</h1>

    <MyCalendarOptionPicker timeperiod="specifyTimePeriod"></MyCalendarOptionPicker>



    <h1>Select2</h1>
<!--    <MySelectByAjax url="api/GPKApi/" method="GetDS_Customer" :send-data="{}" field-name-text="CustomerName" field-name-value="SystemId" v-model="id"></MySelectByAjax>-->


    <h1>V Calendar</h1>
    <vc-calendar :attributes="attributes" @dayclick="onDayClick" />
<!--    <v-calendar />-->


    <h1>VueSimpleCalendar</h1>
    <calendar-view
        :show-date="showDate"
        class="theme-default holiday-us-traditional holiday-us-official">
      <template #header="{ headerProps }">
        <calendar-view-header
            :header-props="headerProps"
            @input="setShowDate" />
      </template>
      <template #item>
        abcd
      </template>
    </calendar-view>

    <h1>Telerik Calendar</h1>
    <calendar />

    <h1>Telerik Scheduler</h1>
    <kendo-scheduler :data-source="localDataSource"
                     :date="date"
                     :height="600"
                     :timezone="'Etc/UTC'">
      <kendo-scheduler-view :type="'day'"></kendo-scheduler-view>
      <kendo-scheduler-view :type="'workWeek'" :selected="true"></kendo-scheduler-view>
      <kendo-scheduler-view :type="'week'"></kendo-scheduler-view>
      <kendo-scheduler-view :type="'month'"></kendo-scheduler-view>
      <kendo-scheduler-view :type="'agenda'"></kendo-scheduler-view>
    </kendo-scheduler>

    <h1>Calendar</h1>
    <div style="max-width:1024px">    <!-- template -->
      <MyCalendar startDate="2018-03-07" :dateData="data" mode="month">
        <div slot="header-left">
          <Button>month</Button>
          <Button>week</Button>
        </div>

        <div
            :class="['calendar-item', { 'is-otherMonth': false }]"
            slot-scope="item"
        >
          <div
              :class="['calendar-item-date']">
            {{ item.date.date.date }}

          </div>
          <div class="calendar-item-name">

            <a role="button" class="btn btn-primary" @click="btnClick">送出</a>
          </div>
        </div>
      </MyCalendar>
    </div>


    <h1>MyListLeftRight</h1>

    <MyListboxLeftRightKendo></MyListboxLeftRightKendo>

<!--    <MyCalendar startDate="2018-03-07" :dateData="data">-->
<!--      <div slot="header-left">-->
<!--        <Button>month</Button>-->
<!--        <Button>week</Button>-->
<!--      </div>-->

<!--      <template v-slot:body="{ data }">-->
<!--        <transition name="fade">-->
<!--          <div class="calendar-body-grid" >-->
<!--            <div v-for="(row, index) in data"-->
<!--                 :key="index"-->
<!--                 class="calendar-body-row">-->
<!--              <div v-for="col in row"-->
<!--                   :key="col.date.date"-->
<!--                   :class="['calendar-item', { 'is-otherMonth': !col.isCurMonth }]">-->
<!--                <div-->
<!--                    :class="['calendar-item-date']">-->
<!--                  {{col.date.date}}-->
<!--                </div>-->
<!--                <div class="calendar-item-name">{{col.data.title}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </transition>-->
<!--      </template>-->
<!--    </MyCalendar>-->
  </div>
</template>

<script>

// import MySelectByAjax from "@/pages/commonComponent/MySelectByAjax";
import MyCalendar from "@/pages/commonComponent/Calendar/calendar";
import { Calendar } from '@progress/kendo-vue-dateinputs';
import { Scheduler, SchedulerView } from '@progress/kendo-scheduler-vue-wrapper';


// import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import MyCalendarOptionPicker from "@/pages/commonComponent/CalendarEx/MyCalendarOptionPicker";
import MyListboxLeftRightKendo from "@/pages/commonComponent/MyListboxLeftRightKendo";
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
// require("vue-simple-calendar/static/css/default.css")
// require("vue-simple-calendar/static/css/holidays-us.css")

/**


 **/


export default {
  components: {
    MyListboxLeftRightKendo,
    MyCalendarOptionPicker, MyCalendar,Calendar,        'kendo-scheduler': Scheduler,
    'kendo-scheduler-view': SchedulerView},

  //name: "Test2",
  // props: [""],
  computed: {
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      data:[],
      days: [],
      // item:{
      //   title: "",
      //   date: {
      //     date: "",
      //   },
      //   data: {
      //     title: "",
      //   },
      // },
      date: new Date('2018/6/6'),
      localDataSource: [
        {
          id: 1,
          start: new Date("2018/6/6 08:00 AM"),
          end: new Date("2018/6/6 09:00 AM"),
          title: "Interview"
        },
        {
          id: 2,
          start: new Date("2018/6/6 08:00 AM"),
          end: new Date("2018/6/6 09:00 AM"),
          title: "Meeting"
        }
      ],
      showDate: new Date()
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    btnClick(){
      alert('hi')
    },
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    setShowDate(d) {
      this.showDate = d;
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
