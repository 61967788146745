var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _vm.showOnePage
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("OnePageIndex")
                  }
                }
              },
              [_vm._v("官網")]
            )
          ])
        : _vm._e(),
      _vm.showOnePage
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("OnePageAdvIndex4")
                  }
                }
              },
              [_vm._v("官網進階")]
            )
          ])
        : _vm._e(),
      _vm.showOnePage
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("OnePageAdvIndex3")
                  }
                }
              },
              [_vm._v("官網進階2")]
            )
          ])
        : _vm._e(),
      _vm.showShop
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary bg-gradient-info btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("OrderIndex")
                  }
                }
              },
              [_vm._v("購物車")]
            )
          ])
        : _vm._e(),
      _vm.showUDM
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("UDMIndex")
                  }
                }
              },
              [_vm._v("UDM")]
            )
          ])
        : _vm._e(),
      _vm.showTicket
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("TicketIndex")
                  }
                }
              },
              [_vm._v("票券")]
            )
          ])
        : _vm._e(),
      _vm.showTicket
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("ADMIndex")
                  }
                }
              },
              [_vm._v("客戶管理")]
            )
          ])
        : _vm._e(),
      _vm.showTicket2
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-primary btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("Ticket2TicketTypeIndex")
                  }
                }
              },
              [_vm._v("票券")]
            )
          ])
        : _vm._e(),
      _vm.showForm
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-warning btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("UFormIndex")
                  }
                }
              },
              [_vm._v("表單")]
            )
          ])
        : _vm._e(),
      _vm.showUTV
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-success btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("UTubeIndex")
                  }
                }
              },
              [_vm._v("UTube")]
            )
          ])
        : _vm._e(),
      _vm.showMarketing
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-success btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("MarketingQRIndex")
                  }
                }
              },
              [_vm._v("行銷工具")]
            )
          ])
        : _vm._e(),
      _vm.showCalendar
        ? _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-primary bg-gradient-success btnNav abutton",
                on: {
                  click: function($event) {
                    return _vm.goto("CalendarIndex")
                  }
                }
              },
              [_vm._v("日曆")]
            )
          ])
        : _vm._e(),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary bg-gradient-dark btnNav abutton",
          attrs: { href: "/Login" }
        },
        [_vm._v("登出")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }