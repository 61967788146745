<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingSiteSetting"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <!--        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>-->
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <div style="max-width: 1024px">
      <div class="row">
        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.A_SettingBasicInfo)">{{ Setting.A_SettingBasicInfo.Text }}</a>
        </div>
      </div>
      <div class="row" >
  <!--      <div class="col-3" v-for="(item,index) in Setting" :key="index">-->
  <!--        <a role="button" class="btn btn-primary aButton" @click="changeSetting(item)">{{ item.Text }}</a>-->
  <!--      </div>-->

<!--        //QQ0412-->
        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.B_SettingShop)">{{ Setting.B_SettingShop.Text }}</a>
        </div>

        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.B_SettingShop2)">{{ Setting.B_SettingShop2.Text }}</a>
        </div>

        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="gotoShopDesignTime">畫面設定</a>
        </div>

<!--        <div class="col-3">-->
<!--          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.PaymentGeneral)">{{ Setting.PaymentGeneral.Text }}</a>-->
<!--        </div>-->
<!--        <div class="col-3" v-if="Setting.PaymentGeneral.Setting.IsEnableNeweb">-->
<!--          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.PaymentNeweb)">{{ Setting.PaymentNeweb.Text }}</a>-->
<!--        </div>-->
<!--        <div class="col-3" v-if="Setting.PaymentGeneral.Setting.IsEnableECPay">-->
<!--          &lt;!&ndash;          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.PaymentECPay)" >{{ Setting.PaymentECPay.Text }}</a>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--          &lt;!&ndash;          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.PaymentEZPay)">{{ Setting.PaymentEZPay.Text }}</a>&ndash;&gt;-->
<!--        </div>-->
      </div>
      <div class="row">
        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.C_SettingOnePage)">{{ Setting.C_SettingOnePage.Text }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <a role="button" class="btn btn-primary aButton" @click="changeSetting(Setting.D_SettingForm)">{{ Setting.D_SettingForm.Text }}</a>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">

        <SettingBlockBase :setting-all="Setting" :current-setting-id="CurrentSettingId" :current-setting-title="CurrentSettingTitle" style="max-width:1024px"></SettingBlockBase>
      </div>
    </div>



  </div>


</template>

<script>

import SettingBlockBase from "@/pages/ubossBk/views/setting/SettingBlockBase";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";

/**

 **/


export default {
  components: { COM_HeaderNav, SettingBlockBase},
  //name: "Setting",
  // props: [""],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      CurrentSettingId: "",
      CurrentSettingTitle: "",
      Setting: {
        A_SettingBasicInfo: {
          Text: "基本資料",
          CompId: "A_SettingBasicInfo",
        },
        B_SettingShop: {
          Text: "購物網站",
          CompId: "B_SettingShop",
        },
        B_SettingShop2: {
          Text: "商品排序",
          CompId: "B_SettingShop",
        },
        C_SettingOnePage: {
          Text: "OnePage官網",
          CompId: "C_SettingOnePage",
        },
        D_SettingForm: {
          Text: "UForm表單",
          CompId: "D_SettingForm",
        },
        T_SettingThirdPartyService_NewebPay: {
          Text: "藍新支付",
          CompId: "T_SettingThirdPartyService_NewebPay",
        },
        PaymentGeneral: {
          Text: "付款",
          CompId: "PaymentGeneral",
          Setting:{
            IsEnableNeweb:true,
            IsEnableECPay:false,
          }
        },
        T_SettingThirdPartyService_EcPay: {
          Text: "綠界支付",
          CompId: "T_SettingThirdPartyService_EcPay",
        },
      }
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    changeSetting(item) {
      console.log(item.CompId);
      this.CurrentSettingId = item.CompId;
      this.CurrentSettingTitle = item.Text;
    },
    gotoShopDesignTime(){
      //QQ0412
      window.open("http://127.0.0.1:9008/Index?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09")
    },
    btnUpdate() {

    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>
.aButton {
  width: 200px;
  color: white;
}
</style>
