<template>
  <div>
    <COM_HeaderNav mode="udmIndex"></COM_HeaderNav>

<!--    <div class="form-group row">-->
<!--      <div class="col-sm-10">-->
<!--        <a class="btn btn-primary mybutton" href="#" @click.prevent="submit" style="width: 100px">儲存</a>-->
<!--        <a class="btn btn-primary" href="#"><i class="fa fa-clone"></i> 複製</a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>-->

<!--    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta"  v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"> </MyToolbarVuetify>-->
    <MyToolbarVuetify ref="myToolbar" :grid-meta="gridMeta"  v-on:button-click="toolbarButton_Click"> </MyToolbarVuetify>

<!--      //QQ width 1000-->
    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig" :grid-meta="gridMeta" class="bk_firstBlock" >
      <template v-slot:Fun="{}">
<!--        <a class="btn btn-primary" ><i class="fa fa-align-justify handle"></i>拖拉順序</a>-->
      </template>
      <template v-slot:IsEnable="{ item }">
        <MyLabelIsEnable v-model="item.IsEnable"></MyLabelIsEnable>
      </template>
    </MyGridVuetify>

form panel
      <MyFormPanelTester></MyFormPanelTester>
table easy
    <a role="button" class="btn btn-primary" @click="btnTest1">Test</a>
    {{info}}
    <MyVueEasytable ref="grid"></MyVueEasytable>
table easy demo
    <MyVueEasytableDemo></MyVueEasytableDemo>
table


<!--    <MyVueBootstrap4Table :columns="gridColumns" :rows="gridRows" :config="gridConfig"></MyVueBootstrap4Table>-->

<!--    <MyVueBootstrap4Table_Demo></MyVueBootstrap4Table_Demo>-->

<!--    <table class="table table-hover" id="tblSecond">-->
<!--      <thead class="thead-light">-->
<!--      <tr>-->
<!--        <th class="text-center">功能</th>-->
<!--        <th>名稱</th>-->
<!--&lt;!&ndash;        <th>是否顯示</th>&ndash;&gt;-->
<!--        <th>是否啟用</th>-->
<!--        <th>Pass Code</th>-->
<!--        <th>說明</th>-->
<!--        <th>Tag</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(item,index) in this.datas.Details" :key="index">-->
<!--        <td class="" style="width: 300px">-->
<!--          <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i>編輯頁面</a>-->
<!--          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="fa fa-trash"></i></a>-->
<!--          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>-->
<!--          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>-->
<!--        </td>-->
<!--        <td width="120px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.Name">-->
<!--        </td>-->

<!--        <td width="120px">-->
<!--          <select class="form-control" style="width: 100px" v-model="item.IsEnable">-->
<!--            <option :value="true">啟用</option>-->
<!--            <option :value="false">停用</option>-->
<!--          </select>-->
<!--        </td>-->
<!--        <td width="120px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.PassCode">-->

<!--        </td>-->
<!--        <td width="120px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.Memo">-->
<!--        </td>-->
<!--        <td width="200px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.Tag">-->
<!--        </td>-->
<!--&lt;!&ndash;        <td width="100px">&ndash;&gt;-->
<!--&lt;!&ndash;          <input type="number" class="form-control" placeholder="" v-model="item.Month">&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>&ndash;&gt;-->
<!--&lt;!&ndash;          <select class="form-control" v-model="item.Type">&ndash;&gt;-->
<!--&lt;!&ndash;            <option></option>&ndash;&gt;-->
<!--&lt;!&ndash;            <option value="USD">USD</option>&ndash;&gt;-->
<!--&lt;!&ndash;            <option value="EUR">EUR</option>&ndash;&gt;-->
<!--&lt;!&ndash;            <option value="GBP">GBP</option>&ndash;&gt;-->
<!--&lt;!&ndash;            <option value="CHF">CHF</option>&ndash;&gt;-->
<!--&lt;!&ndash;          </select>&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>&ndash;&gt;-->
<!--&lt;!&ndash;          <input type="number" class="form-control" placeholder="" v-model="item.Rate">&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--      </tr>-->

<!--      </tbody>-->
<!--    </table>-->
wh
    {{width}} {{height}}

    <span v-if="$mq !== 'sm'">
      <nav class="navbar navbar-light bg-light" style="max-width: 1200px">
          <button class="btn btn-primary justify-content-center">Next</button>
      </nav>
    </span>
    <span v-if="$mq === 'sm'">
      <nav class="navbar fixed-bottom navbar-light bg-light">
        <button class="btn btn-primary ml-auto">Next</button>
      </nav>
    </span>



  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyLabelIsEnable from "@/pages/commonComponent/MyLabelIsEnable.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
// import MyVueBootstrap4Table from "@/pages/commonComponent/MyVue-Bootstrap4-Table.vue";
// import MyVueBootstrap4Table_Demo from "@/pages/commonComponent/MyVue-Bootstrap4-Table_Demo.vue";
import MyVueEasytableDemo from "@/pages/commonComponent/MyVueEasytable/MyVueEasytableDemo.vue";
import MyVueEasytable from "@/pages/commonComponent/MyVueEasytable/MyVueEasytable.vue";
import {MyVueEasyGrid ,MyVueEasyGridUtility,MyVueEasyGridActionBar,MyVueEasyGridFilters,MyVueEasyGridExpandForm} from "@/pages/commonComponent/MyVueEasytable/js/MyVueEasytable";
import MyFormPanelTester from "@/pages/commonComponent/MyFormPanel/MyFormPanelTester.vue";
import {MyToolbar, MyToolbarButtonIdEnum, MyToolbarGroup} from "@/pages/commonComponent/js/MyToolbarVuetify";

let configEditPage="UDMEdit";



export default {
  // setup() {
  //   const { width, height } = useWindowSize()
  //
  //   return {
  //     width,
  //     height,
  //   }
  // },
  components: {
    MyFormPanelTester,
    MyVueEasytable,
    MyVueEasytableDemo,
     MyToolbarVuetify, MyLabelIsEnable, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      info:"",
      id: "",
      width:0,
      height:0,
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:10,
        itemKey:"SystemId",
        headers: [
          {
            text: '功能',
            align: 'start',
            sortable: false,
            value: 'Fun',
          },
          { text: '名稱', value: 'Name' },
          { text: '是否啟用', value: 'IsEnable' },
          { text: '說明', value: 'Memo' },
          { text: '建立日期', value: 'CreateDt' },
          { text: '修改日期', value: 'ModifyDt' },
        ],
        datas: [
          {
            Fun:"",
            SystemId:"1",
            Name:"產品1",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Fun:"",
            SystemId:"2",
            Name:"產品2",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Fun:"",
            SystemId:"3",
            Name:"產品3",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
        ],
      },
      datas:{
        Details:[
          {
            Name:"產品1",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Name:"產品2",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
          {
            Name:"產品3",
            IsEnable:true,
            Memo:"",
            CreateDt:"2020-01-01",
            ModifyDt:"2020-01-01",
          },
        ]

      },
      gridMeta:{},
      ready:false,
      gridRows: [
        {
          Name:"產品1",
          IsEnable:true,
          Memo:"",
          CreateDt:"2020-01-01",
          ModifyDt:"2020-01-01",
        },
        {
          Name:"產品2",
          IsEnable:true,
          Memo:"",
          CreateDt:"2020-01-01",
          ModifyDt:"2020-01-01",
        },
        {
          Name:"產品3",
          IsEnable:true,
          Memo:"",
          CreateDt:"2020-01-01",
          ModifyDt:"2020-01-01",
        },
      ],
      gridColumns: [
        {label:"名稱",name:"Name",uniqueId:true},
        {label:"是否啟用",name:"IsEnable",uniqueId:false},
        {label:"說明",name:"Memo",uniqueId:false},
        {label:"建立日期",name:"CreateDt",uniqueId:false},
        {label:"修改日期",name:"ModifyDt",uniqueId:false},
      ],
      gridColumnsX: [
        {
          label: "id",
          name: "id",
          filter: {
            type: "simple",
            placeholder: "id"
          },
          sort: true,
          uniqueId: true
        },
        {
          label: "First Name",
          name: "name.first_name",
          filter: {
            type: "select",
            placeholder: "Enter first name",
            mode: "multi",
            options: [
              {
                "name": "Irwin",
                "value": "Irwin"
              },
              {
                "name": "Don",
                "value": "Don"
              },
              {
                "name": "Lolita Paris",
                "value": "Lolita"
              }
            ]
          },
          sort: true,
          // slot_name: "my_duplicate_id_column"
        },
        {
          label: "Last Name",
          name: "name.last_name",
          filter: {
            type: "simple",
            placeholder: "Enter last name",
            case_sensitive: true
          },
          sort: true,
        },
        {
          label: "Email",
          name: "email",
          filter: {
            type: "simple",
            placeholder: "Enter email"
          },
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          label: "City",
          name: "address.city",
          sort: true
        },
        {
          label: "Country",
          name: "address.country",
          filter: {
            type: "simple",
            placeholder: "Enter country"
          },
        },
      ],
      // actions: [
      //   {
      //     btn_text: "Download",
      //     event_name: "on-download",
      //     event_payload: {
      //       msg: "my custom message"
      //     }
      //   }
      // ],
      gridConfig: {

      },
      gridData: [
        {
          SystemId: "1",
          name: "Aohn",
          date: "1900-05-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Shanghai",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "2",
          name: "Dickerson",
          date: "1910-06-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Beijing",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "3",
          name: "Larsen",
          date: "2000-07-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Chongqing",
          status: "enable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "4",
          name: "Geneva",
          date: "2010-08-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Xiamen",
          status: "disable",
          textbox:"text",
          select:"select",
        },
        {
          SystemId: "5",
          name: "Jami",
          date: "2020-09-20",
          hobby: "coding and coding repeat",
          address: "No.1 Century Avenue, Shenzhen",
          status: "disable",
          textbox:"text",
          select:"select",
        },
      ],
    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()

      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    toolbarButton_Click(buttonId,selectIds){
      if (buttonId===MyToolbarButtonIdEnum.Add){
        console.log("");
      }else if (buttonId===MyToolbarButtonIdEnum.Delete){
        console.log("");
      }

      alert(buttonId);
      alert(selectIds);
    },
    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },
    btnAdd_Click(){
      this.$router.push({ name: configEditPage, query: { SystemId: "" }})
    },
    btnEdit_Click(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    btnDelete_Click(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    },
    btnTest1(){
      //Radio
      this.$refs.grid.gridUtil_RadioSelectById["1"]
      this.$refs.grid.gridUtil_RadioUnSelect(["1"])

      //Checkbox
      this.$refs.grid.gridUtil_CheckboxSelectAll();
      this.$refs.grid.gridUtil_UnCheckboxSelectAll();

      //Column
      this.$refs.grid.gridUtil_ShowColumnsByKeys(["name"])
      this.$refs.grid.gridUtil_HideColumnsByKeys(["name"])

      //Other
      this.$refs.grid.gridUtil_ShowEmpty();

      //Get Info
      this.info="";
      this.info+="R:"+this.$refs.grid.gridUtil_RadioSelectedId();
      this.info+="C:"+this.$refs.grid.gridUtil_CheckBoxSelectedIds();
    },
    btnRowButtonEdit(row){
      alert(row.SystemId);
    },
    btnRowButtonDelete(row){
      alert(row.SystemId);
    },
  },
  created: function () {
    console.log("page created");
  },
  mounted: function () {
    console.log("page mounted");
    // const { widthX, heightY } = useWindowSize()

    this.loadData()

    //Grid Row Button
    let gridActionBar = new MyVueEasyGridActionBar();

    gridActionBar.addButton("btn btn-primary", "Edit", this.btnRowButtonEdit)
    gridActionBar.addButton("btn btn-danger", "Delete", this.btnRowButtonDelete)

    //Expand顯示的資訊
    let gridExpandForm = new MyVueEasyGridExpandForm();

    gridExpandForm.addItem("name", "Name");
    gridExpandForm.addItem("address", "Address");

    //Render Method
    let gridUtility = new MyVueEasyGridUtility();

    let funRenderHyperLink = gridUtility.GetFun_RenderHyperLink("test-text", (row) => row.name, (row) => "http://github.com/" + row.name);
    let funRenderActionBar=gridUtility.GetFun_RenderActionButton(gridActionBar.Buttons);
    let funRenderExpandForm=gridUtility.GetFun_RenderExpandForm(gridExpandForm.Items);

    //Filter
    let gridFilters=new MyVueEasyGridFilters();
    gridFilters
        .addFilter("enable","enable")
        .addFilter("disabled","disabled")

    //欄位
    let grid = new MyVueEasyGrid(this.$refs.grid)
        .addColumnSeq()
        .addColumnExpand()
        .addColumnCheckBox()
        .addColumnRadio()
        .addColumnRender("Action", "action", funRenderActionBar)
        .addColumnRender("Name", "name", funRenderHyperLink)
        .addColumnRender("Date", "date", funRenderHyperLink)
        .addColumn("Hobby", "hobby",true)
        .addColumn("Address", "address",true)
        .addColumnFilter("Status","status",gridFilters,this.SearchStats_Confirm,this.SearchStats_Reset)
    ;

    //格式化儲存格
    let formatCellFun=({ row, column, rowIndex }) => { // eslint-disable-line
      //整欄
      if (column.key === "date") {
        return "table-body-cell-class1";
      }

      //某欄的 某個儲存格
      if (column.key === "name" && row.name === "Larsen") {
        return "table-body-cell-class2";
      }
    };

    //設定Grid
    this.$refs.grid.set_expandOption(funRenderExpandForm);
    this.$refs.grid.set_columns(grid.Columns);
    this.$refs.grid.set_tableData(this.gridData);
    this.$refs.grid.set_cellStyleOption(formatCellFun);

    //---------------------------------------
    let toolbarGroup1=new MyToolbarGroup();
    toolbarGroup1
        .addButton_Add()
        .addButton_Edit()
        .addButton_Delete()
        .addButton_Copy()//
        .addButton_Setting()
        .addButton_Export()
        .addButton_Sort()
        .addButton_Query()
        .addButton_DeleteAlone()
    ;
    let toolbarGroup2=new MyToolbarGroup();
    // toolbarGroup2
    //     .addButton_Setting()
    //     .addButton_Export()
    //     .addButton_Sort()
    //     .addButton_Query()
    //     .addButton_DeleteAlone()
    // ;

    let toolbar=new MyToolbar();

    toolbar.addGroup(toolbarGroup1).addGroup(toolbarGroup2);

    this.$refs.myToolbar.setProp_Toolbar(toolbar);

  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>

<style>
.table-body-cell-class1 {
  background: #91d5ff !important;
  color: #fff !important;
}

.table-body-cell-class2 {
  background: orange !important;
  color: #fff !important;
}
</style>
