var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "backgroundSetting", style: _vm.cssVars }, [
    _c("div", { attrs: { id: "layoutSidenav" } }, [
      _c(
        "div",
        { attrs: { id: "layoutSidenav_content" } },
        [
          _vm.OnePage.Header
            ? _c("COM_Header", {
                attrs: {
                  "design-time": _vm.designTime,
                  header: _vm.OnePage.Header,
                  common: _vm.OnePage.Common,
                  "web-parts": _vm.OnePage.WebParts,
                  "one-page-adv": _vm.OnePageAdv,
                  "one-page-adv-cname": _vm.OnePageAdvCName,
                  "page-id": _vm.OnePageId,
                  type: this.$u.UBossOnePageHeaderType_OnePage
                },
                on: { changePage: _vm.changePage }
              })
            : _vm._e(),
          _vm.OnePage.Header && _vm.layoutType === "1"
            ? _c("BlockListView", {
                attrs: {
                  "design-time": _vm.designTime,
                  "area-id": "area1",
                  "page-id": _vm.OnePageId,
                  "web-parts": _vm.OnePage.WebParts,
                  "page-mode": this.$u.UBossPageMode_OnePage
                },
                on: { Save: _vm.Save, Preview: _vm.Preview }
              })
            : _vm._e(),
          _vm.OnePage.Header && _vm.layoutType === "2"
            ? _c("BlockListView2", {
                attrs: {
                  "design-time": _vm.designTime,
                  "area-id": "area1",
                  "page-id": _vm.OnePageId,
                  "web-parts": _vm.OnePage.WebParts,
                  "page-mode": this.$u.UBossPageMode_OnePage
                },
                on: { Save: _vm.Save, Preview: _vm.Preview }
              })
            : _vm._e(),
          _vm.OnePage.Header
            ? _c("COM_Footer", {
                attrs: {
                  "design-time": _vm.designTime,
                  footer: _vm.OnePage.Footer,
                  "page-id": _vm.OnePageId
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }