
var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossUTubeBkApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------

    //---------------------------------------
    getApiManager_UTubeChannel() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"UTubeChannel", this.getAxiosManager(),null)
    },

    async api_UTubeChannel_ListQuery ( queryObject)
    {
        return this.getApiManager_UTubeChannel().api_ListQuery(queryObject);
    },

    async api_UTubeChannel_Get ( systemId)
    {
        return this.getApiManager_UTubeChannel().api_Get(systemId);
    },

    async api_UTubeChannel_Save ( record)
    {
        return this.getApiManager_UTubeChannel().api_Save(record);
    },

    async api_UTubeChannel_Remove ( systemId)
    {
        return this.getApiManager_UTubeChannel().api_Remove(systemId);
    },
    //---------------------------------------



}
