var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("先選日期，再選商品")]),
      _c("MyCalendarOptionPicker", {
        attrs: { timeperiod: "specifyTimePeriod" }
      }),
      _c("h1", [_vm._v("Select2")]),
      _c("h1", [_vm._v("V Calendar")]),
      _c("vc-calendar", {
        attrs: { attributes: _vm.attributes },
        on: { dayclick: _vm.onDayClick }
      }),
      _c("h1", [_vm._v("VueSimpleCalendar")]),
      _c("calendar-view", {
        staticClass: "theme-default holiday-us-traditional holiday-us-official",
        attrs: { "show-date": _vm.showDate },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(ref) {
              var headerProps = ref.headerProps
              return [
                _c("calendar-view-header", {
                  attrs: { "header-props": headerProps },
                  on: { input: _vm.setShowDate }
                })
              ]
            }
          },
          {
            key: "item",
            fn: function() {
              return [_vm._v(" abcd ")]
            },
            proxy: true
          }
        ])
      }),
      _c("h1", [_vm._v("Telerik Calendar")]),
      _c("calendar"),
      _c("h1", [_vm._v("Telerik Scheduler")]),
      _c(
        "kendo-scheduler",
        {
          attrs: {
            "data-source": _vm.localDataSource,
            date: _vm.date,
            height: 600,
            timezone: "Etc/UTC"
          }
        },
        [
          _c("kendo-scheduler-view", { attrs: { type: "day" } }),
          _c("kendo-scheduler-view", {
            attrs: { type: "workWeek", selected: true }
          }),
          _c("kendo-scheduler-view", { attrs: { type: "week" } }),
          _c("kendo-scheduler-view", { attrs: { type: "month" } }),
          _c("kendo-scheduler-view", { attrs: { type: "agenda" } })
        ],
        1
      ),
      _c("h1", [_vm._v("Calendar")]),
      _c(
        "div",
        { staticStyle: { "max-width": "1024px" } },
        [
          _c(
            "MyCalendar",
            {
              attrs: {
                startDate: "2018-03-07",
                dateData: _vm.data,
                mode: "month"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(item) {
                    return _c(
                      "div",
                      { class: ["calendar-item", { "is-otherMonth": false }] },
                      [
                        _c("div", { class: ["calendar-item-date"] }, [
                          _vm._v(" " + _vm._s(item.date.date.date) + " ")
                        ]),
                        _c("div", { staticClass: "calendar-item-name" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { role: "button" },
                              on: { click: _vm.btnClick }
                            },
                            [_vm._v("送出")]
                          )
                        ])
                      ]
                    )
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "header-left" }, slot: "header-left" },
                [
                  _c("Button", [_vm._v("month")]),
                  _c("Button", [_vm._v("week")])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("h1", [_vm._v("MyListLeftRight")]),
      _c("MyListboxLeftRightKendo")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }