<template>
  <div>
    <!--  <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>-->
    冰的數量是 "秒" ，請勿輸入小數 <br/>

    最大總量建議:600ml<br/>
    目前總量:{{getTotalMl()}} ml   (每秒冰以35ml計算)

    <div class="row" style="max-width: 800px">
      <div class="col-3" v-for="(value, propertyName) in this.propsValue" :key="propertyName" style="max-width: 200px">
        <label> {{propertyName}}</label>

        <input type="number" class="form-control "  placeholder="" v-model.number="propsValue[propertyName]" :class="getCssClass(propsValue[propertyName])">
        <span v-if="propertyName==='冰'" class="">
            秒
            </span>
        <span v-else>

            </span>
      </div>
    </div>

    <hr>

    <a role="button" class="btn btn-primary" @click="json_ConvertNewFmtToUBoss">由Json載入(3碼)</a>
    <textarea class="form-control" id="txtMemo" name="txtMemo" rows="5" v-model="jsonNew"></textarea>
    <hr>
    <a role="button" class="btn btn-primary" @click="json_ConvertUBossFmtToNew">除錯用-再轉回Json 3碼</a>
<!--    <table class="table table-hover" id="tblMain" style="width:300px;">-->
<!--      <thead class="thead-light">-->
<!--      <tr>-->
<!--        <th>材料</th>-->
<!--        <th>數量(ml)</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(value, propertyName) in this.datasDemo" :key="propertyName">-->
<!--        <td width="120px">-->
<!--          <label> {{propertyName}}</label>-->
<!--        </td>-->
<!--        <td width="100px">-->

<!--            <input type="number" class="form-control "  placeholder="" v-model.number="datasDemo[propertyName]">-->
<!--            <span v-if="propertyName==='冰'" class="">-->
<!--            秒-->
<!--            </span>-->
<!--            <span v-else>-->

<!--            </span>-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
  </div>
</template>

<script>
/**
 <PpdDLGDrinkMaterial v-model="ppdRecipe"></PpdDLGDrinkMaterial>

 ppdRecipe:{},
 **/
import apiPpd from "@/pages/ppd/util/apiPpd";

export default {
  props: {
    // datas: {
    //   type: Array,
    //   default() {
    //     return []
    //   }
    // },
    value: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      datasDemo:{
        冰:0,
        百香果:0,
        西柚:0,
        黑糖:0,
        葡萄:0,
        芒果:0,
        柳橙:0,
        荔枝:0,
        粉桃:0,
        草莓:0,
        蔓越莓:0,
        鳳梨荔枝:0,
        鮮奶:0,
        哈密瓜:0,
        蔗糖:0,
        醇木紅茶:0,
        青茶:0,
        聖羅勒:0,
        純淨水:0,
      },
      jsonNew:"",
    };
  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue(){
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty){
        let template={...this.datasDemo};
        this.value=template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    getTotalMl(){
      let total=0
      for(let key in this.propsValue){
        if (key==="冰"){
          total+=this.propsValue[key] * 35;
        }else{
          total+=this.propsValue[key];
        }

      }
      return total
    },
    getCssClass(value){
      if (value!==0){
        return "haveValueTextValue";
      }
    },
    async json_ConvertNewFmtToUBoss () {
      let data=await apiPpd.api_Drink_Json_ConvertNewFmtToUBoss(this.jsonNew);

      if (data.IsSuccess){
        this.value=JSON.parse(data.SuccessMsg);
      }else{
        this.this.value={};
      }
    },
    async json_ConvertUBossFmtToNew () {
      debugger // eslint-disable-line
      let data=await apiPpd.api_Drink_Json_ConvertUBossFmtToNew(JSON.stringify(this.value));

      if (data.IsSuccess){
        this.jsonNew=data.SuccessMsg;
      }else{
        this.jsonNew="";
      }
    },
  },
  mounted() {
    this.initValue()
  },
}
</script>

<style scoped>
.haveValueTextValue{
  background-color: #1da1f2;
  color:white;
}

</style>
