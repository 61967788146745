<template>
  <div>
    <div id="app" style="max-width: 1500px">
<!--      gridMeta:{{gridMeta}}-->
      <v-app id="inspire">
<!--          mobile-breakpoint="0"   讓grid在mobile時，不會變為card方式顯示一個row -->
        <v-data-table
            :headers="config.headers"
            :items="config.datas"
            :items-per-page="config.items_per_page"
            :footer-props="gridFooterProps"
            :item-key="config.itemKey"
            class="elevation-1"
            show-select
            :single-select=false
            v-model="gridMeta.selectedRecord"
            mobile-breakpoint="0"
        >
          <template
              v-for="slot in Object.keys(config.datas[0])"
              v-slot:[`item.${slot}`]="{ item }"
          >
            <slot
                :name="slot"
                :item="item"
            >
              {{item[slot]}}
            </slot>
          </template>




        </v-data-table>
      </v-app>
    </div>
  </div>
</template>

<script>/**
 <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig"  >
 <template v-slot:name="{ item }">
 {{ item.name}} xx
 </template>
 <template v-slot:calories="{ item }">
 {{ item.calories}} ccc
 </template>
 </MyGridVuetify>


 gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [
          {
            text: '功能',
            align: 'start',
            sortable: false,
            value: 'Fun',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
        ],
        datas: [
          {
            SystemId:"1",
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          {
            SystemId:"2",
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: '1%',
          },
        ],
      }

Column
 <template v-slot:Fun="{ item }">
 <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>
 </template>
 <template v-slot:TITLE="{item}">
 {{item.TITLE|fmtTitle}}
 </template>
 <template v-slot:UPDATE_DATE="{item}">
 {{item.UPDATE_DATE|fmtDate}}
 </template>
 <template v-slot:ORDERKEY="{item}">
 <input type="text" class="form-control"  name="name" placeholder="" v-model.lazy="item.ORDERKEY" v-numeric-or-empty @change="updateOrderKey(item,item.ORDERKEY)" style="width: 80px">
 </template>
 <template v-slot:STATUS="{item}">
 <a href="#" v-if="item.STATUS==='1'" @click="updateStatus(item,'0')"><v-icon>far fa-circle</v-icon></a>
 <a href="#" v-if="item.STATUS!=='1'" @click="updateStatus(item,'1')"><v-icon>fas fa-times</v-icon></a>
 </template>
 <template v-slot:type="{item}">
 <p v-if="item.type==='1'" >常態使用</p>
 <p v-if="item.type==='2'" >客戶使用</p>
 </template>
 <template v-slot:actions="{ item }">
 <v-btn color="primary" @click="editItem(item)">編輯</v-btn>
 </template>

 <template v-slot:isEnable="{ item }">
 <MyLabelIsEnable v-model="item.isEnable"></MyLabelIsEnable>
 </template>
 <template v-slot:isVisible="{ item }">
 <MyLabelIsVisible v-model="item.isVisible"></MyLabelIsVisible>
 </template>
 <template v-slot:isEnable="{ item }">
 <MyLabelTrueFalse v-model="item.isVisible" true-text="見" false-text="不見"></MyLabelTrueFalse>
 </template>


 */
import * as dec from "@/pages/common/declare";

export default {
  // props: [""],
  props: {
    items_per_page: {
      type: Number,
      default: 10
    },
    config:{
      type: Object,
      default: function () {
        return {
          items_per_page:10,
          itemKey:"SystemId",
          headers: [
          ],
          datas: [

          ],
        }
      }
    },
    gridMeta: {
      type: Object,
      default: function () {
        return {
          selectedRecord:[]
        }
      }
    },
    // headers: {
    //   type: Array,
    //   // Object or array defaults must be returned from
    //   // a factory function
    //   default: function () {
    //     return []
    //   }
    // },
    // datas: {
    //   type: Array,
    //   // Object or array defaults must be returned from
    //   // a factory function
    //   default: function () {
    //     return []
    //   }
    // },
  },
  data: function () {
    return {
      id: "",
      fields:["name","SystemId"],
      gridFooterProps: dec.GridFooterProps,
      // gridMeta:{
      //   selectedRecord:[]
      // },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    grid_SearchFunction(row, col, cellValue, searchTerm){// eslint-disable-line
      return cellValue === 'my value';
    },
    selectedRows(){
      return this.$refs.grid.selectedRows;
    },
    rowStyleClassFn(row) { //eslint-disable-line
      return row.vgtSelected ? 'VGT-row _dblclick' : 'VGT-row';
    },
    initSortable() {
      // let table = document.querySelector("handle");
      let table = document.querySelector("tbody");
      const _self = this;
      // this way we avoid data binding
      _self.dragNdrop = JSON.parse(JSON.stringify(_self.config.datas));

      window.Sortable.create(table, {
        handle: ".handle",
        onEnd({
                newIndex,
                oldIndex
              }) {
          _self.dragNdrop.splice(
              newIndex,
              0,
              ..._self.dragNdrop.splice(oldIndex, 1)
          );
          console.log(_self.dragNdrop);
        }
      });
    },
  },
  mounted() {
    //this.loadData()
    this.initSortable()
  },
}
</script>

<style scoped>

</style>

<style scoped lang="scss">
//讓Grid的下方，不會有一大片空白
::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>
