const MyFormPanelFieldUITypeEnum = Object.freeze({"input":1,"inputNumberOnly":2, "checkbox":3, "radio":4,"textarea":5})
const MyFormPanelValidatorEnum = Object.freeze({"require":1, "number":2, "date":3})

class MyFormPanelForm {
    constructor() {
        this._Panels = [];
    }

    get Panels() {
        return this._Panels;
    }

    addPanel(panel) {
        this._Panels.push(panel);
        return this;
    }
}

class MyFormPanelPanel {
    constructor(text) {
        this._text=text;
        this._Rows = [];
    }

    get Rows() {
        return this._Rows;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    addRow() {
        let row = new MyFormPanelRow();

        this._Rows.push(row);
        return row;
    }
}

class MyFormPanelRow {
    constructor() {
        this._Fields = [];
    }

    get Fields() {
        return this._Fields;
    }

    addFields(gridSize,field) {
        field.gridSize=gridSize;
        // let item = new MyFormPanelField();
        // item.text=text;
        // item.fieldName=fieldName;

        this._Fields.push(field);
        return this;
    }

}

//text
//fieldName
//require
//style
//className
class MyFormPanelField {

    constructor(text,fieldName) {
        this._text=text;
        this._fieldName=fieldName;

        this._validators = [];
    }

    get Validators() {
        return this._validators;
    }

    addValidator(validator) {
        this._validators.push(validator);
        return this;
    }

    get gridSize() {
        return this._gridSize;
    }

    set gridSize(value) {
        this._gridSize = value;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get fieldName() {
        return this._fieldName;
    }

    set fieldName(value) {
        this._fieldName = value;
    }

    get require() {
        return this._require;
    }

    /**
     * @param {Boolean} value
     */
    set require(value) {
        this._require = value;
    }

    get style() {
        return this._style;
    }

    set style(value) {
        this._style = value;
    }

    get className() {
        return this._className;
    }

    set className(value) {
        this._className = value;
    }

    /**
     * @returns {MyFormPanelFieldUITypeEnum}
     */
    get type() {
        return this._type;
    }

    /**
     * @param {MyFormPanelFieldUITypeEnum} value
     */
    set type(value) {
        this._type = value;
    }
    //---------------------------------------
    get slot() {
        return this._slot;
    }

    set slot(value) {
        this._slot = value;
    }

    get fieldClassName() {
        return this._fieldClassName;
    }

    set fieldClassName(value) {
        this._fieldClassName = value;
    }

}


// class MyFormPanelValidator {
//
//     /**
//      * @param {MyFormPanelValidatorEnum} type
//      */
//     constructor(type) {
//         this._type=type;
//     }
//
//     /**
//      * @returns {MyFormPanelValidatorEnum}
//      */
//     get type() {
//         return this._type;
//     }
//
//     /**
//      * @param {MyFormPanelValidatorEnum} value
//      */
//     set type(value) {
//         this._type = value;
//     }
// }



export  {MyFormPanelForm,MyFormPanelPanel,MyFormPanelField,MyFormPanelFieldUITypeEnum,MyFormPanelValidatorEnum}
