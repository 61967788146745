<template>
    <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="ADMIndex"></COM_HeaderNav>

    <MyToolbarBase>
        <div class="btn-group mr-2" role="group" aria-label="First group">
            <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save"></i>儲存</button>
            <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁
            </button>
        </div>
    </MyToolbarBase>

    <div class="fieldsPanel">

        <ValidationObserver ref="form">

            編號需要有
            - 明碼 (給人看的)
            - prefix
            - 是否連號
            - 套表

            兌換員管理
            - 建基本資料 (手機)
            - 發簡訊OTP給兌換員手機
            - 確認OTP,及要求設定密碼
            - 要改/忘記密碼
            -     通知tk管理者
            -     由tk管理者，重發簡訊
            -     經otp後重設密碼

            qrcode 畫面
            - 明碼
            - 暗碼
            - 活動內容
            - 是否已兌換

            兌換畫面
            - 承上
            - 輸入兌換員代碼 (4碼以上)
            - btn 兌換





            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">類型</label>
                <div class="a_thinInputDiv col-sm-10">
                    <select class="form-control" v-model="formData.Type">
                        <option value="1">推薦制</option>
                        <option value="2">發行制</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-sm-2">專案名稱(英文)(Prefix)</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" placeholder="" v-model="formData.Name">
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-sm-2">專案說明</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" placeholder="" v-model="formData.Name">
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-sm-2">會員註冊欄位</label>
                <div class="col-sm-10">
                    <MyLiteFormDesigntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormDesigntime>
                    <MyLiteFormRuntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormRuntime>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-sm-2">是否啟用</label>
                <div class="col-sm-10">
                    <select class="form-control" v-model="formData.IsEnable">
                        <option value="1">生效中</option>
                        <option value="2">停用</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="a_thinlabel col-sm-2">有效日期</label>
                <div class="a_thinInputDiv col-sm-10">
                    <div class="d-flex justify-content-between"  style="max-width: 400px">
                        <input type="date" class="form-control" id="name" name="name" placeholder=""  v-model="formData.StartDt">
                        ~
                        <input type="date" class="form-control" id="name" name="name" placeholder=""  v-model="formData.ExpireDt">
                    </div>

                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-sm-2">備註</label>
                <div class="col-sm-10">
                    <textarea class="form-control" id="txtMemo" name="txtMemo" rows="3"  v-model="formData.Memo"></textarea>
                </div>
            </div>





        </ValidationObserver>

        <MyToolbarBase>
            <button class="btn btn-primary" href="#"><i class="fa fa-clone"></i> 產生票券</button>
        </MyToolbarBase>

        <div v-if="formData.Type==='1'">
            <h1>票券列表</h1>
            <!--        //v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"-->
            <MyToolbarVuetify :config="toolbarVuetifyConfig1" :grid-meta="gridMeta1"  > </MyToolbarVuetify>

            <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig1" :grid-meta="gridMeta1" style="width:1000px"  >
                <template v-slot:Fun="{}">
                    <!--        <a class="btn btn-primary" ><i class="fa fa-align-justify handle"></i>拖拉順序</a>-->
                </template>
                <template v-slot:IsEnable="{ item }">
                    <MyLabelIsEnable v-model="item.IsEnable"></MyLabelIsEnable>
                </template>
            </MyGridVuetify>
        </div>
        <div v-if="formData.Type==='2'">
            <h1>票券列表</h1>
            <!--        //v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"-->
            <MyToolbarVuetify :config="toolbarVuetifyConfig2" :grid-meta="gridMeta2"  > </MyToolbarVuetify>

            <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig2" :grid-meta="gridMeta2" style="width:1000px"  >
                <template v-slot:Fun="{}">
                    <!--        <a class="btn btn-primary" ><i class="fa fa-align-justify handle"></i>拖拉順序</a>-->
                </template>
                <template v-slot:IsEnable="{ item }">
                    <MyLabelIsEnable v-model="item.IsEnable"></MyLabelIsEnable>
                </template>
            </MyGridVuetify>
        </div>

    </div>
    </div>
</template>

<script>
// import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import MyLiteFormDesigntime from "@/pages/commonComponent/MyLiteFormDesigntime.vue";
import MyLiteFormRuntime from "@/pages/commonComponent/MyLiteFormRuntime.vue";
import MyLabelIsEnable from "@/pages/commonComponent/MyLabelIsEnable.vue";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import MyToolbarBase from "@/pages/commonComponent/MyToolbarBase.vue";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";

export default {
    components: {
        COM_HeaderNav,
        MyToolbarBase,
        MyToolbarVuetify, MyGridVuetify, MyLabelIsEnable, MyLiteFormRuntime, MyLiteFormDesigntime},
    data: function () {
        return {
            systemId: "",
            formData: {
                Fun:"",
                SystemId:"1",
                Type:"推薦制",
                Name:"老客推薦新客",
                MemberData:"",//**
                IsEnable:true,
                StartDt:"",
                ExpireDt:"",
                Memo:"",
                CreateDt:"2020-01-01",
                ModifyDt:"2020-01-01",
            },
            liteFormData:[

            ],
            liteFormConfig:{

            },
            gridVuetifyConfig1:{
                items_per_page:10,
                itemKey:"SystemId",
                headers: [
                    { text: '類型', value: 'Type' },
                    { text: '票券序號(明碼)', value: 'Name' },
                    { text: '是否可使用', value: 'IsEnable' },
                    { text: '生效日期', value: 'StartDt' },
                    { text: '到期日期', value: 'ExpireDt' },
                    { text: '推薦者', value: 'OldCustomer' },
                    { text: '推薦使用日期', value: 'OldCustomerUseDt' },
                    { text: '兌換者', value: 'NewCustomer' },
                    { text: '兌換使用日期', value: 'NewCustomerUseDt' },
                    // { text: '備註', value: 'Memo' },
                    // { text: '建立日期', value: 'CreateDt' },
                    // { text: '修改日期', value: 'ModifyDt' },
                ],
                datas: [
                    {
                        Fun:"",
                        SystemId:"16e24cb5-512e-409b-8b8b-d5bacecbbbeb",
                        Type:"",
                        Name:"TK0123",
                        IsEnable:true,
                        IsUse:true,
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                        OldCustomer:"Taylor",
                        NewCustomer:"Tom",
                        OldCustomerUseDt:"2020-09-01",
                        NewCustomerUseDt:"2020-09-01",
                    },
                    {
                        Fun:"",
                        SystemId:"eb508b6b-3888-42ef-916e-8081f3b362ee",
                        Type:"",
                        Name:"eb508b6b-3888-42ef-916e-8081f3b362ee",
                        IsEnable:true,
                        IsUse:false,
                        UseDt:"",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                        OldCustomer:"Taylor",
                        NewCustomer:"David",
                        OldCustomerUseDt:"2020-09-01",
                        NewCustomerUseDt:"2020-09-01",
                    },
                    {
                        Fun:"",
                        SystemId:"ad696d4d-d364-46c3-b71e-b98ba3fac13a",
                        IsEnable:true,
                        Type:"",
                        Name:"ad696d4d-d364-46c3-b71e-b98ba3fac13a",
                        IsUse:true,
                        UseDt:"2020-09-01",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                        OldCustomer:"Taylor",
                        NewCustomer:"Peter",
                        OldCustomerUseDt:"2020-09-01",
                        NewCustomerUseDt:"2020-09-01",
                    },
                    {
                        Fun:"",
                        SystemId:"3582ae2c-2da8-42b8-b257-2dd953fbd1ff",
                        Type:"",
                        Name:"3582ae2c-2da8-42b8-b257-2dd953fbd1ff",
                        IsUse:false,
                        UseDt:"",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                        OldCustomer:"Taylor",
                        NewCustomer:"Jack",
                        OldCustomerUseDt:"2020-09-01",
                        NewCustomerUseDt:"2020-09-01",
                    },
                ],
            },

            toolbarVuetifyConfig1:{
                Add:{
                    Enable:false,
                    CallBack:"",
                },
                Edit:{
                    Enable:false,
                    CallBack:"",
                },
                Delete:{
                    Enable:false,
                    CallBack:"",
                },
                Copy:{
                    Enable:false,
                    CallBack:"",
                },
                Sort:{
                    Enable:false,
                },
                CustomButton1:{
                    BtnText:"啟/停用",
                    Enable:true,
                    CallBack:"",
                },
                CustomButton2:{
                    BtnText:"下載QRCode",
                    Enable:true,
                    CallBack:"",
                },
            },
            gridMeta1:{},
            gridVuetifyConfig2:{
                items_per_page:10,
                itemKey:"SystemId",
                headers: [
                    { text: '類型', value: 'Type' },
                    { text: '票券編號', value: 'Name' },
                    { text: '是否可使用', value: 'IsEnable' },
                    { text: '是否已使用', value: 'IsUse' },
                    { text: '使用日期', value: 'UseDt' },
                    { text: '生效日期', value: 'StartDt' },
                    { text: '到期日期', value: 'ExpireDt' },
                    // { text: '備註', value: 'Memo' },
                    // { text: '建立日期', value: 'CreateDt' },
                    // { text: '修改日期', value: 'ModifyDt' },
                ],
                datas: [
                    {
                        Fun:"",
                        SystemId:"16e24cb5-512e-409b-8b8b-d5bacecbbbeb",
                        Type:"",
                        Name:"16e24cb5-512e-409b-8b8b-d5bacecbbbeb",
                        IsEnable:true,
                        IsUse:true,
                        UseDt:"2020-09-01",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                    },
                    {
                        Fun:"",
                        SystemId:"eb508b6b-3888-42ef-916e-8081f3b362ee",
                        Type:"",
                        Name:"eb508b6b-3888-42ef-916e-8081f3b362ee",
                        IsEnable:true,
                        IsUse:false,
                        UseDt:"",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                    },
                    {
                        Fun:"",
                        SystemId:"ad696d4d-d364-46c3-b71e-b98ba3fac13a",
                        IsEnable:true,
                        Type:"",
                        Name:"ad696d4d-d364-46c3-b71e-b98ba3fac13a",
                        IsUse:true,
                        UseDt:"2020-09-01",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                    },
                    {
                        Fun:"",
                        SystemId:"3582ae2c-2da8-42b8-b257-2dd953fbd1ff",
                        Type:"",
                        Name:"3582ae2c-2da8-42b8-b257-2dd953fbd1ff",
                        IsUse:false,
                        UseDt:"",
                        StartDt:"",
                        ExpireDt:"",
                        CreateDt:"2020-01-01",
                        ModifyDt:"2020-01-01",
                    },
                ],
            },

            toolbarVuetifyConfig2:{
                Add:{
                    Enable:false,
                    CallBack:"",
                },
                Edit:{
                    Enable:false,
                    CallBack:"",
                },
                Delete:{
                    Enable:false,
                    CallBack:"",
                },
                Copy:{
                    Enable:false,
                    CallBack:"",
                },
                Sort:{
                    Enable:false,
                },
                CustomButton1:{
                    BtnText:"啟/停用",
                    Enable:true,
                    CallBack:"",
                },
                CustomButton2:{
                    BtnText:"下載QRCode",
                    Enable:true,
                    CallBack:"",
                },
            },
            gridMeta2:{},
        };
    },
    methods: {
        GenPassCode(){
            this.formData.passCode = util.uuid().substring(0,8);
        },
        async loadData() {
            // this.systemId=this.$route.query.systemId;
            //
            // if (this.systemId){
            //   let data=await apiUBossShopBk.api_Member_Get(this.systemId)
            //
            //   this.formData=data
            // }

        },
        btnUpdate(){
            // let router=this.$router
            //
            // this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
            //   if (!isValid) {
            //     let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
            //     util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
            //     return;
            //   } else {
            //     //---------------------------------------
            //     apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
            //       util.ShowMessage("儲存成功","訊息")
            //       router.back()
            //     })
            //     //---------------------------------------
            //   }
            // });
        },
    },
    mounted() {
        this.loadData()
    },
}
</script>

<style scoped>

</style>
