<template>
    <div>
        <COM_HeaderNav mode="ADMIndex"></COM_HeaderNav>

        <MyToolbarBase>
            <div class="btn-group mr-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save"></i>儲存</button>
                <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁
                </button>
            </div>
        </MyToolbarBase>

        <div class="fieldsPanel">
            <ValidationObserver ref="form">

                <div class="form-group row">
                    <label for="" class="a_thinlabel col-sm-2">類型</label>
                    <div class="a_thinInputDiv col-sm-10">
                        <select class="form-control" v-model="formData.type">
                            <option value="1">一般</option>
                            <option value="2">VIP</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">客戶代碼</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="" v-model="formData.No">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">客戶名稱</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="" v-model="formData.Name">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">統編</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="" v-model="formData.NumReg">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">電話</label>
                    <div class="col-sm-10">
                        <input type="tel" class="form-control" placeholder="" v-model="formData.Tel">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">地址</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="" v-model="formData.Address">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">Email</label>
                    <div class="col-sm-10">
                        <input type="email" class="form-control" placeholder="" v-model="formData.Email">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">狀態</label>
                    <div class="col-sm-10">
                        <select class="form-control" v-model="formData.Status">
                            <option value="1">生效中</option>
                            <option value="2">停用</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-2">備註</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" id="txtMemo" name="txtMemo" rows="3" v-model="formData.Memo"></textarea>
                    </div>
                </div>

                <hr>
                <!--        <select class="form-control" v-model="formData.Status">-->
                <!--            <option value="1">biz</option>-->
                <!--            <option value="2">form</option>-->
                <!--            <option value="3">udm</option>-->
                <!--            <option value="4">ticket</option>-->
                <!--        </select>-->

                <div class="shadow p-3 mb-5 bg-white rounded">
                    <div>付款連結</div>
                        <table class="table table-hover">
                            <thead class="thead-light">
                            <tr>
                                <th class="text-center">付款備註</th>
                                <th>金額</th>
                                <th>狀態</th>
                                <th>付款日期/序號</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td >
                                    簡訊儲值 (A客戶)
                                </td>
                                <td >
                                    500
                                </td>
                                <td >已付款</td>
                                <td >2023-03-01 xxx</td>

                            <tr>
                                <td >
                                    udm (A客戶)
                                </td>
                                <td >
                                    3500
                                </td>
                                <td >未付款</td>
                                <td >xxx</td>
                            </tr>

                            </tbody>
                        </table>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="">
                                <form class="form-inline">
                                    <div class="form-group mb-2">
                                        <label for="staticEmail2" class="">付款備註</label>
                                        <input type="text" class="form-control" id="staticEmail2" value="">
                                    </div>
                                    <div class="form-group mx-sm-3 mb-2">
                                        <label for="inputPassword2" class="">金額</label>
                                        <input type="number" class="form-control" id="inputPassword2" placeholder="">
                                    </div>
                                    <button type="submit" class="btn btn-primary mb-2">產生付款連結</button>
                                </form>

                            </div>
                        </div>
                    </div>


                </div>

                <div class="shadow p-3 mb-5 bg-white rounded">
                    Biz

                    <div class="form-group row">
                        <label for="" class="col-sm-2">狀態</label>
                        <div class="col-sm-10">
                            <MyButtonEnableDisable2 v-model="formData.ServiceOnePage.IsEnable"></MyButtonEnableDisable2>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">到期日</label>
                        <div class="col-sm-10">
                            <input type="date" class="form-control" id="name" name="name" placeholder="" v-model="formData.ServiceOnePage.ExpireDt">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">網站標題</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="formData.ServiceOnePage.FlagTitle">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">移除Footer</label>
                        <div class="col-sm-10">
                            <MyButtonEnableDisable2 v-model="formData.ServiceOnePage.FlagRemoveFooter"></MyButtonEnableDisable2>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2"></label>
                        <div class="col-sm-10">
                            <button class="btn btn-primary" >儲存</button>
                        </div>
                    </div>



                </div>

                <div class="shadow p-3 mb-5 bg-white rounded">
                    Form
                    <div class="form-group row">
                        <label for="" class="col-sm-2">狀態</label>
                        <div class="col-sm-10">
                            <MyButtonEnableDisable2 v-model="formData.ServiceForm.IsEnable"></MyButtonEnableDisable2>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">到期日</label>
                        <div class="col-sm-10">
                            <input type="date" class="form-control" id="name" name="name" placeholder="" v-model="formData.ServiceForm.ExpireDt">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="" class="col-sm-2"></label>
                        <div class="col-sm-10">
                            <button class="btn btn-primary" >儲存</button>
                        </div>
                    </div>

                </div>

                <div class="shadow p-3 mb-5 bg-white rounded">
                    UDM
                    <div class="form-group row">
                        <label for="" class="col-sm-2">狀態</label>
                        <div class="col-sm-10">
                            <MyButtonEnableDisable2 v-model="formData.ServiceUDM.IsEnable"></MyButtonEnableDisable2>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">到期日</label>
                        <div class="col-sm-10">
                            <input type="date" class="form-control" id="name" name="name" placeholder="" v-model="formData.ServiceUDM.ExpireDt">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="" class="col-sm-2"></label>
                        <div class="col-sm-10">
                            <button class="btn btn-primary" >儲存</button>
                        </div>
                    </div>
                </div>

                <div class="shadow p-3 mb-5 bg-white rounded">
                    Ticket
                    <div class="form-group row">
                        <label for="" class="col-sm-2">狀態</label>
                        <div class="col-sm-10">
                            <MyButtonEnableDisable2 v-model="formData.ServiceTicket.IsEnable"></MyButtonEnableDisable2>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2">到期日</label>
                        <div class="col-sm-10">
                            <input type="date" class="form-control" id="name" name="name" placeholder="" v-model="formData.ServiceTicket.ExpireDt">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="" class="col-sm-2"></label>
                        <div class="col-sm-10">
                            <button class="btn btn-primary" >儲存</button>
                        </div>
                    </div>
                </div>
                <div class="shadow p-3 mb-5 bg-white rounded">
                    簡訊
                    <div class="form-group row">
                        <label for="" class="col-sm-2">目前剩餘數量: {{formData.PaySMS.CurrentQty}} 封</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="name" name="name" placeholder="">
                            <button class="btn btn-primary" >增減數量</button>
                        </div>
                    </div>
                </div>
                <div class="shadow p-3 mb-5 bg-white rounded">
                    Email
                    <div class="form-group row">
                        <label for="" class="col-sm-2">目前剩餘數量: {{formData.PayEmail.CurrentQty}} 封</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="name" name="name" placeholder="">
                            <button class="btn btn-primary" >增減數量</button>
                        </div>
                    </div>
                </div>

                <hr>

            </ValidationObserver>
        </div>

    </div>
</template>

<script>
// import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarBase from "@/pages/commonComponent/MyToolbarBase.vue";
import MyButtonEnableDisable2 from "@/pages/commonComponent/MyButtonEnableDisable2.vue";

export default {
    components: {MyButtonEnableDisable2, MyToolbarBase, COM_HeaderNav},
    data: function () {
        return {
            systemId: "",
            formData: {
                Fun: "",
                SystemId: "1",
                Type: "",
                No: "0001",
                Name: "優伯斯",
                NumReg: "54931496",
                Tel: "0915071457",
                Address: "桃園市中壢區福州路130號",
                Email: "taylor0225@gmail.com",
                Status: "",
                Memo: "",
                CreateDt: '2023-01-01',
                ModifyDt: '2023-06-30',
                ServiceOnePage:{
                    ExpireDt:"2023-01-01",
                    IsEnabled:true,
                    FlagTitle:"",
                    FlagRemoveFooter:false,
                },
                ServiceForm:{
                    ExpireDt:"",
                    IsEnabled:false,

                },
                ServiceUDM:{
                    ExpireDt:"",
                    IsEnabled:false,
                },
                ServiceTicket:{
                    ExpireDt:"",
                    IsEnabled:false,
                },
                PaySMS:{
                    CurrentQty:120,
                },
                PayEmail:{
                    CurrentQty:512,
                },
            },
        };
    },
    methods: {
        GenPassCode() {
            this.formData.passCode = util.uuid().substring(0, 8);
        },
        async loadData() {
            // this.systemId=this.$route.query.systemId;
            //
            // if (this.systemId){
            //   let data=await apiUBossShopBk.api_Member_Get(this.systemId)
            //
            //   this.formData=data
            // }

        },
        btnUpdate() {
            // let router=this.$router
            //
            // this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
            //   if (!isValid) {
            //     let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
            //     util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
            //     return;
            //   } else {
            //     //---------------------------------------
            //     apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
            //       util.ShowMessage("儲存成功","訊息")
            //       router.back()
            //     })
            //     //---------------------------------------
            //   }
            // });
        },
    },
    mounted() {
        this.loadData()
    },
}
</script>

<style scoped>

</style>
