<template>
  <div>
    <div class="card">
      <div class="card-header">{{title}}</div>
      <div class="card-body">

        <div class="form-group row">
          <label for="" class="col-sm-2">網站名稱</label>
          <div class="a_thinInputDiv col-sm-10">
            <validation-provider rules="required" v-slot="{ errors }"  name="網站名稱" >
              <input type="text" class="form-control " placeholder="" v-model.number="formData.SiteTitle" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">網站Logo</label>
          <div class="a_thinInputDiv col-sm-10">
            <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_Shop" file-name="logo.png"></FileUploadButton>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">運費</label>
          <div class="a_thinInputDiv col-sm-10">
            <validation-provider rules="required" v-slot="{ errors }"  name="運費" >
              <input type="number" class="form-control " placeholder="" v-model.number="formData.OrderShipFee" >
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
            <a href="#"><i class="fa fa-question"></i> 如何免運費? </a>

            <MyListboxLeftRightKendo></MyListboxLeftRightKendo>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import MyListboxLeftRightKendo from "@/pages/commonComponent/MyListboxLeftRightKendo";
/**

 **/


export default {
  components: {MyListboxLeftRightKendo},
  //name: "ShoppingGeneral",
  props: ["title"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    btnUpdate(){

    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
