<template>
  <div>
<!--    <COM_HeaderNav mode="OnePageAdv2CName"></COM_HeaderNav>-->

    <MyToolbarVuetify class="bk_toolbar_fixed-top" ref="myToolbarForm" v-on:button-click="toolbarButtonForm_Click" > </MyToolbarVuetify>
    <div class="bk_firstBlock"/>
    <MyFormPanel ref="form" :data-source="formData">
      <!--      <div slot="testSlot">  //QQ-->
      <!--        <button class="btn btn-primary" >送出</button>-->
      <!--      </div>-->
    </MyFormPanel>

    <h1>頁面</h1>

    <MyToolbarVuetify ref="myToolbar" :grid-select-ids="gridSelectIds"  v-on:button-click="toolbarButton_Click" > </MyToolbarVuetify>

    <MyVueEasytable ref="grid" v-on:grid-selected="grid_Selected"></MyVueEasytable>

  </div>
</template>

<script>

import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
// import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";
import MyVueEasytable from "@/pages/commonComponent/MyVueEasytable/MyVueEasytable.vue";
import {
  MyVueEasyGrid,
  MyVueEasyGridExpandForm,  MyVueEasyGridUtility
} from "@/pages/commonComponent/MyVueEasytable/js/MyVueEasytable";
import {MyToolbar, MyToolbarGroup} from "@/pages/commonComponent/js/MyToolbarVuetify";
import {MyToolbarButtonIdEnum} from "@/pages/commonComponent/js/MyToolbarVuetify";
import MyFormPanel from "@/pages/commonComponent/MyFormPanel/MyFormPanel.vue";
import {
  MyFormPanelField,
  MyFormPanelFieldUITypeEnum, MyFormPanelForm,
  MyFormPanelPanel
} from "@/pages/commonComponent/MyFormPanel/js/MyFormPanelCore";

export default {
  components: {
    MyFormPanel,
    MyVueEasytable,
    MyToolbarVuetify
  },
  data: function () {
    return {
      formData:  {
        SystemId: "1",
        cname: "www",
        memo: "",
        createDt:"2023-07-01",
        modifyDt:"2023-07-01",
      },
      datas:[
        {
          SystemId: "1",
          name: "page1",
          isHomePage:true,
          isVisible: true,
          memo: "",
          createDt:"2023-07-01",
          modifyDt:"2023-07-01",
        },
        {
          SystemId: "2",
          name: "page2",
          isHomePage:false,
          isVisible: true,
          memo: "",
          createDt:"2023-07-01",
          modifyDt:"2023-07-01",
        },
        {
          SystemId: "3",
          name: "page3",
          isHomePage:false,
          isVisible: true,
          memo: "",
          createDt:"2023-07-01",
          modifyDt:"2023-07-01",
        },
      ],
      gridSelectIds:{},
    };
  },
  methods: {
    async loadData() {
      this.datas=await apiUBossShopBk.api_Member_ListQuery(null)
    },
    initForm(){
      //Doc
      //form
      //  panel
      //    row
      //      field

      //---------------------------------------
      //Fields
      //---------------------------------------
      let fName=new MyFormPanelField("CName名稱","cname");
      fName.type=MyFormPanelFieldUITypeEnum.input;
      fName.require=true;

      let fMemo=new MyFormPanelField("備註","memo");
      fMemo.type=MyFormPanelFieldUITypeEnum.textarea;
      fMemo.require=true;

      //---------------------------------------
      //Panels
      //---------------------------------------
      let myFormPanel1=new MyFormPanelPanel("");
      myFormPanel1.addRow()
          .addFields(12,fName)
      ;

      myFormPanel1.addRow()
          .addFields(12,fMemo)
      ;


      // let myFormPanel2=new MyFormPanelPanel("");
      // myFormPanel2.addRow()
      //
      // ;
      //---------------------------------------
      //Form
      //---------------------------------------
      let myForm=new MyFormPanelForm();

      myForm.addPanel(myFormPanel1);

      //---------------------------------------
      this.$refs.form.setPropFormDefine(myForm);

    },
    initGrid(){
      //Expand顯示的資訊
      let gridExpandForm = new MyVueEasyGridExpandForm();

      gridExpandForm.addItem("memo", "備註");//QQ
      gridExpandForm.addItem("createDt", "建立時間");//QQ
      gridExpandForm.addItem("modifyDt", "修改時間");

      //Render Method
      let gridUtility = new MyVueEasyGridUtility();

      let funRenderExpandForm=gridUtility.GetFun_RenderExpandForm(gridExpandForm.Items);

      //欄位
      let grid = new MyVueEasyGrid(this.$refs.grid)
          .addColumnSeq()
          .addColumnExpand()
          .addColumnCheckBox()
          .addColumn("名稱", "name")
          .addColumn("設為首頁", "isHomePage")//QQ
          .addColumn("是否顯示", "isVisible")
          .addColumn("備註", "memo",true)//QQ
          .addColumn("建立時間", "createDt",true)
          .addColumn("修改時間", "modifyDt",true)
      ;

      //設定Grid
      this.$refs.grid.set_expandOption(funRenderExpandForm);
      this.$refs.grid.set_columns(grid.Columns);
      this.$refs.grid.set_tableData(this.datas);
      this.$refs.grid.set_mobileUI();

      //Grid Doc
      // //Radio
      // this.$refs.grid.gridUtil_RadioSelectById["1"]
      // this.$refs.grid.gridUtil_RadioUnSelect(["1"])
      //
      // //Checkbox
      // this.$refs.grid.gridUtil_CheckboxSelectAll();
      // this.$refs.grid.gridUtil_UnCheckboxSelectAll();
      //
      // //Column
      // this.$refs.grid.gridUtil_ShowColumnsByKeys(["name"])
      // this.$refs.grid.gridUtil_HideColumnsByKeys(["name"])
      //
      // //Other
      // this.$refs.grid.gridUtil_ShowEmpty();
      //
      // //Get Info
      // this.info="";
      // this.info+="All:"+this.$refs.grid.gridUtil_SelectedIds();
      // this.info+="R:"+this.$refs.grid.gridUtil_RadioSelectedId();
      // this.info+="C:"+this.$refs.grid.gridUtil_CheckBoxSelectedIds();
    },
    initToolbarForm(){
      let toolbarGroup1=new MyToolbarGroup();
      toolbarGroup1
          .addButton_Home()
          .addButton_BackDefault()
          .addButton_Save()//
      ;

      let toolbar=new MyToolbar();

      toolbar.addGroup(toolbarGroup1);

      this.$refs.myToolbarForm.setProp_Toolbar(toolbar);
    },
    initToolbar(){
      let toolbarGroup1=new MyToolbarGroup();
      toolbarGroup1
          // .addButton_BackDefault()
          .addButton_Add()
          .addButton_Edit()
          .addButton_Delete()
          .addButton_Copy()//
      ;

      let toolbar=new MyToolbar();

      toolbar.addGroup(toolbarGroup1);

      this.$refs.myToolbar.setProp_Toolbar(toolbar);
    },
    toolbarButtonForm_Click(buttonId,selectIds){  // eslint-disable-line
      if (buttonId===MyToolbarButtonIdEnum.Add){
        console.log("");
      }else if (buttonId===MyToolbarButtonIdEnum.Edit){
        this.$router.push({ name: 'OnePageAdvEditStep1', query: { SystemId: 0 }})
      }

      // alert(buttonId);
      // alert(selectIds);
    },
    toolbarButton_Click(buttonId,selectIds){  // eslint-disable-line
      if (buttonId===MyToolbarButtonIdEnum.Add){
        console.log("");
      }else if (buttonId===MyToolbarButtonIdEnum.Edit){
        this.$router.push({ name: 'OnePageAdvEditStep1', query: { SystemId: 0 }})
      }

      // alert(buttonId);
      // alert(selectIds);
    },
    grid_Selected(selectedIds){
      this.gridSelectIds=selectedIds;
    }
  },
  created() {
    console.log("page create start");

    console.log("page create end");
  },
  mounted() {
    console.log("page mount start");
    this.loadData();
    this.initForm();
    this.initToolbarForm();
    this.initGrid();
    this.initToolbar();
    console.log("page mount end");
  },
}
</script>

<style scoped>

</style>
