// export const API_OK = 200;
// export const DS_YES_NO = [
//     "是",
//     "否",
// ]
//
// export const DS_SUPPLIER_GROUP_TYPE = [
//     {id: "1", text: "類別"},
//     {id: "2", text: "CSV"},
// ]


export class JCalendarBlock {
    date= "";
    /** @type {JCalendarBlockButton[]} */
    block_day=[];
    /** @type {JCalendarBlockButton[]} */
    block_halfday=[];
    /** @type {JCalendarBlockButton[]} */
    block_hour=[];
    /** @type {JCalendarBlockButton[]} */
    block_halfhour=[];
}

export class JCalendarBlockButton {
    index = 0;
    isSelected = false;
}



