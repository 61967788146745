<template>
  <div class="" >
    <b-modal ref="my-modal" size="s" hide-footer title="">
      <div class="d-block text-center">
        <h3>票據狀態</h3>
      </div>
      <hr>
      <div class="form-group row">
        <label for="" class="col-sm-4">票據狀態:</label>
        <div class="col-sm-8">
          <MyCodeListButtonRadio v-model="CLStatus" :options="Ticket2Declare.Ticket_CLStatus2" size="md"></MyCodeListButtonRadio>
        </div>
      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//<DLGTicketStatus ref="dlgTicketStatus" v-on:returnOk="dlgTicketStatusReturnOK" />
//
//
// editHtmlContent(item){
//   this.$refs.dlgTicketStatus.callbackItem=item;
//   this.$refs.dlgTicketStatus.htmlContent=item.HelpContent.Content;
//   this.$refs.dlgTicketStatus.showModal();
// },
// dlgTicketStatusReturnOK(item){
//   item.HelpContent.Content=this.$refs.dlgTicketStatus.htmlContent;
// }

// import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import * as Ticket2Declare from "@/pages/ubossBk/views/ticket2/ticket2Declare";
import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";

export default {
  components: {MyCodeListButtonRadio},
  props:["htmlContent","callbackItem"],
  data: function() {
    return {
      showThis:false,
      CLStatus:"",
    };
  },
  computed: {
    Ticket2Declare() {
      return Ticket2Declare
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      if (!this.CLStatus){
        alert("請選擇票據狀態");
        return;
      }
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk',this.CLStatus);
    },
    async loadData(){
      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
