<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="2"></COM_HeaderNav>
<!--    SearchPanel-->
    <SearchPanelForm v-if="false" ref="searchPanelForm" :fieldDefines="searchDefine.searchFieldDefines" :keyword-fields="searchDefine.searchKeywordFields"></SearchPanelForm>

<!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnCreate"><i class="fa fa-plus-circle" ></i>新增</button>
        <button type="button" class="btn btn-danger" @click="btnDelete"><i class="fa fa-trash" ></i>刪除</button>
      </div>
      <div class="btn-group mx-1" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          匯出
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="$refs.grid.exportExcel()">Excel</a>
        </div>
      </div>
    </div>

<!--    Grid-->
    <span v-if="isMobile">
      <MyMobileList :datas="gridDefine.datas" :field-to-show="gridDefine.fieldToShow" :swipe-setting="gridDefine.swipeSetting">
      </MyMobileList>
    </span>
    <span v-else>
      <MyKendoGrid ref="grid" :columns="gridDefine.columns" :datas="gridDefine.datas"></MyKendoGrid>
    </span>
  </div>

</template>

<script>


import SearchPanelForm from "@/pages/ubossBk/views/components/SearchPanelForm.vue";
import MyKendoGrid from "@/pages/commonComponent/MyKendoGrid.vue";
import { isMobile } from 'mobile-device-detect';
import MyMobileList from "@/pages/commonComponent/MyMobileList.vue";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import ubossUIHelper from "@/pages/commonUBoss/ubossUIHelper";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";


export default {
  components: {
    COM_HeaderNav,
    MyMobileList,
    MyKendoGrid,
    SearchPanelForm,
  },
  data: function () {
    return {
      isMobile:isMobile,
      searchDefine:{
        searchFieldDefines:[],
        searchKeywordFields:[],
      },
      gridDefine:{
        columns: [
          // { field: 'SystemId', title: 'SystemId', minWidth: 50, width: '50px'},
          { field: 'RelSP_TicketType_Text', title: '票種' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M,cell: this.cellFunctionLink},
          { field: 'EffectDt', title: '有效日期' ,width:this.$dec.GridColumnWidth_Name, minWidth: this.$dec.GridColumnWidth_Name_M},
          { field: 'QuantityTotal', title: '發行張數' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'IsUsedCount', title: '已使用張數' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          // { field: 'IsRecoverCount', title: '已回收張數' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
          { field: 'Memo', title: '備註' ,width:this.$dec.GridColumnWidth_Money, minWidth: this.$dec.GridColumnWidth_Money_M},
        ],
        datas:[],
        fieldToShow:{
          title:"OrderId",   //QQ token,multi
          memo:"CLOrderStatus",
          badge1:"TotalAmount",
        },
        swipeSetting:{
          ItemClick:this.swipeSettingItemClick
        }
      },
    };
  },
  methods: {
    async loadData() {
      this.gridDefine.datas=await apiUBossBk.api_TicketPublish_ListQuery(null);
    },
    cellFunctionLink: function (h, tdElement , props, clickHandler ) {  // eslint-disable-line
      let that=this;
      let clickFun=function(){
        that.gotoWithSystemId('Ticket2TicketPublishEdit',props.dataItem.SystemId)
      }
      let linkText=props.dataItem.RelSP_TicketType_Text;

      //---------------------------------------
      return h('td', {
        on: {
          click: function(e){// eslint-disable-line
            clickFun();
          }
        },
        domProps: {
          innerHTML: "<a class='alink'><i class='fa fa-edit'></i>"+linkText +" </a>"
        }
      });
    },
    swipeSettingItemClick:function (item){ // eslint-disable-line
      this.gotoWithSystemId('Ticket2TicketPublishEdit',item.SystemId)
    },
    btnCreate(){
      this.$router.push({ name: 'Ticket2TicketPublishEdit', query: { systemId: "" }})
    },
    async btnDelete(){
      let that=this;
      const execRemoveApi = async function(systemIds) {
        // alert(systemIds)
        await apiUBossBk.api_TicketPublish_RemoveMulti(systemIds)
        await that.loadData();
      }
      ubossUIHelper.crud_DeleteItem(this.$refs.grid,execRemoveApi);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
