<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="ticket2" button-index="1"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">名稱</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="名稱" >
            <input type="text" class="form-control " placeholder="" v-model="formData.Name">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">代碼(4位)</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="代碼(4位)" >
            <input type="text" class="form-control saveThenLock " placeholder="" v-model="formData.Code" maxlength="4" :disabled="saveThenLock"> 儲存後不可修改
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">售價</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="售價" >
            <input type="number" class="form-control saveThenLock" placeholder="" v-model.number="formData.Price" :disabled="saveThenLock"> 儲存後不可修改
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2 label-required">是否回收票券</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="是否回收票券" >
            <MyButtonYesNo v-model="formData.IsNeedRecycle"></MyButtonYesNo>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">備註</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="" v-slot="{ errors }"  name="備註" >
            <textarea class="form-control" rows="3" v-model="formData.Memo"></textarea>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">配送方式</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="配送方式" >-->
<!--            <MyCodeListButtonRadio v-model="formData.CLShipType" :options="$u.UCode_CLShipType" size="md"></MyCodeListButtonRadio>-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->


    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav.vue";
import MyButtonYesNo from "@/pages/commonComponent/MyButtonYesNo.vue";
// import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio.vue";

export default {
  components: {MyButtonYesNo,  COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {

      },
      formDataDemo: {

        "OrderId":"",
        "RelMember":"",
        "CLSourceType":"",
        "CLInvoiceType":"",
        "CLPayType":"",
        "PayTypeMemo":"",
        "PayTypeLog":"",
        "CLPayStatus":"",
        "CLOrderStatus":"",
        "CLShipType":"",
        "CLShipStatus":"",
        "ShipDt":"",
        "TotalOrgAmount":"",
        "TotalAmount":"",
      },
      saveThenLock:false,
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossBk.api_TicketType_Get(this.systemId)
        this.formData=data

        this.saveThenLock=true;
      }
    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (this.formData.ShipDt===""){
            this.formData.ShipDt=null;
          }
          //---------------------------------------
          apiUBossBk.api_TicketType_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.saveThenLock{
  width:200px;
}
</style>
