var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    _vm._l(_vm.webPart.PartImages, function(image, index) {
      return _c("div", { key: index, staticClass: "crop" }, [
        _c("div", { staticClass: "d-block d-sm-none" }, [
          image.LinkUrl
            ? _c("div", [
                _c(
                  "a",
                  { attrs: { href: image.LinkUrl, target: image.LinkTarget } },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.toDownloadLink(true, image.ImageUrl),
                          expression: "toDownloadLink(true,image.ImageUrl)"
                        }
                      ],
                      staticStyle: { width: "100%" }
                    })
                  ]
                )
              ])
            : _c("div", [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.toDownloadLink(true, image.ImageUrl),
                      expression: "toDownloadLink(true,image.ImageUrl)"
                    }
                  ],
                  staticStyle: { width: "100%" }
                })
              ])
        ]),
        _c("div", { staticClass: "d-none d-sm-block" }, [
          image.LinkUrl
            ? _c("div", [
                _c(
                  "a",
                  { attrs: { href: image.LinkUrl, target: image.LinkTarget } },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.toDownloadLink(false, image.ImageUrl),
                          expression: "toDownloadLink(false,image.ImageUrl)"
                        }
                      ],
                      staticStyle: { width: "100%" }
                    })
                  ]
                )
              ])
            : _c("div", [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.toDownloadLink(false, image.ImageUrl),
                      expression: "toDownloadLink(false,image.ImageUrl)"
                    }
                  ],
                  staticStyle: { width: "100%" }
                })
              ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }