// import * as dec from "@/pages/common/declare";
// import session from "@/pages/common/session";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/PpdApi/";


export default {

    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager("token")
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------------------------------
    async api_GetAdminInfo() {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("GetAdminInfo",sendData);
    },

    async api_GPKConfigDownload() {
        var apiPath=apiPathBase +"GPKConfigDownload";

        window.open(apiUrl+apiPath,"_blank")
    },

    async api_TransactionQRPrj_CheckQR(posId) {
        var method="TransactionQRPrj_CheckQR?posId=" +posId;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },

    async api_TransactionQRPrj_MakeDrink(code) {
        var method="TransactionQRPrj_MakeDrink?code=" +code;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_FromDrinkJson(code) {
        var method="Drink_FromDrinkJson?code=" +code;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_AdjustDrink(code,ice) {
        var method="Drink_AdjustDrink?code=" +code +"&iICE=" +ice;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_ToDrinkJson(code,ice) {
        var method="Drink_ToDrinkJson?code=" +code +"&iICE=" +ice;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_Json_ConvertOldFmtToNew(jsonOld) {
        var method="Drink_Json_ConvertOldFmtToNew?jsonOld=" +jsonOld ;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_Json_ConvertNewFmtToUBoss(jsonNew) {
        var method="Drink_Json_ConvertNewFmtToUBoss?jsonNew=" +jsonNew ;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_Json_ConvertUBossFmtToNew(jsonUBoss) {
        var method="Drink_Json_ConvertUBossFmtToNew?jsonUBoss=" +jsonUBoss ;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    async api_Drink_MakeDrinkTest(jsonUBoss) {
        var method="Drink_MakeDrinkTest?jsonUBoss=" +jsonUBoss ;
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },

}
