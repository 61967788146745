var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.collapse-1",
              modifiers: { "collapse-1": true }
            }
          ],
          attrs: { variant: "primary" }
        },
        [_vm._v("背景設定")]
      ),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: "collapse-1" } },
        [
          _c("b-card", [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("圖片")
              ]),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("FileUploadButton", {
                    attrs: {
                      "emmit-back-param": undefined,
                      "upload-type": _vm.$u.UBossUploadType_OnePage
                    },
                    on: { uploadSuccess: _vm.uploadSuccessBackground }
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: { click: _vm.clearBackground }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("顏色")
              ]),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("MyColorPicker", {
                    model: {
                      value: _vm.Common.BackgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.Common, "BackgroundColor", $$v)
                      },
                      expression: "Common.BackgroundColor"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("對齊")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.Common.BackgroundPosition,
                        expression: "Common.BackgroundPosition"
                      }
                    ],
                    staticClass: "form-control aselect",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.Common,
                          "BackgroundPosition",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "center" } }, [
                      _vm._v("中")
                    ]),
                    _c("option", { attrs: { value: "top" } }, [_vm._v("上")]),
                    _c("option", { attrs: { value: "left" } }, [_vm._v("左")]),
                    _c("option", { attrs: { value: "right" } }, [_vm._v("右")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("重覆")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.Common.BackgroundRepeat,
                        expression: "Common.BackgroundRepeat"
                      }
                    ],
                    staticClass: "form-control aselect",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.Common,
                          "BackgroundRepeat",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "no-repeat" } }, [
                      _vm._v("不重覆")
                    ]),
                    _c("option", { attrs: { value: "repeat" } }, [
                      _vm._v("重覆")
                    ]),
                    _c("option", { attrs: { value: "repeat-x" } }, [
                      _vm._v("橫向重覆")
                    ]),
                    _c("option", { attrs: { value: "space repeat" } }, [
                      _vm._v("直向重覆")
                    ])
                  ]
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }