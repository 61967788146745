var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.SystemId || _vm.onePageAdvId
        ? _c("OnePageMain", {
            attrs: {
              "design-time": false,
              "one-page-id": _vm.SystemId,
              "layout-type": _vm.layoutType,
              "one-page-adv-id": _vm.onePageAdvId,
              "cname-system-id": _vm.cnameSystemId
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }