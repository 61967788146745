<template>
  <div>

    <vue-good-table ref="grid"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        theme="polar-bear"
        :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: '搜尋',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : '第一頁',
          lastLabel : '最後一頁',
          nextLabel: '下一頁',
          prevLabel: '前一頁',
          rowsPerPageLabel: '每頁筆數',
          ofLabel: 'of',
          pageLabel: '頁', // for 'pages' mode
          allLabel: 'All',
        }"
        :select-options="{
          enabled: showCheckboxColumn,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: '筆資料已選擇',
          clearSelectionText: '清除',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
    />
    <!--    trigger: 'enter',-->
    <!--    searchFn: grid_SearchFunction,-->
    <!--    externalQuery: searchQuery-->
    <!--    @on-search="onSearch"-->
<!--    <h2>Full</h2>-->

<!--    <ul>-->
<!--      <li>固定Header</li>-->
<!--      <li>排序可以多個欄位 (用shift+click)</li>-->
<!--      <li>slot add button</li>-->
<!--    </ul>-->

<!--    <vue-good-table-->
<!--        :columns="simple.columns"-->
<!--        :rows="simple.rows"-->
<!--        :line-numbers="true"-->
<!--        :search-options="{-->
<!--          enabled: true,-->
<!--          skipDiacritics: true,-->
<!--          placeholder: '搜尋',-->
<!--        }"-->
<!--        :max-height="'200px'"-->
<!--        :fixed-header="true"-->
<!--        :sort-options="{-->
<!--          enabled: true,-->
<!--          multipleColumns: true,-->
<!--          initialSortBy: [-->
<!--            {field: 'name', type: 'asc'},-->
<!--            {field: 'age', type: 'desc'}-->
<!--          ],-->
<!--        }"-->
<!--        :pagination-options="{-->
<!--          enabled: true,-->
<!--          mode: 'pages',-->
<!--          perPage: 10,-->
<!--          perPageDropdown: [10, 50, 100],-->
<!--          dropdownAllowAll: true,-->
<!--          setCurrentPage: 1,-->
<!--          jumpFirstOrLast : true,-->
<!--          firstLabel : '第一頁',-->
<!--          lastLabel : '最後一頁',-->
<!--          nextLabel: '下一頁',-->
<!--          prevLabel: '前一頁',-->
<!--          rowsPerPageLabel: '每頁筆數',-->
<!--          ofLabel: 'of',-->
<!--          pageLabel: '頁', // for 'pages' mode-->
<!--          allLabel: 'All',-->
<!--          infoFn: (params) => `my own page ${params.firstRecordOnPage}`,-->
<!--        }"-->
<!--        :select-options="{-->
<!--          enabled: true,-->
<!--          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row-->
<!--          selectionInfoClass: 'custom-class',-->
<!--          selectionText: 'rows selected',-->
<!--          clearSelectionText: 'clear',-->
<!--          disableSelectInfo: false, // disable the select info panel on top-->
<!--          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group-->
<!--        }"-->
<!--        @on-selected-rows-change="selectionChanged"-->
<!--    >-->
<!--      &lt;!&ndash;      <div slot="table-actions">&ndash;&gt;-->
<!--      &lt;!&ndash;        <a role="button" class="btn btn-primary" href="#">do something</a>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div slot="selected-row-actions">-->
<!--        <button>Action 1</button>-->
<!--      </div>-->
<!--      <div slot="emptystate">-->
<!--        沒有符合的資料-->
<!--      </div>-->
<!--      <div slot="table-actions-bottom">-->
<!--        This will show up on the bottom of the table.-->
<!--      </div>-->
<!--    </vue-good-table>-->
  </div>
</template>

<script>
/**



 **/


export default {
  //name: "MyGridGoodTable",
  // props: [""],
  props: {
    showCheckboxColumn: {
      type: Boolean,
      required: false
    },
    columns: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return []
      }
    },
    rows: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      demo:{
        columns: [
          {
            label: 'Name',
            field: 'name',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: '搜尋', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              // filterDropdownItems: ["a","b","c"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: 'enter', //only trigger on enter not on keyup
            },
          },
          {
            label: 'Age',
            field: 'age',
            type: 'number',
          },
          {
            label: 'Created On',
            field: 'createdAt',
            type: 'date',
            dateInputFormat: 'yyyy-MM-dd',
            dateOutputFormat: 'MMM do yy',
          },
          {
            label: 'Percent',
            field: 'score',
            type: 'percentage',
          },
        ],
        rows: [
          { id:1, name:"John", age: 20, createdAt: '',score: 0.03343 },
          { id:2, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
          { id:3, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
          { id:4, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
          { id:5, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
          { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
        ],
      }
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    grid_SearchFunction(row, col, cellValue, searchTerm){// eslint-disable-line
      return cellValue === 'my value';
    },
    selectedRows(){
      return this.$refs.grid.selectedRows;
    },
    rowStyleClassFn(row) { //eslint-disable-line
      return row.vgtSelected ? 'VGT-row _dblclick' : 'VGT-row';
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>
.VGT-row:hover {
  background-color: yellow;
}

tr:hover td {
  background-color: yellow;
}
</style>
