var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bk_page_rootDiv" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "ADMIndex" } }),
      _c("MyToolbarBase", [
        _c(
          "div",
          {
            staticClass: "btn-group mr-2",
            attrs: { role: "group", "aria-label": "First group" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.btnUpdate }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-arrow-left" }),
                _vm._v("回上一頁 ")
              ]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "fieldsPanel" },
        [
          _c("ValidationObserver", { ref: "form" }, [
            _vm._v(
              " 編號需要有 - 明碼 (給人看的) - prefix - 是否連號 - 套表 兌換員管理 - 建基本資料 (手機) - 發簡訊OTP給兌換員手機 - 確認OTP,及要求設定密碼 - 要改/忘記密碼 - 通知tk管理者 - 由tk管理者，重發簡訊 - 經otp後重設密碼 qrcode 畫面 - 明碼 - 暗碼 - 活動內容 - 是否已兌換 兌換畫面 - 承上 - 輸入兌換員代碼 (4碼以上) - btn 兌換 "
            ),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("類型")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.Type,
                        expression: "formData.Type"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "Type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("推薦制")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("發行制")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("專案名稱(英文)(Prefix)")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Name,
                      expression: "formData.Name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.Name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("專案說明")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Name,
                      expression: "formData.Name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.Name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("會員註冊欄位")
              ]),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("MyLiteFormDesigntime", {
                    attrs: { config: _vm.liteFormConfig },
                    model: {
                      value: _vm.liteFormData,
                      callback: function($$v) {
                        _vm.liteFormData = $$v
                      },
                      expression: "liteFormData"
                    }
                  }),
                  _c("MyLiteFormRuntime", {
                    attrs: { config: _vm.liteFormConfig },
                    model: {
                      value: _vm.liteFormData,
                      callback: function($$v) {
                        _vm.liteFormData = $$v
                      },
                      expression: "liteFormData"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("是否啟用")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.IsEnable,
                        expression: "formData.IsEnable"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "IsEnable",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("生效中")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("停用")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("有效日期")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-between",
                    staticStyle: { "max-width": "400px" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.StartDt,
                          expression: "formData.StartDt"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "date",
                        id: "name",
                        name: "name",
                        placeholder: ""
                      },
                      domProps: { value: _vm.formData.StartDt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "StartDt", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" ~ "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.ExpireDt,
                          expression: "formData.ExpireDt"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "date",
                        id: "name",
                        name: "name",
                        placeholder: ""
                      },
                      domProps: { value: _vm.formData.ExpireDt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "ExpireDt",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("備註")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Memo,
                      expression: "formData.Memo"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "txtMemo", name: "txtMemo", rows: "3" },
                  domProps: { value: _vm.formData.Memo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Memo", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ]),
          _c("MyToolbarBase", [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { href: "#" } },
              [_c("i", { staticClass: "fa fa-clone" }), _vm._v(" 產生票券")]
            )
          ]),
          _vm.formData.Type === "1"
            ? _c(
                "div",
                [
                  _c("h1", [_vm._v("票券列表")]),
                  _c("MyToolbarVuetify", {
                    attrs: {
                      config: _vm.toolbarVuetifyConfig1,
                      "grid-meta": _vm.gridMeta1
                    }
                  }),
                  _c("MyGridVuetify", {
                    ref: "myGridVuetify",
                    staticStyle: { width: "1000px" },
                    attrs: {
                      config: _vm.gridVuetifyConfig1,
                      "grid-meta": _vm.gridMeta1
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "Fun",
                          fn: function(ref) {
                            return undefined
                          }
                        },
                        {
                          key: "IsEnable",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("MyLabelIsEnable", {
                                model: {
                                  value: item.IsEnable,
                                  callback: function($$v) {
                                    _vm.$set(item, "IsEnable", $$v)
                                  },
                                  expression: "item.IsEnable"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1302641004
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formData.Type === "2"
            ? _c(
                "div",
                [
                  _c("h1", [_vm._v("票券列表")]),
                  _c("MyToolbarVuetify", {
                    attrs: {
                      config: _vm.toolbarVuetifyConfig2,
                      "grid-meta": _vm.gridMeta2
                    }
                  }),
                  _c("MyGridVuetify", {
                    ref: "myGridVuetify",
                    staticStyle: { width: "1000px" },
                    attrs: {
                      config: _vm.gridVuetifyConfig2,
                      "grid-meta": _vm.gridMeta2
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "Fun",
                          fn: function(ref) {
                            return undefined
                          }
                        },
                        {
                          key: "IsEnable",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("MyLabelIsEnable", {
                                model: {
                                  value: item.IsEnable,
                                  callback: function($$v) {
                                    _vm.$set(item, "IsEnable", $$v)
                                  },
                                  expression: "item.IsEnable"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1302641004
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }