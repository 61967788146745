var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnDownloadQR }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("下載QRCode")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("名稱")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Name,
                              expression: "formData.Name"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("代號")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.CodePrefix,
                              expression: "formData.CodePrefix"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.CodePrefix },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "CodePrefix",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("編號起")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.formData.StartNo,
                              expression: "formData.StartNo",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", placeholder: "" },
                          domProps: { value: _vm.formData.StartNo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "StartNo",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("編號訖")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.formData.EndNo,
                              expression: "formData.EndNo",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", placeholder: "" },
                          domProps: { value: _vm.formData.EndNo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "EndNo",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("進階設定")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.EnableEffectDateRage,
                    expression: "formData.EnableEffectDateRage"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", value: "", id: "defaultCheck1" },
                domProps: {
                  checked: Array.isArray(_vm.formData.EnableEffectDateRage)
                    ? _vm._i(_vm.formData.EnableEffectDateRage, "") > -1
                    : _vm.formData.EnableEffectDateRage
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.formData.EnableEffectDateRage,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.formData,
                            "EnableEffectDateRage",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.formData,
                            "EnableEffectDateRage",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.formData, "EnableEffectDateRage", $$c)
                    }
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "defaultCheck1" }
                },
                [_vm._v(" 有效期間 ")]
              )
            ]),
            _c("div", { staticClass: "form-group custom-control-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.EffectDateRageStartDt,
                    expression: "formData.EffectDateRageStartDt"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "date",
                  id: "dtStart",
                  name: "dtStart",
                  placeholder: ""
                },
                domProps: { value: _vm.formData.EffectDateRageStartDt },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "EffectDateRageStartDt",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" ~ "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.EffectDateRageEndDt,
                    expression: "formData.EffectDateRageEndDt"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "date",
                  id: "dtEnd",
                  name: "dtEnd",
                  placeholder: ""
                },
                domProps: { value: _vm.formData.EffectDateRageEndDt },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "EffectDateRageEndDt",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.EnableLimitCount,
                    expression: "formData.EnableLimitCount"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", value: "", id: "defaultCheck2" },
                domProps: {
                  checked: Array.isArray(_vm.formData.EnableLimitCount)
                    ? _vm._i(_vm.formData.EnableLimitCount, "") > -1
                    : _vm.formData.EnableLimitCount
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.formData.EnableLimitCount,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.formData,
                            "EnableLimitCount",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.formData,
                            "EnableLimitCount",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.formData, "EnableLimitCount", $$c)
                    }
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "defaultCheck2" }
                },
                [_vm._v(" 每個代碼，可使用次數 ")]
              )
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.formData.LimitCount,
                  expression: "formData.LimitCount",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "number",
                id: "name",
                name: "name",
                placeholder: ""
              },
              domProps: { value: _vm.formData.LimitCount },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "LimitCount",
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("行銷內容 (格式為Json資料)")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.ContentJson,
                  expression: "formData.ContentJson"
                }
              ],
              staticClass: "form-control",
              attrs: { rows: "3" },
              domProps: { value: _vm.formData.ContentJson },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "ContentJson", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("說明")]
          ),
          _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.Memo,
                  expression: "formData.Memo"
                }
              ],
              staticClass: "form-control",
              attrs: { rows: "3" },
              domProps: { value: _vm.formData.Memo },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "Memo", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _c("DLGQRImageDownload", {
        ref: "dlgQRImageDownload",
        on: { returnOk: _vm.dlgQRImageDownloadReturnOk }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }