<template>
  <div>
    <COM_HeaderNav mode="uformDefine"></COM_HeaderNav>

    <iframe id="myIframe" >

    </iframe>
  </div>
</template>

<script>
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";


export default {
  components: {COM_HeaderNav},
  //name: "MemberEdit",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      OnePageLink:"",
    };
  },
  methods: {
    async loadData() {
      let systemId=this.$route.query.systemId;

      let ubossHost=new ubossHostManager();
      let url=ubossHost.formShowFormUrlWithDesignMode(systemId);

      this.OnePageLink=url;
      //QQ how to good dev flag
      // this.OnePageLink="http://127.0.0.1:9009/indexForm?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09&id="+systemId;

      document.getElementById('myIframe').src=this.OnePageLink;
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
  margin: 50px;
}
</style>
