var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "ticket2", "button-index": "4" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "a_thinlabel col-sm-2 label-required",
              attrs: { for: "" }
            },
            [_vm._v("名稱")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "名稱" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Name,
                              expression: "formData.Name"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("備註")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "備註" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Memo,
                              expression: "formData.Memo"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "3" },
                          domProps: { value: _vm.formData.Memo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Memo",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("票據樣式")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("FileUploadButton", {
                attrs: {
                  "emmit-back-param": _vm.formData,
                  "upload-type": _vm.$u.UBossUploadType_Ticket
                },
                on: { uploadSuccess: _vm.uploadSuccess }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "680px",
                    height: "200px",
                    "background-image":
                      "url('https://via.placeholder.com/680x200')",
                    "background-size": "100% 100%",
                    "background-repeat": "no-repeat",
                    position: "relative"
                  },
                  style: _vm.getStyle()
                },
                [
                  _c(
                    "vue-draggable-resizable",
                    {
                      attrs: {
                        w: _vm.width,
                        h: _vm.height,
                        x: _vm.x,
                        y: _vm.y,
                        parent: true,
                        grid: [20, 20],
                        "lock-aspect-ratio": true
                      },
                      on: { dragging: _vm.onDrag, resizing: _vm.onResize }
                    },
                    [_vm._v(" QR Code ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        false
          ? _c(
              "a",
              {
                staticClass: "btn btn-primary",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.test()
                  }
                }
              },
              [_vm._v("Test")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }