<template>
  <div class="fieldsPanel">
    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded">
      日曆名稱:<input type="text" class="form-control myInputWidth" placeholder="" v-model="calendar.Name">
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded">
      預約說明
      <MyCkEditor ref="myCkEditor" v-model="calendar.Memo"  height="200" width="100%" :file-upload-list-lite_is-show="false" :template_feature_isEnable="false" :upload-image_feature_is-enable="false">

      </MyCkEditor>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded">
      預約時段單位
      <label class="radio-inline"><input type="radio" value="day" name="" v-model="calendar.Timeperiod">日</label>
      <label class="radio-inline"><input type="radio" value="halfday" name="" v-model="calendar.Timeperiod">上午/下午</label>
      <label class="radio-inline"><input type="radio" value="hour" name="" v-model="calendar.Timeperiod">小時</label>
      <label class="radio-inline"><input type="radio" value="halfhour" name="" v-model="calendar.Timeperiod">半小時</label>

      <br>
      <div >
        上班時間
        <b-form-timepicker  locale="en" class="myInputWidth" v-model="calendar.StartWorkTime"></b-form-timepicker>
        午休時間
        <div class="d-flex justify-content-between"  style="max-width: 400px">
        <b-form-timepicker  locale="en" class="myInputWidth" v-model="calendar.RestStartTime"></b-form-timepicker> ~ <b-form-timepicker  locale="en" class="myInputWidth"  v-model="calendar.RestEndTime"></b-form-timepicker>
        </div>
        下班時間
        <b-form-timepicker  locale="en" class="myInputWidth" v-model="calendar.EndWorkTime"></b-form-timepicker>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded">
      快速選取
      <div>
        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_All_Check()">全部可以</a>
        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_All_UnCheck()">全部不可以</a>

        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_Spec([1,2,3,4,5],[6,0])">周休二日</a>
        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_Spec([1,2,3,4,5,6],[0])">周日休</a>
        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_Spec([1,2,3,4,5,0],[6])">周六休</a>
        <a role="button" class="btn btn-primary mr-1" href="#" @click.prevent="selectAction_Spec([2,3,4,5,6,0],[1])">周一休</a>

        <br class="">
        <b-button :pressed.sync="status_day0" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(0,status_day0)">星期日</b-button>
        <b-button :pressed.sync="status_day1" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(1,status_day1)">星期一</b-button>
        <b-button :pressed.sync="status_day2" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(2,status_day2)">星期二</b-button>
        <b-button :pressed.sync="status_day3" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(3,status_day3)">星期三</b-button>
        <b-button :pressed.sync="status_day4" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(4,status_day4)">星期四</b-button>
        <b-button :pressed.sync="status_day5" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(5,status_day5)">星期五</b-button>
        <b-button :pressed.sync="status_day6" class="mr-1 mt-3" variant="secondary" @click="selectAction_ByDate(6,status_day6)">星期六</b-button>

      </div>

    </div>

    <MyCalendarAvailableSetting ref="myCalendarAvailableSetting" :calendar-setting="calendar"></MyCalendarAvailableSetting>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import MyCalendarAvailableSetting from "@/pages/commonComponent/CalendarEx/MyCalendarAvailableSetting";
import MyCkEditor from "@/pages/commonComponent/MyCkEditor";
// import MyCalendarOptionPicker from "@/pages/commonComponent/CalendarEx/MyCalendarOptionPicker";

export default {
  components: {MyCkEditor, MyCalendarAvailableSetting},
  data: function () {
    return {
      systemId: "",
      formData: {
        name: "",
      },
      /** @type {JCalendarSetting} */
      calendar:{
        Name:"",
        Timeperiod:"halfday",
        StartWorkTime:"09:00",
        EndWorkTime:"18:00",
        RestStartTime:"12:00",
        RestEndTime:"13:00",
        Memo:"上午 10:00 / 下午 14:00"
      },
      status_day0: false,
      status_day1: false,
      status_day2: false,
      status_day3: false,
      status_day4: false,
      status_day5: false,
      status_day6: false,
    };
  },
  watch:{
    "calendar.Timeperiod":function () {
      this.status_day0 = false;
      this.status_day1 = false;
      this.status_day2 = false;
      this.status_day3 = false;
      this.status_day4 = false;
      this.status_day5 = false;
      this.status_day6 = false;
    }
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossShopBk.api_Member_Get(this.systemId)

        this.formData=data
      }

    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossShopBk.api_Member_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
    selectAction_All_Check(){
      this.$refs.myCalendarAvailableSetting.selectAction_AllToggle(true)
    },
    selectAction_All_UnCheck(){
      this.$refs.myCalendarAvailableSetting.selectAction_AllToggle(false)
    },
    selectAction_ByDate(dayIndex,isChecked){
      this.$refs.myCalendarAvailableSetting.selectAction_WeekIndexToggle(dayIndex,isChecked)
    },
    selectAction_Spec(weekIndexListSelected,weekIndexListUnSelected){
      this.$refs.myCalendarAvailableSetting.selectAction_Spec(weekIndexListSelected,weekIndexListUnSelected)
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.active{
  color:#fff;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
</style>
