<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="複製">

<!--      <div class="input-group mb-3">-->
<!--        <div class="input-group-prepend">-->
<!--          <button class="btn btn-outline-secondary" type="button"-->
<!--                  v-clipboard:copy="propsValue"-->
<!--                  v-clipboard:success="onCopy"-->
<!--                  v-clipboard:error="onError">複製</button>-->
<!--        </div>-->
<!--        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" v-model="propsValue">-->
<!--      </div>-->

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" ref="copyButton"  data-clipboard-target="#copyInput">複製</button>
        </div>
        <input type="text" class="form-control" placeholder="" aria-label="" id="copyInput" v-model="propsValue">


      </div>




      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>

    <!--    //<v-input-colorpicker  v-model="propsValue" style="border: black;border-style:solid;width:100px"/>-->
  </div>
</template>

<script>

//use <MyCopySingleLineDlg ref="myCopySingleLineDlg" ></MyCopySingleLineDlg>
//use
// this.$refs.myCopySingleLineDlg.value="test";
// this.$refs.myCopySingleLineDlg.showModal();
import Clipboard from 'clipboard';


export default {
  props: ["value"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
      showThis:false,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    returnModal(value) {
      // this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk',value);
      this.$refs['my-modal'].hide();
    },
    onCopy: function (e) {  // eslint-disable-line
      // alert('You just copied: ' + e.text)
    },
    onError: function (e) { // eslint-disable-line
      alert('無法自動複製，請手動複製')
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    // 初始化複製外掛
    initClipboard() {
      const clipboard = new Clipboard(this.$refs.copyButton);
      clipboard.on('success', (e) => {  // eslint-disable-line
        console.log('複製成功')
      });
      clipboard.on('error', (e) => {// eslint-disable-line
        alert('複製失敗，請手動複製')
      });
    }
  },
  updated() {
    this.initClipboard();
  },
}





</script>

<style scoped>

</style>
