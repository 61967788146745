<template>
  <div >
<!--    :is-vertical-resize="true" 				// 設置表格的縱向自適應-->
<!--    :columns="tableConfig.columns" 			// 列的集合-->
<!--    :title-rows="tableConfig.titleRows"		// 表格的表頭-->
<!--    :table-data="tableConfig.tableData"		// 表格的數據集-->
<!--    :show-vertical-border="false"			// 是否顯示垂直border-->
<!--    :width="1295" 							// 設置表格的寬度-->
<!--    :height="540" 							// 設置表格的高度-->
<!--    :min-height="500"						// 設置表格的最低高度-->
<!--    row-hover-color="#eee"					// 鼠標移動到相應行時變換的顏色-->
<!--    row-click-color="#edf7ff"				// 鼠標點擊時變換的顏色-->
<!--    :paging-index="(pageIndex-1)*pageSize"	// 當前表格列的數目-->
<!--    :select-all="selectAll"					// 選擇所有選項時觸發的函數-->
<!--    :select-change="selectChange"			// 選中某一選項時觸發的函數-->
<!--    :select-group-change="selectGroupChange"// 任意選中選項出發的函數-->
<!--    :is-loading="isLoading"					// 數據加載時顯示loading圖標    -->
<!--    :width="1295"-->
<!--    :height="540"-->
<!--    :min-height="500"-->

<!-- 其他有用的屬性-->
<!--    :title-rows="tableConfig.titleRows"-->

<!--    :max-height="300"-->
<!--    max-height="calc(100vh - 350px)"-->
<!--    :scroll-width="1200"    //在mobile時，可以往右捲動-->
<!--    :max-height="200" :fixed-header="true"-->
<!--    :max-height="200" :fixed-header="false"-->

<!--    column-->
<!--      width: "40%",-->
<!--      width: 400,-->
<!--      fixed: "left",    //釘選欄-->

<!--      //超過長度變成...-->
<!--      ellipsis: {-->
<!--        showTitle: true,-->
<!--      },-->

<!--      //超過長度變成...  (但顯示2行)-->
<!--      ellipsis: {-->
<!--        showTitle: true,-->
<!--        lineClamp: 2,-->
<!--      },-->

    <div class="input-group" style="width:200px">
     <span class="input-group-append">
          <button class="btn btn-outline-secondary border-start-0 border ms-n3" type="button">
              <i class="fa fa-search"></i>
          </button>
      </span>
      <input type="text" class="form-control" placeholder="關鍵字" v-model="searchText" >
    </div>

    <div style="background-color: white;max-width: 1280px">
      <ve-table
          ref="grid"
          :style="{'word-break':wordBreak}"
          :row-style-option="rowStyleOption"
          :radio-option="radioOption"
          :checkbox-option="checkboxOption"
          :is-vertical-resize="true"
          :columns="columns"
          :table-data="filterDataSource"
          :show-vertical-border="true"
          row-hover-color="#eee"
          row-click-color="#edf7ff"
          :select-all="config.selectAll"
          :select-change="config.selectChange"
          :select-group-change="config.selectGroupChange"
          :paging-index="(config.pageIndex-1)*config.pageSize"
          :is-loading="config.isLoading"
          :expand-option="expandOption"
          row-key-field-name="SystemId"
          :border-around="true"
          :border-x="true"
          :border-y="true"
          :sort-option="sortOption"
          :column-width-resize-option="columnWidthResizeOption"
          :cell-style-option="cellStyleOption"
      ></ve-table>
      <ve-pagination v-show="!showEmpty" :total="tableData.length" :page-size-option="[10,50,100]" :page-size="10" />
      <div v-show="showEmpty" class="empty-data">查無資料!</div>
    </div>
  </div>

</template>

<!--
https://happy-coding-clans.github.io/vue-easytable/#/en/doc/start

-->
<script>


import _ from "lodash";

export default {
  components: {
  },
  data: function () {
    return {
      searchText: "",
      searchStatus: "",
      showEmpty:false,
      wordBreak: "normal",      // normal | keep-all | break-all  | break-word    //https://happy-coding-clans.github.io/vue-easytable/#/en/doc/table/column-width?anchor=long-text-destroys-layout
      expandOption: {
        render: ({ row, column, rowIndex }, h) => { // eslint-disable-line
          return (
              <p>
                My name is <span style="color:#1890ff;">{row.name}</span>
                ,I'm living in {row.address}
              </p>
          );
        },
      },
      rowStyleOption: {
        stripe: true,
        hoverHighlight: true,
      },
      cellStyleOption: {
        bodyCellClass: undefined
      },
      columnWidthResizeOption: {
        // default false
        enable: true,
        // column resize min width
        // minWidth: 30,
        // column size change
        // sizeChange: ({ column, differWidth, columnWidth }) => {
        //   this.columnResizeInfo.column = column;
        //   this.columnResizeInfo.differWidth = differWidth;
        //   this.columnResizeInfo.columnWidth = columnWidth;
        // },
      },
      config:{
        pageIndex: 1,
        pageSize: 10,
        selectAll: true,
        selectChange:true,
        isLoading: false,
        selectGroupChange:true,
      },
      sortOption: {
        sortAlways: true,
        sortChange: (params) => {
          this.gridEvent_sortChange(params);
        },
      },
      radioOption: {
        defaultSelectedRowKey: "",
        disableSelectedRowKeys:[],
        selectedRowKey:"",//有宣告這個值後，就需要手動控制此值
        selectedRowChange: ({ row }) => {
          this.gridEvent_RadioSelectedRowChange(row);
        },
      },
      checkboxOption: {
        hideSelectAll: false,
        defaultSelectedRowKeys:[],
        disableSelectedRowKeys:[],
        selectedRowKeys:[],//有宣告這個值後，就需要手動控制此值
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {  // eslint-disable-line
          // console.log(row, isSelected, selectedRowKeys);
          this.gridEvent_CheckboxSelectedRowChange(row,isSelected, selectedRowKeys );
        },
        // selected all change event
        selectedAllChange: ({ isSelected, selectedRowKeys }) => { // eslint-disable-line
          // console.log(isSelected, selectedRowKeys);
          this.gridEvent_CheckboxSelectedRowAll(isSelected, selectedRowKeys );
        },
      },
      columns: [
      ],
      tableData: [
      ],
    };
  },
  watch:{
    'checkboxOption.selectedRowKeys':function(newValue){// eslint-disable-line
      this.$emit('grid-selected',this.gridUtil_SelectedIds())
    },
    'radioOption.selectedRowKey':function(newValue){// eslint-disable-line
      this.$emit('grid-selected',this.gridUtil_SelectedIds())
    },
  },
  computed: {
    filterDataSource(){
      //filter all properties in array
      let dataToShow =this.tableData;

      if (this.searchText){
        dataToShow = dataToShow.filter((item) => {
          return Object.keys(item).some((key) => {
            return String(item[key]).toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
          });
        });
      }
      if (this.searchStatus.length>0){
        dataToShow = dataToShow.filter(
              (x) => this.searchStatus.length === 0 || this.searchStatus.includes(x.status),
          );
      }


      return dataToShow;
    }
  },
  methods: {
    set_expandOption(expandOptionRender){
      this.expandOption.render=expandOptionRender
    },
    set_columns(columns){
      this.columns=columns
    },
    set_tableData(gridData){
      this.tableData=gridData
    },
    set_cellStyleOption(bodyCellClassFun){
      this.cellStyleOption.bodyCellClass=bodyCellClassFun;
    },
    set_mobileUI(){
      // debugger // eslint-disable-line
      //處理RWD
      if (this.$mq === 'sm') {
        //將expand顯示出來
        if (this.columnExist("expand")){
          this.$refs.grid.showColumnsByKeys(["expand"]);
        }

        //將seq隱藏，省空間
        if (this.columnExist("seq")){
          this.$refs["grid"].hideColumnsByKeys(["seq"]);
        }

        let columnsToHide = this.columns.filter((s) => s.hideInMobile).map((s) => s.key);

        if (columnsToHide.length>0){
          this.$refs.grid.hideColumnsByKeys(columnsToHide);
        }
      } else {
        //將expand隱藏 (桌面版不用)
        if (this.columnExist("expand")) {
          this.$refs.grid.hideColumnsByKeys(["expand"]);
        }
      }

    },
    //---------------------------------------
    gridUtil_RadioSelectById(key) {
      let selectedRowKey = this.radioOption.selectedRowKey;

      if (selectedRowKey === key) {
        this.radioOption.selectedRowKey = "";
      } else {
        this.radioOption.selectedRowKey = key;
      }
    },
    gridUtil_RadioUnSelect() {
      this.radioOption.selectedRowKey = "";
    },
    gridUtil_CheckboxSelectAll() {
      this.checkboxOption.selectedRowKeys = this.tableData.map((x) => x.SystemId);
    },
    gridUtil_UnCheckboxSelectAll() {
      this.checkboxOption.selectedRowKeys = [];
    },
    gridUtil_ShowColumnsByKeys(columnKeys) {
      this.$refs.grid.showColumnsByKeys(columnKeys);
    },
    gridUtil_HideColumnsByKeys(columnKeys) {
      this.$refs.grid.hideColumnsByKeys(columnKeys);
    },
    gridUtil_CheckBoxSelectedIds(){
      return this.checkboxOption.selectedRowKeys;
    },
    gridUtil_RadioSelectedId(){
      return this.radioOption.selectedRowKey;
    },
    gridUtil_SelectedIds(){
      if (this.checkboxOption.selectedRowKeys.length>0){
        return this.checkboxOption.selectedRowKeys;
      }else if (this.radioOption.selectedRowKey.length>0){
        return this.radioOption.selectedRowKey;
      }else{
        return [];
      }
    },
    gridUtil_ShowEmpty(bShow=true){
      return this.showEmpty=bShow;
    },
    //---------------------------------------
    gridEvent_RadioSelectedRowChange(row){
      this.radioOption.selectedRowKey = row.SystemId;
    },
    gridEvent_CheckboxSelectedRowChange(row,isSelected, selectedRowKeys ){  // eslint-disable-line
      console.log(row.SystemId,isSelected,selectedRowKeys);
      this.checkboxOption.selectedRowKeys = selectedRowKeys;
    },
    gridEvent_CheckboxSelectedRowAll(isSelected, selectedRowKeys){
      console.log(isSelected,selectedRowKeys);
    },
    gridEvent_sortChange(params) {// eslint-disable-line
      //foreach params property
      Object.entries(params).forEach(([key, value]) => {
        if (value){
          this.tableData=_.orderBy(this.tableData,[key],[value])
        }
      });
    },
    //---------------------------------------
    // cell data change (不能運作)
    cellDataChange(row, column, cellValue) {
      const { submitData } = this;

      let currentCell = submitData.find(
          (x) => x.rowKey === row["rowKey"] && x.field === column.field,
      );

      if (currentCell) {
        currentCell.value = cellValue;
      } else {
        let newCell = {
          rowKey: row["rowKey"],
          field: column.field,
          value: cellValue,
        };
        this.submitData.push(newCell);
      }
    },
    //---------------------------------------
    columnExist(fieldKey){
      return this.columns.some((s) => s.key === fieldKey);
    },
  },
  created: function () {
    console.log("grid created");
  },
  mounted: function () {
    console.log("grid mounted");


  },
}
</script>

<style scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>

