var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ckeditor", {
        ref: "ckEditor",
        attrs: {
          editorUrl: "https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js",
          config: _vm.editorConfig
        },
        model: {
          value: _vm.propsValue,
          callback: function($$v) {
            _vm.propsValue = $$v
          },
          expression: "propsValue"
        }
      }),
      _vm.uploadImage_feature_isEnable
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("FileUploadListLite", {
                ref: "fileUploadListLite",
                attrs: {
                  "emmit-back-param": "undefined",
                  "owner-system-id": _vm.fileUploadListLite_ownerSystemId,
                  "file-use-memo": _vm.fileUploadListLite_fileUseMemo
                },
                on: { imageClick: _vm.imageClick }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.template_feature_isEnable
        ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _c("p", { staticStyle: { display: "inline" } }, [
              _vm._v("插入範本")
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.templateType,
                    expression: "templateType"
                  }
                ],
                staticClass: "form-control aselect",
                staticStyle: { display: "inline" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.templateType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [_vm._v("請選擇")]),
                _c("option", { attrs: { value: "textAlignLeft" } }, [
                  _vm._v("文字靠左")
                ]),
                _c("option", { attrs: { value: "textAlignRight" } }, [
                  _vm._v("文字靠右")
                ]),
                _c("option", { attrs: { value: "textAlignCenter" } }, [
                  _vm._v("文字置中")
                ]),
                _c("option", { attrs: { value: "tableAlignCenter" } }, [
                  _vm._v("表格置中")
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }