
<template>
  <form>
    <div class="shadow p-3 mb-5 bg-white rounded" v-for="(panel,indexPanel) in formDefine.Panels" :key="indexPanel">
      <div class="row">
<!--        mx-auto-->
        <div class="col-md-10">
          <div class="form-group row" v-for="(row,indexRow) in panel.Rows" :key="indexRow">
            <div  v-for="(field,indexField) in row.Fields" :key="indexField"  v-bind:class="getFieldClass(field)">
              <label for="" >{{ field.text }}</label>
              <MyFormPanelField :field="field" :model.sync="dataSource[field.fieldName]"></MyFormPanelField>
              <slot v-if="field.slot" v-bind:name="field.slot"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import MyFormPanelField from "@/pages/commonComponent/MyFormPanel/MyFormPanelField.vue";

export default {
  props: ["dataSource"],
  components: {MyFormPanelField},
  data: function () {
    return {
      formDefine:{
      }
    };
  },
  methods:{
    setPropFormDefine(form){
      this.formDefine=form;
    },

    getFieldClass(field){
      let classStr="col-sm-" + field.gridSize +" ";

      if (field.fieldClassName){
        classStr = classStr+ field.fieldClassName + " ";
      }

      return classStr;
    },
  },
  mounted() {

  }

}
</script>



<style scoped>

</style>
