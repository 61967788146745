var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "c-sidebar c-sidebar-lg c-sidebar-light c-sidebar-right c-sidebar-overlaid",
      attrs: { id: "aside" }
    },
    [
      _c(
        "button",
        {
          staticClass: "c-sidebar-close c-class-toggler",
          attrs: {
            type: "button",
            "data-target": "_parent",
            "data-class": "c-sidebar-show"
          }
        },
        [
          _c("svg", { staticClass: "c-icon" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-x"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }