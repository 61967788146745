<template>
  <div>
<!--    <swipe-list-->
<!--        ref="list"-->
<!--        class="card"-->
<!--        :disabled="false"-->
<!--        :items="datas"-->
<!--        item-key="id"-->
<!--        @swipeout:click="itemClick"-->
<!--    >-->
<!--      <template v-slot="{ item, index, revealLeft, revealRight, close }">   &lt;!&ndash; eslint-disable-line &ndash;&gt;-->
<!--&lt;!&ndash;        <slot name="listItemView" :dataItem="item"></slot>&ndash;&gt;-->

<!--        <div class="list-group-item list-group-item-action flex-column align-items-start active" data-abc="true">-->
<!--          <div class="w-100" style="white-space: nowrap; text-overflow: ellipsis;overflow: hidden;font-width: bold;">-->
<!--            <h4>{{ item[fieldToShow.title] }}</h4>-->
<!--          </div>-->
<!--          <div class="w-100" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">-->
<!--            {{ item[fieldToShow.memo] }}-->
<!--          </div>-->
<!--          <div class="w-100" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">-->

<!--            <span class="badge badge-pill badge-info">{{ item[fieldToShow.badge1] }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="list-group-item list-group-item-action flex-column align-items-start active" data-abc="true">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="w-100" style="white-space: nowrap; text-overflow: ellipsis;overflow: hidden;font-width: bold;">&ndash;&gt;-->
<!--&lt;!&ndash;            <h4>{{ item.表單名稱 }}</h4>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="w-100" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">&ndash;&gt;-->
<!--&lt;!&ndash;            {{ item.說明 }}&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="w-100" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">&ndash;&gt;-->

<!--&lt;!&ndash;            <span class="badge badge-pill badge-info">{{ item.建立日期 }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </template>-->
<!--      &lt;!&ndash; left swipe side template and v-slot:left="{ item }" is the item clearly &ndash;&gt;-->
<!--      &lt;!&ndash; remove if you dont wanna have left swipe side  &ndash;&gt;-->
<!--      <template v-slot:left="{ item, close }">    &lt;!&ndash; eslint-disable-line &ndash;&gt;-->
<!--        <div class="swipeout-action red" title="remove" @click="swipeClick_Right(item)">-->
<!--          &lt;!&ndash; place icon here or what ever you want &ndash;&gt;-->
<!--          <i class="fa fa-trash"></i>-->
<!--        </div>-->
<!--        &lt;!&ndash;          <div class="swipeout-action purple" @click="close">&ndash;&gt;-->
<!--        &lt;!&ndash;            &lt;!&ndash; place icon here or what ever you want &ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;            <i class="fa fa-close"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;          </div>&ndash;&gt;-->
<!--      </template>-->
<!--      &lt;!&ndash; right swipe side template and v-slot:right"{ item }" is the item clearly &ndash;&gt;-->
<!--      &lt;!&ndash; remove if you dont wanna have right swipe side  &ndash;&gt;-->
<!--      <template v-slot:right="{ item }">                       &lt;!&ndash; eslint-disable-line &ndash;&gt;-->
<!--        <div class="swipeout-action blue" @click="swipeClick_Left(item)">-->
<!--          &lt;!&ndash; place icon here or what ever you want &ndash;&gt;-->
<!--          &lt;!&ndash;            <i class="fa fa-heart"></i>&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="fas fa-file-excel"></i>報名結果&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;          <div class="swipeout-action green">&ndash;&gt;-->
<!--        &lt;!&ndash;            &lt;!&ndash; place icon here or what ever you want &ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;            <i class="fa fa-heart"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;          </div>&ndash;&gt;-->
<!--      </template>-->
<!--      <template v-slot:empty>-->
<!--        <div>-->
<!--          &lt;!&ndash; change mockSwipeList to an empty array to see this slot in action  &ndash;&gt;-->
<!--          list is empty ( filtered or just empty )-->
<!--        </div>-->
<!--      </template>-->
<!--    </swipe-list>-->
  </div>
</template>

<script>
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
// import { SwipeList } from 'vue-swipe-actions';


export default {
  props:{
    fieldToShow: {
      type: Object,
      required: true,
    },
    datas: {
      type: Array,
      required: true,
    },
    swipeSetting:{
      type:Object,
      required:true,
    }
  },
  components:{
    // SwipeOut,
    // SwipeList
  },
  data: function () {
    return {
      staticColumns:[],
      selectedField: 'selected',
      selectedID: 0,
      selectedIDs: [],
      pageSize: 20,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'input',
        pageSizes: true,
        previousNext: true
      },
      skip: 0,
      take: 10,
      allowUnsort: true,
      multiple: false,
      sort:[],
      columnsSetWidthed:[],
      setMinWidth: false,
      gridCurrent: 0,
    };
  },
  computed: {

  },
  methods: {
    itemClick(item) {
      // console.log(e, "item click");
      // this.$router.push({ name: 'UFormEdit', query: { ID: 0 }})
      this.swipeSetting.ItemClick(item)
    },
    swipeClick_Right(item){
      console.log(item);
    },
    swipeClick_Left(item){
      console.log(item);
    }
  },
  created() {
    // let selectColumn={ field: 'selected', width: '30px',minWidth:'30px', headerSelectionValue: this.areAllSelected }
    // this.columns.unshift (selectColumn)

    // this.columnsSetWidthed=this.columns
    //---------------------------------------

  },
  mounted() {


  },
}
</script>

<style scoped>
.list-group-item.active{
  background-color: white;
  color: black;
  /*border: black;*/
}
.card {
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22)
}

.card-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6f2
}
/*//---------------------------------------*/

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
/* app specific styles */
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 3rem;
  cursor: pointer;
  left: 0;
}
/* https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
.swipeout-action.blue {
  color: white;
  background-color: rgb(0, 122, 255);
}
.swipeout-action.blue:hover {
  background-color: darken(rgb(0, 122, 255), 5%);
}
.swipeout-action.purple {
  color: white;
  background-color: rgb(88, 86, 214);
}
.swipeout-action.purple:hover {
  background-color: darken(rgb(88, 86, 214), 5%);
}
.swipeout-action.red {
  color: white;
  background-color: rgb(255, 59, 48);
}
.swipeout-action.red:hover {
  background-color: darken(rgb(255, 59, 48), 5%);
}
.swipeout-action.green {
  color: white;
  background-color: rgb(76, 217, 100);
}
.swipeout-action.green:hover {
  background-color: darken(rgb(76, 217, 100), 5%);
}
.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid lightgray;
}
.swipeout-list-item:last-of-type {
  border-bottom: none;
}
.card {
  width: 100%;
  background-color: white;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid lightgray;
}
.card-content {
  padding: 1rem;
}
.transition-right {
  transform: translate3d(100%, 0 ,0) !important;
}
.transition-left {
  transform: translate3d(-100%, 0 ,0) !important;
}
.toolbar {
  display: flex;
  align-items: center;
}
.toolbar .toolbar-section {
  flex: 0 0 auto;
}
.toolbar .toolbar-section--center {
  flex: 1000 1 0%;
}

/*//---------------------------------------*/

.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}
</style>
