<template>
  <div class="fieldsPanel">
    <COM_HeaderNav mode="shoppingOrder"></COM_HeaderNav>

    <!--    Toolbar-->
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary" @click="btnUpdate"><i class="fa fa-save" ></i>儲存</button>
        <button type="button" class="btn btn-info" @click="$router.back()"><i class="fa fa-arrow-left"></i>回上一頁</button>
      </div>
    </div>

    <ValidationObserver ref="form">
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">訂單編號/來源</label>
        <div class="a_thinInputDiv col-sm-10">
          <label for="" class="a_thinlabel col-sm-2">{{ formData.OrderId }}/{{ formData.CLSourceType }}</label>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">會員</label>
        <div class="a_thinInputDiv col-sm-10">
<!--          <label for="" class="a_thinlabel col-sm-2">{{ formData.RelMember }}</label>-->
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">訂單來源</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="訂單來源" >-->
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.CLSourceType">-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">發票類型</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="發票類型" >
            <MyCodeListButtonRadio v-model="formData.CLInvoiceType" :options="$u.UCode_CLInvoiceType" size="md"></MyCodeListButtonRadio>

<!--            <select class="form-control" v-model="formData.CLInvoiceType">-->
<!--              <option></option>-->
<!--              <option value="person">個人電子發票</option>-->
<!--              <option value="company">公司戶電子發票</option>-->
<!--              <option value="donation">捐贈發票</option>-->
<!--              <option value="paper">寄送實體發票</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">付款類型</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="付款類型" >
            <MyCodeListButtonRadio v-model="formData.CLPayType" :options="$u.UCode_CLPayType" size="md" :add-empty-select-item="true" empty-item-text="未知" empty-item-value=""></MyCodeListButtonRadio>
<!--            <select class="form-control" v-model="formData.CLPayType">-->
<!--              <option></option>-->
<!--              <option value="CASH">現金</option>-->
<!--              <option value="CREDIT">信用卡</option>-->
<!--              <option value="WEBATM">WebATM</option>-->
<!--              <option value="VACC">ATM</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">付款備註</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="付款備註" >-->
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.PayTypeMemo">-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">付款記錄</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="付款記錄" >-->
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.PayTypeLog">-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">付款狀態</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="付款狀態" >
            <MyCodeListButtonRadio v-model="formData.CLPayStatus" :options="$u.UCode_CLPayStatus" size="md"></MyCodeListButtonRadio>
<!--            <select class="form-control" v-model="formData.CLPayStatus">-->
<!--              <option></option>-->
<!--              <option value="success">付款成功</option>-->
<!--              <option value="fail">付款失敗</option>-->
<!--              <option value="wait">付款等待中</option>-->
<!--              <option value="waitRefund">等待退款</option>-->
<!--              <option value="refund">已退款</option>-->
<!--              <option value="dispute">交易爭執</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">訂單狀態</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="訂單狀態" >
            <MyCodeListButtonRadio v-model="formData.CLOrderStatus" :options="$u.UCode_CLOrderStatus" size="md"></MyCodeListButtonRadio>
<!--            <select class="form-control" v-model="formData.CLOrderStatus">-->
<!--              <option></option>-->
<!--              <option value="unsetup">尚未成立</option>-->
<!--              <option value="setup">成立</option>-->
<!--              <option value="finish">完成</option>-->
<!--              <option value="cancel">取消</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">收件人姓名</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="收件人姓名" >
            <input type="text" class="form-control " placeholder="" v-model="formData.ReceiverName">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">收件人聯絡電話</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="收件人聯絡電話" >
            <input type="text" class="form-control " placeholder="" v-model="formData.ReceiverTel">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">配送方式</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="配送方式" >
            <MyCodeListButtonRadio v-model="formData.CLShipType" :options="$u.UCode_CLShipType" size="md"></MyCodeListButtonRadio>
<!--            <select class="form-control" v-model="formData.CLShipType">-->
<!--              <option></option>-->
<!--              <option value="self">自取</option>-->
<!--              <option value="ship">宅配</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">送貨地址</label>
        <div class="a_thinInputDiv col-sm-10 form-inline">
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="送貨地址" >  //QQ-->
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.AddressPostCode" style="width: 100px">-->
            <input type="text" class="form-control " placeholder="" v-model="formData.AddressCode1" style="width: 100px">
            <input type="text" class="form-control " placeholder="" v-model="formData.AddressCode2" style="width: 100px">
            <input type="text" class="form-control " placeholder="" v-model="formData.AddressRoad" style="width: 100%">
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">配送狀態</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="配送狀態" >
            <MyCodeListButtonRadio v-model="formData.CLShipStatus" :options="$u.UCode_CLShipStatus" size="md"></MyCodeListButtonRadio>
<!--            <select class="form-control" v-model="formData.CLShipStatus">-->
<!--              <option></option>-->
<!--              <option value="unship">尚未出貨</option>-->
<!--              <option value="shipping">運送中</option>-->
<!--              <option value="arrival">到達</option>-->
<!--            </select>-->
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">出貨日</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="出貨日" >
            <input type="date" class="form-control " placeholder="" v-model="formData.ShipDt">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
<!--      <div class="form-group row">-->
<!--        <label for="" class="a_thinlabel col-sm-2">訂單原始金額</label>-->
<!--        <div class="a_thinInputDiv col-sm-10">-->
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="訂單原始金額" >-->
<!--            <input type="text" class="form-control " placeholder="" v-model="formData.TotalOrgAmount">-->
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">訂單金額</label>
        <div class="a_thinInputDiv col-sm-10">
          <validation-provider rules="required" v-slot="{ errors }"  name="訂單金額" >
            <input type="number" class="form-control " placeholder="" v-model.number="formData.TotalAmount">
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="a_thinlabel col-sm-2">備註</label>
        <div class="a_thinInputDiv col-sm-10">
<!--          <validation-provider rules="required" v-slot="{ errors }"  name="備註" >-->
          <textarea class="form-control"  rows="3" v-model="formData.Memo"></textarea>
<!--            <span class="error_msg">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
        </div>
      </div>

      <h2>訂購內容</h2>
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
          <th class="text-center">項目</th>
          <th>單價</th>
          <th>數量</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in formData.Details" :key="index">
          <td class="text-left">
            {{item.ProductText}}
          </td>
          <td class="text-center">
            {{item.UnitPrice}}
          </td>
          <td class="text-left">
            {{item.Quantity}}
          </td>
        </tr>

        </tbody>
      </table>


    </ValidationObserver>
  </div>
</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossShopBk from "@/pages/ubossBk/util/apiUBossShopBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyCodeListButtonRadio from "@/pages/commonComponent/MyCodeListButtonRadio";

export default {
  components: {MyCodeListButtonRadio, COM_HeaderNav},
  data: function () {
    return {
      systemId: "",
      formData: {

      },
      formDataDemo: {

        "OrderId":"",
        "RelMember":"",
        "CLSourceType":"",
        "CLInvoiceType":"",
        "CLPayType":"",
        "PayTypeMemo":"",
        "PayTypeLog":"",
        "CLPayStatus":"",
        "CLOrderStatus":"",
        "CLShipType":"",
        "CLShipStatus":"",
        "ShipDt":"",
        "TotalOrgAmount":"",
        "TotalAmount":"",
      },
    };
  },
  methods: {
    async loadData() {
      this.systemId=this.$route.query.systemId;

      if (this.systemId){
        let data=await apiUBossShopBk.api_Order_Get(this.systemId)
        this.formData=data
      }
    },
    btnUpdate(){
      let router=this.$router

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          if (this.formData.ShipDt===""){
            this.formData.ShipDt=null;
          }
          //---------------------------------------
          apiUBossShopBk.api_Order_Save(this.formData).then(()=>{
            util.ShowMessage("儲存成功","訊息")
            router.back()
          })
          //---------------------------------------
        }
      });
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
