<template>
  <div>
    <input type="text" className="form-control" placeholder="" v-model="propsValue">

    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary"><i class="fa fa-save"></i> 儲存</button>
        <button type="button" class="btn btn-primary"><i class="fa fa-clone"></i> 複製</button>
        <button type="button" class="btn btn-primary"><i class="fa fa-arrow-left"></i> 回上一頁</button>
        <button type="button" class="btn btn-danger"><i class="fa fa-times-circle"></i> 關閉</button>
      </div>
    </div>

  </div>
</template>

<script>/**

 **/
// import util from "@/pages/common/util";


export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    // this.initValue()
    //this.loadData()
    // util.ObjectOverride(this.configDefault,this.config);
  },
}
</script>

<style scoped>

</style>
