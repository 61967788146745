var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "ckeditorDiv" }, [
      _c("table", { staticClass: "table table-hover" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _vm._m(1),
            _c("td", { staticClass: "text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsWebPart.Line,
                    expression: "propsWebPart.Line"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "name",
                  name: "name",
                  placeholder: ""
                },
                domProps: { value: _vm.propsWebPart.Line },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.propsWebPart, "Line", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("tr", [
            _vm._m(2),
            _c("td", { staticClass: "text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsWebPart.FB,
                    expression: "propsWebPart.FB"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "name",
                  name: "name",
                  placeholder: ""
                },
                domProps: { value: _vm.propsWebPart.FB },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.propsWebPart, "FB", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("tr", [
            _vm._m(3),
            _c(
              "td",
              { staticClass: "text-center" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.propsWebPart.Email,
                      expression: "propsWebPart.Email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  },
                  domProps: { value: _vm.propsWebPart.Email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.propsWebPart, "Email", $event.target.value)
                    }
                  }
                }),
                _c("MyLiteFormDesigntime", {
                  attrs: { config: _vm.liteFormConfig },
                  model: {
                    value: _vm.liteFormData,
                    callback: function($$v) {
                      _vm.liteFormData = $$v
                    },
                    expression: "liteFormData"
                  }
                }),
                _vm._v(" 預覽 "),
                _c("MyLiteFormRuntime", {
                  attrs: { config: _vm.liteFormConfig },
                  model: {
                    value: _vm.liteFormData,
                    callback: function($$v) {
                      _vm.liteFormData = $$v
                    },
                    expression: "liteFormData"
                  }
                })
              ],
              1
            )
          ]),
          _c("tr", [
            _vm._m(4),
            _c("td", { staticClass: "text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsWebPart.CellPhone,
                    expression: "propsWebPart.CellPhone"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "name",
                  name: "name",
                  placeholder: ""
                },
                domProps: { value: _vm.propsWebPart.CellPhone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.propsWebPart, "CellPhone", $event.target.value)
                  }
                }
              })
            ])
          ])
        ])
      ])
    ]),
    _c("hr")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [_c("th", [_vm._v("項目")]), _c("th", [_vm._v("聯絡資訊")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-left" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: "radio",
          name: "exampleRadios",
          id: "exampleRadios1",
          value: "option1"
        }
      }),
      _vm._v(" Line ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-left" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: "radio",
          name: "exampleRadios",
          id: "exampleRadios1",
          value: "option2"
        }
      }),
      _vm._v(" FB ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-left" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: "radio",
          name: "exampleRadios",
          id: "exampleRadios1",
          value: "option3"
        }
      }),
      _vm._v(" 電子信箱 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-left" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: "radio",
          name: "exampleRadios",
          id: "exampleRadios1",
          value: "option4"
        }
      }),
      _vm._v(" 簡訊 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }