<template>
  <span>
    <input ref="file" type="file" class="form-control" placeholder="" style="" hidden @change="selectFile"  >
<!--    multiple-->
<!--    accept=".png,.jpg,.jpeg,.gif"-->

    <a class="btn btn-primary" @click="fileUplaodControlClick"><i class="fa fa-upload"></i></a>

    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th>預覽</th>
        <th>檔名</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.files" :key="index">
        <td class="text-center">
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td width="120px">
          <a href="#"><img :src="toDownloadLink(item) + '?width=100'" @click.prevent="showImage(item)"/></a>
        </td>
        <td >
          <a href="#" @click.prevent="downloadFile(item)">{{item.FileName}}</a>
        </td>
      </tr>

      </tbody>
    </table>

    <MyLightBox ref="mylightBox"></MyLightBox>
  </span>
</template>

<script>

/**
 <FileUploadList emmit-back-param="undefined" :owner-system-id="this.systemId" file-use-memo="productImage"></FileUploadList>


 **/
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import MyLightBox from "@/pages/commonComponent/MyLightBox";
import apiServerUtil from "@/pages/common/apiServerUtil";
// import apiUBossCommonFile from "@/pages/commonUBoss/apiUBossCommonFile";
import apiUBossCommonFileSec from "@/pages/commonUBoss/apiUBossCommonFileSec";
export default {
  components: {MyLightBox},
  props: ["emmitBackParam","ownerSystemId","fileUseMemo"],
  data: function () {
    return {
      selectdFiles:undefined,
      files:[],
    };
  },
  methods: {
    fileUplaodControlClick(){
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    async selectFile(event){
      this.selectdFiles=event.target.files;
      await this.uploadFiles();
      event.target.value=null;

      // doc event.target.files 的內容
      // lastModified: 1612407992013
      // lastModifiedDate: Thu Feb 04 2021 11:06:32 GMT+0800 (台北標準時間) {}
      // name: "GPKlogo2.png"
      // size: 45641
      // type: "image/png"
      // webkitRelativePath: ""
    },
    async uploadFiles(){
      let uploadFiles=this.selectdFiles;

      let result=await apiUBossCommonFileSec.api_File_UpdateFile(uploadFiles,this.ownerSystemId,this.fileUseMemo);

      this.files=result;
      this.$emit('uploadSuccess',this.emmitBackParam,result)

    },
    async loadData() {
      let data=await apiUBossCommonFileSec.api_File_Query(this.ownerSystemId)

      this.files=data
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.files,"SystemId","OrderKey")
      this.DataItem_UpdateOrderKey()
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.files,"SystemId","OrderKey")
      this.DataItem_UpdateOrderKey()
    },
    DataItem_Remove(item){
      apiUBossCommonFileSec.api_File_RemoveFile(item.SystemId).then(()=>{
        this.loadData()
      })
    },
    async DataItem_UpdateOrderKey(){
      let infoOrderKey=this.files.map((value,index) => {  // eslint-disable-line
        return {
          SystemId:value.SystemId,
          OrderKey:value.OrderKey,
        };
      });

      await apiUBossCommonFileSec.api_File_UpdateOrderKey(this.ownerSystemId,infoOrderKey)
    },
    toDownloadLink(item){
      let link=apiServerUtil.toDownloadLink(item.Url);

      return link;
    },
    showImage(item){
      let url=apiServerUtil.toDownloadLink(item.Url);

      this.$refs.mylightBox.showSingle(url)
    },
    downloadFile(item){
      let link=apiServerUtil.toDownloadLink(item.Url);

      util.downloadFile(link)
    }
  },
  mounted() {


    this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  /*width: 1000px;*/
}
.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}
</style>
