<template>
  <div class="" >
    <b-modal ref="my-modal" size="s" hide-footer title="">
      <div class="d-block text-center">
        <h3>票據列印</h3>
      </div>

      <hr>

      <div class="form-group row">
        <label for="" class="col-sm-4">票據樣式:</label>
        <div class="col-sm-8">
          <select class="form-control" v-model="ticketDesignSystemId" style="width:200px">
            <option v-for="(item, index) in printTemplates" v-bind:key="index" :value="item.SystemId">{{ item.Name }}</option>
          </select>
        </div>
      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">列印</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>


//<DLGPrint ref="dlgEditor" v-on:returnOk="dlgEditorReturnOK" />
//
//
// editHtmlContent(item){
//   this.$refs.dlgEditor.callbackItem=item;
//   this.$refs.dlgEditor.htmlContent=item.HelpContent.Content;
//   this.$refs.dlgEditor.showModal();
// },
// dlgEditorReturnOK(item){
//   item.HelpContent.Content=this.$refs.dlgEditor.htmlContent;
// }

import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";

export default {
  props:["htmlContent","callbackItem"],
  data: function() {
    return {
      showThis:false,
      printTemplates:[],
      ticketDesignSystemId:"",
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      if (!this.ticketDesignSystemId){
        alert("請選擇票據樣式");
        return;
      }
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk',this.ticketDesignSystemId);
    },
    async loadData(){
      this.printTemplates=await apiUBossBk.api_TicketDesign_ListQuery(null);
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
