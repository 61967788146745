var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "1200px" } },
    [
      _c(
        "MyFormPanel",
        { ref: "form", attrs: { "data-source": _vm.formData } },
        [
          _c("div", { attrs: { slot: "testSlot" }, slot: "testSlot" }, [
            _c("button", { staticClass: "btn btn-primary" }, [_vm._v("送出")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }