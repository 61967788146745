var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { role: "button" },
          on: {
            click: function($event) {
              return _vm.openSetting()
            }
          }
        },
        [_vm._v("格式設定")]
      ),
      _c("MyCSSPickerDlg", {
        ref: "myCSSPickerDlg",
        attrs: { setting: _vm.setting },
        on: { returnOk: _vm.dialogReturnOK },
        model: {
          value: this.value,
          callback: function($$v) {
            _vm.$set(this, "value", $$v)
          },
          expression: "this.value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }