<template>
  <div>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css">

    <tags-input
        v-model="propsValue"
        element-id="xxxxxxxxyyyyyyy"
        :existing-tags="existingTags"
        :placeholder="'標籤'"
        :typeahead="true"
        :typeahead-style="'dropdown'"
        :typeahead-max-results="20"
        :typeahead-activation-threshold="1"
        :typeahead-show-on-focus="true"
        :allow-duplicates="false"
        :only-existing-tags="onlyExistingTags"
        :delete-on-backspace="true"
        :id-field="idField"
        :text-field="textField"
        discard-search-text="請選擇標籤"
    ></tags-input>

<!--    element-id="tags"-->
<!--    :typeahead-style="badges"-->
<!--    :typeahead-always-show="typeheadAlwaysShow"-->
<!--    :typeahead-hide-discard="typeaheadHideDiscard"-->

<!--    :limit="limit"-->

<!--    :only-existing-tags="onlyExistingTags"-->
<!--    :case-sensitive-tags="caseSensitiveTags"-->
<!--    :allow-duplicates="allowDuplicates"-->
<!--    :add-tags-on-comma="addTagsOnComma"-->
<!--    :add-tags-on-space="addTagsOnSpace"-->
<!--    :add-tags-on-blur="addTagsOnBlur"-->
<!--    :hide-input-on-limit="hideInputOnLimit"-->
<!--    @initialized="onInitialized"-->
<!--    @tag-added="onTagAdded"-->
<!--    @tag-removed="onTagRemoved"-->
<!--    @tags-updated="onTagsUpdated"-->
<!--    @change="onChange"-->

  </div>
</template>

<script>
/**
目前一個頁面上只能放一個，不知道什麼 (否則一使用本控制項，會hang 住整個畫面)

  ===========
 簡易
 <MyTagInput v-model="tags" :only-existing-tags="false"></MyTagInput>

 ===========
 嚴謹 (類似select)

 <MyTagInput v-model="tags" :only-existing-tags="true" :existingTags="options" ></MyTagInput>

 options:[
  {key: '1', value: 'Apple'},
 {key: '2', value: 'Banana'},
 ]

 ===========
 嚴謹 (指定id/text 欄位名稱)

 <MyTagInput v-model="tags" :only-existing-tags="true" :existingTags="options" :id-field="idField" :text-field="textField"></MyTagInput>

 options:[
  {key: '1', value: 'Apple'},
 {key: '2', value: 'Banana'},
 ]

 **/

export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    existingTags: {
      type: Array,
      default() {
        return []
      }
    },
    onlyExistingTags: {
      type: Boolean,
      default() {
        return false
      }
    },
    idField: {
      type: String,
      default() {
        return "key"
      }
    },
    textField: {
      type: String,
      default() {
        return "value"
      }
    },
  },
  data: function () {
    return {
      id: "",
      typeaheadStyle:"Dropdown",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
    };
  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>
.tags-input-root .typeahead-dropdown .row {
  display: flex;
  padding: .25rem 0;
}

.tags-input-root .typeahead-dropdown .row > .icon {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  font-size: 1.5rem;
  background: #ffffff;
  color: #000000;
  margin-right: 1rem;
}

.tags-input-root .typeahead-dropdown .row > .text {
  flex: 1;
}

.tags-input-root .typeahead-dropdown .row > .text > .name {
  font-weight: 600;
}

.tags-input-root .typeahead-dropdown .row > .text > .address {
  font-size: .8rem;
}
</style>
