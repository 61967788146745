var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-block btn-lg btn-primary",
          attrs: { role: "button", href: "#" }
        },
        [_vm._v("送出")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }