var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v(' 冰的數量是 "秒" ，請勿輸入小數 '),
    _c("br"),
    _vm._v(" 最大總量建議:600ml"),
    _c("br"),
    _vm._v(" 目前總量:" + _vm._s(_vm.getTotalMl()) + " ml (每秒冰以35ml計算) "),
    _c(
      "div",
      { staticClass: "row", staticStyle: { "max-width": "800px" } },
      _vm._l(this.propsValue, function(value, propertyName) {
        return _c(
          "div",
          {
            key: propertyName,
            staticClass: "col-3",
            staticStyle: { "max-width": "200px" }
          },
          [
            _c("label", [_vm._v(" " + _vm._s(propertyName))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.propsValue[propertyName],
                  expression: "propsValue[propertyName]",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-control ",
              class: _vm.getCssClass(_vm.propsValue[propertyName]),
              attrs: { type: "number", placeholder: "" },
              domProps: { value: _vm.propsValue[propertyName] },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.propsValue,
                    propertyName,
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            propertyName === "冰"
              ? _c("span", {}, [_vm._v(" 秒 ")])
              : _c("span")
          ]
        )
      }),
      0
    ),
    _c("hr"),
    _c(
      "a",
      {
        staticClass: "btn btn-primary",
        attrs: { role: "button" },
        on: { click: _vm.json_ConvertNewFmtToUBoss }
      },
      [_vm._v("由Json載入(3碼)")]
    ),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.jsonNew,
          expression: "jsonNew"
        }
      ],
      staticClass: "form-control",
      attrs: { id: "txtMemo", name: "txtMemo", rows: "5" },
      domProps: { value: _vm.jsonNew },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.jsonNew = $event.target.value
        }
      }
    }),
    _c("hr"),
    _c(
      "a",
      {
        staticClass: "btn btn-primary",
        attrs: { role: "button" },
        on: { click: _vm.json_ConvertUBossFmtToNew }
      },
      [_vm._v("除錯用-再轉回Json 3碼")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }