var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "ADMIndex" } }),
      _c("MyToolbarBase", [
        _c(
          "div",
          {
            staticClass: "btn-group mr-2",
            attrs: { role: "group", "aria-label": "First group" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.btnUpdate }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-arrow-left" }),
                _vm._v("回上一頁 ")
              ]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "fieldsPanel" },
        [
          _c("ValidationObserver", { ref: "form" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("類型")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.type,
                        expression: "formData.type"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("一般")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("VIP")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("客戶代碼")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.No,
                      expression: "formData.No"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.No },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "No", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("客戶名稱")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Name,
                      expression: "formData.Name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.Name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("統編")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.NumReg,
                      expression: "formData.NumReg"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.NumReg },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "NumReg", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("電話")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Tel,
                      expression: "formData.Tel"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "tel", placeholder: "" },
                  domProps: { value: _vm.formData.Tel },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Tel", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("地址")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Address,
                      expression: "formData.Address"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.Address },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Address", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("Email")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Email,
                      expression: "formData.Email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "email", placeholder: "" },
                  domProps: { value: _vm.formData.Email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Email", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("狀態")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.Status,
                        expression: "formData.Status"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "Status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("生效中")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("停用")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("備註")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.Memo,
                      expression: "formData.Memo"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "txtMemo", name: "txtMemo", rows: "3" },
                  domProps: { value: _vm.formData.Memo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "Memo", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("hr"),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _c("div", [_vm._v("付款連結")]),
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", { staticClass: "thead-light" }, [
                  _c("tr", [
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("付款備註")
                    ]),
                    _c("th", [_vm._v("金額")]),
                    _c("th", [_vm._v("狀態")]),
                    _c("th", [_vm._v("付款日期/序號")])
                  ])
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(" 簡訊儲值 (A客戶) ")]),
                    _c("td", [_vm._v(" 500 ")]),
                    _c("td", [_vm._v("已付款")]),
                    _c("td", [_vm._v("2023-03-01 xxx")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" udm (A客戶) ")]),
                    _c("td", [_vm._v(" 3500 ")]),
                    _c("td", [_vm._v("未付款")]),
                    _c("td", [_vm._v("xxx")])
                  ])
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", {}, [
                    _c("form", { staticClass: "form-inline" }, [
                      _c("div", { staticClass: "form-group mb-2" }, [
                        _c("label", { attrs: { for: "staticEmail2" } }, [
                          _vm._v("付款備註")
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", id: "staticEmail2", value: "" }
                        })
                      ]),
                      _c("div", { staticClass: "form-group mx-sm-3 mb-2" }, [
                        _c("label", { attrs: { for: "inputPassword2" } }, [
                          _vm._v("金額")
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "inputPassword2",
                            placeholder: ""
                          }
                        })
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mb-2",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("產生付款連結")]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" Biz "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("狀態")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("MyButtonEnableDisable2", {
                      model: {
                        value: _vm.formData.ServiceOnePage.IsEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.ServiceOnePage, "IsEnable", $$v)
                        },
                        expression: "formData.ServiceOnePage.IsEnable"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("到期日")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.ServiceOnePage.ExpireDt,
                        expression: "formData.ServiceOnePage.ExpireDt"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "date",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    },
                    domProps: { value: _vm.formData.ServiceOnePage.ExpireDt },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData.ServiceOnePage,
                          "ExpireDt",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("網站標題")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.ServiceOnePage.FlagTitle,
                        expression: "formData.ServiceOnePage.FlagTitle"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    },
                    domProps: { value: _vm.formData.ServiceOnePage.FlagTitle },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData.ServiceOnePage,
                          "FlagTitle",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("移除Footer")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("MyButtonEnableDisable2", {
                      model: {
                        value: _vm.formData.ServiceOnePage.FlagRemoveFooter,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData.ServiceOnePage,
                            "FlagRemoveFooter",
                            $$v
                          )
                        },
                        expression: "formData.ServiceOnePage.FlagRemoveFooter"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("儲存")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" Form "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("狀態")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("MyButtonEnableDisable2", {
                      model: {
                        value: _vm.formData.ServiceForm.IsEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.ServiceForm, "IsEnable", $$v)
                        },
                        expression: "formData.ServiceForm.IsEnable"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("到期日")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.ServiceForm.ExpireDt,
                        expression: "formData.ServiceForm.ExpireDt"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "date",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    },
                    domProps: { value: _vm.formData.ServiceForm.ExpireDt },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData.ServiceForm,
                          "ExpireDt",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("儲存")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" UDM "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("狀態")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("MyButtonEnableDisable2", {
                      model: {
                        value: _vm.formData.ServiceUDM.IsEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.ServiceUDM, "IsEnable", $$v)
                        },
                        expression: "formData.ServiceUDM.IsEnable"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("到期日")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.ServiceUDM.ExpireDt,
                        expression: "formData.ServiceUDM.ExpireDt"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "date",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    },
                    domProps: { value: _vm.formData.ServiceUDM.ExpireDt },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData.ServiceUDM,
                          "ExpireDt",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("儲存")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" Ticket "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("狀態")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("MyButtonEnableDisable2", {
                      model: {
                        value: _vm.formData.ServiceTicket.IsEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.ServiceTicket, "IsEnable", $$v)
                        },
                        expression: "formData.ServiceTicket.IsEnable"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v("到期日")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.ServiceTicket.ExpireDt,
                        expression: "formData.ServiceTicket.ExpireDt"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "date",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    },
                    domProps: { value: _vm.formData.ServiceTicket.ExpireDt },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData.ServiceTicket,
                          "ExpireDt",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("儲存")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" 簡訊 "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v(
                    "目前剩餘數量: " +
                      _vm._s(_vm.formData.PaySMS.CurrentQty) +
                      " 封"
                  )
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    }
                  }),
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("增減數量")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "shadow p-3 mb-5 bg-white rounded" }, [
              _vm._v(" Email "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                  _vm._v(
                    "目前剩餘數量: " +
                      _vm._s(_vm.formData.PayEmail.CurrentQty) +
                      " 封"
                  )
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: "name",
                      name: "name",
                      placeholder: ""
                    }
                  }),
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("增減數量")
                  ])
                ])
              ])
            ]),
            _c("hr")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }