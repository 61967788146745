<template>
  <div>
    <b-form-group label="" >
      <b-form-radio-group
          :id="getContrlName()"
          v-model="value"
          :options="options"
          :button-variant="buttonVariant"
          :size="size"
          :name="getContrlName()"
          buttons
      ></b-form-radio-group>
    </b-form-group>
<!--    :aria-describedby="ariaDescribedby"-->
  </div>
</template>

<script>/**
 <MyButtonRadio v-model="selectValue1" :options="convertToButtonRadionOption(item.Options)" size="md"  ></MyButtonRadio>

 convertToButtonRadionOption(systemOptions){
      let options=[];

      systemOptions.forEach((item)=>{

        let option={
          text:item.Text,
          value:item.SystemId,
        }

        options.push(option);
      })

      return options;
    }
 **/
import Util from "@/pages/common/util";


export default {
  // name: "MyColorPicker",
  props: {
    value: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "lg"
    },
    buttonVariant: {
      type: String,
      default: "outline-primary"
    },
    options: {
      type: Object,
      required: true
    },
  },
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
      selected: 'radio1',
      optionsDemo: [
        { text: 'Radio 1', value: 'radio1' },
        { text: 'Radio 3', value: 'radio2' },
        { text: 'Radio 3 (disabled)', value: 'radio3', disabled: true },
        { text: 'Radio 4', value: 'radio4' }
      ]
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    disable(){
      this.value=false;
    },
    enable(){
      this.value=true;
    },
    getContrlName(){
      return Util.uuid();
    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
