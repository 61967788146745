<template>
  <div>
    <MyButtonBoolean v-model="propsValue" true-text="顯示" false-text="隱藏" ></MyButtonBoolean>
  </div>
</template>

<script>/**
 <MyButtonIsVisible v-model="item.IsVisible"></MyButtonIsVisible>
 **/
import MyButtonBoolean from "@/pages/commonComponent/MyButtonBoolean.vue";


export default {
  components: {MyButtonBoolean},
  // name: "MyColorPicker",
  props: ["value"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {

  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
