var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-block btn-lg btn-primary",
          attrs: { role: "button" },
          on: { click: _vm.submit }
        },
        [_vm._v("送出")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }