var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-2" }, [_vm._v("標題")]),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.field.Title,
              expression: "field.Title"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.field.Title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.field, "Title", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-2" }, [_vm._v("預設文字")]),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.field.DefaultValue,
              expression: "field.DefaultValue"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.field.DefaultValue },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.field, "DefaultValue", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-2" }, [_vm._v("必填")]),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("label", { staticClass: "checkbox-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.field.ValidateRule,
                expression: "field.ValidateRule"
              }
            ],
            attrs: { type: "checkbox", value: "required" },
            domProps: {
              checked: Array.isArray(_vm.field.ValidateRule)
                ? _vm._i(_vm.field.ValidateRule, "required") > -1
                : _vm.field.ValidateRule
            },
            on: {
              change: function($event) {
                var $$a = _vm.field.ValidateRule,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "required",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.field, "ValidateRule", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.field,
                        "ValidateRule",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.field, "ValidateRule", $$c)
                }
              }
            }
          }),
          _vm._v("必填")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }