class MyVueEasyGridExpandForm {
    constructor() {
        this._Items = [];
    }

    get Items() {
        return this._Items;
    }

    addItem(value,label) {
        let item = new MyVueEasyGridExpandFormItem();
        item.value=value;
        item.label=label;

        this._Items.push(item);
        return this;
    }
}
class MyVueEasyGridExpandFormItem {
    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get label() {
        return this._label;
    }

    set label(value) {
        this._label = value;
    }
}


class MyVueEasyGridFilters {
    constructor() {
        this._Filters = [];
    }

    get Filters() {
        return this._Filters;
    }

    addFilter(value,label) {
        let filter = new MyVueEasyGridFilterItem();
        filter.value=value;
        filter.label=label;

        this._Filters.push(filter);
        return this;
    }
}
class MyVueEasyGridFilterItem {
    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get label() {
        return this._label;
    }

    set label(value) {
        this._label = value;
    }

    get selected() {
        return this._selected;
    }

    set selected(value) {
        this._selected = value;
    }
}

//---------------------------------------
class MyVueEasyGridActionBar {
    constructor() {
        this._Buttons = [];
    }

    get Buttons() {
        return this._Buttons;
    }

    addButton(className,text,onClickFun) {
        let button = new MyVueEasyGridActionButton();
        button.className=className;
        button.text=text;
        button.onClickFun=onClickFun;

        this._Buttons.push(button);
        return this;
    }
}
class MyVueEasyGridActionButton {

    get className() {
        return this._className;
    }

    set className(value) {
        this._className = value;
    }

    get onClickFun() {
        return this._onClickFun;
    }

    set onClickFun(value) {
        this._onClickFun = value;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }
}

class MyVueEasyGridColumn {
    field="";
    key="";
    type= "";
    title= "";
    width= "";
    align= "left";
    fixed= "";
    sortBy="";
    filter=undefined;

    hideInMobile=false;

    renderBodyCell(){

    }
}

class MyVueEasyGridUtility{
    GetFun_RenderHyperLink(className,textFun,linkFun){
        return ({ row, column, rowIndex }, h) => { // eslint-disable-line
                // const link = "aa";
                const link = linkFun(row);
                const text = textFun(row);
                return (
                    <span>
                                    <a
                                        class={className}
                                        href={link}
                                        style="color:#1890ff;cursor:pointer;"
                                    >
                                        {text}
                                    </a>
                                </span>
                );
            }
    }
    GetFun_RenderActionButton(buttons){
        return ({ row, column, rowIndex }, h) => { // eslint-disable-line
            return (
                <span>
                  {buttons.map((value, index) => { // eslint-disable-line
                      return           <button
                          class={value.className}
                          on-click={() => value.onClickFun(row)}
                      >
                          {value.text}
                      </button>
                    })
                  }
                </span>
            );
        }
    }
    GetFun_RenderExpandForm(expandForm){
        return ({ row, column, rowIndex }, h) => { // eslint-disable-line
            return (
                <div>
                    <table class="table table-hover table-striped">
                        <tbody>
                        {expandForm.map((value, index) => { // eslint-disable-line
                            return    <tr>
                                <td class="text-left bg-info">
                                    {value.label}
                                </td>
                                <td class="text-left">
                                    {row[value.value]}
                                </td>
                            </tr>
                        })
                        }
                       </tbody>
                    </table>

                </div>
            );
        }
    }
}

// class MyVueEasyGridConfig {
//     get expandOption_RenderFun() {
//         return this._expandOption_RenderFun;
//     }
//
//     set expandOption_RenderFun(value) {
//         this._expandOption_RenderFun = value;
//     }
// }

class MyVueEasyGrid {
    constructor() {
        // this._EasyGridRef=gridRef;
        this._Columns = [];
        // this.config = new GridConfig();
    }

    get Columns() {
        return this._Columns;
    }

    addColumnSeq() {
        let column = new MyVueEasyGridColumn();
        column.key="seq";
        column.title="#";
        column.width=7;
        column.fixed="left";
        column.align="center";
        column.sortBy=undefined;

        column.renderBodyCell=({ row, column, rowIndex }, h) => { // eslint-disable-line
            return (
                <span class="text-bold" style="color:#1890ff;">
                                    {++rowIndex}
                                </span>
            );
        }

        this._Columns.push(column);
        return this;
    }

    addColumnExpand() {
        let column = new MyVueEasyGridColumn();
        column.key="expand";
        column.type="expand";
        column.width=7;
        column.fixed="left";
        column.sortBy=undefined;

        this._Columns.push(column);
        return this;
    }
    addColumnCheckBox() {
        let column = new MyVueEasyGridColumn();
        column.key="checkbox";
        column.type="checkbox";
        column.width=7;
        column.fixed="left";
        column.sortBy=undefined;

        this._Columns.push(column);
        return this;
    }
    addColumnRadio() {
        let column = new MyVueEasyGridColumn();
        column.key="radio";
        column.type="radio";
        column.width=7;
        column.fixed="left";
        column.sortBy=undefined;

        this._Columns.push(column);
        return this;
    }


    addColumn(title, fieldName,hideInMobile=false) {
        const column = new MyVueEasyGridColumn();
        column.title=title;
        column.key=fieldName;
        column.field=fieldName;
        column.hideInMobile=hideInMobile;

        this._Columns.push(column);
        return this;
    }

    addColumnRender(title, fieldName,fun) {    // eslint-disable-line
        const column = new MyVueEasyGridColumn();
        column.title=title;
        column.key=fieldName;
        column.field=fieldName;

        column.renderBodyCell=fun;

        this._Columns.push(column);
        return this;
    }

    addColumnFilter(title, fieldName,filters,funConfim,funRest) {    // eslint-disable-line
        const column = new MyVueEasyGridColumn();
        column.title=title;
        column.key=fieldName;
        column.field=fieldName;

        column.filter={
            filterList: filters.Filters,
            // filter confirm hook
            filterConfirm: (filterList) => {
                const labels = filterList
                    .filter((x) => x.selected)
                    .map((x) => x.label);
                // searchStatus=labels;
                //this.searchByDateField(labels);
                funConfim(labels);
            },
            // filter reset hook
            filterReset: (filterList) => {  // eslint-disable-line
                funRest();
                // searchStatus=[];
                // this.searchByDateField([]);
            },
        };

        this._Columns.push(column);
        return this;
    }

    setConfig(config) {
        this.config = config;
        return this;
    }

    toJSON() {
        return JSON.stringify({ columns: this._Columns, config: this.config });
    }
}

export  {MyVueEasyGrid,MyVueEasyGridUtility,MyVueEasyGridActionBar,MyVueEasyGridFilters,MyVueEasyGridExpandForm}

//export multi class


// const grid = new MyVueEasyGrid().addColumn("aa").addColumn("bb").setConfig(new GridConfig("xyz"));
//
// const json = grid.toJSON();
//
// console.log(json);
