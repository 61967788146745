var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: { "hide-footer": "", title: "設定", size: "xl" }
        },
        [
          _c("div", { staticClass: "d-block text-center" }),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("網頁內容")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("MyCkEditor", {
                  ref: "myCkEditor",
                  attrs: {
                    height: "400",
                    width: "800",
                    "file-upload-list-lite_is-show": false
                  },
                  model: {
                    value: _vm.propsValue,
                    callback: function($$v) {
                      _vm.propsValue = $$v
                    },
                    expression: "propsValue"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.toggleModal()
                }
              }
            },
            [_vm._v("儲存")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }