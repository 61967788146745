

<template>
  <div>
  <!-- 頂部導覽列 -->
  <nav class="navbar top-toolbar" v-if="showLogo">
    <div class="container" >
      <slot name="Logo">

      </slot>
<!--      <a class="navbar-brand" href="#">標誌</a>-->
    </div>
  </nav>

  <!-- 麵包屑 -->
  <nav aria-label="breadcrumb">
    <slot name="Breadcrumb">

    </slot>
<!--    <ol class="breadcrumb">-->
<!--      <li class="breadcrumb-item"><a href="#">首頁</a></li>-->
<!--      <li class="breadcrumb-item"><a href="#">分類</a></li>-->
<!--      <li class="breadcrumb-item active" aria-current="page">目前頁面</li>-->
<!--    </ol>-->
  </nav>

  <!-- 資訊部分 -->
  <div class="container info-section">
    <slot name="HeaderBar">

    </slot>

    <slot name="Body">

    </slot>
    <slot name="BodyBar">

    </slot>

    <!--    <h1>歡迎訪問我們的手機網頁</h1>-->
<!--    <p>這是一個基於Bootstrap 4的示例，包括頂部導覽、麵包屑、資訊部分和底部工具列。</p>-->
  </div>

  <!-- 底部工具列 -->
  <div class="horizontal-toolbar navbar fixed-bottom bottom-toolbar" v-if="showBottomBar">
    <slot name="BottomBar">

    </slot>
<!--    <a href="#">首頁</a>-->
<!--    <a href="#">關於</a>-->
<!--    <a href="#">聯絡我們</a>-->
  </div>




</div>



</template>


<script>

/**
 <MobileUILayout>
 <slot name="Logo">

 </slot>
 <slot name="Breadcrumb">

 </slot>
 <slot name="HeaderBar">

 </slot>
 <slot name="Body">

 </slot>
 <slot name="BodyBar">

 </slot>
 <slot name="BottomBar">

 </slot>
 </MobileUILayout>
 */


export default {
  name: "MobileUILayout",
  props: ["showLogo","showBottomBar"],
  data: function () {
    return {
      // showLogo: true,
      // showBottomBar: true,
    };
  },
}
</script>


<style scoped>
/* 設定頂部和底部工具列的背景顏色和文字顏色 */
.top-toolbar, .bottom-toolbar {
  background-color: #333;
  color: #fff;
}

/* 調整頂部和底部工具列的內邊距 */
.top-toolbar, .bottom-toolbar {
  padding: 10px;
}

/* 設定麵包屑的樣式 */
.breadcrumb {
  background-color: #f8f9fa;
  padding: 10px;
  margin: 0;
}

/* 設定資訊部分的樣式 */
.info-section {
  padding: 20px;
}

/* 設定橫向工具列的樣式 */
.horizontal-toolbar {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.horizontal-toolbar a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px; /* 設定項目間的間距 */
}
</style>
