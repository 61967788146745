var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-xl-10 col-lg-12 col-md-9" }, [
      _c("div", { staticClass: "card o-hidden border-0 shadow-lg my-5" }, [
        _c("div", { staticClass: "card-body p-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3" }),
            _c("div", { staticClass: "col-lg-6" }, [
              _c(
                "div",
                { staticClass: "p-5" },
                [
                  _c("div", { staticClass: "text-center" }, [
                    _c("h1", { staticClass: "h4 text-gray-900 mb-4" }, [
                      _vm._v(_vm._s(_vm.Title))
                    ])
                  ]),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "user" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("validation-provider", {
                            attrs: { rules: "required", name: "帳號/手機號碼" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.formData.LoginId,
                                          expression: "formData.LoginId"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-user",
                                      attrs: {
                                        name: "",
                                        placeholder: "帳號/手機號碼",
                                        type: "text"
                                      },
                                      domProps: { value: _vm.formData.LoginId },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.formData,
                                            "LoginId",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", { staticClass: "error_msg" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("validation-provider", {
                            attrs: { rules: "required", name: "密碼" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.formData.Pwd,
                                          expression: "formData.Pwd"
                                        }
                                      ],
                                      staticClass:
                                        "form-control form-control-user",
                                      attrs: {
                                        name: "",
                                        placeholder: "密碼",
                                        type: "password"
                                      },
                                      domProps: { value: _vm.formData.Pwd },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.formData,
                                            "Pwd",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", { staticClass: "error_msg" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-primary btn-user btn-block abutton",
                          attrs: { type: "button" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" 登入 ")]
                      ),
                      _c("hr")
                    ])
                  ]),
                  _c("hr")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-lg-3" })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }