var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: { "hide-footer": "", title: "設定", size: "lg" }
        },
        [
          _c("div", { staticClass: "d-block text-center" }),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("網站標題")
            ]),
            _c("div", { staticClass: "col-sm-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsCommon.SiteTitle,
                    expression: "propsCommon.SiteTitle"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "" },
                domProps: { value: _vm.propsCommon.SiteTitle },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.propsCommon, "SiteTitle", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("網站圖示(.ico)")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-8" },
              [
                _c("FileUploadButton", {
                  attrs: {
                    "emmit-back-param": undefined,
                    "upload-type": _vm.$u.UBossUploadType_OnePage
                  },
                  on: { uploadSuccess: _vm.uploadSuccessIcon }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("網站Logo")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("FileUploadButton", {
                  attrs: {
                    "emmit-back-param": undefined,
                    "upload-type": _vm.$u.UBossUploadType_OnePage
                  },
                  on: { uploadSuccess: _vm.uploadSuccessLogo }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("對齊")
            ]),
            _c("div", { staticClass: "col-sm-10" }, [
              _vm._v(" Logo對齊 "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.propsHeader.LogoAlign,
                      expression: "propsHeader.LogoAlign"
                    }
                  ],
                  staticClass: "form-control aselect",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.propsHeader,
                        "LogoAlign",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "center" } }, [
                    _vm._v("置中")
                  ]),
                  _c("option", { attrs: { value: "left" } }, [_vm._v("靠左")])
                ]
              ),
              _vm._v(" 選單對齊 "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.propsHeader.MenuAlign,
                      expression: "propsHeader.MenuAlign"
                    }
                  ],
                  staticClass: "form-control aselect",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.propsHeader,
                        "MenuAlign",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "left" } }, [_vm._v("置左")]),
                  _c("option", { attrs: { value: "right" } }, [_vm._v("靠右")])
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("文字顏色")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("MyColorPicker", {
                  model: {
                    value: _vm.propsHeader.FontColor,
                    callback: function($$v) {
                      _vm.$set(_vm.propsHeader, "FontColor", $$v)
                    },
                    expression: "propsHeader.FontColor"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("標題選單顏色")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("MyColorPicker", {
                  model: {
                    value: _vm.propsHeader.BackgroundColor,
                    callback: function($$v) {
                      _vm.$set(_vm.propsHeader, "BackgroundColor", $$v)
                    },
                    expression: "propsHeader.BackgroundColor"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("背景設定")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [_c("MyCSSBackground", { attrs: { common: _vm.propsCommon } })],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("連結")
            ]),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("MyListUrls", {
                  model: {
                    value: _vm.propsHeader.MenuLinks,
                    callback: function($$v) {
                      _vm.$set(_vm.propsHeader, "MenuLinks", $$v)
                    },
                    expression: "propsHeader.MenuLinks"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.toggleModal()
                }
              }
            },
            [_vm._v("儲存")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }