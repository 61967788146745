<template>
  <div>
    <b-form-group label="" >
      <b-form-radio-group
          :id="getContrlName()"
          v-model="value"
          :options="optionsConvered()"
          :button-variant="buttonVariant"
          :size="size"
          :name="getContrlName()"
          buttons
      ></b-form-radio-group>
    </b-form-group>
<!--    :aria-describedby="ariaDescribedby"-->
  </div>
</template>

<script>/**
 <MyCodeListButtonRadio v-model="selectValue" :options="$u.UCode_CLInvoiceType" size="md"></MyCodeListButtonRadio>

 允許空的值
 <MyCodeListButtonRadio v-model="formData.CLPayType" :options="$u.UCode_CLPayType" size="md" :add-empty-select-item="true" empty-item-text="未知" empty-item-value=""></MyCodeListButtonRadio>
 **/
import Util from "@/pages/common/util";


export default {
  // name: "MyColorPicker",
  props: {
    value: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "lg"
    },
    buttonVariant: {
      type: String,
      default: "outline-primary"
    },
    options: {
      type: Object,
      required: true
    },
    addEmptySelectItem:{
      type:Boolean,
      default:false,
    },
    EmptyItemText:{
      type:String,
      default:"",
    },
    EmptyItemValue:{
      type:String,
      default:"",
    }
  },
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
      selected: 'radio1',
      optionsDemo:{
        個人電子發票:"person",
        公司戶電子發票:"company",
        捐贈發票:"donation",
        寄送實體發票:"paper",
      },
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    disable(){
      this.value=false;
    },
    enable(){
      this.value=true;
    },
    getContrlName(){
      return Util.uuid();
    },
    optionsConvered(){
      let optionsConvered=[];

      optionsConvered=Util.ConvertCodeListToObject_textvalue(this.options);

      if (this.addEmptySelectItem){
        let option={
          text:this.EmptyItemText,
          value:this.EmptyItemValue,
        }

        optionsConvered.push(option);
      }

      return optionsConvered;
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
