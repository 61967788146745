var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thisDiv" }, [
    !_vm.IsEnable
      ? _c("div", [
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              on: { click: _vm.DataItemMaster_Enable }
            },
            [
              _c("i", { staticClass: "fas fa-plus-circle" }),
              _vm._v("開啟商品規格")
            ]
          )
        ])
      : _vm._e(),
    _vm.IsEnable
      ? _c(
          "div",
          [
            _vm.formDataDemo.OptionSets.Items.length === 0
              ? _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.DataItemMaster_Enable }
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus-circle" }),
                      _vm._v("開啟商品規格")
                    ]
                  )
                ])
              : _vm._e(),
            _vm._l(_vm.formDataDemo.OptionSets.Items, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticStyle: { position: "relative", left: "80%", top: "0" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            return _vm.DataItemMaster_Remove(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.DataItemMaster_MoveUp(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-angle-up" })]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.DataItemMaster_MoveDown(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-angle-down" })]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#fafafa",
                      "margin-bottom": "10px"
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 thisLabel",
                          attrs: { for: "" }
                        },
                        [_vm._v("規格名稱")]
                      ),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.Text,
                              expression: "item.Text"
                            }
                          ],
                          staticClass: "form-control thisControl",
                          attrs: { type: "text" },
                          domProps: { value: item.Text },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "Text", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._l(item.Options, function(option, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "form-group row" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 thisLabel",
                              attrs: { for: "" }
                            },
                            [_vm._v("選項")]
                          ),
                          _c("div", { staticClass: "col-sm-10 form-inline" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: option.Text,
                                  expression: "option.Text"
                                }
                              ],
                              staticClass: "form-control thisControl",
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: option.Text },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(option, "Text", $event.target.value)
                                }
                              }
                            }),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function($event) {
                                    return _vm.DataItemDetail_Remove(
                                      item,
                                      option
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "far fa-trash-alt" })]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.DataItemDetail_MoveUp(
                                      item.Options,
                                      option
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-angle-up" })]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.DataItemDetail_MoveDown(
                                      item.Options,
                                      option
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-angle-down" })]
                            )
                          ])
                        ]
                      )
                    }),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", {
                        staticClass: "col-sm-2 thisLabel",
                        attrs: { for: "" }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.DataItemDetail_AddLast(item.Options)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-plus-circle" }),
                          _vm._v("增加選項")
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c("hr")
              ])
            }),
            _c("hr"),
            _vm.formDataDemo.OptionSets.Items.length > 0 &&
            _vm.formDataDemo.OptionSets.Items.length < _vm.maxOptionCount
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.DataItemMaster_AddLast }
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus-circle" }),
                    _vm._v("增加規格")
                  ]
                )
              : _vm._e(),
            _vm.formDataDemo.OptionSets.Items.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "span",
                      { staticClass: "form-inline" },
                      [
                        _c("h2", [_vm._v("規格表")]),
                        _c("MyButtonEnableDisable", {
                          model: {
                            value: _vm.formDataDemo.IsEnableOptionTable,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formDataDemo,
                                "IsEnableOptionTable",
                                $$v
                              )
                            },
                            expression: "formDataDemo.IsEnableOptionTable"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.settingApply_Price,
                            expression: "settingApply_Price",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "價格" },
                        domProps: { value: _vm.settingApply_Price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.settingApply_Price = _vm._n($event.target.value)
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.settingApply_Quantity,
                            expression: "settingApply_Quantity",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "庫存量" },
                        domProps: { value: _vm.settingApply_Quantity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.settingApply_Quantity = _vm._n(
                              $event.target.value
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { role: "button" },
                          on: { click: _vm.ApplyRefresh }
                        },
                        [_vm._v("全部套用")]
                      )
                    ]),
                    _vm.formDataDemo.IsEnableOptionTable
                      ? _c(
                          "table",
                          {
                            staticClass: "table table-hover",
                            staticStyle: { width: "500px" }
                          },
                          [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c("tr", [
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(_vm.getOptionName(0)))
                                ]),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(_vm.getOptionName(1)))
                                ]),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v("價格")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.calculatedOptionTable(0), function(
                                item,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c("td", [_vm._v(_vm._s(item.MasterText))]),
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(" " + _vm._s(item.DetailText) + " ")
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.Price,
                                          expression: "item.Price"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", placeholder: "" },
                                      domProps: { value: item.Price },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "Price",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("h1", [_vm._v("商品頁預覽")]),
                    _c("ProductOptionSelector", {
                      attrs: { "product-option": _vm.formDataDemo }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }