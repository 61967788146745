var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { "hide-footer": "", title: "複製" } },
        [
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "button",
                {
                  ref: "copyButton",
                  staticClass: "btn btn-outline-secondary",
                  attrs: {
                    type: "button",
                    "data-clipboard-target": "#copyInput"
                  }
                },
                [_vm._v("複製")]
              )
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.propsValue,
                  expression: "propsValue"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "",
                "aria-label": "",
                id: "copyInput"
              },
              domProps: { value: _vm.propsValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.propsValue = $event.target.value
                }
              }
            })
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }