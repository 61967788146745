var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: this.$onePageUtil.DebugOnePage
        ? "border-color: red;border-style: solid"
        : ""
    },
    [
      this.$onePageUtil.DebugOnePage
        ? _c("div", [
            _vm._v(" Id:" + _vm._s(_vm.webPart.Id)),
            _c("br"),
            _vm._v(" Title:" + _vm._s(_vm.webPart.Title)),
            _c("br"),
            _vm._v(" WebPartType:" + _vm._s(_vm.webPart.WebPartType)),
            _c("br")
          ])
        : _vm._e(),
      this.webPart.WebPartType === this.$onePageUtil.WebPartType_Html
        ? _c(
            "div",
            [_c("WebPartHtmlRunner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Image
        ? _c(
            "div",
            [_c("WebPartImageRunner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_SliderImage
        ? _c(
            "div",
            [
              _c("WebPartSlickerImageRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_SliderImage2
        ? _c(
            "div",
            [
              _c("WebPartSlickerImage2Runner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_SliderImage3
        ? _c(
            "div",
            [
              _c("WebPartSlickerImage3Runner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Video
        ? _c(
            "div",
            [
              _c("WebPartVideo", {
                attrs: {
                  "design-time": _vm.designTime,
                  "web-part": _vm.webPart
                }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormLink
        ? _c(
            "div",
            [
              _c("WebPartFormLinkRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Form
        ? _c(
            "div",
            [_c("WebPartFormRunner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormSubmit
        ? _c(
            "div",
            [
              _c("WebPartFormSubmitRunner", {
                attrs: {
                  "web-part": _vm.webPart,
                  "rel-form-system-id": _vm.pageId
                }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_FormShop
        ? _c(
            "div",
            [
              _c("WebPartFormShopRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_ListData
        ? _c(
            "div",
            [
              _c("WebPartListDataRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_FormShopWithProduct
        ? _c(
            "div",
            [
              _c("WebPartFormShopWithProductRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_FormShopWithProductCalendar
        ? _c(
            "div",
            [
              _c("WebPartFormShopWithProductCalendarRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_HR
        ? _c(
            "div",
            [_c("WebPartHRRunner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_PricePlan
        ? _c(
            "div",
            [
              _c("WebPartPricePlanRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_HotProducts
        ? _c(
            "div",
            [
              _c("WebPartHotProductsRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType ===
          this.$onePageUtil.WebPartType_CallToAction
        ? _c(
            "div",
            [
              _c("WebPartCallToActionRunner", {
                attrs: { "web-part": _vm.webPart }
              })
            ],
            1
          )
        : this.webPart.WebPartType === this.$onePageUtil.WebPartType_Card
        ? _c(
            "div",
            [_c("WebPartCardRunner", { attrs: { "web-part": _vm.webPart } })],
            1
          )
        : _c("div")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }