<template>
<!--  <div class="container-fluid">-->
<!--      search-->
<!--      <div class="myFormContainer">-->

<!--      </div>-->
<!--  </div>-->
  <div id="accordion" class="mySearchPanel">
    <div class="card col-md-12">
      <h3 class="mb-0">
        <div class="search-container">
          <i class="fa fa-search"></i>
          <input class="form-control mySearchPanel_txtKeyword"  :placeholder="keywordLabel" type="text" value="" v-model="keywordValue" v-on:keyup.enter="btnSearch">
          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
            進階搜尋
          </button>
        </div>
      </h3>
      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body queryPanel_body">
          <div class="form-group row">

            <div v-for="(item,index) in fieldDefines" :key="index" class="col-sm-3">
              <div class="col-sm-12" >
                <SearchFormField :model-defined="item" :model-field.sync="item.InputValue" :model-field_-s.sync="item.InputValue_S" :model-field_-e.sync="item.InputValue_E"  :role="role" v-if="item.Visible && havePermission(item)"></SearchFormField>
              </div>
            </div>

          </div>
        </div>
        <div class="card-footer text-muted">
          <input id="btnQuery" type="button" value="搜尋" class="btn btn-primary queryPanel_btnQuery" @click="btnSearch"/>
          <input id="btnReset" type="button" value="重設" class="btn btn-secondary queryPanel_btnReset" @click="collapse"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchFormField from "./SearchFormField";

export default {
  components: {SearchFormField},
  props: ["fieldDefines","keywordLabel", "keywordFields"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      role:"",
      roles:"",
      fieldDefinesDemo:[
        {
          // Group:"A",
          Title:"客戶名稱",
          FieldName:"",
          InputType:"text",
          Values:"",
          MustInput:"",
          Visible:"Y",
          List:"",
          Search:"",
          InputValue:"",
          InputValue_S:"",
          InputValue_E:"",
          Permission:"",
          SearchType:"LIKE",
          SearchInputType:"",
        },
      ],
      keywordValue:"",
    };
  },
  methods: {
    collapse(){
      $("#collapseOne").collapse("hide");// eslint-disable-line
    },
    getQueryObject(){
      // let queryObjectDemo={
      //   "KeywordFields": [],
      //   "KeywordValue": "",
      //   "OrderByField": "",
      //   "OrderByDesc": false,
      //   "QueryFields": [
      //       {
      //           "FieldName": "客戶名稱",
      //           "QueryType": "LIKE",
      //           "QueryValue": "CT",
      //           "QueryValueS": "",
      //           "QueryValueE": ""
      //       }
      //   ]
      // }

      let queryObject={
        "keywordLabel":"",
        "KeywordFields": [],
        "KeywordValue": "",
        "OrderByField": "",
        "OrderByDesc": false,
        "QueryFields": [
        ],
        "Tag01":"",
        "Tag02":"",
        "Tag03":"",
        "Tag04":"",
        "Tag05":"",
      }

      //keyword
      queryObject.keywordLabel=this.keywordLabel;
      queryObject.KeywordFields=this.keywordFields;
      queryObject.KeywordValue=this.keywordValue;

      //進階搜尋
      for (let fieldDefine of this.fieldDefines) {
        // if (fieldDefine.Name==="合約金額_原合約幣別"){
        // }

        if (this.haveInputValue(fieldDefine)){
          this.preProcessValue(fieldDefine)

          let queryField= {
            "Title": fieldDefine.Title,
            "FieldName": fieldDefine.FieldName,
            "QueryType": fieldDefine.SearchType,
            "QueryValue": fieldDefine.InputValue,
            "QueryValueS": fieldDefine.InputValue_S,
            "QueryValueE": fieldDefine.InputValue_E,
          }

          if (fieldDefine.SearchInputType==="selectWithInput" ) {
            if (queryField.QueryValue){
              queryField.QueryValue=queryField.QueryValue.code;
            }
          }

          queryObject.QueryFields.push(queryField)
        }
      }

      return queryObject
    },
    preProcessValue(fieldDefine){
      if (fieldDefine.SearchInputType==="numberRange" ) {
        if (fieldDefine.InputValue_S===0){
          fieldDefine.InputValue_S=""
        }else{
          //確保為字串
          fieldDefine.InputValue_S=fieldDefine.InputValue_S +""
        }
        if (fieldDefine.InputValue_E===0){
          fieldDefine.InputValue_E=""
        }else{
          //確保為字串
          fieldDefine.InputValue_E=fieldDefine.InputValue_E +""
        }
      }
    },
    haveInputValue(fieldDefine){
      if (fieldDefine.SearchInputType==="dateRange" ) {
        if (fieldDefine.InputValue_S || fieldDefine.InputValue_E){
          return true
        }
      }
      else if (fieldDefine.SearchInputType==="numberRange" ){
        if (fieldDefine.InputValue_S !==0 || fieldDefine.InputValue_E !==0){
          return true
        }
      }else {
        if (fieldDefine.InputValue){
          return true
        }
      }
      return false
    },
    havePermission(field){ // eslint-disable-line
      // if (field.Permission){
      //   let aPermission=field.Permission.split(",")
      //
      //   if (aPermission.includes(this.role)){
      //     return true;
      //   }else{
      //     return false;
      //   }
      // }

      return true;
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    btnSearch(){
      this.$emit('search')
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>
/* SearchPanelForm/SearchFormField 均有此class*/
.col-sm-12{
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

</style>
