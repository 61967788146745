<template>
  <div>
    <a role="button" class="btn btn-primary" @click="openSetting()">格式設定</a>

    <MyCSSPickerDlg ref="myCSSPickerDlg" v-model="this.value" :setting="setting" v-on:returnOk="dialogReturnOK"></MyCSSPickerDlg>
  </div>
</template>

<script>
import MyCSSPickerDlg from "@/pages/commonComponent/MyCSSPickerDlg";
/**
 <MyCSSPicker v-model="item.StyleCSS"></MyCSSPicker>
 **/

export default {
  components: {MyCSSPickerDlg},
  // name: "MyColorPicker",
  props: ["value","setting"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },

  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },

    openSetting(){
      this.$refs.myCSSPickerDlg.showModal();
    },
    dialogReturnOK(){
      this.propsValue = this.$refs.myCSSPickerDlg.propsValue;
    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
