var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "onePageAdv" } }),
      _c(
        "div",
        {
          staticClass: "form-group row",
          staticStyle: { "margin-top": "50px" }
        },
        [
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary mybutton",
                staticStyle: { width: "100px" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v(" 儲存")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mybutton",
                staticStyle: { width: "100px" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.PreviewCName_Current($event)
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-eye" }), _vm._v("預覽")]
            )
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          on: { click: _vm.DataItem_Page_AddLast }
        },
        [_c("i", { staticClass: "fas fa-plus-circle" }), _vm._v("新增頁面")]
      ),
      _c(
        "table",
        { staticClass: "table table-hover", attrs: { id: "tblSecond" } },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(this.currentCName.Pages, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", {}, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Page_Edit(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-pen" }), _vm._v("編輯頁面")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Page_Remove(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Page_MoveUp(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-up" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Page_MoveDown(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-down" })]
                  )
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.Name,
                        expression: "item.Name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "" },
                    domProps: { value: item.Name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "Name", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("td", [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setHomePage(item)
                        }
                      }
                    },
                    [
                      _c("MyButtonBoolean", {
                        attrs: { "true-text": "首頁", "false-text": "子頁面" },
                        model: {
                          value: item.IsHomePage,
                          callback: function($$v) {
                            _vm.$set(item, "IsHomePage", $$v)
                          },
                          expression: "item.IsHomePage"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "td",
                  [
                    _c("MyButtonIsVisible", {
                      model: {
                        value: item.IsVisible,
                        callback: function($$v) {
                          _vm.$set(item, "IsVisible", $$v)
                        },
                        expression: "item.IsVisible"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("功能")]),
        _c("th", [_vm._v("名稱")]),
        _c("th", [_vm._v("設為首頁")]),
        _c("th", [_vm._v("是否顯示")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }