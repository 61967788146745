var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.propsValue,
            expression: "propsValue"
          }
        ],
        staticClass: "form-control",
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.propsValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c("option", { attrs: { value: "" } }),
        _vm._l(_vm.datasFiltered, function(item, index) {
          return _c(
            "option",
            { key: index, domProps: { value: item[_vm.fieldNameValue] } },
            [_vm._v(_vm._s(item[_vm.fieldNameText]))]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }