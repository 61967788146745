<template>
  <div>
    <COM_HeaderNav mode="UDMLogIndex"></COM_HeaderNav>

<!--    <div class="form-group row">-->
<!--      <div class="col-sm-10">-->
<!--        <a class="btn btn-primary mybutton" href="#" @click.prevent="submit" style="width: 100px">儲存</a>-->

<!--      </div>-->
<!--    </div>-->


    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta" v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"></MyToolbarVuetify>

    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig"  :grid-meta="gridMeta">
<!--      <template v-slot:Fun="{ item }">-->
<!--        <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>-->
<!--      </template>-->
    </MyGridVuetify>

<!--    <table class="table table-hover" id="tblSecond">-->
<!--      <thead class="thead-light">-->
<!--      <tr>-->
<!--        <th class="text-center">功能</th>-->
<!--        <th>DM名稱</th>-->
<!--        <th>使用者</th>-->
<!--        <th>瀏覽器資訊</th>-->
<!--        <th>時間</th>-->
<!--        <th>IP</th>-->
<!--        <th>動作</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(item,index) in this.datas.Details" :key="index">-->
<!--        <td class="" style="width: 50px">-->
<!--          <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>-->
<!--        </td>-->
<!--        <td>{{item.dmName}}</td>-->
<!--        <td>{{item.passCode}}</td>-->
<!--        <td>{{item.browserInfo}}</td>-->
<!--        <td>{{item.time}}</td>-->
<!--        <td>{{item.ip}}</td>-->
<!--        <td style="width: 150px">{{item.action}}</td>-->

<!--      </tr>-->

<!--      </tbody>-->
<!--    </table>-->

    <table class="table table-hover" id="tblSecond">
      <thead class="thead-light">
      <tr>
        <th>統計</th>
        <th>資訊</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.datasStatic.Details" :key="index">
        <td>{{item.name}}</td>
        <td>{{item.info}}</td>
      </tr>

      </tbody>
    </table>


  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";

let configEditPage="OnePageAdvEdit";

export default {
  components: {MyToolbarVuetify, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [
          { text: 'DM名稱', value: 'dmName' },
          { text: '使用者', value: 'passCode' },
          { text: '時間', value: 'time' },
          { text: '動作', value: 'action' },
          { text: '瀏覽器資訊', value: 'browserInfo' },
          { text: 'IP', value: 'ip' },
        ],
        datas: [
          {
            Fun:"",
            SystemId:"1",
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 12:14:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (23秒)",
          },{
            Fun:"",
            SystemId:"2",
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 15:01:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (4分鐘)",
          },{
            Fun:"",
            SystemId:"3",
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 17:23:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (79分鐘)",
          },{
            Fun:"",
            SystemId:"4",
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 11:22:00",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },{
            Fun:"",
            SystemId:"5",
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 14:05:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },
        ]
      },
      datasStatic: {
        Details: [
          {
            name: "2023-02-07",
            info: "開啟次數 3",
          }, {
            name: "2023-02-08",
            info: "開啟次數 2",
          }
        ]
      },
      datas:{
        Details:[
          {
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 12:14:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (23秒)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 15:01:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (4分鐘)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 17:23:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (79分鐘)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 11:22:00",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 14:05:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },
        ]
      },
      ready:false,
      toolbarVuetifyConfig:{

      },

      gridMeta:{},
    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()
      //
      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },

    DataItem_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    },
    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },

  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>
