var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn ",
        class: _vm.getClass(this.value),
        on: {
          click: function($event) {
            return _vm.enable()
          }
        }
      },
      [_vm._v("是")]
    ),
    _c(
      "button",
      {
        staticClass: "btn ",
        class: _vm.getClass(!this.value),
        on: {
          click: function($event) {
            return _vm.disable()
          }
        }
      },
      [_vm._v("否")]
    ),
    _vm._v(" " + _vm._s(this.value) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }