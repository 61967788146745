<template>
  <div class="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
    <div class="c-sidebar-brand d-md-down-none">
<!--      <svg class="c-sidebar-brand-full" width="118" height="46" alt="CoreUI Logo">-->
<!--        <use xlink:href="theme/coreUI3/assets/brand/coreui-pro.svg#full"></use>-->
<!--      </svg>-->
<!--      <svg class="c-sidebar-brand-minimized" width="46" height="46" alt="CoreUI Logo">-->
<!--        <use xlink:href="theme/coreUI3/assets/brand/coreui-pro.svg#signet"></use>-->
<!--      </svg>-->
    </div>
    <ul class="c-sidebar-nav">
      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" href="#" @click="gotoHome()">
          <i class="fa fa-home c-sidebar-nav-icon"></i>
          首頁
<!--          <span class="badge badge-info">NEW</span>-->
        </a>
      </li>

      <li class="c-sidebar-nav-title">購物車</li>
      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" @click="goto('OrderIndex')">
          <i class="fa fa-credit-card c-sidebar-nav-icon"></i>
          訂單
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" @click="goto('ProductIndex')">
          <i class="fa fa-store-alt c-sidebar-nav-icon"></i>
          商品
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" @click="goto('MemberIndex')">
          <i class="fa fa-users c-sidebar-nav-icon"></i>
          會員
        </a>
      </li>

      <li class="c-sidebar-nav-title">網站管理</li>

<!--      <li class="c-sidebar-nav-item">-->
<!--        <a class="c-sidebar-nav-link" href="http://172.104.81.182:8082/?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09#" target="_blank">-->
<!--          <i class="fa fa-cog c-sidebar-nav-icon"></i>-->
<!--          外觀設定-->
<!--        </a>-->
<!--      </li>-->


      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" @click="gotoWithSystemId('SettingPanel','')">
          <i class="fa fa-cog c-sidebar-nav-icon"></i>
          購物設定設定
        </a>
      </li>

      <li class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link" href="/Login">
          <i class="fa fa-sign-out-alt c-sidebar-nav-icon"></i>
          登出
        </a>
      </li>
    </ul>

    <button class="c-sidebar-minimizer c-class-toggler" type="button" data-target="_parent" data-class="c-sidebar-unfoldable"></button>
  </div>
  <!--  <div class="c-sidebar c-sidebar-lg c-sidebar-light c-sidebar-right c-sidebar-overlaid" id="aside">-->
  <!--    <button class="c-sidebar-close c-class-toggler" type="button" data-target="_parent" data-class="c-sidebar-show">-->
  <!--      <svg class="c-icon">-->
  <!--        <use xlink:href="theme/coreUI3/vendors/@coreui/icons/svg/free.svg#cil-x"></use>-->
  <!--      </svg>-->
  <!--    </button>-->
  <!--  </div>-->


  <!--  &lt;!&ndash; Sidebar &ndash;&gt;-->
  <!--  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" v-if="showMenu">-->

  <!--    &lt;!&ndash; Sidebar - Brand &ndash;&gt;-->
  <!--    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">-->
  <!--      <div class="sidebar-brand-icon rotate-n-15">-->
  <!--        &lt;!&ndash;        <i class="fas fa-laugh-wink"></i>&ndash;&gt;-->
  <!--      </div>-->
  <!--      <div class="sidebar-brand-text mx-3">-->
  <!--        UBoss Admin-->
  <!--      </div>-->
  <!--    </a>-->

  <!--    &lt;!&ndash; Divider &ndash;&gt;-->
  <!--    <hr class="sidebar-divider my-0">-->

  <!--    &lt;!&ndash; Nav Item - Dashboard &ndash;&gt;-->
  <!--    <li class="nav-item active">-->
  <!--      <a class="nav-link" href="index.html">-->
  <!--        <i class="fas fa-fw fa-tachometer-alt"></i>-->
  <!--        <span>Home  hi:{{userId}}</span></a>-->
  <!--    </li>-->

  <!--    &lt;!&ndash; Divider &ndash;&gt;-->
  <!--    <hr class="sidebar-divider">-->

  <!--    &lt;!&ndash; Heading &ndash;&gt;-->
  <!--    <div class="sidebar-heading">-->
  <!--      功能-->
  <!--    </div>-->

  <!--    &lt;!&ndash; Nav Item - Pages Collapse Menu &ndash;&gt;-->
  <!--    &lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
  <!--    &lt;!&ndash;      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <i class="fas fa-fw fa-cog"></i>&ndash;&gt;-->
  <!--    &lt;!&ndash;        <span>系統管理</span>&ndash;&gt;-->
  <!--    &lt;!&ndash;      </a>&ndash;&gt;-->
  <!--    &lt;!&ndash;      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <div class="bg-white py-2 collapse-inner rounded">&ndash;&gt;-->
  <!--    &lt;!&ndash;          <h6 class="collapse-header"></h6>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CodeListCourseCatalogList">課程Category</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CodeListCourseTargetList">課程目標受眾</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/SupplierGroupList">供應商群組</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CodeListSupplierAnnouncementCatalogList">Supplier announcement 分類</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;        </div>&ndash;&gt;-->
  <!--    &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </li>&ndash;&gt;-->

  <!--    &lt;!&ndash;    &lt;!&ndash; Nav Item - Utilities Collapse Menu &ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
  <!--    &lt;!&ndash;      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <i class="fas fa-fw fa-wrench"></i>&ndash;&gt;-->
  <!--    &lt;!&ndash;        <span>課程管理</span>&ndash;&gt;-->
  <!--    &lt;!&ndash;      </a>&ndash;&gt;-->
  <!--    &lt;!&ndash;      <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <div class="bg-white py-2 collapse-inner rounded">&ndash;&gt;-->
  <!--    &lt;!&ndash;          <h6 class="collapse-header"></h6>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CourseList">課程管理</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CourseMissionAllocList">課程分派管理</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;          <a class="collapse-item" href="/CourseMissionAllocReport">課程完成率</a>&ndash;&gt;-->
  <!--    &lt;!&ndash;&lt;!&ndash;          <a class="collapse-item" href="/CourseReport2">Tier-1 課程完成率 (開發中)</a>&ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;&lt;!&ndash;          <a class="collapse-item" href="/CourseReport3">Tier-1 員工課程完成率 (開發中)</a>&ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;&lt;!&ndash;          <a class="collapse-item" href="/CourseReport4">Tier-2 課程完成率 (開發中)</a>&ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;&lt;!&ndash;          <a class="collapse-item" href="/CourseReport5">Guest 課程完成率 (開發中)</a>&ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;&lt;!&ndash;          <a class="collapse-item" href="/CourseQuizeList">課程試題管理 (開發中)</a>&ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;        </div>&ndash;&gt;-->
  <!--    &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </li>&ndash;&gt;-->

  <!--    &lt;!&ndash; Nav Item - Charts &ndash;&gt;-->
  <!--    <li class="nav-item">-->
  <!--      <a class="nav-link" href="/">-->
  <!--        <i class="fas fa-fw fa-chart-area"></i>-->
  <!--        <span>首頁</span></a>-->
  <!--    </li>-->
  <!--&lt;!&ndash;    &lt;!&ndash; Nav Item - Charts &ndash;&gt;&ndash;&gt;-->
  <!--&lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
  <!--&lt;!&ndash;      <a class="nav-link" href="/GPKInputFormList?type=purchase">&ndash;&gt;-->
  <!--&lt;!&ndash;        <i class="fas fa-fw fa-chart-area"></i>&ndash;&gt;-->
  <!--&lt;!&ndash;        <span>採購管理</span></a>&ndash;&gt;-->
  <!--&lt;!&ndash;    </li>&ndash;&gt;-->


  <!--&lt;!&ndash;    <li class="nav-item" v-if="showFunReport">&ndash;&gt;-->
  <!--&lt;!&ndash;      <a class="nav-link" href="/GPKReport">&ndash;&gt;-->
  <!--&lt;!&ndash;        <i class="fas fa-fw fa-chart-area"></i>&ndash;&gt;-->
  <!--&lt;!&ndash;        <span>報表中心</span></a>&ndash;&gt;-->
  <!--&lt;!&ndash;    </li>&ndash;&gt;-->


  <!--    &lt;!&ndash; Nav Item - Charts &ndash;&gt;-->
  <!--    <li class="nav-item">-->
  <!--      <a class="nav-link" @click="logout">-->
  <!--        <i class="fas fa-fw fa-chart-area"></i>-->
  <!--        <span>登出</span></a>-->
  <!--    </li>-->


  <!--    &lt;!&ndash;    &lt;!&ndash; Divider &ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;    <hr class="sidebar-divider">&ndash;&gt;-->


  <!--    &lt;!&ndash;    &lt;!&ndash; Nav Item - Charts &ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
  <!--    &lt;!&ndash;      <a class="nav-link" href="/Test">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <i class="fas fa-fw fa-chart-area"></i>&ndash;&gt;-->
  <!--    &lt;!&ndash;        <span>Test</span></a>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </li>&ndash;&gt;-->

  <!--    &lt;!&ndash;    &lt;!&ndash; Nav Item - Charts &ndash;&gt;&ndash;&gt;-->
  <!--    &lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
  <!--    &lt;!&ndash;      <a class="nav-link" href="/TestGrid">&ndash;&gt;-->
  <!--    &lt;!&ndash;        <i class="fas fa-fw fa-chart-area"></i>&ndash;&gt;-->
  <!--    &lt;!&ndash;        <span>TestGrid</span></a>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </li>&ndash;&gt;-->


  <!--    &lt;!&ndash; Sidebar Toggler (Sidebar) &ndash;&gt;-->
  <!--    &lt;!&ndash;    <div class="text-center d-none d-md-inline">&ndash;&gt;-->
  <!--    &lt;!&ndash;      <button class="rounded-circle border-0" id="sidebarToggle"></button>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </div>&ndash;&gt;-->

  <!--  </ul>-->
  <!-- End of Sidebar -->
</template>

<script>
import session from "../../../common/session";

export default {
  name: "COM_Menu",
  data: function () {
    return {
      showMenu: true,
      userId: "",
      showFunReport: false,
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    logout() {
      session.logout(this.$router, this)
    }
  },
  mounted() {


    //this.loadData()
    this.userId = session.getAccUid()

    if (this.userId === "ADMIN") {
      this.showFunReport = true;
    }
  },
}
</script>

<style scoped>
.navbar-nav {
  width: 200px !important;
}
</style>
