import { render, staticRenderFns } from "./EditMediaList.vue?vue&type=template&id=73ed396e&scoped=true&"
import script from "./EditMediaList.vue?vue&type=script&lang=js&"
export * from "./EditMediaList.vue?vue&type=script&lang=js&"
import style0 from "./EditMediaList.vue?vue&type=style&index=0&id=73ed396e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73ed396e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\UBoss\\Source\\Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73ed396e')) {
      api.createRecord('73ed396e', component.options)
    } else {
      api.reload('73ed396e', component.options)
    }
    module.hot.accept("./EditMediaList.vue?vue&type=template&id=73ed396e&scoped=true&", function () {
      api.rerender('73ed396e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ubossBk/views/utube/EditMediaList.vue"
export default component.exports