var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show",
      attrs: { id: "sidebar" }
    },
    [
      _c("div", { staticClass: "c-sidebar-brand d-md-down-none" }),
      _c("ul", { staticClass: "c-sidebar-nav" }, [
        _c("li", { staticClass: "c-sidebar-nav-item" }, [
          _c(
            "a",
            {
              staticClass: "c-sidebar-nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.gotoHome()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-home c-sidebar-nav-icon" }),
              _vm._v(" 首頁 ")
            ]
          )
        ]),
        _c("li", { staticClass: "c-sidebar-nav-title" }, [_vm._v("購物車")]),
        _c("li", { staticClass: "c-sidebar-nav-item" }, [
          _c(
            "a",
            {
              staticClass: "c-sidebar-nav-link",
              on: {
                click: function($event) {
                  return _vm.goto("OrderIndex")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-credit-card c-sidebar-nav-icon" }),
              _vm._v(" 訂單 ")
            ]
          )
        ]),
        _c("li", { staticClass: "c-sidebar-nav-item" }, [
          _c(
            "a",
            {
              staticClass: "c-sidebar-nav-link",
              on: {
                click: function($event) {
                  return _vm.goto("ProductIndex")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-store-alt c-sidebar-nav-icon" }),
              _vm._v(" 商品 ")
            ]
          )
        ]),
        _c("li", { staticClass: "c-sidebar-nav-item" }, [
          _c(
            "a",
            {
              staticClass: "c-sidebar-nav-link",
              on: {
                click: function($event) {
                  return _vm.goto("MemberIndex")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-users c-sidebar-nav-icon" }),
              _vm._v(" 會員 ")
            ]
          )
        ]),
        _c("li", { staticClass: "c-sidebar-nav-title" }, [_vm._v("網站管理")]),
        _c("li", { staticClass: "c-sidebar-nav-item" }, [
          _c(
            "a",
            {
              staticClass: "c-sidebar-nav-link",
              on: {
                click: function($event) {
                  return _vm.gotoWithSystemId("SettingPanel", "")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-cog c-sidebar-nav-icon" }),
              _vm._v(" 購物設定設定 ")
            ]
          )
        ]),
        _vm._m(0)
      ]),
      _c("button", {
        staticClass: "c-sidebar-minimizer c-class-toggler",
        attrs: {
          type: "button",
          "data-target": "_parent",
          "data-class": "c-sidebar-unfoldable"
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "c-sidebar-nav-item" }, [
      _c(
        "a",
        { staticClass: "c-sidebar-nav-link", attrs: { href: "/Login" } },
        [
          _c("i", { staticClass: "fa fa-sign-out-alt c-sidebar-nav-icon" }),
          _vm._v(" 登出 ")
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }