

import * as onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import * as formUtil from "@/pages/featureOnePage/util/formUtil";

export default {
  run: function (Vue) {
    Vue.prototype.$onePageUtil=onePageUtil
    Vue.prototype.$formUtil=formUtil

    //event bus
    Vue.prototype.$bus = new Vue();

  },
}
