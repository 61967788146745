var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-modal",
        { ref: "my-modal", attrs: { size: "s", "hide-footer": "", title: "" } },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("聯絡我們")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "form-group row" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4", attrs: { for: "" } }, [
                _vm._v("您的手機:")
              ]),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "name",
                    name: "name",
                    placeholder: ""
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4", attrs: { for: "" } }, [
                _vm._v("聯絡事項:")
              ]),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("textarea", {
                  staticClass: "form-control",
                  attrs: { id: "txtMemo", name: "txtMemo", rows: "10" }
                })
              ])
            ])
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "btn btn-primary", block: "" },
              on: {
                click: function($event) {
                  return _vm.toggleModal()
                }
              }
            },
            [_vm._v("送出")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "btn btn-danger", block: "" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("關閉")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }