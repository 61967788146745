var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "shoppingMember" } }),
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.btnUpdate }
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-left" }),
                  _vm._v("回上一頁")
                ]
              )
            ]
          )
        ]
      ),
      _c("ValidationObserver", { ref: "form" }, [
        _c("div", { staticClass: "thin-form-group form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("登入帳號")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "登入帳號" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.LoginId,
                              expression: "formData.LoginId"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.LoginId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "LoginId",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "thin-form-group form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("姓名")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Name,
                              expression: "formData.Name"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("Email")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "", name: "Email" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Email,
                              expression: "formData.Email"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "Email",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
            [_vm._v("電話")]
          ),
          _c(
            "div",
            { staticClass: "a_thinInputDiv col-sm-10" },
            [
              _c("validation-provider", {
                attrs: { rules: "required", name: "電話" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.Tel,
                              expression: "formData.Tel"
                            }
                          ],
                          staticClass: "form-control ",
                          attrs: { type: "text", placeholder: "" },
                          domProps: { value: _vm.formData.Tel },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.formData, "Tel", $event.target.value)
                            }
                          }
                        }),
                        _c("span", { staticClass: "error_msg" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }