var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "d-block d-sm-none" },
      [
        _c(
          "b-carousel",
          {
            ref: "carousel",
            staticStyle: { "text-shadow": "1px 1px 2px #333" },
            attrs: {
              interval: 5000,
              controls: "",
              indicators: "",
              background: "#ababab",
              "img-width": "1024"
            }
          },
          _vm._l(_vm.propsWebPart.PartImages, function(image, index) {
            return _c("b-carousel-slide", {
              key: index,
              attrs: {
                "img-src": _vm.toDownloadLink(true, image.ImageUrl),
                "data-interval": image.Interval.makeSureNumber(5) * 1000
              }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-none d-sm-block" },
      [
        _c(
          "b-carousel",
          {
            ref: "carousel",
            staticStyle: { "text-shadow": "1px 1px 2px #333" },
            attrs: {
              interval: 5000,
              controls: "",
              indicators: "",
              background: "#ababab",
              "img-width": "1024"
            },
            on: { "sliding-end": _vm.slidingEnd }
          },
          _vm._l(_vm.propsWebPart.PartImages, function(image, index) {
            return _c("b-carousel-slide", {
              key: index,
              attrs: {
                "img-src": _vm.toDownloadLink(false, image.ImageUrl),
                "data-interval": image.Interval.makeSureNumber(5) * 1000
              }
            })
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }