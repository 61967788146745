<template>
  <div class="thisDiv">
    <div v-if="!IsEnable">
      <a class="btn btn-primary" @click="DataItemMaster_Enable"><i class="fas fa-plus-circle"></i>開啟商品規格</a>
    </div>
    <div v-if="IsEnable">
      <div v-if="formDataDemo.OptionSets.Items.length ===0">
        <a class="btn btn-primary" @click="DataItemMaster_Enable"><i class="fas fa-plus-circle"></i>開啟商品規格</a>
      </div>


      <div v-for="(item,index) in formDataDemo.OptionSets.Items" :key="index">
        <div style="position: relative;left: 80%;top: 0;	">
          <a class="btn btn-danger" @click="DataItemMaster_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItemMaster_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItemMaster_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </div>
        <div style="background-color: #fafafa;margin-bottom: 10px">
          <div class="form-group row">
            <label for="" class="col-sm-2 thisLabel">規格名稱</label>
            <div class="col-sm-10">
              <input type="text" class="form-control thisControl" v-model="item.Text">
            </div>
          </div>

          <div class="form-group row" v-for="(option,index) in item.Options" :key="index">
            <label for="" class="col-sm-2 thisLabel">選項</label>
            <div class="col-sm-10 form-inline">
              <input type="text" class="form-control thisControl" placeholder="" v-model="option.Text">

              <a class="btn btn-danger" @click="DataItemDetail_Remove(item,option)"><i class="far fa-trash-alt"></i></a>
              <a class="btn btn-primary" @click="DataItemDetail_MoveUp(item.Options,option)"><i class="fa fa-angle-up"></i></a>
              <a class="btn btn-primary" @click="DataItemDetail_MoveDown(item.Options,option)"><i class="fa fa-angle-down"></i></a>
            </div>
          </div>

          <div class="form-group row">
            <label for="" class="col-sm-2 thisLabel"></label>
            <a class="btn btn-primary" @click="DataItemDetail_AddLast(item.Options)"><i class="fas fa-plus-circle"></i>增加選項</a>
          </div>
        </div>

        <hr>
      </div>


      <hr>

      <a v-if="formDataDemo.OptionSets.Items.length >0 && formDataDemo.OptionSets.Items.length < maxOptionCount" class="btn btn-primary" @click="DataItemMaster_AddLast"><i class="fas fa-plus-circle"></i>增加規格</a>

      <div v-if="formDataDemo.OptionSets.Items.length >0">
        <span class="form-inline">
          <h2>規格表</h2>
          <MyButtonEnableDisable v-model="formDataDemo.IsEnableOptionTable"></MyButtonEnableDisable>
        </span>

<!--        <table class="table table-hover" style="width:500px">-->
<!--          <thead class="thead-light">-->
<!--          <tr>-->
<!--            <th class="text-center">價格</th>-->
<!--            <th>庫存量</th>-->
<!--            <th></th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td class="text-left">-->
<!--              <input type="text" class="form-control" placeholder="價格" v-model="settingApply_Price">-->
<!--            </td>-->
<!--            <td class="text-left">-->
<!--              <input type="text" class="form-control" placeholder="庫存量" v-model="settingApply_Quantity">-->
<!--            </td>-->
<!--            <td class="text-left">-->
<!--              <a role="button" class="btn btn-primary" @click="ApplyRefresh">全部套用</a>-->
<!--            </td>-->
<!--          </tr>-->

<!--          </tbody>-->
<!--        </table>-->

        <div class="form-inline">
          <input type="text" class="form-control" placeholder="價格" v-model.number="settingApply_Price">
          <input type="text" class="form-control" placeholder="庫存量" v-model.number="settingApply_Quantity">
          <a role="button" class="btn btn-primary" @click="ApplyRefresh">全部套用</a>
        </div>


        <table class="table table-hover" style="width:500px" v-if="formDataDemo.IsEnableOptionTable">
          <thead class="thead-light">
          <tr>
            <th class="text-center">{{ getOptionName(0) }}</th>
            <th class="text-center">{{ getOptionName(1) }}</th>
            <th class="text-center">價格</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in calculatedOptionTable(0)" :key="index">
            <td>{{ item.MasterText }}</td>
            <td class="text-left">
              {{ item.DetailText }}
            </td>
            <td class="text-center">
              <input type="text" class="form-control" placeholder="" v-model="item.Price">
            </td>
          </tr>

          </tbody>
        </table>

        <h1>商品頁預覽</h1>
        <ProductOptionSelector :product-option="formDataDemo"></ProductOptionSelector>
      </div>
    </div>

  </div>
</template>

<script>
/**
 <ProductOptionSetting style="max-width:750px "></ProductOptionSetting>
 **/

import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";
import ProductOptionSelector from "@/pages/ubossBk/views/components/shop/ProductOptionSelector";
import MyButtonEnableDisable from "@/pages/commonComponent/MyButtonEnableDisable";


export default {
  components: {MyButtonEnableDisable, ProductOptionSelector},
  //name: "ProductOptionSetting",
  props: ["IsEnable"],
  data: function () {
    return {
      id: "",
      dataReady: false,
      maxOptionCount: "",
      settingApply_Price:"",
      settingApply_Quantity:"",
      formData: {
        SystemId:"",
        IsEnableOptionTable: true,
        Options: {
          Items: [
            {
              SystemId: "",
              OrderKey: "",
              Text: "",
              Options: [
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "",
                }
              ],
            }
          ]
        },
        OptionTable: {
          Items: [
            {
              OptionText: "",
              OptionItem1Text: "",
              OptionItem2Text: "",
              Price: 0,
              Quantity: 0,
              ProductNo: "",
            }
          ]
        }
      },
      formDataDemo: {
        SystemId:"",
        IsEnableOptionTable: true,
        OptionSets: {
          Items: []
        },
        OptionTable: {
          Items: []
        }
      },
      formDataDemo_o: {
        IsEnableOptionTable: true,
        OptionSets: {
          Items: [
            {
              SystemId: "",
              OrderKey: "1",
              Text: "顏色",
              Options: [
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "白",
                },
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "紅",
                },
              ],
            },
            {
              SystemId: "",
              OrderKey: "2",
              Text: "尺寸",
              Options: [
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "大",
                },
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "中",
                },
                {
                  SystemId: "",
                  OrderKey: "1",
                  Text: "小",
                },
              ],
            },
          ]
        },
        OptionTable: {
          Items: [
            {
              OptionText: "顏色",
              OptionItem1Text: "白",
              OptionItem2Text: "大",
              Price: 100,
              Quantity: 0,
              ProductNo: "",
            },
            {
              OptionText: "顏色",
              OptionItem1Text: "白",
              OptionItem2Text: "中",
              Price: 90,
              Quantity: 0,
              ProductNo: "",
            },
            {
              OptionText: "顏色",
              OptionItem1Text: "白",
              OptionItem2Text: "小",
              Price: 80,
              Quantity: 0,
              ProductNo: "",
            },
          ]
        }
      },
    };
  },

  methods: {
    getOptionName(index) {
      if (this.formDataDemo.OptionSets.Items.length > index) {
        let name = this.formDataDemo.OptionSets.Items[index].Text;

        if (name) {
          return name;
        } else {
          // return "規格" + (index + 1);
          return "";
        }
      } else {
        // return "規格" + (index + 1);
        return "";
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    submit() {
      // for (let i = 0; i < this.formDataDemo.OptionSets.Items.length; i++) {
      //   let item = this.formDataDemo.Items[i];
      //
      //   if (item.Year===""){
      //     util.showErrorMessage("第"+i+1+" 行，「年」為必填欄位")
      //     return;
      //   }
      //   if (item.Month===""){
      //     util.showErrorMessage("第"+i+1+" 行，「月」為必填欄位")
      //     return;
      //   }
      //   if (item.Type===""){
      //     util.showErrorMessage("第"+i+1+" 行，「幣別」為必填欄位")
      //     return;
      //   }
      //   if (item.Rate===""){
      //     util.showErrorMessage("第"+i+1+" 行，「匯率」為必填欄位")
      //     return;
      //   }
      // }
      //
      // apiGPK.api_Save_ExchangeRate(this.formDataDemo.SystemId,"",this.formDataDemo).then((data)=>{
      //   util.ShowMessage('儲存成功')
      // })
    },
    DataItemMaster_MoveUp(item) {
      arrayUtil.orderMove(false, item.SystemId, this.formDataDemo.OptionSets.Items, "SystemId", "OrderKey")
    },
    DataItemMaster_MoveDown(item) {
      arrayUtil.orderMove(true, item.SystemId, this.formDataDemo.OptionSets.Items, "SystemId", "OrderKey")
    },
    DataItemMaster_Enable() {
      this.IsEnable = true;
      this.DataItemMaster_AddLast();
    },
    DataItemMaster_AddLast() {
      let newItem = {
        SystemId: "",
        OrderKey: "999",
        Text: "",
        Options: [
          {
            SystemId: "",
            OrderKey: "999",
            Text: "",
          }
        ],
      }
      newItem.SystemId = util.uuid();
      newItem.Options[0].SystemId = util.uuid();

      arrayUtil.orderAppendLast(newItem, this.formDataDemo.OptionSets.Items, "OrderKey")
    },
    DataItemMaster_Remove(item) {
      this.formDataDemo.OptionSets.Items = this.formDataDemo.OptionSets.Items.filter(s => s.SystemId !== item.SystemId);
    },
    DataItemDetail_MoveUp(parent, item) {
      arrayUtil.orderMove(false, item.SystemId, parent, "SystemId", "OrderKey")
    },
    DataItemDetail_MoveDown(parent, item) {
      arrayUtil.orderMove(true, item.SystemId, parent, "SystemId", "OrderKey")
    },
    DataItemDetail_AddLast(parent) {
      let newItem = {
        SystemId: "",
        OrderKey: "999",
        Text: "",
      };
      newItem.SystemId = util.uuid();

      arrayUtil.orderAppendLast(newItem, parent, "OrderKey")
    },
    DataItemDetail_Remove(parent, item) {
      parent.Options = parent.Options.filter(s => s.SystemId !== item.SystemId);
    },
    calculatedOptionTable(applyPrice) {
      let optionTable = [];

      let group1 = [];
      let group2 = [];

      let optionItems = this.formDataDemo.OptionSets.Items;

      if (optionItems.length > 0) {
        group1 = optionItems[0].Options;
      }
      if (optionItems.length > 1) {
        group2 = optionItems[1].Options;
      }

      if (group1.length>0 && group2.length>0){
        group1.forEach((option1) => {
          group2.forEach((option2) => {
            optionTable.push({
              MasterText: option1.Text,
              DetailText: option2.Text,
              Price: applyPrice,
            });
          })
        })
      }else if (group1.length>0){
        group1.forEach((option1) => {
          optionTable.push({
            MasterText: option1.Text,
            DetailText: "",
            Price: applyPrice,
          });
        })
      }

      // this.formDataDemo.OptionSets.Items.forEach((item)=>{
      //   item.Options.forEach((option)=>{
      //     optionTable.push({
      //       MasterText:item.Text,
      //       DetailText:option.Text,
      //       Price:0,
      //     });
      //   })
      // })


      return optionTable;
    },
    ApplyRefresh(){
      this.calculatedOptionTable(this.settingApply_Price)
    }
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>
#tblMain {
  width: 1000px;
}

.btn {
  color: white !important;
  margin: 5px;
  /*width: 40px;*/
}

.row {
  padding: 0px;
}

.thisDiv {
  padding: 10px;
  background-color: #fafafa;
}

.thisLabel {
  width: 300px;
}

.thisControl {
  width: 400px;
}
</style>
