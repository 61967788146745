var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    _vm._l(_vm.formDefine.Panels, function(panel, indexPanel) {
      return _c(
        "div",
        { key: indexPanel, staticClass: "shadow p-3 mb-5 bg-white rounded" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-10" },
              _vm._l(panel.Rows, function(row, indexRow) {
                return _c(
                  "div",
                  { key: indexRow, staticClass: "form-group row" },
                  _vm._l(row.Fields, function(field, indexField) {
                    return _c(
                      "div",
                      { key: indexField, class: _vm.getFieldClass(field) },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(field.text))
                        ]),
                        _c("MyFormPanelField", {
                          attrs: {
                            field: field,
                            model: _vm.dataSource[field.fieldName]
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.dataSource,
                                field.fieldName,
                                $event
                              )
                            }
                          }
                        }),
                        field.slot ? _vm._t(field.slot) : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                )
              }),
              0
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }