var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("input", {
        ref: "file",
        staticClass: "form-control",
        attrs: { type: "file", placeholder: "", hidden: "" },
        on: { change: _vm.selectFile }
      }),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          on: { click: _vm.fileUplaodControlClick }
        },
        [_c("i", { staticClass: "fa fa-upload" })]
      ),
      _c(
        "table",
        { staticClass: "table table-hover", attrs: { id: "tblMain" } },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(this.files, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Remove(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveUp(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-up" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveDown(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-down" })]
                  )
                ]),
                _c("td", { attrs: { width: "120px" } }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("img", {
                      attrs: { src: _vm.toDownloadLink(item) + "?width=100" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showImage(item)
                        }
                      }
                    })
                  ])
                ]),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.downloadFile(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.FileName))]
                  )
                ])
              ])
            }),
            0
          )
        ]
      ),
      _c("MyLightBox", { ref: "mylightBox" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
        _c("th", [_vm._v("預覽")]),
        _c("th", [_vm._v("檔名")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }