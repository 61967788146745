var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-wrapper" }, [
    _vm.showMenu
      ? _c(
          "span",
          [
            false ? _c("COM_Menu") : _vm._e(),
            _c("COM_MenuRight"),
            _c(
              "div",
              { staticClass: "c-wrapper", attrs: { id: "wrapper" } },
              [
                _c("COM_Header"),
                _c("div", { staticClass: "c-body" }, [
                  _c("main", { staticClass: "c-main" }, [
                    _c(
                      "div",
                      { staticClass: "container-fluid" },
                      [_c("router-view")],
                      1
                    )
                  ])
                ]),
                _c("COM_Footer")
              ],
              1
            )
          ],
          1
        )
      : _c("span", [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }