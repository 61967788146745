var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "kendo-listbox",
        {
          staticStyle: { width: "300px", height: "400px" },
          attrs: {
            id: "lstSelected",
            draggable: true,
            "connect-with": "lstAll",
            "drop-sources": ["lstAll"],
            "toolbar-tools": [
              "moveUp",
              "moveDown",
              "transferTo",
              "transferFrom",
              "transferAllTo",
              "transferAllFrom",
              "remove"
            ]
          }
        },
        _vm._l(_vm.value, function(item, index) {
          return _c("option", { key: index }, [_vm._v(_vm._s(item))])
        }),
        0
      ),
      _c(
        "kendo-listbox",
        {
          staticStyle: { width: "300px", height: "400px" },
          attrs: {
            id: "lstAll",
            draggable: true,
            "connect-with": "lstSelected",
            "drop-sources": ["lstSelected"]
          }
        },
        _vm._l(_vm.datasourceLeft, function(item, index) {
          return _c("option", { key: index }, [_vm._v(_vm._s(item))])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }