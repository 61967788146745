var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bk_page_rootDiv" },
    [
      _c("COM_HeaderNav", { attrs: { mode: "ADMIndex" } }),
      _c("MyToolbarBase", [
        _c(
          "div",
          {
            staticClass: "btn-group mr-2",
            attrs: { role: "group", "aria-label": "First group" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.btnUpdate }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.btnUpdate }
              },
              [_c("i", { staticClass: "fa fa-search" }), _vm._v("票券使用明細")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("回上一頁")]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "fieldsPanel" },
        [
          _c("ValidationObserver", { ref: "form" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("類型")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.type,
                        expression: "formData.type"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("一般")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("VIP")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("會員名稱")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.name,
                      expression: "formData.name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("會員編號")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.no,
                      expression: "formData.no"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.no },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "no", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("會員名稱")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.name,
                      expression: "formData.name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("電話")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.tel,
                      expression: "formData.tel"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "tel", placeholder: "" },
                  domProps: { value: _vm.formData.tel },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "tel", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("地址")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.address,
                      expression: "formData.address"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.formData.address },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "address", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("狀態")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.status,
                        expression: "formData.status"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("生效中")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("停用")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
                _vm._v("備註")
              ]),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.memo,
                      expression: "formData.memo"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "txtMemo", name: "txtMemo", rows: "3" },
                  domProps: { value: _vm.formData.memo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "memo", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }