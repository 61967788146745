var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        ref: "grid",
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          "line-numbers": true,
          theme: "polar-bear",
          "search-options": {
            enabled: true,
            skipDiacritics: true,
            placeholder: "搜尋"
          },
          "pagination-options": {
            enabled: true,
            mode: "pages",
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: true,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: "第一頁",
            lastLabel: "最後一頁",
            nextLabel: "下一頁",
            prevLabel: "前一頁",
            rowsPerPageLabel: "每頁筆數",
            ofLabel: "of",
            pageLabel: "頁", // for 'pages' mode
            allLabel: "All"
          },
          "select-options": {
            enabled: _vm.showCheckboxColumn,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: "custom-class",
            selectionText: "筆資料已選擇",
            clearSelectionText: "清除",
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }