


import MyVuePlugin from "@/pages/common/plugin/MyVuePlugin";
import * as dec from "@/pages/common/declare";
import * as ubossDeclare from "@/pages/commonUBoss/ubossDeclare";

export default {
  run: function (Vue) {

    Vue.prototype.$dec=dec
    Vue.prototype.$u=ubossDeclare;

    Vue.use(MyVuePlugin)
    //---------------------------------------
    // Vue.directive('resize', {
    //   bind: function(el, { value = {} }) {
    //     el.addEventListener('load', () => iframeResize(value, el))
    //   },
    //   unbind: function (el) {
    //     el.iFrameResizer.removeListeners();
    //   }
    // })
    //---------------------------------------

    Vue.filter('fmtDate', function (value) {
      if (!value) return ''

      return value.substr(0,19).replaceAll("T"," ")
    })
    Vue.filter('fmtTitle', function (value) {
      if (!value) return ''

      if (value.length<25) return value

      return value.substr(0,22) +"..."
    })

    Vue.directive('uppercase', {
      update (el) {
        el.value = el.value.toUpperCase()
      },
    })

    Vue.directive('numericOrEmpty', {
      bind(el) {
        el.addEventListener('keyup', () => {
          let regex = /^[0-9]*$/
          if (!regex.test(el.value)) {
            let regex2 = /\s/
            if (!regex2.test(el.value)) {
              el.value = el.value.slice(0, -1)
            }
          }
        })
      }
    })

    Vue.directive('numericOnly', {
      bind(el) {
        el.addEventListener('keyup', () => {
          let regex = /^[0-9]*$/
          if (!regex.test(el.value)) {
            el.value = el.value.slice(0, -1)
          }
        })
      }
    })
  },
}
