var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 labelClass",
          staticStyle: { color: "#2c9faf" }
        },
        [_vm._v("填寫標題")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webPart.DescText,
              expression: "webPart.DescText"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webPart.DescText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webPart, "DescText", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 labelClass",
          staticStyle: { color: "#2c9faf" }
        },
        [_vm._v("填寫說明文字")]
      ),
      _c(
        "div",
        { staticClass: "col-sm-10" },
        [
          _c("MyCkEditor", {
            ref: "myCkEditor",
            attrs: {
              height: "200",
              width: "100%",
              "file-upload-list-lite_is-show": true,
              "file-upload-list-lite_owner-system-id": _vm.webPart.Id,
              "file-upload-list-lite_file-use-memo": this.$u
                .UBossFileUseType_OnePageWebPartFormShop
            },
            model: {
              value: _vm.webPart.Desc,
              callback: function($$v) {
                _vm.$set(_vm.webPart, "Desc", $$v)
              },
              expression: "webPart.Desc"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "a",
      { staticClass: "btn btn-primary", on: { click: _vm.DataItem_AddLast } },
      [_c("i", { staticClass: "fas fa-plus-circle" })]
    ),
    _c(
      "table",
      { staticClass: "table table-hover", attrs: { id: "tblMain" } },
      [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(this.webPart.ShopItems, function(item, index) {
            return _c("tr", { key: index }, [
              _c(
                "td",
                { staticClass: "text-center", attrs: { width: "200px" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Insert(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-plus-circle" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_Remove(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveUp(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-up" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.DataItem_MoveDown(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-angle-down" })]
                  )
                ]
              ),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.Text,
                      expression: "item.Text"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: item.Text },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "Text", $event.target.value)
                    }
                  }
                })
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.Price,
                      expression: "item.Price"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number", placeholder: "" },
                  domProps: { value: item.Price },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "Price", $event.target.value)
                    }
                  }
                })
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
        _c("th", [_vm._v("名稱")]),
        _c("th", [_vm._v("價格")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }