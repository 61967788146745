var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MyToolbarBase",
    _vm._l(_vm.toolbar.Groups, function(group, indexGroup) {
      return _c(
        "div",
        {
          key: indexGroup,
          staticClass: "btn-group mr-2",
          attrs: { role: "group", "aria-label": "First group" }
        },
        _vm._l(group.Buttons, function(button, indexButton) {
          return _c(
            "button",
            {
              key: indexButton,
              staticClass: "btn ",
              class: button.className || _vm.getClass(button),
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.button_Click(button)
                }
              }
            },
            [
              _c("i", { class: button.icon }),
              _vm._v(" " + _vm._s(_vm.getButtonText(button)))
            ]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }