var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fieldsPanel" },
    [
      _c(
        "v-app",
        { staticClass: "myGridPanel", attrs: { id: "inspire" } },
        [
          _c(
            "div",
            {
              staticClass: "btn-toolbar",
              attrs: {
                role: "toolbar",
                "aria-label": "Toolbar with button groups"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn-group mr-2",
                  attrs: { role: "group", "aria-label": "First group" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnUpdate }
                    },
                    [_c("i", { staticClass: "fa fa-save" }), _vm._v("儲存")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.btnCopyLink }
                    },
                    [
                      _c("i", { staticClass: "fa fa-save" }),
                      _vm._v("複製播放連結")
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v("回上一頁")
                    ]
                  )
                ]
              )
            ]
          ),
          _c("ValidationObserver", { ref: "form" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("頻道名稱")]
              ),
              _c(
                "div",
                { staticClass: "a_thinInputDiv col-sm-10 " },
                [
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "Name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.Name,
                                  expression: "formData.Name"
                                }
                              ],
                              staticClass: "form-control ",
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: _vm.formData.Name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formData,
                                    "Name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c("span", { staticClass: "error_msg" }, [
                              _vm._v(_vm._s(errors[0]))
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("重新整理次數")]
              ),
              _c(
                "div",
                { staticClass: "a_thinInputDiv col-sm-10 " },
                [
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "RefreshLoopCount" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.RefreshLoopCount,
                                  expression: "formData.RefreshLoopCount"
                                }
                              ],
                              staticClass: "form-control ",
                              attrs: { type: "text", placeholder: "" },
                              domProps: {
                                value: _vm.formData.RefreshLoopCount
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formData,
                                    "RefreshLoopCount",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" (輪播幾次後，重新下載頻道內容) "),
                            _c("span", { staticClass: "error_msg" }, [
                              _vm._v(_vm._s(errors[0]))
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "a_thinlabel col-sm-2", attrs: { for: "" } },
                [_vm._v("頻道裝置")]
              ),
              _c("div", { staticClass: "a_thinInputDiv col-sm-10" }, [
                _vm._v(" 旋轉角度 "),
                _c("div", { staticClass: "btn-group btn-group-toggle" }, [
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      class: _vm.formData.RotateAngle === 0 ? "active" : ""
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.formData.RotateAngle,
                            expression: "formData.RotateAngle",
                            modifiers: { number: true }
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "0",
                          autocomplete: "off"
                        },
                        domProps: {
                          checked: _vm._q(_vm.formData.RotateAngle, _vm._n("0"))
                        },
                        on: {
                          change: function($event) {
                            _vm.$set(_vm.formData, "RotateAngle", _vm._n("0"))
                          }
                        }
                      }),
                      _vm._v(" 0 ")
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      class: _vm.formData.RotateAngle === 90 ? "active" : ""
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.formData.RotateAngle,
                            expression: "formData.RotateAngle",
                            modifiers: { number: true }
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "90",
                          autocomplete: "off"
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.formData.RotateAngle,
                            _vm._n("90")
                          )
                        },
                        on: {
                          change: function($event) {
                            _vm.$set(_vm.formData, "RotateAngle", _vm._n("90"))
                          }
                        }
                      }),
                      _vm._v(" 90 ")
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      class: _vm.formData.RotateAngle === 180 ? "active" : ""
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.formData.RotateAngle,
                            expression: "formData.RotateAngle",
                            modifiers: { number: true }
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "180",
                          autocomplete: "off"
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.formData.RotateAngle,
                            _vm._n("180")
                          )
                        },
                        on: {
                          change: function($event) {
                            _vm.$set(_vm.formData, "RotateAngle", _vm._n("180"))
                          }
                        }
                      }),
                      _vm._v(" 180 ")
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      class: _vm.formData.RotateAngle === 270 ? "active" : ""
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.formData.RotateAngle,
                            expression: "formData.RotateAngle",
                            modifiers: { number: true }
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "270",
                          autocomplete: "off"
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.formData.RotateAngle,
                            _vm._n("270")
                          )
                        },
                        on: {
                          change: function($event) {
                            _vm.$set(_vm.formData, "RotateAngle", _vm._n("270"))
                          }
                        }
                      }),
                      _vm._v(" 270 ")
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "accordion", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticStyle: { "text-align": "left" },
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("播放列表")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: "",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("EditMediaList", {
                            attrs: { "media-list": _vm.formData.MediaList }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true }
                            }
                          ],
                          staticStyle: { "text-align": "left" },
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("跑馬燈 (上)")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("EditMarqueeList", {
                            attrs: { marquee: _vm.formData.MarqueeListUp }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: { "text-align": "left" },
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("跑馬燈 (下)")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c("EditMarqueeList", {
                            attrs: { marquee: _vm.formData.MarqueeListDown }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("MyCopySingleLineDlg", { ref: "myCopySingleLineDlg" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }