<template>
  <div class="bk_page_rootDiv">
    <COM_HeaderNav mode="TicketLogIndex"></COM_HeaderNav>

<!--    <div class="form-group row">-->
<!--      <div class="col-sm-10">-->
<!--        <a class="btn btn-primary mybutton" href="#" @click.prevent="submit" style="width: 100px">儲存</a>-->

<!--      </div>-->
<!--    </div>-->

      - Detail 頁面
      - 匯出 (全部欄位，而不僅是grid的欄位)

    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta" v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click"></MyToolbarVuetify>

    <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig"  :grid-meta="gridMeta">
<!--      <template v-slot:Fun="{ item }">-->
<!--        <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>-->
<!--      </template>-->
    </MyGridVuetify>

<!--    <table class="table table-hover" id="tblSecond">-->
<!--      <thead class="thead-light">-->
<!--      <tr>-->
<!--        <th>統計</th>-->
<!--        <th>資訊</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(item,index) in this.datasStatic.Details" :key="index">-->
<!--        <td>{{item.name}}</td>-->
<!--        <td>{{item.info}}</td>-->
<!--      </tr>-->

<!--      </tbody>-->
<!--    </table>-->


  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";

let configEditPage="OnePageAdvEdit";

export default {
  components: {MyToolbarVuetify, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [
          { text: '專案名稱', value: 'ProjectName' },
          { text: '兌換者', value: 'MemberName' },
          // { text: '會員編號', value: 'MemberNo' },
          { text: '會員名稱', value: 'MemberName' },
          { text: '票券序號(明碼)', value: 'TicketNo' },
          { text: '使用時間', value: 'CreateDt' },
        ],
        datas: [
          {
              Fun:"",
              SystemId:"1",
              ProjectName:"老客推薦新客",
              MemberNo:"0009",
              MemberName:"aylor",
              TicketNo:"TK009",
              CreateDt:"2023-02-07 12:14:11",
          },{
                Fun:"",
                SystemId:"2",
                ProjectName:"老客推薦新客",
                MemberNo:"0009",
                MemberName:"aylor",
                TicketNo:"TK010",
                CreateDt:"2023-02-07 12:14:11",
          },{
                Fun:"",
                SystemId:"3",
                ProjectName:"老客推薦新客",
                MemberNo:"0009",
                MemberName:"aylor",
                TicketNo:"f8bbced5-22f4-4ca7-9ab2-9826db3c3a6f",
                CreateDt:"2023-02-07 12:14:11",
          },{
                Fun:"",
                SystemId:"4",
                ProjectName:"老客推薦新客",
                MemberNo:"0009",
                MemberName:"aylor",
                TicketNo:"f8bbced5-22f4-4ca7-9ab2-9826db3c3a6f",
                CreateDt:"2023-02-07 12:14:11",
          },{
                Fun:"",
                SystemId:"5",
                ProjectName:"老客推薦新客",
                MemberNo:"0009",
                MemberName:"aylor",
                TicketNo:"f8bbced5-22f4-4ca7-9ab2-9826db3c3a6f",
                CreateDt:"2023-02-07 12:14:11",
          },
        ]
      },
      datas:{
        Details:[
          {
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 12:14:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (23秒)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 15:01:11",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (4分鐘)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-07 17:23:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"顯示 (79分鐘)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 11:22:00",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },{
            dmName:"產品1",
            passCode:"1234",
            browserInfo:"chrome",
            time:"2023-02-08 14:05:09",
            ip:"114.32.143.241 (Taoyuan City/Chunghwa Telecom Co.,Ltd.)",
            action:"影片播放(https://www.youtube.com/watch?v=3Z3ui9USHc0)",
          },
        ]
      },
      ready:false,
      toolbarVuetifyConfig:{

      },
      gridMeta:{},

    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()
      //
      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },

    DataItem_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    },
    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },

  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>
