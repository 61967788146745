var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "1200px" } },
    [
      _c("MyToolbarVuetify", {
        ref: "myToolbar",
        staticClass: "bk_toolbar_fixed-top",
        on: { "button-click": _vm.toolbarButton_Click }
      }),
      _c("div", { staticClass: "bk_firstBlock" }),
      _c("MyFormPanel", {
        ref: "form",
        attrs: { "data-source": _vm.formData }
      }),
      _vm.$mq !== "sm"
        ? _c("span", [
            _c(
              "nav",
              {
                staticClass: "navbar navbar-light bg-light",
                staticStyle: { "max-width": "1200px" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary justify-content-center",
                    on: {
                      click: function($event) {
                        return _vm.editOnePage()
                      }
                    }
                  },
                  [_vm._v("編輯頁面")]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.$mq === "sm"
        ? _c("span", [
            _c(
              "nav",
              { staticClass: "navbar fixed-bottom navbar-light bg-light" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary ml-auto",
                    on: {
                      click: function($event) {
                        return _vm.editOnePage()
                      }
                    }
                  },
                  [_vm._v("編輯頁面")]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }