var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "UDMLogIndex" } }),
      _c("MyToolbarVuetify", {
        attrs: { config: _vm.toolbarVuetifyConfig, "grid-meta": _vm.gridMeta },
        on: {
          "add-click": _vm.btnAdd_Click,
          "edit-click": _vm.btnEdit_Click,
          "delete-click": _vm.btnDelete_Click
        }
      }),
      _c("MyGridVuetify", {
        ref: "myGridVuetify",
        attrs: { config: _vm.gridVuetifyConfig, "grid-meta": _vm.gridMeta }
      }),
      _c(
        "table",
        { staticClass: "table table-hover", attrs: { id: "tblSecond" } },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(this.datasStatic.Details, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.name))]),
                _c("td", [_vm._v(_vm._s(item.info))])
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [_c("th", [_vm._v("統計")]), _c("th", [_vm._v("資訊")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }