var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("aside", { staticClass: "col-lg-9" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "table",
              { staticClass: "table table-borderless table-shopping-cart" },
              [
                _c("thead", { staticClass: "text-muted" }, [
                  _c("tr", { staticClass: "small text-uppercase" }, [
                    _c("th", { attrs: { scope: "col" } }, [_vm._v("Product")]),
                    _c("th", { attrs: { scope: "col", width: "120" } }, [
                      _vm._v("Quantity")
                    ]),
                    _c("th", { attrs: { scope: "col", width: "120" } }, [
                      _vm._v("Price")
                    ]),
                    _c("th", {
                      staticClass: "text-right",
                      attrs: { scope: "col", width: "200" }
                    })
                  ])
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c(
                        "figure",
                        { staticClass: "itemside align-items-center" },
                        [
                          _c("div", { staticClass: "aside" }, [
                            _c("img", {
                              staticClass: "img-sm",
                              attrs: { src: "" }
                            })
                          ]),
                          _c("figcaption", { staticClass: "info" }, [
                            _c(
                              "a",
                              {
                                staticClass: "title text-dark",
                                attrs: { href: "#" }
                              },
                              [_vm._v("Camera Canon EOS M50 Kit")]
                            ),
                            _c("p", { staticClass: "text-muted small" }, [
                              _vm._v("Matrix: 25 Mpx "),
                              _c("br"),
                              _vm._v(" Brand: Canon")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("td", [
                      _c("select", { staticClass: "form-control" }, [
                        _c("option", [_vm._v("1")]),
                        _c("option", [_vm._v("2")]),
                        _c("option", [_vm._v("3")]),
                        _c("option", [_vm._v("4")])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "price-wrap" }, [
                        _c("var", { staticClass: "price" }, [
                          _vm._v("$1156.00")
                        ]),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(" $315.20 each ")
                        ])
                      ])
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-light",
                          attrs: {
                            "data-original-title": "Save to Wishlist",
                            title: "",
                            href: "",
                            "data-toggle": "tooltip"
                          }
                        },
                        [_c("i", { staticClass: "fa fa-heart" })]
                      ),
                      _c(
                        "a",
                        { staticClass: "btn btn-light", attrs: { href: "" } },
                        [_vm._v(" Remove")]
                      )
                    ])
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "card-body border-top" }, [
              _c("p", { staticClass: "icontext" }, [
                _c("i", { staticClass: "icon text-success fa fa-truck" }),
                _vm._v(" Free Delivery within 1-2 weeks")
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("aside", { staticClass: "col-lg-3" }, [
          _c("div", { staticClass: "card mb-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Have coupon?")]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "",
                        placeholder: "Coupon code"
                      }
                    }),
                    _c("span", { staticClass: "input-group-append" }, [
                      _c("button", { staticClass: "btn btn-primary" }, [
                        _vm._v("Apply")
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("dl", { staticClass: "dlist-align" }, [
                _c("dt", [_vm._v("Total price:")]),
                _c("dd", { staticClass: "text-right" }, [_vm._v("$69.97")])
              ]),
              _c("dl", { staticClass: "dlist-align" }, [
                _c("dt", [_vm._v("Discount:")]),
                _c("dd", { staticClass: "text-right text-danger" }, [
                  _vm._v("- $10.00")
                ])
              ]),
              _c("dl", { staticClass: "dlist-align" }, [
                _c("dt", [_vm._v("Total:")]),
                _c("dd", { staticClass: "text-right text-dark b" }, [
                  _c("strong", [_vm._v("$59.97")])
                ])
              ]),
              _c("hr"),
              _c("p", { staticClass: "text-center mb-3" }, [
                _c("img", {
                  attrs: {
                    src:
                      "http://127.0.0.1:9005/ubossHome/images/misc/payments.png",
                    height: "26"
                  }
                })
              ]),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-block",
                  attrs: { href: "#" }
                },
                [_vm._v(" Make Purchase ")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-light btn-block",
                  attrs: { href: "#" }
                },
                [_vm._v("Continue Shopping")]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }