var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", {}, [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.webPart.Cards, function(item, index) {
          return _c("div", { key: index, class: _vm.colClass }, [
            _c("div", { staticClass: "card" }, [
              _c("img", {
                staticClass: "card-img-top",
                attrs: {
                  src: _vm.toDownloadLink(false, item.ImageUrl),
                  alt: ""
                }
              }),
              _c("div", { staticClass: "card-body" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _c("div", [
                    _c("span", { domProps: { innerHTML: _vm._s(item.Title) } })
                  ])
                ]),
                _c("div", { staticClass: "card-text" }, [
                  _c("div", [
                    _c("span", { domProps: { innerHTML: _vm._s(item.Text) } })
                  ])
                ])
              ])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }