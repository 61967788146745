var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showLogo
      ? _c("nav", { staticClass: "navbar top-toolbar" }, [
          _c("div", { staticClass: "container" }, [_vm._t("Logo")], 2)
        ])
      : _vm._e(),
    _c(
      "nav",
      { attrs: { "aria-label": "breadcrumb" } },
      [_vm._t("Breadcrumb")],
      2
    ),
    _c(
      "div",
      { staticClass: "container info-section" },
      [_vm._t("HeaderBar"), _vm._t("Body"), _vm._t("BodyBar")],
      2
    ),
    _vm.showBottomBar
      ? _c(
          "div",
          {
            staticClass: "horizontal-toolbar navbar fixed-bottom bottom-toolbar"
          },
          [_vm._t("BottomBar")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }