<template>


  <div class="c-wrapper">
  <span v-if="showMenu">

<!--    <COM_Menu></COM_Menu>-->
<!--   //上線前-->
    <COM_Menu v-if="false"></COM_Menu>
    <COM_MenuRight></COM_MenuRight>

    <div class="c-wrapper" id="wrapper">
      <COM_Header></COM_Header>

      <div class="c-body">
        <main class="c-main">
          <div class="container-fluid">
            <router-view/>
            <!--          <div class="fade-in">-->
            <!--            <div class="row"></div>-->
            <!--            &lt;!&ndash; /.row&ndash;&gt;-->
            <!--          </div>-->
          </div>
        </main>
      </div>

      <COM_Footer></COM_Footer>
    </div>

  </span>

  <span v-else>
    <router-view/>
  </span>

  </div>


</template>

<style>

[v-cloak] {
  display: none;
}


.mySearchPanel_toolbar {
  max-width: 1600px;
}

.myFormContainer {
  max-width: 1024px;
}

.mySearchPanel {
  width: 100%;
}

.mySearchPanel_txtKeyword {
  width: 300px !important;
  display: inline !important;
}

.myGridPanel {
  max-width: 1600px;
}

.mybutton {
  color: white !important;
  margin: 10px;
}

.errorAdm {
  border-color: red;
}

.message {
  border-color: red;
  color: red;
}

.require {
  color: red;
}

.myInput_Date {
  max-width: 185px;
  padding: 0px;
    display: block !important;
}

.myInput_DateTime {
  max-width: 230px;
  padding: 0px;
    display: block !important;
}

.sidebar {
  width: 18em !important;
}

select {
  appearance: menulist !important;
}



</style>


<script>
import COM_Header from "@/pages/ubossBk/views/components/COM_Header";
import COM_Footer from "@/pages/ubossBk/views/components/COM_Footer";
import COM_Menu from "@/pages/ubossBk/views/components/COM_Menu";
import COM_MenuRight from "@/pages/ubossBk/views/components/COM_MenuRight";

import '@progress/kendo-theme-default/dist/all.css';
export default {
  name: '',
  components: {COM_MenuRight, COM_Menu, COM_Header, COM_Footer},
  // components: {
  //   HelloWorld
  // }
  data: function () {
    return {
      showMenu: true,
      // layout1: true,
      // layout2: false,
    };
  },
  methods: {},
  created() {
    if (this.$route.name === "Login") {
      this.showMenu = false
    }

  },
  mounted() {
    // if (this.$route.name==="MyWorkspace"){
    //   this.layout1=false;
    //   this.layout2=true;
    // }else{
    //   this.layout1=true;
    //   this.layout2=false;
    // }


  },
}
</script>
