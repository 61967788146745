<template>
  <footer class="c-footer">
<!--    <div><a href="https://coreui.io">CoreUI</a> © 2020 creativeLabs.</div>-->
<!--    <div class="mfs-auto">Powered by&nbsp;<a href="https://coreui.io/pro/">CoreUI Pro</a></div>-->
  </footer>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
//
export default {
  name: 'COM_Footer',
  // components: {
  //   HelloWorld
  // }
}
</script>
