var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["" + _vm.prefixCls, "is-" + _vm.mode] },
    [
      _c("calendar-header", {
        attrs: {
          mode: _vm.mode,
          "prefix-cls": _vm.prefixCls,
          "first-day": _vm.firstDay,
          "render-header": _vm.renderHeader,
          "header-left": _vm.$slots["header-left"],
          "header-right": _vm.$slots["header-right"],
          "current-date": _vm.formatedDay
        },
        on: { prev: _vm.prev, next: _vm.next }
      }),
      _c(
        "div",
        { class: _vm.prefixCls + "-week" },
        _vm._l(_vm.titleArray, function(item) {
          return _c(
            "div",
            { key: item, class: _vm.prefixCls + "-week__item" },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _c(
        "div",
        { class: _vm.prefixCls + "-body" },
        [
          _vm._t(
            "body",
            [
              _c(
                "div",
                { class: _vm.prefixCls + "-body-grid" },
                _vm._l(_vm.monthData, function(row, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.prefixCls + "-body-row" },
                    [
                      _vm._l(row, function(col) {
                        return [
                          col
                            ? _c(
                                "div",
                                {
                                  key: col.date.full,
                                  class: _vm.prefixCls + "-day-item"
                                },
                                [
                                  _vm._t(
                                    "default",
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(col.date.date))
                                      ])
                                    ],
                                    { date: col }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ],
            { data: _vm.monthData }
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }