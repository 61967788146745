var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    this.timeType === this.$onePageUtil.OnePage_TimeType_Design
      ? _c("div", [
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("影片網址")
            ]),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsWebPart.VideoLink,
                    expression: "propsWebPart.VideoLink"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "url", placeholder: "" },
                domProps: { value: _vm.propsWebPart.VideoLink },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.propsWebPart, "VideoLink", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c(
            "div",
            { staticClass: "form-group row" },
            [
              _c("youtube", {
                attrs: {
                  "video-id": _vm.propsWebPart.VideoId,
                  "player-width": "100%",
                  "max-width": "1140",
                  "player-height": "641"
                }
              })
            ],
            1
          )
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "d-block d-sm-none" },
            [
              _c("youtube", {
                attrs: {
                  "video-id": _vm.propsWebPart.VideoId,
                  "player-width": "100%"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-sm-block" },
            [
              _c("youtube", {
                attrs: {
                  "video-id": _vm.propsWebPart.VideoId,
                  "player-width": "100%",
                  "max-width": "1140",
                  "player-height": "641",
                  width: 1140,
                  height: 500
                }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }