<template>
  <div>

<!--    <MyCalendar startDate="2018-03-07" :dateData="data">-->
<!--      <div slot="header-left">-->
<!--        <Button>month</Button>-->
<!--        <Button>week</Button>-->
<!--      </div>-->

<!--      <template v-slot:body="{ data }">-->
<!--        <transition name="fade">-->
<!--          <div class="calendar-body-grid" :key="indentifier">-->
<!--            <div v-for="(row, index) in data"-->
<!--                 :key="index"-->
<!--                 class="calendar-body-row">-->
<!--              <div v-for="col in row"-->
<!--                   :key="col.date.date"-->
<!--                   :class="['calendar-item', { 'is-otherMonth': !col.isCurMonth }]">-->
<!--                <div-->
<!--                    :class="['calendar-item-date']">-->
<!--                  {{col.date.date}}-->
<!--                </div>-->
<!--                <div class="calendar-item-name">{{col.data.title}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </transition>-->
<!--      </template>-->
<!--    </MyCalendar>-->

      <MyCalendar ref="myCalendar" startDate="2022-11-22" :dateData="data" :mode="thisMode" >
        <div :class="['calendar-item', { 'is-otherMonth': false }]" slot-scope="item">
          <div :class="['calendar-item-date']">
            {{ item.date.date.date }}
          </div>
          <div class="calendar-item-name">
            <div v-if="propsCalendarSetting.Timeperiod==='day'">
              {{initBlock(item,0)}}
              <a role="button" class="btn btn-danger" v-bind:class="{ 'btnSelect': isSelect(item,0)}" @click="btnClickAvalable(item,0)">{{ isSelectText(item,0) }}</a>
            </div>
            <div v-else-if="propsCalendarSetting.Timeperiod==='halfday'">
              <div class="row">
                <div class="col-3">
                  上午
                </div>
                <div class="col-9">
                  {{initBlock(item,0)}}
                  <a role="button" class="btn btn-danger" v-bind:class="{ 'btnSelect': isSelect(item,0)}" @click="btnClickAvalable(item,0)">{{ isSelectText(item,0) }}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  下午
                </div>
                <div class="col-9">
                  {{initBlock(item,1)}}
                  <a role="button" class="btn btn-danger" v-bind:class="{ 'btnSelect': isSelect(item,1)}" @click="btnClickAvalable(item,1)">{{ isSelectText(item,1) }}</a>
                </div>
              </div>
            </div>
            <div v-else-if="propsCalendarSetting.Timeperiod==='hour'">
              <div class="row" v-for="(blockText,index) in getTimeperiodHour" :key="index">
                <div class="col-5">
                  {{blockText}}
                </div>
                <div class="col-7">
                  {{initBlock(item,index)}}
                  <a role="button" class="btn btn-danger" v-bind:class="{ 'btnSelect': isSelect(item,index)}" @click="btnClickAvalable(item,index)">{{ isSelectText(item,index) }}</a>
                </div>
              </div>
            </div>
            <div v-else-if="propsCalendarSetting.Timeperiod==='halfhour'">
              <div class="row" v-for="(blockText,index) in getTimeperiodHalfHour" :key="index">
                <div class="col-5">
                  {{blockText}}
                </div>
                <div class="col-7">
                  {{initBlock(item,index)}}
                  <a role="button" class="btn btn-danger" v-bind:class="{ 'btnSelect': isSelect(item,index)}" @click="btnClickAvalable(item,index)">{{ isSelectText(item,index) }}</a>
                </div>
              </div>
            </div>
            <div v-else>
              {{"case else"}}
            </div>
          </div>
        </div>


      </MyCalendar>

<!--    <pre>-->
<!--      {{ log.length }}-->
<!--      {{log}}-->
<!--    </pre>-->
<!--    <pre>-->
<!--    {{userSelectedBlock}}-->
<!--    </pre>-->
  </div>
</template>

<script>
import MyCalendar from "@/pages/commonComponent/Calendar/calendar";
import {JCalendarBlock, JCalendarBlockButton} from "@/pages/commonComponent/CalendarEx/info/info";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
/**

 **/


export default {
  //name: "MyCalendarAvailableSetting",
  props: {
    CalendarSetting: {
      type: Object,
      required: true,
    },
  },
  components: {
    MyCalendar
  },
  data: function () {
    return {
      /** @type {JCalendarSetting} */
      propsCalendarSetting: this.CalendarSetting,
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      data2:[],
      data: [
        {
          date: `2022-02-16`,
          title: 'buy something'
        },
      ],
      data3:{
        "2022-02-16": "有",
        "2022-02-17": "無",
      },
      mode: "month",
      transitionName: 'slide-left',
      indentifier: '',
      timeperiodHour:[
          '8:00~9:00',
          '9:00~10:00',
          '10:00~11:00',
          '11:00~12:00',
          '12:00~13:00',
          '13:00~14:00',
          '14:00~15:00',
          '15:00~16:00',
          '16:00~17:00',
          '17:00~18:00',
          '18:00~19:00',
          '19:00~20:00',
      ],
      timeperiodHalfHour:[
          '8:30~9:30',
          '9:30~10:00',
          '10:30~11:00',
          '11:00~11:30',
          '11:30~12:00',
          '12:30~13:30',
          '13:30~14:00',
          '14:30~15:30',
          '15:30~16:00',
          '16:30~17:30',
          '17:30~18:00',
          '18:30~19:30',
          '19:30~20:00',
      ],
      /** @type {JCalendarBlockButton[]} */
      log:[],
      /** @type {JCalendarBlock[]} */
      userSelectedBlock:[
      ],
      userBlockList:[],
    };
  },
  computed: {
    thisMode: function () {
      if (this.propsCalendarSetting.Timeperiod==="day"){
        return "month";
      }else{
        return "week";
      }
    },
    getTimeperiodHour: function () {
      console.log("run");
      return this.getTimeBlock(60);
    },
    getTimeperiodHalfHour: function () {
      console.log("run2");
      return this.getTimeBlock(30);
    },
  },
  watch:{
    "thisMode":function (newVal,oldVal) { // eslint-disable-line
      this.log=[];
    },
  },
  methods:{
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    // getTimeperiodHour(){
    //   console.log("run");
    //   return this.getTimeBlock(60);
    // },
    // getTimeperiodHalfHour(){
    //   console.log("run2");
    //   return this.getTimeBlock(30);
    // },

    getTimeBlock(minutes){

      let timeBlock=[];

      const dtStartWorkTime=dayjs(this.propsCalendarSetting.StartWorkTime,"HH:mm")
      const dtEndWorkTime=dayjs(this.propsCalendarSetting.EndWorkTime,"HH:mm")
      const dtRestStartTime=dayjs(this.propsCalendarSetting.RestStartTime,"HH:mm")
      const dtRestEndTime=dayjs(this.propsCalendarSetting.RestEndTime,"HH:mm")

      let timeBlock1=this.getTimeBlockDuration(minutes,dtStartWorkTime,dtRestStartTime)
      let timeBlock2=this.getTimeBlockDuration(minutes,dtRestEndTime,dtEndWorkTime)

      timeBlock=timeBlock.concat(timeBlock1,timeBlock2)

      return timeBlock;
    },
    getTimeBlockDuration(minutes,dtBlockStartTime,dtBlockEndTime){
      let timeBlock=[];

      let dtPreviousBlock=dtBlockStartTime;
      let dtCurrentBlock=null;
      let isContinue=false;
      do{
        dtCurrentBlock=dtPreviousBlock.add(minutes,"m")

        if (dtCurrentBlock.isBefore(dtBlockEndTime) || dtCurrentBlock.isSame(dtBlockEndTime)) {
          isContinue = true;
          timeBlock.push(dtPreviousBlock.format('HH:mm')+"~"+dtCurrentBlock.format('HH:mm'));
          dtPreviousBlock=dtCurrentBlock;
        }else{
          isContinue = false;
        }
      }while(isContinue)

      return timeBlock;
    },
    // isRestTime(dtCheckOrg,dtRestStartTime,dtRestEndTime){
    //   let dtCheck=dtCheckOrg.add(1,"m")
    //   if (dtCheck.isBetween(dtRestStartTime,dtRestEndTime)){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // },
    initBlock(dayItem,blockItemIndex){
      let dayStr=dayItem.date.date.year + "-"+ dayItem.date.date.month + "-"+ dayItem.date.date.date;


      const index = this.userBlockList.findIndex(s => s.date=== dayStr && s.index=== blockItemIndex);

      if (index === -1) {
        this.userBlockList.push({
          date: dayStr,
          index: blockItemIndex,
        });
      }

      // this.log.push("initBlock:"+dayStr + " " +blockItemIndex);
    },
    btnClickAvalable(dayItem,blockItemIndex){
      //item.date.date.date
      let dayStr=dayItem.date.date.year + "-"+ dayItem.date.date.month + "-"+ dayItem.date.date.date;

      let userSelectBlock=this.getDay(dayStr);

      if (this.propsCalendarSetting.Timeperiod==="day"){
        this.ensureBlockItem(userSelectBlock.block_day,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="halfday"){
        this.ensureBlockItem(userSelectBlock.block_halfday,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="hour"){
        this.ensureBlockItem(userSelectBlock.block_hour,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="halfhour"){
        this.ensureBlockItem(userSelectBlock.block_halfhour,blockItemIndex);
      }else{
        console.error("case else:"+this.propsCalendarSetting.Timeperiod);
      }
    },
    isSelectText(dayItem,blockItemIndex){
      let isSelect=this.isSelect(dayItem,blockItemIndex);
      if (isSelect){
        return "可預約";
      }else{
        return "不可預約";
      }
    },
    isSelect(dayItem,blockItemIndex){
      let dayStr=dayItem.date.date.year + "-"+ dayItem.date.date.month + "-"+ dayItem.date.date.date;
      let userSelectBlock=this.getDay(dayStr);

      let value=false;
      if (this.propsCalendarSetting.Timeperiod==="day"){
        value=this.getBlockItem_IsSelected(userSelectBlock.block_day,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="halfday"){
        value=this.getBlockItem_IsSelected(userSelectBlock.block_halfday,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="hour"){
        value=this.getBlockItem_IsSelected(userSelectBlock.block_hour,blockItemIndex);
      }else if (this.propsCalendarSetting.Timeperiod==="halfhour"){
        value=this.getBlockItem_IsSelected(userSelectBlock.block_halfhour,blockItemIndex);
      }else{
        console.error("case else:"+this.propsCalendarSetting.Timeperiod);
      }

      return value;
    },
    getDay(dayStr){
      let item=this.userSelectedBlock.find(k => k.date===dayStr);

      if (item){
        return item;
      }else{
        let newItem= new JCalendarBlock();
        newItem.date=dayStr;

        this.userSelectedBlock.push(newItem);

        return newItem;
      }
    },
    getBlockItem_IsSelected(blockItems, blockItemIndex){ // eslint-disable-line
      let item=blockItems.find(k => k.index===blockItemIndex);

      if (item){
        return item.isSelected;
      }else{
        return false;
      }
    },
    ensureBlockItem(blockItems, blockItemIndex){
      let item=blockItems.find(k => k.index===blockItemIndex);

      if (item){
        item.isSelected=!item.isSelected;
      }else{
        let optionItem=new JCalendarBlockButton();
        optionItem.index=blockItemIndex;
        optionItem.isSelected=true;//預設為false,改為true

        blockItems.push (optionItem);
      }
    },
    ensureBlockItem_Select(blockItems, blockItemIndex,selectStatus){
      let item=blockItems.find(k => k.index===blockItemIndex);

      if (item){
        item.isSelected=selectStatus;
      }else{
        let optionItem=new JCalendarBlockButton();
        optionItem.index=blockItemIndex;
        optionItem.isSelected=selectStatus;

        blockItems.push (optionItem);
      }
    },
    selectAction_AllToggle(selected){
      this.ensureAllBlockExist();
      this.userSelectedBlock.forEach(k => {
        if (this.propsCalendarSetting.Timeperiod==="day"){
          this.ensureBlockItem_Select(k.block_day,0,selected);
        }else if (this.propsCalendarSetting.Timeperiod==="halfday"){
          this.ensureBlockItem_Select(k.block_halfday,0,selected);
          this.ensureBlockItem_Select(k.block_halfday,1,selected);
        }else if (this.propsCalendarSetting.Timeperiod==="hour"){
          for (let i = 0; i < this.getTimeperiodHour.length; i++) {
            this.ensureBlockItem_Select(k.block_hour,i,selected);
          }
        }else if (this.propsCalendarSetting.Timeperiod==="halfhour"){
          for (let i = 0; i < this.getTimeperiodHalfHour.length; i++) {
            this.ensureBlockItem_Select(k.block_halfhour,i,selected);
          }
        }else{
          console.error("case else:"+this.propsCalendarSetting.Timeperiod);
        }
      });
    },
    selectAction_WeekIndexToggle(dayIndex,selected){
      this.ensureAllBlockExist();
      this.userSelectedBlock.forEach(k => {
        if (this.propsCalendarSetting.Timeperiod==="day"){
          this.dateByWeek(k.date,k.block_day,dayIndex,0,selected);
        }else if (this.propsCalendarSetting.Timeperiod==="halfday"){
          this.dateByWeek(k.date,k.block_halfday,dayIndex,0,selected);
          this.dateByWeek(k.date,k.block_halfday,dayIndex,1,selected);
        }else if (this.propsCalendarSetting.Timeperiod==="hour"){
          for (let i = 0; i < this.getTimeperiodHour.length; i++) {
            this.dateByWeek(k.date,k.block_hour,dayIndex,i,selected);
          }
        }else if (this.propsCalendarSetting.Timeperiod==="halfhour"){
          for (let i = 0; i < this.getTimeperiodHalfHour.length; i++) {
            this.dateByWeek(k.date, k.block_halfhour, dayIndex, i, selected);
          }
        }else{
          console.error("case else:"+this.propsCalendarSetting.Timeperiod);
        }
      });
    },
    selectAction_Spec(weekIndexListSelected,weekIndexListUnSelected){
      this.ensureAllBlockExist();
      this.userSelectedBlock.forEach(k => {
        if (this.propsCalendarSetting.Timeperiod==="day"){
          this.dateBySpec(k.date,k.block_day,0,weekIndexListSelected,weekIndexListUnSelected);
        }else if (this.propsCalendarSetting.Timeperiod==="halfday"){
          this.dateBySpec(k.date,k.block_halfday,0,weekIndexListSelected,weekIndexListUnSelected);
          this.dateBySpec(k.date,k.block_halfday,1,weekIndexListSelected,weekIndexListUnSelected);
        }else if (this.propsCalendarSetting.Timeperiod==="hour"){
          for (let i = 0; i < this.getTimeperiodHour.length; i++) {
            this.dateBySpec(k.date, k.block_hour, i, weekIndexListSelected, weekIndexListUnSelected);
          }
        }else if (this.propsCalendarSetting.Timeperiod==="halfhour"){
          for (let i = 0; i < this.getTimeperiodHalfHour.length; i++) {
            this.dateBySpec(k.date, k.block_halfhour, i, weekIndexListSelected, weekIndexListUnSelected);
          }
        }else{
          console.error("case else:"+this.propsCalendarSetting.Timeperiod);
        }
      });
    },
    dateByWeek(date,blockItems,dayIndex,blockItemIndex,selected){
      if (dayjs(date).day()===dayIndex) {
        this.ensureBlockItem_Select(blockItems, blockItemIndex, selected);
      }
    },
    dateBySpec(date,blockItems,blockItemIndex,weekIndexListSelected,weekIndexListUnSelected){
      if (weekIndexListSelected.includes(dayjs(date).day())) {
        this.ensureBlockItem_Select(blockItems, blockItemIndex, true);
      }

      if (weekIndexListUnSelected.includes(dayjs(date).day())) {
        this.ensureBlockItem_Select(blockItems, blockItemIndex, false);
      }
    },
    ensureAllBlockExist(){
      this.userBlockList.forEach(k => {
        let item=this.userSelectedBlock.find(kk => kk.date===k.date);

        if (!item){
          this.userSelectedBlock.push (new JCalendarBlock());
        }
      });
    },
  },
  mounted() {
    dayjs.extend(customParseFormat);
    dayjs.extend(isBetween);
    this.loadData()

  },
}
</script>

<style scoped>
.btn{
  color: white !important;
}
.btnSelect{
  background-color: #28a745;
}
</style>
