var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.propsValue,
          expression: "propsValue"
        }
      ],
      attrs: { type: "text", className: "form-control", placeholder: "" },
      domProps: { value: _vm.propsValue },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.propsValue = $event.target.value
        }
      }
    }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "btn-toolbar",
        attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
      },
      [
        _c(
          "div",
          {
            staticClass: "btn-group mr-2",
            attrs: { role: "group", "aria-label": "First group" }
          },
          [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "button" } },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v(" 儲存")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "button" } },
              [_c("i", { staticClass: "fa fa-clone" }), _vm._v(" 複製")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "button" } },
              [
                _c("i", { staticClass: "fa fa-arrow-left" }),
                _vm._v(" 回上一頁")
              ]
            ),
            _c(
              "button",
              { staticClass: "btn btn-danger", attrs: { type: "button" } },
              [_c("i", { staticClass: "fa fa-times-circle" }), _vm._v(" 關閉")]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }