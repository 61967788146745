import OnePagePreview from "@/pages/featureOnePage/pages/OnePagePreview.vue";

//加入到router.index.js


//-----------使用Feature OnePage需要設定的Route----------------------------
// import routeOnePage from "@/pages/featureOnePage/router/routeOnePage";
//---------------------------------------
//...routeOnePage,


export default [
    {
        path: '/OnePagePreview',
        name: 'OnePagePreview',
        component: OnePagePreview,
        meta: {
            requiresAuth: true,
            roles: ['USER']
        }
    },
]
