import { render, staticRenderFns } from "./MyLiteFormRuntime.vue?vue&type=template&id=b4d2a8ae&scoped=true&"
import script from "./MyLiteFormRuntime.vue?vue&type=script&lang=js&"
export * from "./MyLiteFormRuntime.vue?vue&type=script&lang=js&"
import style0 from "./MyLiteFormRuntime.vue?vue&type=style&index=0&id=b4d2a8ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4d2a8ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\UBoss\\Source\\Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4d2a8ae')) {
      api.createRecord('b4d2a8ae', component.options)
    } else {
      api.reload('b4d2a8ae', component.options)
    }
    module.hot.accept("./MyLiteFormRuntime.vue?vue&type=template&id=b4d2a8ae&scoped=true&", function () {
      api.rerender('b4d2a8ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/commonComponent/MyLiteFormRuntime.vue"
export default component.exports