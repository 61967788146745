var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c(_vm.componentType, { tag: "component", attrs: { field: _vm.field } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }