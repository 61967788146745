<template>
  <div>
    <COM_HeaderNav mode="UDMPassCodeIndex"></COM_HeaderNav>


    <MyToolbarVuetify :config="toolbarVuetifyConfig" :grid-meta="gridMeta" v-on:add-click="btnAdd_Click" v-on:edit-click="btnEdit_Click" v-on:delete-click="btnDelete_Click" ></MyToolbarVuetify>


      Ststus (紅/黃色 為特殊色，其他色可以視狀況使用)
      <span class="badge bg-primary">常</span>  常態使用
      <span class="badge bg-success">單</span> 單一使用




      <MyGridVuetify ref="myGridVuetify" :config="gridVuetifyConfig"  :grid-meta="gridMeta">
<!--      <template v-slot:Fun="{ item }">-->
<!--        <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i></a>-->
<!--        <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="fa fa-trash"></i></a>-->
<!--        <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>-->
<!--        <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>-->
<!--      </template>-->

      <template v-slot:type="{item}">
        <p v-if="item.type==='1'" >常態使用</p>
        <p v-if="item.type==='2'" >單一使用</p>
      </template>
    </MyGridVuetify>


<!--    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>-->
<!--    <table class="table table-hover" id="tblSecond">-->
<!--      <thead class="thead-light">-->
<!--      <tr>-->
<!--        <th class="text-center">功能</th>-->
<!--        <th>類型</th>-->
<!--        <th>使用者</th>-->
<!--        <th>Pass Code</th>-->
<!--        <th>有效日期</th>-->
<!--        <th>可以使用的UDM</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(item,index) in this.datas.Details" :key="index">-->
<!--        <td class="" style="width: 200px">-->

<!--          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="fa fa-trash"></i></a>-->
<!--          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>-->
<!--          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>-->
<!--        </td>-->
<!--        <td width="150px">-->
<!--&lt;!&ndash;          <input type="text" class="form-control" placeholder="" v-model="item.userName">&ndash;&gt;-->
<!--          <div class="form-group">-->
<!--            <label class="custom-control custom-checkbox">-->
<!--              <input type="checkbox" class="custom-control-input" checked="">-->
<!--              <div class="custom-control-label"> </div>-->
<!--            </label>-->
<!--          </div>-->
<!--        </td>-->
<!--        <td width="300px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.userName">-->
<!--        </td>-->
<!--        <td width="200px">-->
<!--          <input type="text" class="form-control" placeholder="" v-model="item.passCode">-->
<!--          <a role="button" class="btn btn-primary" href="#">產生</a>-->
<!--        </td>-->
<!--        <td width="120px">-->
<!--          <input type="date" class="form-control" placeholder="" v-model="item.expireDt">-->
<!--        </td>-->
<!--        <td width="300px">-->
<!--&lt;!&ndash;          <input type="text" class="form-control" placeholder="" v-model="item.expireDt">&ndash;&gt;-->
<!--          <a class="btn btn-primary" @click="DataItem_Edit(item)"><i class="fa fa-pen"></i>選擇UDM</a>-->
<!--        </td>-->

<!--      </tr>-->

<!--      </tbody>-->
<!--    </table>-->


  </div>
</template>

<script>
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiUBossOnePageBk from "@/pages/ubossBk/util/apiUBossOnePageBk";
import COM_HeaderNav from "@/pages/ubossBk/views/components/COM_HeaderNav";
import MyGridVuetify from "@/pages/commonComponent/MyGridVuetify.vue";
import MyToolbarVuetify from "@/pages/commonComponent/MyToolbarVuetify.vue";

let configEditPage="UDMPassCodeEdit";

export default {
  components: {MyToolbarVuetify, MyGridVuetify, COM_HeaderNav},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      gridVuetifyConfig:{
        items_per_page:30,
        itemKey:"SystemId",
        headers: [
          {
            text: '功能',
            align: 'start',
            sortable: false,
            value: 'Fun',
          },
          { text: '類型', value: 'type' },
          { text: '使用者', value: 'userName' },
          { text: 'Pass Code', value: 'passCode' },
          { text: '生效日期', value: 'startDt' },
          { text: '失效日期', value: 'expireDt' },
          { text: '可以使用的UDM', value: 'resourceUDM' },
        ],
        datas: [
          {
            Fun:"",
            SystemId:"1",
            type:"1",
            userName:"taylor",
            passCode:"zade12aa",
            startDt:"2023-01-01",
            expireDt:"2023-06-30",
            resourceUDM:['全部'],
          },
          {
            Fun:"",
            SystemId:"2",
            type:"2",
            userName:"給IBM使用",
            passCode:"e1291aac",
            startDt:"2023-01-01",
            expireDt:"2023-06-30",
            resourceUDM:['產品一'],
          },
          {
            Fun:"",
            SystemId:"3",
            type:"1",
            userName:"john",
            passCode:"ppe11aa2",
            startDt:"2023-01-01",
            expireDt:"2023-06-30",
            resourceUDM:['全部'],
          },
        ],
      },
      datas:{
        Details:[
          {
            userName:"給taylor使用",
            passCode:"zade12aa",
            expireDt:"2023-06-30",
            resourceUDM:[],
          },
          {
            userName:"給IBM使用",
            passCode:"e1291aac",
            expireDt:"2023-06-30",
            resourceUDM:[],
          },
          {
            userName:"john",
            passCode:"ppe11aa2",
            expireDt:"2023-06-30",
            resourceUDM:[],
          },
        ]
      },
      ready:false,
      toolbarVuetifyConfig:{
        Add:{
          Enable:true,
          CallBack:"",
        },
        Edit:{
          Enable:true,
          CallBack:"",
        },
        Delete:{
          Enable:true,
          CallBack:"",
        },
        Copy:{
          Enable:true,
          CallBack:"",
        },
        Sort:{
          Enable:false,
        },
      },

      gridMeta:{},
    };
  },
  computed:{
    ModeText(){
      return this.datas.UseCNameFeature ? "不使用CName 來區別頁面":"使用CName 來區別頁面";
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUBossOnePageBk.api_Get_OnePageAdv()

      // this.datas=data
      // this.ready=true;
    },
    submit(){
      for (let i = 0; i < this.datas.Details.length; i++) {
        let item = this.datas.Details[i];

        if (item.Name===""){
          util.showErrorMessage("第"+i+1+" 行，「名稱」為必填欄位")
          return;
        }
      }

      apiUBossOnePageBk.api_Save_OnePageAdv(this.datas.SystemId,this.datas).then(()=>{
        util.ShowMessage('儲存成功')
      })
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:util.uuid(),
        Name:"",
        IsVisible:true,
        IsEnable:true,
        Memo:"",
        OrderKey:"0",
      }

      arrayUtil.orderAppendLast(newItem,this.datas.Details,"OrderKey")
    },
    DataItem_Edit(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas.Details,"SystemId","OrderKey")
    },

    DataItem_Remove(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    },
    switchMode(){
      this.datas.UseCNameFeature=!this.datas.UseCNameFeature;
    },

    btnAdd_Click(){
      this.$router.push({ name: configEditPage, query: { SystemId: "" }})
    },
    btnEdit_Click(item){
      this.$router.push({ name: configEditPage, query: { systemId: item.SystemId }})
    },
    btnDelete_Click(item){
      if (!window.confirm("確定刪除?")) {
        return
      }
      this.datas.Details= this.datas.Details.filter(s => s.SystemId !==item.SystemId);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
  #tblMain{
    width: 800px;
  }
  #tblSecond{
    width: 1000px;
  }
  .btn{
    color: white !important;
    margin: 5px;
    /*width: 40px;*/
  }
</style>
