<template>
  <div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <!--            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>-->
            <div class="col-lg-3">
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
<!--                  //QQUDM-->
                  <h1 class="h4 text-gray-900 mb-4">UBoss UDM</h1>
                </div>
                <ValidationObserver ref="form">
                  <form class="user">
                    <div class="form-group">
                      <validation-provider rules="required" v-slot="{ errors }"  name="Pass Code" >
                        <input name="" class="form-control form-control-user" placeholder="Pass Code" type="text" v-model="formData.LoginId">
                        <span class="error_msg">{{ errors[0] }}</span>
                      </validation-provider>

                    </div>
<!--                    <div class="form-group">-->
<!--                      <validation-provider rules="required" v-slot="{ errors }"  name="密碼" >-->
<!--                        <input name="" class="form-control form-control-user" placeholder="密碼" type="password" v-model="formData.Pwd">-->
<!--                        <span class="error_msg">{{ errors[0] }}</span>-->
<!--                      </validation-provider>-->
<!--                    </div>-->
                    <!--                  <div class="form-group">-->
                    <!--                    <div class="custom-control custom-checkbox small">-->
                    <!--                      <input type="checkbox" class="custom-control-input" id="customCheck">-->
                    <!--                      <label class="custom-control-label" for="customCheck">Remember Me</label>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                    <a type="button" @click="submit" class="btn btn-primary btn-user btn-block abutton">
                      登入
                    </a>
                    <hr>
                    <!--                  <a href="index.html" class="btn btn-google btn-user btn-block">-->
                    <!--                    <i class="fab fa-google fa-fw"></i> Login with Google-->
                    <!--                  </a>-->
                    <!--                  <a href="index.html" class="btn btn-facebook btn-user btn-block">-->
                    <!--                    <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook-->
                    <!--                  </a>-->
                  </form>
                </ValidationObserver>
                <hr>
                <!--                <div class="text-center">-->
                <!--                  <a class="small" href="forgot-password.html">Forgot Password?</a>-->
                <!--                </div>-->
                <!--                <div class="text-center">-->
                <!--                  <a class="small" href="register.html">Create an Account!</a>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="col-lg-3">
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import util from "@/pages/common/util";
import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import veeHelper from "@/pages/common/veeHelper";
import ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default {
  name: "Login",
  data: function() {
    return {
      formData: {
        LoginId:"",
        Pwd:"",
      },
      Title:ubossCommon.BizOrFormTitle(),
    }
  },
  methods:{
    submit(){


      // this.formData.LoginId=this.formData.LoginId.toUpperCase()
      // this.formData.Pwd=this.formData.Pwd.toUpperCase()
      // let user=dec.UserDB.filter(s=>s.LoginId.toUpperCase()===this.formData.LoginId && s.Pwd.toUpperCase()===this.formData.Pwd);
      //
      // if (user.length>0){
      //   apiUBossBk.adminAfterLogin(this,this.formData.LoginId)
      //   this.$router.push({ name: 'Index', query: {  }})
      //   location.reload();//QQ
      // }else{
      //   util.ShowMessage("不正確的帳號或密碼")
      // }

      this.$refs.form.validateWithInfo().then(({ isValid, errors }) => {
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(errors);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          apiUBossBk.api_User_Login(this.formData.LoginId,this.formData.Pwd).then((data)=>{
            if (data.IsSuccess){
              ubossCommon.BkAfterLogin(this,data)

              if (ubossCommon.IsFormMode()){
                this.$router.push({ name: 'UFormIndex'})
              }else{
                this.$router.push({ name: '/'})
              }
              location.reload();//QQ
            }else{
              util.ShowMessage(data.ErrorMsg,"訊息")
            }
          })
          //---------------------------------------
        }
      });
    }
  },
  created() {
    ubossCommon.BkLogout(this)
  }
}
</script>

<style scoped>

</style>
