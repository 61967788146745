import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossFormBkApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default {

    //---------------axios------------------------
    getAxiosManager() {
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------user------------------------

    //-------------Form--------------------------
    getApiManager_Form() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"Form", this.getAxiosManager(),null)
    },

    async api_Form_ListQuery ( queryObject)
    {
        return this.getApiManager_Form().api_ListQuery(queryObject);
    },

    async api_Form_Get ( systemId)
    {
        return this.getApiManager_Form().api_Get(systemId);
    },

    async api_Form_Save ( record)
    {
        return this.getApiManager_Form().api_Save(record);
    },

    async api_Form_Remove ( systemId)
    {
        return this.getApiManager_Form().api_Remove(systemId);
    },

    //=============
    async api_Form_GridInfo_ListQuery ( queryObject)
    {
        return this.getApiManager_Form().api_CallMethod_SendObj("Form_GridInfo_ListQuery",queryObject);
    },

    async api_Form_SaveInit ( sFormTitle)
    {
        var sendData={
            sFormTitle:sFormTitle,
        };

        return this.getApiManager_Form().api_CallMethod_ByParamObj("Form_SaveInit",sendData)
    },
    //-------------FormData--------------------------
    getApiManager_FormData() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"FormData", this.getAxiosManager(),null)
    },

    async api_FormData_ListQuery ( queryObject)
    {
        return this.getApiManager_FormData().api_ListQuery(queryObject);
    },

    async api_FormData_Get ( systemId)
    {
        return this.getApiManager_FormData().api_Get(systemId);
    },

    async api_FormData_Save ( record)
    {
        return this.getApiManager_FormData().api_Save(record);
    },

    async api_FormData_Remove ( systemId)
    {
        return this.getApiManager_FormData().api_Remove(systemId);
    },

    //=============
    async api_FormData_Query ( sRelFormSystemId)
    {
        var sendData={
            sRelFormSystemId:sRelFormSystemId
        };

        return this.getApiManager_FormData().api_CallMethod_SendObj("FormData_Query",sendData);
    },

    async api_FormDataFromForm ( sFormSystemId)
    {
        var sendData={
            sFormSystemId:sFormSystemId,
        };
        return this.getApiManager_FormData().api_CallMethod_ByParamObj("FormDataFromForm",sendData);
    },

    //---------------------------------------

}
