var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("COM_HeaderNav", { attrs: { mode: "udmIndex" } }),
      _c("MyToolbarBase", [
        _c("button", { staticClass: "btn btn-primary", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fa fa-clone" }),
          _vm._v(" 複製")
        ]),
        _c(
          "button",
          {
            staticClass: "btn btn-info",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("回上一頁")]
        )
      ]),
      _c("div", { staticClass: "fieldsPanel" }),
      _c("OnePageMain", {
        attrs: {
          "design-time": true,
          "one-page-id": "69ceccd8-008b-4d2a-8ba1-ec0cc2099bd2",
          "layout-type": "2"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }