var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossBkApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {

    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"User", this.getAxiosManager(),null)
    },

    //---------------user------------------------

    async api_SendConfirmCode ( sClientKey, sTel)
    {
        var sendData={
            sClientKey:sClientKey,
            sTel:sTel,
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("SendConfirmCode",sendData);
    },

    async api_UserReg_Check_ConfirmCode ( sClientKey, sConfirmCode)
    {
        var sendData={
            sClientKey:sClientKey,
            sConfirmCode:sConfirmCode,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("UserReg_Check_ConfirmCode",sendData);
    },

    async api_UserReg_Check_LoginId ( sLoginId)
    {
        var sendData={
            sLoginId:sLoginId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("UserReg_Check_LoginId",sendData);
    },

    async api_User_Reg ( memberRecordWebInput)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("User_Reg",memberRecordWebInput);
    },

    async api_User_Login ( sLoginId, sPwd)
    {
        var sendData={
            sLoginId:sLoginId,
            sPwd:sPwd,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("User_Login",sendData);
    },

    async api_User_ResetPwd (sLoginId )
    {
        var sendData={
            sLoginId:sLoginId
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("User_ResetPwd",sendData);
    },

    async api_User_ChangePwd ( sCurrentPwd, sNewPwd)
    {
        var sendData={
            sCurrentPwd:sCurrentPwd,
            sNewPwd:sNewPwd,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("User_ChangePwd",sendData);
    },

    async api_UserBasicInfoGet ()
    {
        return this.getApiManager_General().api_CallMethod("UserBasicInfoGet");
    },

    async api_UserBasicInfoSave ( UserRecordBasicInfo)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("UserBasicInfoSave",UserRecordBasicInfo);
    },


    //---------------OnePage------------------------
    async api_CustomerSetting ()
    {
        return this.getApiManager_General().api_CallMethod("CustomerSetting")
    },

    //---------------------------------------
    getApiManager_MarketingQRTicketType() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"MarketingQRTicketType", this.getAxiosManager(),null)
    },

    async api_MarketingQRTicketType_ListQuery ( queryObject)
    {
        return this.getApiManager_MarketingQRTicketType().api_ListQuery(queryObject);
    },

    async api_MarketingQRTicketType_Get ( systemId)
    {
        return this.getApiManager_MarketingQRTicketType().api_Get(systemId);
    },

    async api_MarketingQRTicketType_Save ( record)
    {
        return this.getApiManager_MarketingQRTicketType().api_Save(record);
    },

    async api_MarketingQRTicketType_Remove ( systemId)
    {
        return this.getApiManager_MarketingQRTicketType().api_Remove(systemId);
    },

    //---------------------------------------
    async api_DownloadQR_Pdf(systemId) {
        var apiPath=apiPathBase +"DownloadQR_Pdf?systemId=" + systemId;

        window.open(apiUrl+apiPath,"_blank")
    },
    async api_DownloadQR_Zip(systemId) {
        var apiPath=apiPathBase +"DownloadQR_Zip?systemId=" + systemId;

        window.open(apiUrl+apiPath,"_blank")
    },
    async DownloadQR_Txt(systemId) {
        var apiPath=apiPathBase +"DownloadQR_Txt?systemId=" + systemId;

        window.open(apiUrl+apiPath,"_blank")
    },
    //---------------------------------------

    //---------------------------------------
    getApiManager_TicketType() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"TicketType", this.getAxiosManager(),null)
    },

    async api_TicketType_ListQuery ( queryObject)
    {
        return this.getApiManager_TicketType().api_ListQuery(queryObject);
    },

    async api_TicketType_Get ( systemId)
    {
        return this.getApiManager_TicketType().api_Get(systemId);
    },

    async api_TicketType_Save ( record)
    {
        return this.getApiManager_TicketType().api_Save(record);
    },

    async api_TicketType_Remove ( systemId)
    {
        return this.getApiManager_TicketType().api_Remove(systemId);
    },

    async api_TicketType_RemoveMulti ( systemId)
    {
        return this.getApiManager_TicketType().api_RemoveMulti(systemId);
    },

    //---------------------------------------
    getApiManager_TicketPublish() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"TicketPublish", this.getAxiosManager(),null)
    },

    async api_TicketPublish_ListQuery ( queryObject)
    {
        return this.getApiManager_TicketPublish().api_ListQuery(queryObject);
    },

    async api_TicketPublish_Get ( systemId)
    {
        return this.getApiManager_TicketPublish().api_Get(systemId);
    },

    async api_TicketPublish_Save ( record)
    {
        return this.getApiManager_TicketPublish().api_Save(record);
    },

    async api_TicketPublish_Remove ( systemId)
    {
        return this.getApiManager_TicketPublish().api_Remove(systemId);
    },

    async api_TicketPublish_RemoveMulti ( systemId)
    {
        return this.getApiManager_TicketPublish().api_RemoveMulti(systemId);
    },

    //---------------
    async api_TicketPublish_SaveTicketPublish ( record)
    {
        return await this.getApiManager_TicketPublish().api_CallMethod_ByParamObj("TicketPublish_SaveTicketPublish",record);
        // debugger // eslint-disable-line
        // if (result.DownloadFile !==undefined && result.DownloadFile !==""){
        //     let fileUrl=apiPathBase+"Common/DownloadFile?sFile=" + result.DownloadFile;
        //     window.open(apiUrl+fileUrl,"_blank");
        // }
    },

    async api_TicketPublish_DownloadTicketQRCode ( sTicketPublishSystemId)
    {
        let record={
            sTicketPublishSystemId:sTicketPublishSystemId
        };
        let result= await this.getApiManager_TicketPublish().api_CallMethod_ByParamObj("TicketPublish_DownloadTicketQRCode",record);

        if (result.DownloadFile !==undefined && result.DownloadFile !==""){
            let fileUrl="Common/DownloadFile?sFile=" + result.DownloadFile;
            window.open(apiUrl+fileUrl,"_blank");
        }
    },

    async api_TicketPublish_DownloadTicketPDF ( sTicketPublishSystemId,sTicketDesignSystemId)
    {
        let record={
            sTicketPublishSystemId:sTicketPublishSystemId,
            sTicketDesignSystemId:sTicketDesignSystemId,
        };
        let result= await this.getApiManager_TicketPublish().api_CallMethod_ByParamObj("TicketPublish_DownloadTicketPDF",record);
        if (result.DownloadFile !==undefined && result.DownloadFile !==""){
            let fileUrl="Common/DownloadFile?sFile=" + result.DownloadFile;
            window.open(apiUrl+fileUrl,"_blank");
        }
    },

    // async api_TicketPublish_DownloadTicketPublish(sTicketPublishSystemId) {
    //     var apiPath=apiPathBase +"TicketPublish_DownloadTicketPublish?sTicketPublishSystemId=" + sTicketPublishSystemId;
    //
    //     window.open(apiUrl+apiPath,"_blank")
    // },
    //---------------------------------------
    getApiManager_Ticket() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"Ticket", this.getAxiosManager(),null)
    },

    async api_Ticket_ListQuery ( queryObject)
    {
        return this.getApiManager_Ticket().api_ListQuery(queryObject);
    },

    async api_Ticket_Get ( systemId)
    {
        return this.getApiManager_Ticket().api_Get(systemId);
    },

    async api_Ticket_Save ( record)
    {
        return this.getApiManager_Ticket().api_Save(record);
    },

    async api_Ticket_Remove ( systemId)
    {
        return this.getApiManager_Ticket().api_Remove(systemId);
    },

    async api_Ticket_RemoveMulti ( systemId)
    {
        return this.getApiManager_Ticket().api_RemoveMulti(systemId);
    },

    async api_Ticket_UpdateStatus ( systemIds,sStatus)
    {
        var sendData={
            systemIds:systemIds,
            tag1:sStatus,
        };

        return this.getApiManager_Ticket().api_CallMethod_SendObj("Ticket_UpdateStatus",sendData);
    },

    async api_Ticket_UpdateStatusByPublishId (sPublishId, sStatus)
    {
        var sendData={
            sPublishId:sPublishId,
            sStatus:sStatus,
        };

        return this.getApiManager_Ticket().api_CallMethod_ByParamObj("Ticket_UpdateStatusByPublishId",sendData);

    },

    //---------------------------------------
    getApiManager_TicketDesign() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"TicketDesign", this.getAxiosManager(),null)
    },

    async api_TicketDesign_ListQuery ( queryObject)
    {
        return this.getApiManager_TicketDesign().api_ListQuery(queryObject);
    },

    async api_TicketDesign_Get ( systemId)
    {
        return this.getApiManager_TicketDesign().api_Get(systemId);
    },

    async api_TicketDesign_Save ( record)
    {
        return this.getApiManager_TicketDesign().api_Save(record);
    },

    async api_TicketDesign_Remove ( systemId)
    {
        return this.getApiManager_TicketDesign().api_Remove(systemId);
    },

    async api_TicketDesign_RemoveMulti ( systemId)
    {
        return this.getApiManager_TicketDesign().api_RemoveMulti(systemId);
    },

}
